import * as Yup from "yup"
export const defaultCreateForm = [
  {
    label: "Full Name",
    defaultValue: "Arpit Kalra",
    inputType: "text",
    value: "",
    identifer: "name",
    validationSchema: Yup.string()
      .required(`Full Name is required`)
      .matches(/^[aA-zZ\s]+$/, `Only alphabets are allowed`),
  },
  {
    label: "Address Line 1",
    defaultValue: "Arpit Kalra",
    inputType: "text",
    value: "",
    identifer: "line1",
    validationSchema: Yup.string(),
    //.required(`Address Line 1 is required`),
  },
  {
    label: "Address Line 2",
    defaultValue: "",
    value: "",
    inputType: "text",
    identifer: "line2",
    validationSchema: Yup.string(),
    //.required(`Address Line 2 is required`),
  },
  {
    label: "Address Line 3",
    defaultValue: "",
    value: "",
    inputType: "text",
    identifer: "line3",
    validationSchema: Yup.string(),
    //.required(`Address Line 3 is required`),
  },
  {
    label: "Address Line 4",
    defaultValue: "",
    value: "",
    inputType: "text",
    identifer: "line4",
    validationSchema: Yup.string(),
    //.required(`Address Line 4 is required`),
  },
  {
    label: "Country",
    value: "",
    defaultValue: "India",
    list: ["India", "Pakistan"],
    inputType: "dropdown",
    identifer: "country",
  },
  {
    label: "State",
    value: "",
    defaultValue: "Rajasthan",
    list: ["Rajasthan", "Karnataka"],
    inputType: "dropdown",
    identifer: "state",
  },
  {
    label: "City",
    defaultValue: "Jaipur",
    list: ["Jaipur", "Bangalore"],
    value: "",
    inputType: "dropdown",
    identifer: "city",
  },
  {
    label: "Zip Code 5",
    defaultValue: "332001",
    inputType: "number",
    identifer: "zip_code_5",
    validationSchema: Yup.string().required(`Zip Code 5 is required`),
  },
  {
    label: "Billing",
    value: true,
    defaultChecked: true,
    inputType: "checkBox",
    identifer: "billing",
  },
  {
    label: "Shipping",
    value: true,
    defaultChecked: true,
    inputType: "checkBox",
    identifer: "shipping",
  },
]

export const defaultEditForm = [
  {
    label: "Full Name",
    defaultValue: "Test Name",
    inputType: "text",
    identifer: "name",
    validationSchema: Yup.string()
      .required(`Full Name is required`)
      .matches(/^[aA-zZ\s]+$/, `Only alphabets are allowed`),
  },
  {
    label: "Address Line 1",
    defaultValue: "Test Name",
    inputType: "text",
    identifer: "line1",
    validationSchema: Yup.string(),
    //.required(`Address Line 1 is required`),
  },
  {
    label: "Address Line 2",
    defaultValue: "Address Line 2",
    inputType: "text",
    identifer: "line2",
    validationSchema: Yup.string(),
    //.required(`Address Line 2 is required`),
  },
  {
    label: "Address Line 3",
    defaultValue: "Address Line 3",
    inputType: "text",
    identifer: "line3",
    validationSchema: Yup.string(),
    //.required(`Address Line 3 is required`),
  },
  {
    label: "Address Line 4",
    defaultValue: "Address Line 4",
    inputType: "text",
    identifer: "line4",
    validationSchema: Yup.string(),
    //.required(`Address Line 4 is required`),
  },
  {
    label: "Country",
    defaultValue: "India",
    list: ["India", "Pakistan"],
    inputType: "dropdown",
    identifer: "country",
  },
  {
    label: "State",
    defaultValue: "Rajasthan",
    list: ["Rajasthan", "Karnataka"],
    inputType: "dropdown",
    identifer: "state",
  },
  {
    label: "City",
    defaultValue: "Jaipur",
    list: ["Jaipur", "Bangalore"],
    inputType: "dropdown",
    identifer: "city",
  },
  {
    label: "Zip Code 5",
    defaultValue: "332001",
    inputType: "number",
    identifer: "zip_code_5",
    validationSchema: Yup.string().required(`Zip Code 5 is required`),
  },
  {
    label: "Billing",
    defaultChecked: false,
    inputType: "checkBox",
    identifer: "billing",
  },
  {
    label: "Shipping",
    defaultChecked: false,
    inputType: "checkBox",
    identifer: "shipping",
  },
]

export const editListForm = ({
  name = "",
  line1 = "",
  line2 = "",
  line3 = "",
  line4 = "",
  country = "",
  state = "",
  city = "",
  zip_code_5 = "",
  zip_code_4 = "",
  billing = false,
  shipping = false,
}) => [
    {
      label: "Full Name",
      defaultValue: name,
      inputType: "text",
      identifer: "name",
      validationSchema: Yup.string()
        .required(`Full Name is required`)
        .matches(/^[aA-zZ\s]+$/, `Only alphabets are allowed`),
    },
    {
      label: "Address Line 1",
      defaultValue: line1,
      inputType: "text",
      identifer: "line1",
      validationSchema: Yup.string(),
      //.required(`Address Line 1 is required`),
    },
    {
      label: "Address Line 2",
      defaultValue: line2,
      inputType: "text",
      identifer: "line2",
      validationSchema: Yup.string(),
      //.required(`Address Line 2 is required`),
    },
    {
      label: "Address Line 3",
      defaultValue: line3,
      inputType: "text",
      identifer: "line3",
      validationSchema: Yup.string(),
      //.required(`Address Line 3 is required`),
    },
    {
      label: "Address Line 4",
      defaultValue: line4,
      inputType: "text",
      identifer: "line4",
      validationSchema: Yup.string(),
      //.required(`Address Line 4 is required`),
    },
    {
      label: "Country",
      defaultValue: country,
      list: ["India", "Pakistan"],
      inputType: "dropdown",
      identifer: "country",
    },
    {
      label: "State",
      defaultValue: state,
      list: ["Rajasthan", "Karnataka"],
      inputType: "dropdown",
      identifer: "state",
    },
    {
      label: "City",
      defaultValue: city,
      list: ["Jaipur", "Bangalore"],
      inputType: "dropdown",
      identifer: "city",
    },
    {
      label: "Zip Code 5",
      defaultValue: zip_code_5,
      inputType: "number",
      identifer: "zip_code_5",
      validationSchema: Yup.string().required(`Zip Code 5 is required`),
    },
    {
      label: "Zip Code 4",
      defaultValue: zip_code_4,
      inputType: "number",
      identifer: "zip_code_4",
      validationSchema: Yup.string().required(`Zip Code 4 is required`),
    },
    {
      label: "Billing",
      defaultChecked: billing,
      inputType: "checkBox",
      identifer: "billing",
    },
    {
      label: "Shipping",
      defaultChecked: shipping,
      inputType: "checkBox",
      identifer: "shipping",
    },
  ]
