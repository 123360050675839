import React from "react"
import { useState } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, IconButton, Box, Tooltip, colors, Dialog, AppBar, Typography, Toolbar, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"
import {
  MoreVertIcon,
  EditIcon,
  CloseIcon,
  DeleteIcon,
  SaveIcon,
  CancelIcon,
  VisibilityIcon,
} from "@iconsGallery"
import HistoryIcon from "@material-ui/icons/History"
import styled from "styled-components"
import AuditLogPopup from "../../pages/logviewer/auditLogs/AuditLogPopup"
import Slide from "@material-ui/core/Slide"
import { display } from "@material-ui/system"
import _ from "lodash"

const MenuListParentWrapper = styled.div`
  .MuiIconButton-root {
    width: 20px;
    margin: auto;
    display: flex;
  }
`
const MenuListWrapper = styled.div`
  box-shadow: 0px 2px 2px #0000008f;
  border: 1px solid #eaeaea;
`

// const StyledDialog = styled(Dialog)`
// overflow-y: hidden;
// }
// `
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: "var(--primaryColor)"

  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

function ActionMenu(props) {
  // console.log("ActionMenu", props);
  const { isInlineEditAllowed = false, historyPopup = true } = props
  const [actionsState, setActionsState] = useState(null)
  const [isOpen, setOpen] = useState(false)
  const [scroll, setScroll] = React.useState("paper")
  const classes = useStyles()

  const handleClickOpen = (scrollType) => {
    setOpen(true)
    setScroll(scrollType)
  }

  const handlePopClose = () => {
    setOpen(false)
    document.body.style.overflow = "scroll"
    document.body.style.paddingRight = "0px"
  }

  const ITEM_HEIGHT = 45

  const handleMenu = (event) => {
    setActionsState(event.currentTarget)
  }

  const descriptionElementRef = React.useRef(null)
  React.useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [isOpen])

  const handleClose = (event) => {
    setActionsState(null)
    /*  event.stopPropagation(null) */
  }

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />
  })

  let isDisableFunction =
    props.menutype === "master" ? false : !isInlineEditAllowed
  return (
    <>
      {
        props.data.inEdit ? (
          <>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Tooltip title="Save" placement="top" onClick={props.save}>
                <IconButton key={"save"} style={{ padding: '10px' }} >
                  <SaveIcon style={{ color: colors.green[600] }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel" placement="top" onClick={props.cancel}>
                <IconButton key={"cancel"} style={{ padding: '10px' }} >
                  <CancelIcon style={{ color: colors.red[400] }} />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        ) :
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Tooltip title={props.menutype === "master" ? "View" : "Edit"} placement="top" >
              <div onClick={isDisableFunction ? () => { } : props.in_edit}>
                <IconButton key={"edit"} disabled={props.menutype === 'master' ? false : !isInlineEditAllowed} style={{ padding: '10px' }} >
                  {
                    props.menutype === "master" ?
                      <VisibilityIcon style={{ color: "var(--primaryColor)" }} />
                      :
                      <EditIcon style={{ color: isInlineEditAllowed ? 'var(--primaryColor)' : '#ccc', pointer: isInlineEditAllowed ? "cursor" : "none" }}/*  onClick={props.in_edit} */ />
                  }
                </IconButton>
              </div>
            </Tooltip>
            {isInlineEditAllowed && !props?.deleteIcon && historyPopup ? (
              <Tooltip title={"History"} placement="top">
                <div
                  onClick={() => {
                    setOpen(true)
                  }}
                >
                  <IconButton style={{ padding: "10px" }}>
                    <HistoryIcon />
                  </IconButton>
                </div>
              </Tooltip>
            ) : props?.deleteIcon ? (
              <Tooltip title={"Delete"} placement="top" >
                <IconButton style={{ padding: "5px" }} disabled={!isInlineEditAllowed} onClick={isInlineEditAllowed ? props.delete : () => { }}>
                  <DeleteIcon style={{ color: isInlineEditAllowed ? '#f8364d' : '#ccc', pointer: isInlineEditAllowed ? "cursor" : "no-drop" }} />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
      }

      <Dialog fullScreen open={isOpen} onClose={handlePopClose}
        TransitionComponent={Transition}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        style={{
          maxWidth: "1000px",
          width: "60%",
          left: "auto",
          right: "0",
          display: isOpen === true ? "block" : "none",
        }}
      >
        <DialogTitle id="scroll-dialog-title" style={{ padding: "0" }}>
          <AppBar className={classes.appBar}>
            <Toolbar style={{ minHeight: "40px" }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handlePopClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                History List
              </Typography>
              {/* <Button autoFocus color="inherit" onClick={handleClose}>
                save
              </Button> */}
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"} style={{ padding: "0" }}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <AuditLogPopup
              isWriteAllowed={isInlineEditAllowed}
              recordId={props?.data?._id}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
      {/* <StyledDialog
        open={isOpen}
        onClose={(e) => setOpen(false)}
        maxWidth="md"
        aria-labelledby="form-dialog-title"
        disableBackdropClick
        
      >
        <AuditLogPopup
          isWriteAllowed={isInlineEditAllowed}
          recordId={props?.data?._id}
        />
      </StyledDialog> */}
    </>

    // <MenuListParentWrapper>
    //   <IconButton
    //     aria-label="more"
    //     aria-controls={"controlMenu"}
    //     aria-haspopup="true"
    //     onClick={(event) => {
    //       handleMenu(event)
    //     }}

    //   >
    //     <MoreVertIcon />
    //   </IconButton>
    //   <Menu
    //     id={"controlMenu"}
    //     keepMounted
    //     anchorEl={actionsState}
    //     open={Boolean(actionsState)}
    //     getContentAnchorEl={null}
    //     onClose={(event) => {
    //       handleClose(event)
    //     }}
    //     PaperProps={{
    //       style: {
    //         maxHeight: ITEM_HEIGHT * 4.5,
    //         width: "20ch",
    //       },
    //     }}
    //   >
    //     {props.data.inEdit ? (
    //       <MenuListWrapper>
    //         <MenuItem key={"save"} onClick={props.save}>
    //           <SaveIcon /> &ensp;Save
    //         </MenuItem>
    //         <MenuItem key={"cancel"} onClick={props.cancel}>
    //           <CancelIcon /> &ensp;Cancel
    //         </MenuItem>
    //        {/*  <MenuItem key={"delete"} onClick={props.delete}>
    //           <DeleteIcon /> &ensp;delete
    //         </MenuItem> */}
    //       </MenuListWrapper>
    //     ) : (
    //       <MenuListWrapper>
    //         <MenuItem key={"edit"} onClick={props.in_edit}>
    //           <EditIcon /> &ensp;Edit
    //         </MenuItem>
    //         <MenuItem key={"delete"} onClick={props.delete}>
    //           <DeleteIcon /> &ensp;Delete
    //         </MenuItem>
    //       </MenuListWrapper>
    //     )}
    //   </Menu>
    // </MenuListParentWrapper>
  )
}

export default ActionMenu
