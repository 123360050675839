export const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg"
export const defaultSrcs =
  "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-handling-images/dev-product-handling-images//51_JB//efc5f0ff-dcf3-4ef1-9a84-0d7b8cfdbfab.jpeg?sv=2021-10-04&st=2023-04-17T07%3A30%3A17Z&se=2123-04-18T07%3A30%3A00Z&sr=c&sp=racwdxltf&sig=ILU%2B%2BHfDbG0pJ8gJ4KeDimfk8RKeNIdY6%2BZ4QQG31Gg%3D"

export const defaultList = [
  {
    url:
      "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg",
  },
  {
    url:
      "https://images.unsplash.com/photo-1587473555771-96aef0d968cc?q=80&w=3456&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    url:
      "https://images.unsplash.com/photo-1571781926291-c477ebfd024b?q=80&w=2789&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    url:
      "https://images.unsplash.com/photo-1627384113743-6bd5a479fffd?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    url:
      "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-handling-images/dev-product-handling-images//51_JB//efc5f0ff-dcf3-4ef1-9a84-0d7b8cfdbfab.jpeg?sv=2021-10-04&st=2023-04-17T07%3A30%3A17Z&se=2123-04-18T07%3A30%3A00Z&sr=c&sp=racwdxltf&sig=ILU%2B%2BHfDbG0pJ8gJ4KeDimfk8RKeNIdY6%2BZ4QQG31Gg%3D",
  },
  {
    url:
      "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-handling-images/dev-product-handling-images//51_JB//efc5f0ff-dcf3-4ef1-9a84-0d7b8cfdbfab.jpeg?sv=2021-10-04&st=2023-04-17T07%3A30%3A17Z&se=2123-04-18T07%3A30%3A00Z&sr=c&sp=racwdxltf&sig=ILU%2B%2BHfDbG0pJ8gJ4KeDimfk8RKeNIdY6%2BZ4QQG31Gg%3D",
  },
  {
    url:
      "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-handling-images/dev-product-handling-images//51_JB//efc5f0ff-dcf3-4ef1-9a84-0d7b8cfdbfab.jpeg?sv=2021-10-04&st=2023-04-17T07%3A30%3A17Z&se=2123-04-18T07%3A30%3A00Z&sr=c&sp=racwdxltf&sig=ILU%2B%2BHfDbG0pJ8gJ4KeDimfk8RKeNIdY6%2BZ4QQG31Gg%3D",
  },
  {
    url:
      "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-handling-images/dev-product-handling-images//51_JB//efc5f0ff-dcf3-4ef1-9a84-0d7b8cfdbfab.jpeg?sv=2021-10-04&st=2023-04-17T07%3A30%3A17Z&se=2123-04-18T07%3A30%3A00Z&sr=c&sp=racwdxltf&sig=ILU%2B%2BHfDbG0pJ8gJ4KeDimfk8RKeNIdY6%2BZ4QQG31Gg%3D",
  },
  {
    url:
      "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-handling-images/dev-product-handling-images//51_JB//efc5f0ff-dcf3-4ef1-9a84-0d7b8cfdbfab.jpeg?sv=2021-10-04&st=2023-04-17T07%3A30%3A17Z&se=2123-04-18T07%3A30%3A00Z&sr=c&sp=racwdxltf&sig=ILU%2B%2BHfDbG0pJ8gJ4KeDimfk8RKeNIdY6%2BZ4QQG31Gg%3D",
  },
  {
    url:
      "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-handling-images/dev-product-handling-images//51_JB//efc5f0ff-dcf3-4ef1-9a84-0d7b8cfdbfab.jpeg?sv=2021-10-04&st=2023-04-17T07%3A30%3A17Z&se=2123-04-18T07%3A30%3A00Z&sr=c&sp=racwdxltf&sig=ILU%2B%2BHfDbG0pJ8gJ4KeDimfk8RKeNIdY6%2BZ4QQG31Gg%3D",
  },
  {
    url:
      "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-handling-images/dev-product-handling-images//51_JB//efc5f0ff-dcf3-4ef1-9a84-0d7b8cfdbfab.jpeg?sv=2021-10-04&st=2023-04-17T07%3A30%3A17Z&se=2123-04-18T07%3A30%3A00Z&sr=c&sp=racwdxltf&sig=ILU%2B%2BHfDbG0pJ8gJ4KeDimfk8RKeNIdY6%2BZ4QQG31Gg%3D",
  },
  {
    url:
      "https://stdgrabdatanonprod001.blob.core.windows.net/dev-product-handling-images/dev-product-handling-images//51_JB//efc5f0ff-dcf3-4ef1-9a84-0d7b8cfdbfab.jpeg?sv=2021-10-04&st=2023-04-17T07%3A30%3A17Z&se=2123-04-18T07%3A30%3A00Z&sr=c&sp=racwdxltf&sig=ILU%2B%2BHfDbG0pJ8gJ4KeDimfk8RKeNIdY6%2BZ4QQG31Gg%3D",
  },
]

export const defaultProductList = ["Apple", "Orange", "Graph"]
