import { IconButton as MuiIconButton } from "@material-ui/core";
import styled from "styled-components/macro";

export const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

export const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;
