//customized components are imported like <Cusotmized.componentName/> from styles folder
import React, { useState, useContext, useEffect } from "react"
import { NavLink, withRouter } from "react-router-dom"
import "../../vendor/perfect-scrollbar.css"
import { Collapse } from "@material-ui/core"
import * as Customized from "../../styles/components/sideBar/styles"
import { sidebarRoutes } from "../../routes/index"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { Layers } from "@iconsGallery"
import * as Adm from "@adm"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import _ from "lodash"
import { JSUtils, AppContext } from "@utils"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import Generictooltip from "./Generictooltip"
import { useStyles } from "./style"
import { DashboardContext } from "@utils"

export const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  showMore,
  setShowMore,
  handleToggle = () => { },
  ...rest
}) => {
  //Working for tooltip close
  const history = useHistory()

  return (
    <Customized.Category {...rest}>
      {/* <div>{icon}</div> */}
      <Generictooltip
        handleToggle={handleToggle}
        formatRoutes={formatRoutes}
        showMore={showMore}
        name={name}
        icon={icon}
        setShowMore={setShowMore}
        isCollapsable={isCollapsable}
      />
      {/* <Customized.Link button dense component={NavLink} exact activeclassname="active">
        <Customized.LinkText>{name}</Customized.LinkText> */}
      {!showMore ? (
        <Customized.CategoryText showMore={rest.showMore}>
          {name}
        </Customized.CategoryText>
      ) : null}
      {/* </Customized.Link> */}
      {isCollapsable ? (
        isOpen ? (
          // <img src="/static/img/iconMore.svg" alt="" />
          <Customized.CategoryIconLess />
        ) : (
          <Customized.CategoryIconMore />
          // <img src="/static/img/iconLess.svg" alt="" />
        )
      ) : null}
      {badge ? <Customized.CategoryBadge label={badge} /> : ""}
    </Customized.Category>
  )
}
export const SidebarSubCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  showMore,
  ...rest
}) => {
  const history = useHistory()
  return (
    <Customized.SubCategory {...rest}>
      {/* <Customized.Link button dense component={NavLink} exact activeclassname="active">
        <Customized.LinkText>{name}</Customized.LinkText> */}

      {!showMore ? (
        <Customized.CategoryText>{name}</Customized.CategoryText>
      ) : null}
      {/* </Customized.Link> */}
      {isCollapsable ? (
        isOpen ? (
          // <img src="/static/img/iconMore.svg" alt="" />
          <Customized.CategoryIconLess />
        ) : (
          <Customized.CategoryIconMore />
          // <img src="/static/img/iconLess.svg" alt="" />
        )
      ) : null}
      {badge ? <Customized.CategoryBadge label={badge} /> : ""}
    </Customized.SubCategory>
  )
}

export const SidebarLink = ({ name, to, badge, icon, showMore, ...others }) => {
  const history = useHistory()
  return (
    <Customized.Link
      button
      dense
      component={!showMore && NavLink}
      exact={false}
      to={to}
      activeclassname="active"
    >
      {!showMore ? <Customized.LinkText>{name}</Customized.LinkText> : null}
      {badge ? <Customized.LinkBadge label={badge} /> : ""}
    </Customized.Link>
  )
}

export const SidebarSubLink = ({ name, to, badge, showMore, icon }) => {
  const history = useHistory()
  return (
    <Customized.SubLink
      button
      dense
      component={!showMore && NavLink}
      exact={false}
      to={to}
      activeclassname="active"
    >
      {!showMore ? <Customized.LinkText>{name}</Customized.LinkText> : null}
      {badge ? <Customized.LinkBadge label={badge} /> : ""}
    </Customized.SubLink>
  )
}

const formatRoutes = (routesObj, pathname = "") => {
  if (!_.isArray(routesObj)) {
    return []
  }

  return routesObj.reduce((acc, o, i) => {
    let isOpen = pathname.indexOf(o.path) === 0
    if (_.isEmpty(o.children)) {
      acc.push(Object.assign({}, o, { isOpen }))
    } else {
      let children = formatRoutes(o?.children || null, pathname)
      acc.push(Object.assign({}, o, { children }, { isOpen }))
    }
    return acc
  }, [])
}

const getReadWritePermissions = (x, userPermAttr = {}) =>
  JSUtils.getReadWritePermissions(x, userPermAttr)

const Sidebar = ({
  classes,
  staticContext,
  location,
  mobileOpen,
  routes,
  ...rest
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    showMore,
    setShowMore,
    routesWithActiveStatus,
    setRoutesWithActiveStatus,
  } = useContext(DashboardContext)
  // const isTablet = useMediaQuery('(max-width: 959px)')
  const isMobile = useMediaQuery("(max-width: 768px)")
  const props = { isMobile }
  const classesOne = useStyles(props)

  // const [routesWithActiveStatus, setRoutesWithActiveStatus] = useState(
  //   formatRoutes(
  //     _.cloneDeep(routes),
  //     location.pathname.replaceAll(`/${userProfileDetails?.roleType}`, "")
  //   )
  // )
  const userPermAttr = useSelector(
    (state) => state.adminReducer.userKeycloakAttributes
  )

  const userProfileDetails =
    useSelector((state) => state.adminReducer.userProfileDetails) || {}

  const theme = useTheme()
  const { getAppThemes } = useContext(AppContext)

  useEffect(() => {
    setRoutesWithActiveStatus(
      formatRoutes(
        _.cloneDeep(routes),
        location.pathname.replaceAll(`/${userProfileDetails?.roleType}`, "")
      )
    )
  }, [userProfileDetails?.roleType])

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("expandDetails")) &&
      JSON.parse(localStorage.getItem("expandDetails")).iconOnlyDisplay
    ) {
      setShowMore(true)
      let tempRoutes = _.cloneDeep(routesWithActiveStatus)
      var activeMenu = _.findIndex(tempRoutes, function (o) {
        return o?.isOpen === true
      })
      var objResult = JSON.parse(localStorage.getItem("expandDetails"))
      const expandDetailsObj = {
        ...objResult,
        indicesArr: [activeMenu],
        iconOnlyDisplay: true,
        isOpen: true,
        tooltipOpen: false,
      }
      localStorage.setItem("expandDetails", JSON.stringify(expandDetailsObj))
      handleToggleClose()
    }
  }, [
    JSON.parse(localStorage.getItem("expandDetails")) &&
    JSON.parse(localStorage.getItem("expandDetails")).iconOnlyDisplay,
  ])

  const handleToggle = (indicesArr, route = {}, updateStatus = true) => {
    const { redirect = true } = route
    let tempRoutes = _.cloneDeep(routesWithActiveStatus)
    if (
      indicesArr[0] !==
      (JSON.parse(localStorage.getItem("expandDetails")) &&
        JSON.parse(localStorage.getItem("expandDetails")).indicesArr[0])
    ) {
      _.map(tempRoutes, (value, index) => {
        tempRoutes[index].isOpen = false
      })
      // closeOldExpand(JSON.parse(localStorage.getItem("expandDetails")).indicesArr,
      //   JSON.parse(localStorage.getItem("expandDetails")).route)
    }
    if (_.isArray(indicesArr)) {
      let isOnly = indicesArr.length === 1
      let path = indicesArr.reduce((acc, x, i, arr) => {
        let isFirst = i === 0
        let isLast = arr.length - 1 === i
        acc = `${isFirst ? "" : `${acc}.`}${x}${isLast ? "" : ".children"}`
        return acc
      }, "")
      _.set(tempRoutes, path, {
        ..._.get(tempRoutes, path),
        isOpen: !_.get(tempRoutes, `${path}.isOpen`),
      })

      if (indicesArr.length === 1 && updateStatus) {
        const expandDetailsObj = {
          indicesArr: indicesArr,
          route: route,
          isOpen: _.get(tempRoutes, `${path}.isOpen`),
          tooltipOpen: false,
          iconOnlyDisplay: false,
        }
        localStorage.setItem("expandDetails", JSON.stringify(expandDetailsObj))
      }
    }
    setRoutesWithActiveStatus(tempRoutes)
    if (redirect) {
      if (_.has(route, "path")) {
        history.push(`/${userProfileDetails?.roleType}${_.get(route, "path")}`)
      }
    }
  }

  const handleToggleOpen = () => {
    let expandDetailsObj = JSON.parse(localStorage.getItem("expandDetails"))
    handleToggle(
      _.split(expandDetailsObj?.indicesArr[0], ","),
      expandDetailsObj?.route,
      false
    )
  }

  const handleToggleClose = () => {
    var expandDetailsObj = JSON.parse(localStorage.getItem("expandDetails"))
    expandDetailsObj = {
      ...expandDetailsObj,
      iconOnlyDisplay: true,
    }
    localStorage.setItem("expandDetails", JSON.stringify(expandDetailsObj))
    expandDetailsObj = JSON.parse(localStorage.getItem("expandDetails"))
    const tempRoutes = _.cloneDeep(routesWithActiveStatus)
    if (expandDetailsObj?.isOpen && !expandDetailsObj?.tooltipOpen) {
      var indicesArr = expandDetailsObj?.indicesArr || []
      var route = expandDetailsObj?.route || {}
      const { redirect = true } = route

      if (_.isArray(indicesArr)) {
        let isOnly = indicesArr.length === 1
        let path = indicesArr.reduce((acc, x, i, arr) => {
          let isFirst = i === 0
          let isLast = arr.length - 1 === i
          acc = `${isFirst ? "" : `${acc}.`}${x}${isLast ? "" : ".children"}`
          return acc
        }, "")
        _.set(tempRoutes, path, {
          ..._.get(tempRoutes, path),
          isOpen: !expandDetailsObj?.isOpen,
        })
      }
      setRoutesWithActiveStatus(tempRoutes)
      if (redirect) {
        if (_.has(route, "path")) history.push(_.get(route, "path"))
      }
    } else {
      var activeMenu = _.findIndex(tempRoutes, function (o) {
        return o?.isOpen === true
      })
      const expandDetailsObj = {
        indicesArr: [activeMenu],
        route: {},
        isOpen: true,
        tooltipOpen: false,
        iconOnlyDisplay: false,
      }
      localStorage.setItem("expandDetails", JSON.stringify(expandDetailsObj))
      _.map(tempRoutes, (value, index) => {
        tempRoutes[index].isOpen = false
      })
      setRoutesWithActiveStatus(tempRoutes)
    }
  }

  let getRoleName = localStorage.getItem("sourceRole")
  let getTenantId = localStorage.getItem("tenantId")
  getRoleName = getRoleName.toLocaleLowerCase()
  const isSassRole = getRoleName.includes("saas")

  let dynamicRoutes = isSassRole
    ? `/${userProfileDetails?.roleType}/dashboard?page=home`
    : `/${userProfileDetails?.roleType}/dashboard?page=autonomous&retailer=${getTenantId}&subpage=0`

  let dynCatDashboardId = isSassRole
    ? "?page=home"
    : `?page=autonomous&retailer=${getTenantId}&subpage=0`

  return (
    <>
      {routesWithActiveStatus.length > 0 ? (
        <div>
          {mobileOpen ? (
            ""
          ) : (
            <div>
              {showMore ? (
                <ChevronRightIcon
                  className={classesOne.chevronRighticon}
                  isMobile={isMobile}
                  onClick={() => {
                    setShowMore(false)
                    handleToggleOpen()
                  }}
                />
              ) : (
                <KeyboardArrowLeftIcon
                  className={classesOne.chevronLefticon}
                  isMobile={isMobile}
                  onClick={() => {
                    setShowMore(true)
                    handleToggleClose()
                  }}
                />
              )}
            </div>
          )}
          <Customized.Drawer variant="permanent" {...rest}>
            <Customized.Brand
              style={{
                paddingLeft: "5px",
                height: "15px",
                width: showMore ? "200px" : "auto",
                backgroundColor: "var(--secondaryColor)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              component={NavLink}
              to={dynamicRoutes}
              button
            >
              <Adm.CustomLogo
                showMore={showMore}
                isMobile={isMobile}
                smallImage={getAppThemes?.companySmallLogImageUrl}
                largeImage={getAppThemes?.companyLargeLogImageUrl}
                isImageUrl={getAppThemes?.companyLargeLogImageUrl?.length > 0}
              />
            </Customized.Brand>
            <Customized.Scrollbar>
              <Customized.List disablePadding>
                <Customized.Items>
                  {routes?.map((category, index) => {
                    let {
                      isReadAllowed: isCategoryAllowed,
                    } = getReadWritePermissions(category, userPermAttr)
                    let allowedHeadModule = []
                    let allowedSubHeadModule = []
                    _.map(category?.children, (ro, i) => {
                      allowedHeadModule.push(
                        getReadWritePermissions(ro, userPermAttr).isReadAllowed
                      )
                      _.map(ro?.childArray, (rou, i) => {
                        allowedSubHeadModule.push(
                          getReadWritePermissions(rou, userPermAttr)
                            .isReadAllowed
                        )
                      })
                    })

                    return (
                      <React.Fragment key={index}>
                        {category.header ? (
                          <Customized.SidebarSection>
                            {category.header}
                          </Customized.SidebarSection>
                        ) : null}
                        {isCategoryAllowed &&
                          category.children &&
                          category.icon &&
                          !allowedHeadModule.every((x) => x === false) ? (
                          <React.Fragment key={index}>
                            <SidebarCategory
                              isOpen={_.get(
                                routesWithActiveStatus,
                                `${index}.isOpen`,
                                false
                              )}
                              isCollapsable={!showMore && true}
                              name={t(category.id)}
                              icon={category.icon}
                              button={true}
                              onClick={() => !showMore && handleToggle([index])}
                              showMore={showMore}
                              setShowMore={setShowMore}
                              handleToggle={handleToggle}
                            />
                            <Collapse
                              in={_.get(
                                routesWithActiveStatus,
                                `${index}.isOpen`,
                                false
                              )}
                              timeout="auto"
                              unmountOnExit
                              className={classesOne.collapse}
                            >
                              {/* {category.children.map((route, index) => (
                        <SidebarLink
                          key={index}
                          name={t(route.name)}
                          to={route.path}
                          icon={route.icon}
                          badge={route.badge}
                        />
                      ))} */}
                              {category.children.map((route, ind) => {
                                let {
                                  isReadAllowed: isRouteAllowed,
                                } = getReadWritePermissions(route, userPermAttr)
                                if (route.children && isRouteAllowed) {
                                  return (
                                    <>
                                      {route.children &&
                                        !allowedSubHeadModule.every(
                                          (x) => x === false
                                        ) ? (
                                        <React.Fragment
                                          key={`sidebarsubcategory_${ind}`}
                                        >
                                          <SidebarSubCategory
                                            isOpen={_.get(
                                              routesWithActiveStatus,
                                              `${index}.children.${ind}.isOpen`,
                                              false
                                            )}
                                            isCollapsable={!showMore && true}
                                            name={t(route.id)} // master auto frition;
                                            icon={route.icon}
                                            button={true}
                                            onClick={() =>
                                              !showMore &&
                                              handleToggle([index, ind], route)
                                            }
                                            showMore={showMore}
                                            setShowMore={setShowMore}
                                          />
                                          <Collapse
                                            in={_.get(
                                              routesWithActiveStatus,
                                              `${index}.children.${ind}.isOpen`,
                                              false
                                            )}
                                            timeout="auto"
                                            unmountOnExit
                                            className={classesOne.collapse}
                                          >
                                            {route.children.map((r, i) => {
                                              let {
                                                isReadAllowed: isChildRouteAllowed,
                                              } = getReadWritePermissions(
                                                r,
                                                userPermAttr
                                              )
                                              if (isChildRouteAllowed) {
                                                return (
                                                  <React.Fragment
                                                    key={`sidebarsublink_${i}`}
                                                  >
                                                    <SidebarSubLink
                                                      key={`sidebarsublink_${i}`}
                                                      name={t(r.name)}
                                                      to={`/${userProfileDetails?.roleType}${r.path}`} //group ,product ,group
                                                      icon={r.icon}
                                                      badge={r.badge}
                                                      onClick={() => {
                                                        history.push(route.path)
                                                      }}
                                                      showMore={showMore}
                                                    />
                                                  </React.Fragment>
                                                )
                                              }
                                              return null
                                            })}
                                          </Collapse>
                                        </React.Fragment>
                                      ) : null}
                                    </>
                                  )
                                } else {
                                  return (
                                    isRouteAllowed && (
                                      <React.Fragment
                                        key={`sidebarsubcategory_${ind}`}
                                      >
                                        <SidebarLink // plonogram,inventory, userMangement,permission,role permission,country,tax masterData
                                          isRouteAllowed={getReadWritePermissions(
                                            route,
                                            userPermAttr
                                          )}
                                          route={route}
                                          routePerms={getReadWritePermissions(
                                            route,
                                            userPermAttr
                                          )}
                                          key={`sidebarsubcategory_${ind}`}
                                          name={t(route.name)}
                                          to={`/${userProfileDetails?.roleType}${route.path}`}
                                          icon={route.icon}
                                          badge={route.badge}
                                          showMore={showMore}
                                        />
                                      </React.Fragment>
                                    )
                                  )
                                }
                              })}
                            </Collapse>
                          </React.Fragment>
                        ) : (
                          <>
                            {isCategoryAllowed &&
                              category.icon &&
                              allowedHeadModule.every((x) => x !== false) ? (
                              <React.Fragment key={`sidebarCategory_${index}`}>
                                <SidebarCategory
                                  isCollapsable={!showMore && false}
                                  name={t(category.id)}
                                  to={
                                    t(category.id) === "Dashboard"
                                      ? `/${userProfileDetails?.roleType}${category.path}${dynCatDashboardId}`
                                      : `/${userProfileDetails?.roleType}${category.path}`
                                  }
                                  activeclassname="active"
                                  component={NavLink}
                                  icon={category.icon}
                                  exact={false}
                                  button
                                  badge={category.badge}
                                  key={index}
                                  showMore={showMore}
                                  onClick={() => handleToggle([index])}
                                />
                              </React.Fragment>
                            ) : null}
                          </>
                        )}
                      </React.Fragment>
                    )
                  })}
                </Customized.Items>
              </Customized.List>
            </Customized.Scrollbar>
          </Customized.Drawer>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}
const SideBarWrapper = (props) => <Sidebar {...props} routes={sidebarRoutes} />
export default withRouter(SideBarWrapper)
