import {
  Switch,
  Box,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormControl,
  InputLabel,
  withStyles,
  InputBase,
  makeStyles,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"

import customTableStyles from "../../styles/components/customTable/styles"
import _ from "lodash"
import MultiDropdown from "./MultiDropdown"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: { vertical: 'bottom', horizontal: 'left' }
}

function RoleDropdown(props) {
  const { setSelectedRoles = () => { } } = props || {}
  const data = props.dataItem
  const dataValue = props.value
  const classes = customTableStyles()
  const { bgColor = "", value = [] } = props
  const [roleVal, setRoleVal] = React.useState([])
  const [selectedRoleName, setSelectedRoleName] = useState([])
  const Roles = props?.list || []
  const [selectedObj, setSelectedObj] = useState([])
  const [getRoleData, setRoleData] = useState("");

  useEffect(() => {
    let selectedId = []
    let selectedText = []
    Roles?.map((x, i) => {
      if (x?.is_selected) {
        selectedId.push(x?.role_id)
        selectedText.push(x?.role_name)
      }
    })
    setRoleVal(selectedId)
    setSelectedRoleName(selectedText)
    setRoleData(_.join(props?.dataItem[props?.field], ','))
  }, [])

  const totalRoles = getRoleData.split(',');
  const firstRole = totalRoles[0];
  const remainingRolesCount = totalRoles.length - 1;
  const displayRolesText = `${firstRole} + ${remainingRolesCount} others`;
  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    let selectedText = []
    let objList = []
    if (value?.length === 0) {
      Roles?.map((x, i) => {
        return (Roles[i]["is_selected"] = false)
      })
    } else {
      Roles?.map((x, i) => {
        value?.map((y, ind) => {
          if (x?.role_id === y) {
            x.is_selected = true
            selectedText.push(x?.role_name)
            if (!x?.read_only) {
              objList.push({ ...x })
            }
          } else {
            if (!value.includes(x?.role_id)) {
              x.is_selected = false
            }
          }
          return x
        })
        return Roles
      })
    }
    setSelectedRoleName(selectedText)
    setSelectedObj(objList)
    setRoleVal(typeof value === "string" ? value.split(",") : value)
    setRoleData(_.join(props?.dataItem[props?.field], ','))
  }

  return (
    <td>
      {data.inEdit && data.is_subscribed ? (
        <>
          <FormControl
            size="small"
            className={classes.selectCheckbox}
            style={{ width: "200px" }}
          >
            <InputLabel htmlFor="demo-mutiple-checkbox-label">Roles</InputLabel>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              value={roleVal}
              MenuProps={MenuProps}
              onChange={handleChange}
              input={<OutlinedInput label="Select Roles" />}
              renderValue={(selected) => {
                console.log("rendervalue", selected)
                return (selectedRoleName)
                selected.map((obj) => Roles[obj - 1]?.role)?.join(", ")
              }}
              onClose={() => {
                setSelectedRoles(selectedObj)
              }}
            >
              {Roles.map((name) => (
                <MenuItem
                  className={classes.selectCheckList}
                  key={name?.role_id}
                  value={name?.role_id}
                  disabled={name?.read_only || false}
                >
                  <Checkbox checked={roleVal?.indexOf(name?.role_id) > -1} style={{ color: "var(--primaryColor)" }} />
                  <ListItemText primary={name.role_name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      ) : (
        <div style={{ display: "flex" }}>
          {_.isArray(props?.dataItem[props?.field]) &&
            <Typography
              style={{
                backgroundColor: bgColor,
                width: "fit-content",
                padding: "2px 4px",
                color: "#ffffff",
                borderRadius: "4px",
                marginRight: "5px",
                fontSize: "12px",
                fontWeight: 500
              }}
            >
              {displayRolesText}
            </Typography>
          }
        </div>
      )}
    </td>
  )
}

export default RoleDropdown
