import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

// const types = REDUX_CONSTANTS

export const addLocationInfo = (data) => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.ADD_LOCATION_INFO,
    locationInfo: data,
  })
}

export const addOpenDate = (data) => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.ADD_LOCATION_OPEN_DATE,
    openDate: data,
  })
}

export const addOperatingHoursAlchol = (data) => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.ADD_LOCATION_OPERATING_HOURS_ALCHOL,
    operatingHoursAlchol: data,
  })
}

export const addOperatingHoursBusiness = (data) => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.ADD_LOCATION_OPERATING_HOURS_BUSINESS,
    operatingHoursBusiness: data,
  })
}

export const addLocationHierarchy = (data) => (dispatch) => {
  console.log("addLocationHierarchy", data)
  dispatch({
    type: REDUX_CONSTANTS.ADD_LOCATION_TYPE_HIERARCHY,
    locationHierarchy: data,
  })
}

export const addLocationSerivces = (data) => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.ADD_LOCATION_SERVICES,
    locationServices: data,
  })
}

export const addLocationAttributes = (data) => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.ADD_LOCATION_ATTRIBUTES,
    locationAttributes: data,
  })
}

export const addLocationReset = () => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.ADD_LOCATION_RESET,
  })
}
