import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

// const types = REDUX_CONSTANTS

export const updateOpenDate = (date) => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.UPDATEOPENDATE,
    openDate: date,
  })
}

export const updateCloseDate = (date) => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.UPDATECLOSEDATE,
    closeDate: date,
  })
}
