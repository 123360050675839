import * as React from "react"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  ListItemText,
} from "@material-ui/core"
import { CloseIcon } from "@iconsGallery"
import { Formik } from "formik"
import * as Yup from "yup"
import { Edit, Slash } from "@iconsGallery"
import _ from "lodash"
import { FormCommonFormUI } from "./form"
import { formInput } from "./fixture"
import { useCommonFormUIContentStyles } from "./styles"
import { useCommonFormUIState } from "./state"
import * as Utils from "@utils"
import styled from "styled-components"
import DynamicListItemKit from "./dynamicListItemKit/index"

const ListWrapper = styled(List)`
  &.list-split {
    display: flex;
    flex-direction: initial;
    flex-wrap: wrap;
  }
`
const ListItemWrapper = styled(ListItem)`
  &.list-split-sub {
    width: 50%;
  }
`

function View(props) {
  const {
    listColumnSplit = false,
    edit: commonFormEdit = false,
    commonFormData = [],
    commonFormSetData = () => "",
    updateData,
    inputField = false,
  } = props || {}

  const classes = useCommonFormUIContentStyles()
  const {
    edit,
    setEdit,
    collapseOpen,
    setCollapseOpen,
    data,
    setData,
    expanded,
    setExpanded,
  } = useCommonFormUIState({ defaultData: commonFormData })

  React.useEffect(() => {
    setData(commonFormData)
  }, [commonFormData])
  React.useEffect(() => {}, [data])

  const doDataUpdate = (item) => {
    setData([...item])
    commonFormSetData([...item])
  }

  const doSingleDataUpdate = (item) => {
    setData([...data])
    commonFormSetData([...commonFormData])
  }

  const formSubmit = (values, { resetForm }) => {
    const DataKit = [...data, values]
    const isDuplicate = Utils.JSUtils.isDuplicateObject(DataKit, "id")
    if (isDuplicate) {
      alert("Duplicate Detected")
    } else {
      doDataUpdate(DataKit)
      resetForm()
    }
  }

  const formStateProps = {
    data,
    setData,
    updateData: updateData,
    onSubmit: formSubmit,
    ...formInput,
  }

  return (
    <div>
      <FormCommonFormUI
        {...formStateProps}
        list={data}
        listColumnSplit={listColumnSplit}
        commonFormEdit={commonFormEdit}
        doDataUpdate={doSingleDataUpdate}
      />
    </div>
  )
}
export { View }
export default View
