import { call, put, takeEvery, delay } from "redux-saga/effects"
import _ from 'lodash';
import {
  receiveProductList, receiveSignIndata, receiveBarCodeType, receiveStoreType, receiveUnitOfMeasure, receiveProductDetails,
  receiveProductParams, receiveCategoryList, receiveSubCategoryList, receiveProductGroup, receiveProductGroupEditParams, receiveProductGroupDetails,
  receiveProductGroupDelete,
  receiveOrdersList,
  getSearchTerm,
  receiveOrderDetailsData, receiveLocationList, receiveSelectedLocation, getTurnstileList, receiveTurnstileList, receiveGondolaList, receiveGondolaSelected, receiveCameraList, postCameraAndGpu, receiveGondolaDetails, receiveLocationSetup, receiveStoreDimensionStep, receiveGondolaStep, receiveCameraAndGPUStep, receiveCartManagerStep,
  setUserRoleInformation,
  setUserDecodeInformation
} from "./actions"
import {
  fetchSignUpData, fetchSignIn, fetchProductList, fetchProductListInitial, fetchCreateProduct, fetchAddImage, fetchBarCodeInitial,
  fetchBarCode, fetchStoreTypeInitial, fetchStoreType, fetchUnitOfMeasureInitial, fetchUnitOfMeasure,
  fetchProductDetails, fetchEditProduct, fetchDeleteProduct, fetchProductListRedux, fetchCategoryList, fetchSubCategoryList, fetchProductGroupList,
  fetchCreateProductGroup, fetchAssociateProductGroup, fetchDeleteProductGroup, fetchProductGroupInitial, fetchProductGroupListFinal, fetchProductGroupListRedux,
  fetchProductGroupDetails, fetchOrdersListFinal, fetchOrdersListInitial, fetchSearchResults, fetchDataAndTimeResults, fetchStateAndStatusResults, fetchDeleteMultipleProduct
} from "../../utils/requests"
import {
  fetchLocationList, fetchTurnstileList, fetchGondolaList, fetchGroundsplot, fetchCartManager, fetchCameraList, fetchCameraAndGpu, fetchCameraUpdate, fetchStoreDimension,
  fetchGondolaDetails, fetchLocationSetup, fetchGetLocationDataStepper, fetchStoreDimensionStepper, fetchGondaStepData, fetchCameraAndGPUdata, fetchCartManagerStepData
} from "../../utils/stepperApis"
import { history } from "../../../src/App"
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import { toast } from 'react-toastify';
import {
  GET_CREATE_PRODUCT, GET_PRODUCT_LIST, GET_SIGNIN_DATA, GET_SIGNUP_DATA, GET_BARCODE_TYPE, GET_STORE_TYPE, GET_UNIT_OF_MEASURE, GET_PRODUCT_DETAILS,
  GET_EDIT_PRODUCT, EDIT_PRODUCT_PARAMS, GET_DELETE_PRODUCT, GET_CATEGORY_LIST, GET_SUB_CATEGORY_LIST, GET_PRODUCT_GROUP, GET_CREATE_PRODUCT_GROUP, PRODUCT_GROUP_EDIT_PARAMS,
  ASSOCIATE_PRODUCT_GROUP, GET_DELETE_PRODUCT_GROUP, GET_PRODUCT_GROUP_DETAILS, GET_ORDERS_LIST, GET_SEARCH_TERM, GET_ORDER_DETAILS, GET_DATE_TIME, GET_ORDER_STATE_STATUS, GET_DELETE_MULTIPLE_PRODUCT,
  GET_LOCATION_LIST,
  GET_SELECTED_LOCATION,
  GET_TURNSTILE_LIST,
  GET_GONDOLA_LIST,
  SELECTED_GONDOLA,
  POST_GROUNDSPLOT_DATA,
  POST_CART_MANAGER,
  GET_CAMERA_LIST,
  POST_CAMERA_GPU,
  CAMERA_UPDATE,
  POST_STORE_DIMENSION,
  GET_GONDOLA_DETAILS,
  POST_LOCATION_SETUP,
  GET_LOCATION_SETUP,
  GET_STORE_DIMENSION_STEP,
  GET_GONDOLA_STEP,
  GET_CAMERA_AND_GPU_STEP,
  GET_CART_MANAGER_STEP,
  USER_ROLE_INFORMATION, USER_DECODE_INFORMATION
} from "./constants"

const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}




const notify = (message) => toast(message, {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  success: "bg-blue-600",

}
);


export function* getSignInAuthenication(payload) {
  const response = yield call(fetchSignIn, payload.data)
  if (response.status !== 200) {
    notify('❌ Login Failed');

    // yield put(receiveSignIndata(payload))
  } else {
    notify('✔️ Login successfully !');

    let successData = response.signin

    window.localStorage.setItem('accessToken', successData.token);
    history.push("/dashboard")
    history.go(0)
  }
}

export function* getSignUpData(payload) {
  const response = yield call(fetchSignUpData, payload.data)
  if (response.status !== 200) {
  } else {
    let successData = response.signup
    history.push("/auth/sign-in")

    // yield put(receiveRoverImages(images));
  }
}

export function* getUserRole(data) {
  yield put(setUserRoleInformation(data));
}

export function* getUserDecodeInfo(data) {
  yield put(setUserDecodeInformation(data));
}


export function* getProductListData(payload) {
  const response = yield call(fetchProductListInitial, payload.data)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.products;
    const totalProducts = response.products.data.products.totalDocs;
    const response2 = yield call(fetchProductList, payload.data, totalProducts)
    if (response2.status !== 200) {
    } else {
      let successData2 = response2.products;
      yield put(receiveProductList(successData2));

    }
  }
}

export function* postCreateProduct(payload) {
  // logger.info('price-handler - Successfully created/stored price');

  const response = yield call(fetchCreateProduct, payload.data, payload.data2)
  if (response.status !== 200) {
    notify('❌ Product Creation Failed');

  } else {
    let successData = response.products;
    notify('✔️ Product Created successfully !');


    if (payload.data3 !== []) {
      const response2 = yield call(fetchAddImage, payload.data, payload.data3)
      if (response2.status !== 200) {
      } else {
        let successData2 = response2.products
      }

    }
    else {
    }
  }
}

export function* getBarCodeType(payload) {
  let body = { page: 1, limit: 1000, filter: [{ field: "status", operator: "eq", value: "true" }], sort: { barcode_type: 1 } }
  let response = yield call(ServiceAPI.fetchCommonData, API_END_POINTS.getAllBarcodeTypes, body)

  if (response.status !== 200) {
  } else {
    const list = response.data.data.docs;
    yield put(receiveBarCodeType(list));
  }
}


export function* getStoreType(payload) {

  const response = yield call(fetchStoreTypeInitial)
  if (response.status !== 200) {
  } else {
    let successData = response.products;
    const totalProducts = response.products.data.totalDocs;
    const response2 = yield call(fetchStoreType, totalProducts)
    if (response2.status !== 200) {
    } else {
      let successData2 = response2.products.data.stores;
      yield put(receiveStoreType(successData2));

    }
  }
}

export function* getUnitOfMeasure(payload) {
  let body = { page: 1, limit: 1000, filter: [{ field: "status", operator: "eq", value: "true" }], sort: { measure: 1 } }
  let response = yield call(ServiceAPI.fetchCommonData, API_END_POINTS.getAllMeasure, body)

  if (response.status !== 200) {
  } else {
    const list = response.data.data.docs;
    yield put(receiveUnitOfMeasure(list));
  }
}

export function* getProductDetails(payload) {

  const response = yield call(fetchProductDetails, payload.data, payload.data2)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.products;
    const productDet = successData === undefined ? [] : successData.data === undefined ? [] : successData.data.products === undefined ? [] : successData.data.products[0];
    yield put(receiveProductDetails([]));

    yield put(receiveProductDetails(productDet));

  }
}

export function* postEditProduct(payload) {
  // logger.info('price-handler - Successfully created/stored price');

  const response = yield call(fetchEditProduct, payload.data, payload.data3, payload.data4, payload.data5, payload.data6, payload.data7)
  // payload.data2,
  if (response.status !== 200 || undefined) {
    notify('❌ Product Edit Failed');

  } else {
    let successData = response.products;
    notify('✔️ Product Edited successfully !');

    if (payload.data2.length > 0) {
      const response2 = yield call(fetchAddImage, payload.data, payload.data2)
      if (response2.status !== 200) {
      } else {
        let successData2 = response2.products
      }

    }
    else {
    }
  }
}


export function* getEditProductParams(payload) {
  const data = {
    "storeID": payload.data,
    "upc": payload.data2,
    "productTitle": payload.data3
  }
  yield put(receiveProductParams(data));
}

export function* postDeleteProduct(payload) {
  const response = yield call(fetchDeleteProduct, payload.data)
  if (response.status !== 200) {
    notify('❌ Product Deletion Failed');

  } else {
    let successData = response.products;
    notify('✔️ Product Deleted successfully !');
    const response3 = yield call(fetchProductListInitial)
    if (response3.status !== 200) {
    } else {
      // let successData2 = response3.products;
      const totalProducts = response3.products.data.products.totalDocs;
      const response2 = yield call(fetchProductListRedux, totalProducts)
      if (response2.status !== 200) {
      } else {
        let successData2 = response2.products;
        yield put(receiveProductList(successData2));

      }
    }
  }
}
export function* postDeleteMultipleProduct(payload) {
  const response = yield call(fetchDeleteMultipleProduct, payload.data)
  if (response.status !== 200) {

    notify('❌ Product Deletion Failed');

  } else {
    let successData = response.products;
    notify('✔️ Product Deleted successfully !');
    const response3 = yield call(fetchProductListInitial)
    if (response3.status !== 200) {
    } else {
      //let successData2 = response3.products;
      const totalProducts = response3.products.data.products.totalDocs;
      const response2 = yield call(fetchProductListRedux, totalProducts)
      if (response2.status !== 200) {
      } else {
        let successData2 = response2.products;

        yield put(receiveProductList(successData2));

      }
    }
  }
}


export function* getCatergoryList(payload) {

  const response = yield call(fetchCategoryList)
  if (response.status !== 200) {
  } else {
    let successData = response.products;
    // yield put(receiveProductDetails([]));
    yield put(receiveCategoryList(successData));

  }
}


export function* getSubCategoryList(payload) {

  const response = yield call(fetchSubCategoryList)
  if (response.status !== 200) {
  } else {
    let successData = response.products;

    yield put(receiveSubCategoryList(successData));

  }
}

export function* getProductGroupList(payload) {

  // const response = yield call(fetchProductGroupList)
  // if (response.status !== 200) {
  // } else {
  //   let successData = response.products;

  //  yield put(receiveProductGroup(successData));

  // }
  const response = yield call(fetchProductGroupInitial, payload.data)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.products;
    const totalProducts = response.products.data.products.totalDocs;
    const response2 = yield call(fetchProductGroupListFinal, payload.data, totalProducts)
    if (response2.status !== 200) {
    } else {
      let successData2 = response2.products;
      yield put(receiveProductGroup(successData2));

    }
  }


}

export function* postCreateProductGroup(payload) {

  const response = yield call(fetchCreateProductGroup, payload.data)
  if (response.status !== 200) {
  }
  else {
    notify('✔️ Product Group created successfully !');
    let successData = response.products;

    const response2 = yield call(fetchProductGroupInitial)
    if (response2.status !== 200 || undefined) {
    } else {
      let successData2 = response2.products;
      const totalProducts = response2.products.data.products.totalDocs;
      const response3 = yield call(fetchProductGroupListRedux, totalProducts)
      if (response3.status !== 200) {
      } else {
        let successData3 = response3.products;
        yield put(receiveProductGroup(successData3));

      }
    }
  }
}

//   const response2 = yield call(fetchProductGroupList)
//   if (response2.status !== 200) {
// } else {
//   let successData2 = response2.products;

//  yield put(receiveProductGroup(successData2));
// if (response2.status !== 200) {
// } else {
//   let successData2 = response2.products;

//  yield put(receiveProductGroup(successData2));

//   }



export function* getProductGroupParams(payload) {
  const data = {
    "groupID": payload.data,
    "groupName": payload.data2,
  }
  yield put(receiveProductGroupEditParams(data));
}


export function* postAssociateProductGroup(payload) {

  const response = yield call(fetchAssociateProductGroup, payload.data)
  if (response.status !== 200) {
  }
  else {
    notify('✔️ Products successfully Associated !');
    let successData = response.products;

  }
}


export function* postDeleteProductGroup(payload) {
  yield put(receiveProductGroupDelete(false));

  // receiveProductGroupDelete(false)


  const response = yield call(fetchDeleteProductGroup, payload.data)
  if (response.status !== 200) {
  }
  else {
    notify(`✔️ Product group ${payload.data.groupName} and it’s association are successfully Deleted !`);
    let successData = response.products;
    yield put(receiveProductGroupDelete(true));
    const response2 = yield call(fetchProductGroupInitial)
    if (response2.status !== 200 || undefined) {
    } else {
      let successData2 = response2.products;
      const totalProducts = response2.products.data.products.totalDocs;
      const response3 = yield call(fetchProductGroupListRedux, totalProducts)
      if (response3.status !== 200) {
      } else {
        let successData3 = response3.products;
        yield put(receiveProductGroup(successData3));

      }
    }

  }
}

export function* getProductGroupDetails(payload) {

  const response = yield call(fetchProductGroupDetails, payload.data)
  if (response.status !== 200) {
  }
  else {
    let successData = response.products;
    yield put(receiveProductGroupDetails(successData));

  }
}

export function* getOrdersList() {

  const response = yield call(fetchOrdersListInitial)
  if (response.status !== 'success' || undefined) {
  } else {
    // let successData = response.products;
    const totalProducts = _.get(response, 'data.totalDocs', 0);
    const response2 = yield call(fetchOrdersListFinal, totalProducts)
    if (response2.status !== 'success') {
    } else {
      let successData2 = response2.data.docs;
      yield put(receiveOrdersList(successData2));
    }
  }


}

export function* postSearchField(payload) {

  const response = yield call(fetchSearchResults, payload.data)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.products;
    yield put(receiveOrdersList(successData));
  }


}


export function* getOrderDetails(payload) {
  if (payload.data && payload.data._source) {
    yield put(receiveOrderDetailsData(payload.data._source))
  } else {
    yield put(receiveOrderDetailsData(payload.data))
  }
}

export function* getDateAndTimeResults(payload) {

  const response = yield call(fetchDataAndTimeResults, payload.data)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.products;

    yield put(receiveOrdersList(successData));

  }
}

export function* getStateandStatusResults(payload) {

  const response = yield call(fetchStateAndStatusResults, payload.data)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.products;
    yield put(receiveOrdersList(successData));

  }
}

export function* getLocationList() {

  const response = yield call(fetchLocationList)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.locations;
    yield put(receiveLocationList(successData));

  }
}

export function* getSelectedLocation(payload) {
  yield put(receiveSelectedLocation(payload.data));

}
export function* getTurnstileLists() {

  const response = yield call(fetchTurnstileList)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.turnstile;
    yield put(receiveTurnstileList(successData));

  }
}

export function* getGondolaList() {

  const response = yield call(fetchGondolaList)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.gondola;
    yield put(receiveGondolaList(successData));

  }
}

export function* getSelectedGondola(payload) {
  yield put(receiveGondolaSelected(payload.data));
}

export function* getlocation(payload) {
}

export function* postGroundsplot(payload) {

  const response = yield call(fetchGroundsplot, payload.data, payload.data2)
  if (response.status !== 201 || undefined) {
  } else {
    let successData = response.groundsplot;
    notify('✔️ Groundsplot !');


  }
}


export function* postCartManager(payload) {

  const response = yield call(fetchCartManager, payload.data)
  if (response.status !== 201 || undefined) {
  } else {
    let successData = response.cartManager;
    notify('✔️ cartManager !');


  }
}

export function* getCameraList() {

  const response = yield call(fetchCameraList)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.camera;
    yield put(receiveCameraList(successData));



  }
}
export function* postCameraAndGpuData(payload) {

  const response = yield call(fetchCameraAndGpu, payload.data, payload.data2)
  if (response.status !== 201 || undefined) {
  } else {
    let successData = response.cameraAndGpu;
    notify('✔️ CameraAndGpu !');


  }
}

export function* postCameraUpdate(payload) {

  const response = yield call(fetchCameraUpdate, payload.data)
  if (response.status !== 201 || undefined) {
  } else {
    let successData = response.cameraUpdate;
    notify('✔️ Camera Updated !');


  }
}

export function* postStoreDimension(payload) {

  const response = yield call(fetchStoreDimension, payload.data, payload.data2, payload.data3)
  if (response.status !== 201 || undefined) {
  } else {
    let successData = response.storeDimension;
    notify('✔️ storeDimension created !');


  }
}

export function* getGondolaDetails(payload) {

  const response = yield call(fetchGondolaDetails, payload.data)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.gondola;
    yield put(receiveGondolaDetails(successData));

  }
}

export function* postLocationSetup(payload) {

  const response = yield call(fetchLocationSetup, payload.data, payload.data2, payload.data3)
  if (response.status !== 201 || undefined) {
  } else {
    let successData = response.locationSetup;
    notify('✔️ location setup completed !');


  }
}



export function* getLocationSetup(payload) {

  const response = yield call(fetchGetLocationDataStepper, payload.data)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.locationData;
    yield put(receiveLocationSetup(successData));


  }
}


export function* getStoreDimensionStepperData(payload) {

  const response = yield call(fetchStoreDimensionStepper, payload.data)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.storeDimensionData;
    yield put(receiveStoreDimensionStep(successData));


  }
}



export function* getGondaStepData(payload) {

  const response = yield call(fetchGondaStepData, payload.data)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.storeDimensionData;
    yield put(receiveGondolaStep(successData));


  }
}


export function* getCameraAndGpuStepDataValue(payload) {

  const response = yield call(fetchCameraAndGPUdata, payload.data)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.storeDimensionData;
    yield put(receiveCameraAndGPUStep(successData));


  }
}


export function* getCartManagerStepData(payload) {

  const response = yield call(fetchCartManagerStepData, payload.data)
  if (response.status !== 200 || undefined) {
  } else {
    let successData = response.storeDimensionData;
    yield put(receiveCartManagerStep(successData));


  }
}






export default function* adminSaga() {
  yield takeEvery(GET_SIGNIN_DATA, getSignInAuthenication)
  yield takeEvery(GET_SIGNUP_DATA, getSignUpData)
  yield takeEvery(USER_ROLE_INFORMATION, getUserRole)
  yield takeEvery(USER_DECODE_INFORMATION, getUserDecodeInfo)
  yield takeEvery(GET_PRODUCT_LIST, getProductListData)
  yield takeEvery(GET_CREATE_PRODUCT, postCreateProduct)
  yield takeEvery(GET_BARCODE_TYPE, getBarCodeType)
  yield takeEvery(GET_STORE_TYPE, getStoreType)
  yield takeEvery(GET_UNIT_OF_MEASURE, getUnitOfMeasure)
  yield takeEvery(GET_PRODUCT_DETAILS, getProductDetails)
  yield takeEvery(GET_EDIT_PRODUCT, postEditProduct)
  yield takeEvery(EDIT_PRODUCT_PARAMS, getEditProductParams)
  yield takeEvery(GET_DELETE_PRODUCT, postDeleteProduct)
  yield takeEvery(GET_DELETE_MULTIPLE_PRODUCT, postDeleteMultipleProduct)
  yield takeEvery(GET_CATEGORY_LIST, getCatergoryList)
  yield takeEvery(GET_SUB_CATEGORY_LIST, getSubCategoryList)
  yield takeEvery(GET_PRODUCT_GROUP, getProductGroupList)
  yield takeEvery(GET_CREATE_PRODUCT_GROUP, postCreateProductGroup)
  yield takeEvery(PRODUCT_GROUP_EDIT_PARAMS, getProductGroupParams)
  yield takeEvery(ASSOCIATE_PRODUCT_GROUP, postAssociateProductGroup)
  yield takeEvery(GET_DELETE_PRODUCT_GROUP, postDeleteProductGroup)
  yield takeEvery(GET_PRODUCT_GROUP_DETAILS, getProductGroupDetails)
  yield takeEvery(GET_ORDERS_LIST, getOrdersList)
  yield takeEvery(GET_SEARCH_TERM, postSearchField)
  yield takeEvery(GET_ORDER_DETAILS, getOrderDetails)
  yield takeEvery(GET_DATE_TIME, getDateAndTimeResults)
  yield takeEvery(GET_ORDER_STATE_STATUS, getStateandStatusResults)


  yield takeEvery(GET_LOCATION_LIST, getLocationList)
  yield takeEvery(GET_SELECTED_LOCATION, getSelectedLocation)
  yield takeEvery(GET_TURNSTILE_LIST, getTurnstileLists)
  yield takeEvery(GET_GONDOLA_LIST, getGondolaList)
  yield takeEvery(SELECTED_GONDOLA, getSelectedGondola)
  yield takeEvery(POST_GROUNDSPLOT_DATA, postGroundsplot)
  yield takeEvery(POST_CART_MANAGER, postCartManager)
  yield takeEvery(GET_CAMERA_LIST, getCameraList)
  yield takeEvery(POST_CAMERA_GPU, postCameraAndGpuData)
  yield takeEvery(CAMERA_UPDATE, postCameraUpdate)
  yield takeEvery(POST_STORE_DIMENSION, postStoreDimension)
  yield takeEvery(GET_GONDOLA_DETAILS, getGondolaDetails)
  yield takeEvery(POST_LOCATION_SETUP, postLocationSetup)

  yield takeEvery(GET_LOCATION_SETUP, getLocationSetup)
  yield takeEvery(GET_STORE_DIMENSION_STEP, getStoreDimensionStepperData)
  yield takeEvery(GET_GONDOLA_STEP, getGondaStepData)
  yield takeEvery(GET_CAMERA_AND_GPU_STEP, getCameraAndGpuStepDataValue)
  yield takeEvery(GET_CART_MANAGER_STEP, getCartManagerStepData)












































}
