/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react"
import _ from "lodash"
import useStyles from "./styles"
import MaterialDataGrid from "../../../../components/MaterialDataGrid"
import locationDataApi from "../../../../services/masterData/locations/api"
import storageDataApi from "../../../../services/masterData/storages/api"
import {
  productDataApiMethods,
  productStorageDataApiMethods,
} from "../../../../services/masterData/products/api"
import { Button, Typography } from "@material-ui/core"
import update from "immutability-helper"
import * as Adm from "@adm"
import LocationLookupFixture from "./LocationLookupFixture"
import ProductLookupFixture from "./ProductLookupFixture"
import StorageLookupFixture from "./StorageLookupFixture"
import KCLocationLookupFixture from "./KCLocationLookupFixture"
import { connect } from "react-redux"

const columnData = [
  {
    field: "locationName",
    title: "Store Name",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 10,
  },
  {
    field: "locationAddress.line1",
    title: "Store Address",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 15,
  },
  // {
  //   field: "locationType.name",
  //   title: "Store Type",
  //   editable: false,
  //   editor: "text",
  //   border: true,
  //   filter: true,
  //   show: true,
  //   min_width: 15,
  // },
]

const productColumnData = [
  {
    field: "title",
    title: "Product Name",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 10,
  },
  {
    field: "barcode.name",
    title: "Item Barcode Value",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 12,
  },
  {
    field: "stockCode",
    title: "SKU",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 17,
  },
]

const defaultDataState = {
  filter: {
    logic: "and",
    filters: [],
  },
  sort: [],
  take: 10,
  skip: 0,
}

const Table = ({
  pageName = "",

  isWriteAllowed,
  columns = null,
  hierarchyMapping = false,
  isLookUp = false,
  onPrimaryActionClick = () => { },
  preSelectedItems = [],
  gridNoRecordsText,
  triggerDataFetch = { uncategorized: false, categorized: false },
  setTriggerDataFetch = () => { },
  dataFetchTriggerKey = "uncategorized",
  title = "UnCategorized Locations",
  selectedHierarchyIds = [],
  fetchOnlyOnSelectedIdUpdate = false,
  dataUniqueIdPath = "_id",
  primaryActionLabel = "ASSOCIATE",
  additionalFilters = [
    { field: "locationHierarchy", operator: "eq", value: "" },
  ],
  lookupType = "location",
  hideCheckBoxAll = false,
  isInventory = false,
  searchObj = {},
  filterSelectedItems = {},
  SDCFilter = [],
  ShowCheckBox = true,
  isKC,
  locationBasedProduct = false,
}) => {
  const classes = useStyles()
  const [gridState, setGridState] = useState({ dataState: defaultDataState })
  const [selectedItems, setSelectedItems] = useState(
    () => preSelectedItems || []
  )
  const [rawData, setRawData] = useState({})
  const [gridData, setGridData] = useState({ data: [] })
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setSelectedItems(selectedItems)
  }, [selectedItems])

  useEffect(() => {
    if (!isLookUp) {
      handleClearSelection()
    }
  }, [selectedHierarchyIds])

  const adopterFunction = (
    dataState,
    page,
    limit,
    additionalFilters = [],
    searchObj = {}
  ) => {
    let sort = {}
    if (isLookUp) {
      sort = dataState.sort.reduce((acc, o) => {
        acc[o.field] = o.dir === "asc" ? 1 : -1
        return acc
      }, {})
      let cityFilter = []
      let stateFilter = []
      let brandFilter = []
      let manufacturerFilter = []
      let hierarchyFilterVal = ""
      let locTypeFilter = ""
      let locFilter = ""
      let productLoc = ""
      let locStoreTypeFilter = ""
      searchObj?.dropdown?.map((o) => {
        if (lookupType === "location") {
          if (o.label === "City") {
            cityFilter = o.filteredItems.join()
          }
          if (o.label === "State") {
            stateFilter = o.filteredItems.join()
          }
        } else {
          if (o.label === "Brand") {
            brandFilter = o.filteredItems.join()
          }
          if (o.label === "Manufacturer") {
            manufacturerFilter = o.filteredItems.join()
          }
        }
      })
      additionalFilters?.map((x) => {
        if (x.field === "locationType.name") {
          locTypeFilter = x.value
        }
        if (x.field === "locations.locationId") {
          locFilter = x.value
        }
        if (x.field === "storeType.name") {
          locStoreTypeFilter = x.value
        }
        if (x.field === "locationId") {
          productLoc = x.value
        }
        if (x.field === "locationHierarchy") {
          hierarchyFilterVal = x.value
        } else if (x.field === "product_mapped_category_id") {
          hierarchyFilterVal = x.value
        }
      })

      let tempFilter = {}

      if (lookupType === "location") {
        tempFilter = {
          "locationAddress.city": cityFilter.split(","),
          "locationAddress.region": stateFilter.split(","),
          locationHierarchy: hierarchyFilterVal,
          "locationType.name": locTypeFilter,
          "storeType.name": locStoreTypeFilter,
        }
        if (cityFilter.length === 0) {
          delete tempFilter["locationAddress.city"]
        }
        if (stateFilter.length === 0) {
          delete tempFilter["locationAddress.region"]
        }
        if (hierarchyFilterVal === "") {
          delete tempFilter["locationHierarchy"]
        }
        if (locTypeFilter === "") {
          delete tempFilter["locationType.name"]
        }
        if (locStoreTypeFilter === "") {
          delete tempFilter["storeType.name"]
        }
      } else {
        tempFilter = {
          brand: brandFilter?.length > 0 && brandFilter?.split(","),
          manufacturer:
            manufacturerFilter?.length > 0 && manufacturerFilter?.split(","),
          product_mapped_category_id: hierarchyFilterVal,
          "locations.locationId": locFilter,
          locationId: productLoc,
        }

        if (brandFilter?.length === 0) {
          delete tempFilter["brand"]
        }
        if (manufacturerFilter?.length === 0) {
          delete tempFilter["manufacturer"]
        }
        if (hierarchyFilterVal === "") {
          delete tempFilter["product_mapped_category_id"]
        }
        if (locFilter === "") {
          delete tempFilter["locations.locationId"]
        }
        if (productLoc === "") {
          delete tempFilter["locationId"]
        }
      }

      let tempFilterKit = []
      if (pageName === "storage" && lookupType === "product") {
        /**
         * @TODO_FILTER_BRAND_AND_MANIFACTURE
         */
        const data = additionalFilters.filter(
          (itm) => itm.field === "storage_unit_id"
        )
        if (data.length > 0) {
          const [_x] = data
          tempFilterKit = [_x]
        }
      }

      return {
        page,
        limit,
        filter:
          pageName === "storage" && lookupType === "product"
            ? [...tempFilterKit]
            : tempFilter,
        sort,
        searchTerm: searchObj?.searchTerm,
      }
    } else {
      sort = dataState.sort.reduce((acc, o) => {
        acc[o.field] = o.dir === "asc" ? 1 : -1
        return acc
      }, {})

      return {
        page,
        limit,
        filter: [
          ...(dataState?.filter?.filters || []),
          ...(additionalFilters || []),
        ],
        sort,
      }
    }
  }

  const fetchData = async () => {
    setLoading(true)
    if (lookupType === "product") {
      let productAPI =
        pageName === "storage"
          ? productStorageDataApiMethods
          : productDataApiMethods

      if (locationBasedProduct) {
        let body = adopterFunction(
          gridState.dataState,
          Math.floor(gridState.dataState.skip / gridState.dataState.take) + 1,
          gridState.dataState.take,
          additionalFilters,
          searchObj
        )
        productAPI
          .getProductMapping(body)
          .then((resp) => {
            setRawData(_.get(resp, "data.data"))
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
          })
      } else if (isLookUp) {
        productAPI
          .elasticSearchLocation(
            adopterFunction(
              gridState.dataState,
              Math.floor(gridState.dataState.skip / gridState.dataState.take) +
              1,
              gridState.dataState.take,
              additionalFilters,
              searchObj
            )
          )
          .then((resp) => {
            let tempDocs = []
            _.get(resp, "data.data.docs").map((o) =>
              tempDocs.push({ ...o, _id: o._id })
            )
            let tempArr = {
              docs: tempDocs,
              totalDocs: _.get(resp, "data.data.totalDocs"),
            }
            setRawData(tempArr)
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
          })
      } else {
        productAPI
          .getProducts(
            adopterFunction(
              gridState.dataState,
              Math.floor(gridState.dataState.skip / gridState.dataState.take) +
              1,
              gridState.dataState.take,
              additionalFilters
            )
          )
          .then((resp) => {
            setRawData(_.get(resp, "data.data"))
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
          })
      }
    } else {
      if (isLookUp) {
        if (lookupType === "storage") {
          storageDataApi
            .elasticSearchLocation(
              adopterFunction(
                gridState.dataState,
                Math.floor(
                  gridState.dataState.skip / gridState.dataState.take
                ) + 1,
                gridState.dataState.take,
                additionalFilters,
                searchObj
              )
            )
            .then((resp) => {
              let tempDocs = []
              _.get(resp, "data.data.docs").map((o) =>
                tempDocs.push({ ...o, _id: o._id })
              )
              let tempArr = {
                docs: tempDocs,
                totalDocs: _.get(resp, "data.data.totalDocs"),
              }
              setRawData(tempArr)
              setLoading(false)
            })
            .catch((err) => {
              setLoading(false)
            })
        } else {
          locationDataApi
            .elasticSearchLocation(
              adopterFunction(
                gridState.dataState,
                Math.floor(
                  gridState.dataState.skip / gridState.dataState.take
                ) + 1,
                gridState.dataState.take,
                additionalFilters,
                searchObj
              )
            )
            .then((resp) => {
              let tempDocs = []
              _.get(resp, "data.data.docs").map((o) =>
                tempDocs.push({ ...o, _id: o._id })
              )
              let tempArr = {
                docs: tempDocs,
                totalDocs: _.get(resp, "data.data.totalDocs"),
              }
              setRawData(tempArr)
              setLoading(false)
            })
            .catch((err) => {
              setLoading(false)
            })
        }
      } else {
        locationDataApi
          .getAllLocations(
            adopterFunction(
              gridState.dataState,
              Math.floor(gridState.dataState.skip / gridState.dataState.take) +
              1,
              gridState.dataState.take,
              additionalFilters
            )
          )
          .then((resp) => {
            setRawData(_.get(resp, "data.data"))
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
          })
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [gridState.dataState, SDCFilter])

  useEffect(() => {
    setGridState((c) => ({ dataState: { ...c.dataState, skip: 0 } }))
  }, [
    filterSelectedItems.citySelectedItems,
    filterSelectedItems.stateSelectedItems,
    filterSelectedItems.brandSelectedItems,
    filterSelectedItems.manufacturerSelectedItems,
    filterSelectedItems.searchTerm,
  ])

  useEffect(() => {
    let productAPI =
      pageName === "storage"
        ? productStorageDataApiMethods
        : productDataApiMethods
    if (triggerDataFetch[dataFetchTriggerKey] || fetchOnlyOnSelectedIdUpdate) {
      if (lookupType === "product") {
        if (locationBasedProduct) {
          let body = adopterFunction(
            gridState.dataState,
            Math.floor(gridState.dataState.skip / gridState.dataState.take) + 1,
            gridState.dataState.take,
            additionalFilters,
            searchObj
          )
          productAPI
            .getProductMapping(body)
            .then((resp) => {
              setRawData(_.get(resp, "data.data"))
              setLoading(false)
            })
            .catch((err) => {
              setLoading(false)
            })
        } else if (isLookUp) {
          productAPI
            .elasticSearchLocation(
              adopterFunction(
                gridState.dataState,
                Math.floor(
                  gridState.dataState.skip / gridState.dataState.take
                ) + 1,
                gridState.dataState.take,
                additionalFilters,
                searchObj
              )
            )
            .then((resp) => {
              let tempDocs = []
              _.get(resp, "data.data.docs").map((o) =>
                tempDocs.push({ ...o, _id: o._id })
              )
              let tempArr = {
                docs: tempDocs,
                totalDocs: _.get(resp, "data.data.totalDocs"),
              }
              setRawData(tempArr)
            })
            .catch((err) => { })
        } else {
          productAPI
            .getProducts(
              adopterFunction(
                gridState.dataState,
                Math.floor(
                  gridState.dataState.skip / gridState.dataState.take
                ) + 1,
                gridState.dataState.take,
                additionalFilters
              )
            )
            .then((resp) => {
              setRawData(_.get(resp, "data.data"))
              setTriggerDataFetch((c) => ({
                ...c,
                [dataFetchTriggerKey]: false,
              }))
            })
            .catch((err) => { })
        }
      } else {
        if (isLookUp) {
          if (isKC) {
            locationDataApi
              .elasticSearchKCLocation(
                adopterFunction(
                  gridState.dataState,
                  Math.floor(
                    gridState.dataState.skip / gridState.dataState.take
                  ) + 1,
                  gridState.dataState.take,
                  additionalFilters,
                  searchObj
                )
              )
              .then((resp) => {
                let tempDocs = _.get(resp, "data.response").map((m) => ({
                  ...m,
                  _id: `${m._id}${m.kcpos_id}`,
                }))
                let tempArr = {
                  docs: tempDocs,
                  totalDocs: _.get(resp, "data.data.totalDocs"),
                }
                setRawData(tempArr)
                setLoading(false)
              })
              .catch((err) => {
                setLoading(false)
              })
          } else {
            if (lookupType === "storage") {
              storageDataApi
                .elasticSearchLocation(
                  adopterFunction(
                    gridState.dataState,
                    Math.floor(
                      gridState.dataState.skip / gridState.dataState.take
                    ) + 1,
                    gridState.dataState.take,
                    additionalFilters,
                    searchObj
                  )
                )
                .then((resp) => {
                  let tempDocs = []
                  _.get(resp, "data.data.docs").map((o) =>
                    tempDocs.push({ ...o, _id: o._id })
                  )
                  let tempArr = {
                    docs: tempDocs,
                    totalDocs: _.get(resp, "data.data.total"),
                  }
                  setRawData(tempArr)
                  setLoading(false)
                })
                .catch((err) => {
                  setLoading(false)
                })
            } else {
              locationDataApi
                .elasticSearchLocation(
                  adopterFunction(
                    gridState.dataState,
                    Math.floor(
                      gridState.dataState.skip / gridState.dataState.take
                    ) + 1,
                    gridState.dataState.take,
                    additionalFilters,
                    searchObj
                  )
                )
                .then((resp) => {
                  let tempDocs = []
                  _.get(resp, "data.data.docs").map((o) =>
                    tempDocs.push({ ...o, _id: o._id })
                  )
                  let tempArr = {
                    docs: tempDocs,
                    totalDocs: _.get(resp, "data.data.total"),
                  }
                  setRawData(tempArr)
                  setLoading(false)
                })
                .catch((err) => {
                  setLoading(false)
                })
            }
          }
        } else {
          locationDataApi
            .getAllLocations(
              adopterFunction(
                gridState.dataState,
                Math.floor(
                  gridState.dataState.skip / gridState.dataState.take
                ) + 1,
                gridState.dataState.take,
                additionalFilters
              )
            )
            .then((resp) => {
              setRawData(_.get(resp, "data.data"))
              setTriggerDataFetch((c) => ({
                ...c,
                [dataFetchTriggerKey]: false,
              }))
            })
            .catch((err) => { })
        }
      }
    }
  }, [triggerDataFetch[dataFetchTriggerKey], selectedHierarchyIds, isKC])

  useEffect(() => {
    if (!_.isEmpty(rawData)) {
      setGridData(() => {
        let x = _.cloneDeep(_.get(rawData, "docs", []))
        let data = x.reduce((acc, o, i) => {
          if (
            _.findIndex(
              selectedItems,
              (c) =>
                _.get(o, dataUniqueIdPath, "*_*") ===
                _.get(c, dataUniqueIdPath, "-_-")
            ) !== -1
          ) {
            o.selected = true
          }
          acc.push(o)
          return acc
        }, [])
        return { data }
      })
    }
  }, [rawData])

  const handleSelection = (e) => {
    let temp = []
    let tempGridData = {}
    let dataItem = e.dataItem
    let existingIdx = _.findIndex(
      selectedItems,
      (o) =>
        _.get(o, dataUniqueIdPath, "*_*") ===
        _.get(dataItem, dataUniqueIdPath, "-_-")
    )
    let idxInDataState = _.findIndex(
      gridData.data,
      (o) =>
        _.get(o, dataUniqueIdPath, "*_*") ===
        _.get(dataItem, dataUniqueIdPath, "-_-")
    )
    if (existingIdx === -1) {
      temp = _.concat(dataItem, selectedItems)
      tempGridData = update(gridData, {
        data: { [idxInDataState]: { $merge: { selected: true } } },
        allSelected: { $set: false },
      })
    } else {
      temp = update(selectedItems, { $splice: [[existingIdx, 1]] })
      tempGridData = update(gridData, {
        data: { [idxInDataState]: { $merge: { selected: false } } },
        allSelected: { $set: false },
      })
    }
    if ((lookupType === "product" && isInventory) || hideCheckBoxAll) {
      setSelectedItems([])
      temp = []
      tempGridData.data.map((object, i) => {
        if (dataItem._id === tempGridData.data[i]._id) {
          tempGridData.data[i].selected = true
          update(selectedItems, { $push: [dataItem] })
          temp.push(dataItem)
        } else {
          tempGridData.data[i].selected = false
        }
      })
    }
    setSelectedItems(temp)
    setGridData(tempGridData)
  }

  const handleClearSelection = () => {
    setSelectedItems([])
    setGridData((c) => {
      return {
        data: c.data.map((x) => {
          x.selected = false
          return x
        }),
      }
    })
  }

  const onHeaderSelectionChange = React.useCallback(async (event) => {
    setAlert(false)
    const checkboxElement = event.syntheticEvent.target
    const checked = checkboxElement.checked
    if (lookupType === "product" && isInventory) {
      await setAlertType("error")
      await setAlertMessage(
        "More than one Product cannot be selected for inventory configuration"
      )
      await setAlert(true)
    } else if (checked) {
      const newRowDataState = event.dataItems
      let newSelectedItems = selectedItems
      newRowDataState?.map((item) => {
        if (checked === true) {
          if (!_.find(newSelectedItems, { locationId: item.locationId })) {
            newSelectedItems.unshift(item)
          }
        } else {
          newSelectedItems = newSelectedItems.filter(function (item1) {
            return item1.locationId !== item.locationId
          })
        }
        return item
      })
      setGridData((c) => {
        let data = _.map(c?.data || [], (o, i) => {
          o.selected = true
          return o
        })
        setSelectedItems((s) => {
          let temp = _.isEmpty(s) ? [] : _.cloneDeep(s)
          var test = _.uniqBy([...temp, ...data], dataUniqueIdPath)
          var test1 = [...test].reverse()
          return _.uniqBy([...temp, ...data], dataUniqueIdPath)
        })
        return {
          allSelected: true,
          data,
        }
      })
    } else {
      setGridData((c) => {
        let data = c.data.map((x) => {
          x.selected = false
          return x
        })

        setSelectedItems((s) => {
          let filteredSelection = _.differenceBy(s, data, dataUniqueIdPath)
          return filteredSelection
        })
        return {
          data,
        }
      })
    }
  }, [])

  return (
    <div className={classes.container}>
      <Adm.BackdropOverlay open={loading} />
      <div className={classes.header}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        {hierarchyMapping ? (
          selectedItems.length > 0 || gridData?.allSelected || isLookUp ? (
            <HeaderActionsContainer
              isLookUp={isLookUp}
              onPrimaryActionClick={() =>
                onPrimaryActionClick(selectedItems, handleClearSelection)
              }
              count={
                selectedItems.length > 0
                  ? selectedItems.length
                  : gridData?.allSelected
                    ? gridData?.data?.length
                    : selectedItems.length
              }
              classes={classes}
              label={primaryActionLabel}
              handleClearSelection={handleClearSelection}
              selectedHierarchyIds={selectedHierarchyIds}
            />
          ) : null
        ) : selectedItems.length > 0 || gridData?.allSelected || isLookUp ? (
          <HeaderActionsContainer
            isLookUp={isLookUp}
            onPrimaryActionClick={() =>
              onPrimaryActionClick(selectedItems, handleClearSelection)
            }
            count={
              selectedItems.length > 0
                ? selectedItems.length
                : gridData?.allSelected
                  ? gridData?.data?.length
                  : selectedItems.length
            }
            classes={classes}
            label={primaryActionLabel}
            handleClearSelection={handleClearSelection}
          />
        ) : null}
      </div>
      <div className={classes.content}>
        <MaterialDataGrid
          isGrid={!true}
          isInlineEditAllowed={isWriteAllowed}
          columnData={
            columns ||
            (lookupType === "storage"
              ? StorageLookupFixture
              : lookupType === "product"
                ? isLookUp
                  ? ProductLookupFixture
                  : productColumnData
                : isLookUp
                  ? isKC
                    ? KCLocationLookupFixture
                    : LocationLookupFixture
                  : columnData)
          }
          gridState={gridState}
          setGridState={setGridState}
          rowData={gridData}
          setRowData={setGridData}
          isLookup
          number_of_items={_.toFinite(rawData?.totalDocs || 0)}
          selectionChange={(event) => handleSelection(event)}
          otherProps={{ style: { height: "100%" } }}
          headerSelectionChange={onHeaderSelectionChange}
          buttonCount={1}
          gridNoRecordsText={gridNoRecordsText}
          checkBoxWidth={isLookUp ? "" : "30px"}
          hideCheckboxAll={hideCheckBoxAll}
          ShowCheckBox={ShowCheckBox}
        />
      </div>
      {alert ? (
        <Adm.SnackBar
          open={true}
          autoCloseTrigger={() => {
            setAlert(alert)
          }}
          message={alertMessage}
          type={alertType}
        />
      ) : null}
    </div>
  )
}

const HeaderActionsContainer = ({
  onPrimaryActionClick = () => { },
  classes = {},
  count = 0,
  label = "Primary Action",
  isLookUp = false,
  handleClearSelection = () => { },
}) => {
  return (
    <div className={classes.headerActionsContainer}>
      {count > 0 ? (
        <>
          <Typography variant="h5" className={classes.subTitle}>
            {count} {count > 1 ? " " : " "}Selected
          </Typography>
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "var(--primaryColor)",
              color: "var(--white)",
            }}
            disabled={isLookUp ? false : count === 0}
            onClick={onPrimaryActionClick}
          >
            {label}
          </Button>
          <Button
            color="primary"
            style={{ fontWeight: "bold", color: "var(--primaryColor)" }}
            disableElevation
            onClick={handleClearSelection}
          >
            Clear
          </Button>
        </>
      ) : null}
    </div>
  )
}

export default Table
