import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS
let profileData = {}

export default function reducer(state = profileData, actions) {
    switch (actions.type) {
        case types.PROFILE_DATA:
            return actions.profileData

        default:
            return state
    }
}