import * as React from "react"

function useAddressCardUIState(props) {
  const [edit, setEdit] = React.useState(true)
  const [data, setData] = React.useState([])
  return {
    edit,
    setEdit,
    data,
    setData,
  }
}

export { useAddressCardUIState }
