import React from "react"
import { TextField, Grid, Typography } from "@material-ui/core"
import { IconButton } from "@material-ui/core"
import { CloseIcon } from "@iconsGallery"
import styled from "styled-components"
import * as Utils from "@utils"
import { bool } from "prop-types"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  textInputDisabled: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    }
  },
  textInput: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "var(--primaryColor)",
    },
    '& .MuiFormLabel-root.Mui-focused': {
      "color": "var(--primaryColor)",
    },
  }
})

const AttributeMainWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  position: relative;
`
const AttributeSubWrapper = styled(Grid)`
  width: 100%;
`
const AttributeCloseButton = styled(IconButton)`
  // position: absolute;
  right: 10px;
  top: -5px;
  height: 45px !important;
`

function FacetCell(props) {
  const {
    idx = 0,
    keyText = "",
    valueText = "",
    errorText = "",
    keyUpdate = () => "",
    valueUpdate = () => "",
    removeRecord = () => "",
    isDisableClose = false,
    keyPlaceholder = "Enter Key",
    valuePlaceholder = 'Enter Value',
    isDetailView = false,
    onBlur = () => { }
  } = props || {}
  const [textKey, setKeyText] = React.useState(keyText)
  const [textValue, setValueText] = React.useState(valueText)
  const [textError, setTextError] = React.useState(errorText)

  const classes = useStyles()

  React.useEffect(() => {
    setKeyText(keyText)
  }, [keyText])

  React.useEffect(() => {
    setValueText(valueText)
  }, [valueText])

  React.useEffect(() => {
    setTextError(errorText)
  }, [errorText])
  return (
    <AttributeMainWrapper>
      <AttributeSubWrapper>
        {isDetailView ?
          <>
            <div style={{ display: 'flex' }}>
              <Typography style={{ paddingRight: "15px", width: "42%", marginBottom: "10px", fontWeight: 500, fontSize: '14px' }}>{textKey}</Typography>
              <Typography style={{ marginBottom: "10px", paddingRight: "15px" }}>{textValue}</Typography>
            </div>
          </>
          :
          <>
            <Grid container item md={12} lg={12} sm={12} xs={12} direction="row"  >

              <Grid container direction="row" item md={6} lg={6} sm={6} xs={6} >
                <TextField
                  id={"label" + idx}
                  label={keyPlaceholder}
                  placeholder={keyPlaceholder}
                  variant={"outlined"}
                  value={textKey}
                  error={Boolean(textError)}
                  helperText={textError}
                  onBlur={onBlur}
                  onKeyPress={onBlur}
                  onChange={(e) => {
                    setKeyText(e.target.value)
                    keyUpdate(e.target.value, idx)
                  }}
                  size="small"
                  style={{ width: "100%", marginBottom: "0px" }}
                  className={classes.textInput}
                />
              </Grid>
              <Grid container item md={6} lg={6} sm={6} xs={6}>
                <TextField
                  id={"value" + idx}
                  label={valuePlaceholder}
                  placeholder={valuePlaceholder}
                  variant="outlined"
                  value={textValue}
                  // label="Value"
                  onChange={(e) => {
                    setValueText(e.target.value)
                    valueUpdate(e.target.value, idx)
                  }}
                  size="small"
                  style={{ width: "100%", marginBottom: "0px", marginLeft: "15px", }}
                  className={classes.textInput}
                />
              </Grid>
            </Grid>
          </>
        }
      </AttributeSubWrapper>


      {(isDisableClose === false && isDetailView === false) && (
        <AttributeCloseButton onClick={() => removeRecord(idx)} style={{ padding: "5px", right: "-5px" }}>
          <CloseIcon />
        </AttributeCloseButton>
      )}
    </AttributeMainWrapper>
  )
}

export { FacetCell }
