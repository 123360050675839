import React from "react"
import { Button, ButtonGroup, MenuItem, Menu } from "@material-ui/core"

function QTY(props) {
  const {
    cellsCount = 0,
    maxCellWidth = 0,
    count = 1,
    onUpdateQTY = () => "",
    disabled = false,
    totalWidth = "0",
    isEdit = false,
    minCellWidth = 0,
    isEnableNumberDropdown = true,
    remainingWidth = 0,
  } = props || {}

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const [getState, setState] = React.useState({ counter: 0 })
  React.useEffect(() => {
    setState({ counter: count })
  }, [count])
  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (anchorEl !== null) {
          document.querySelector(".cls-qty-active-sk").scrollIntoView()
        }
      }, 300)
    }
  }, [open])

  const handleIncrement = () => {
    setState((state) => {
      let lcState = { counter: state.counter + 1 }
      onUpdateQTY(lcState)
      return lcState
    })
  }

  const handleDecrement = () => {
    setState((state) => {
      let lcState = { counter: state.counter - 1 }
      onUpdateQTY(lcState)
      return lcState
    })
  }

  const displayCounter = getState.counter > 0

  const leastCheck = getState.counter <= minCellWidth

  const renderDropdown = () => {
    return (
      <div>
        <Button
          className="cls-qty-dropdown-btn-kit"
          style={{
            backgroundColor: "#fff",
            color: "black",
            fontSize: 14,
            border: "1px solid #c4c4c4",
          }}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          {getState.counter}
        </Button>
        <Menu
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              transform: "translateX(0%) translateY(20%)",
              height: 140,
            },
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            style: {
              padding: 0,
            },
          }}
        >
          {Array(totalWidth)
            .fill(0)
            .map((_, index) => {
              let lcIndex = index + 1
              let maxWidth =
                totalWidth || cellsCount === 1 ? totalWidth : maxCellWidth
              let isMinValueDisable = !(
                minCellWidth <= lcIndex && maxWidth >= lcIndex
              )
              return (
                <MenuItem
                  value={lcIndex}
                  className={
                    getState.counter === lcIndex ? "cls-qty-active-sk" : ""
                  }
                  style={{
                    backgroundColor:
                      getState.counter === lcIndex ? "#99368d" : "transparent",
                    color:
                      getState.counter === lcIndex
                        ? "#fff"
                        : isMinValueDisable
                        ? "#000"
                        : "#99368d",
                    pointerEvents: isMinValueDisable
                      ? "none"
                      : getState.counter === lcIndex
                      ? "none"
                      : "unset",
                    opacity: isMinValueDisable ? 0.5 : 1,
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    let lcState = { ...getState, counter: lcIndex }
                    setState(lcState)
                    onUpdateQTY(lcState)
                    setAnchorEl(null)
                  }}
                >
                  {lcIndex}
                </MenuItem>
              )
            })}
        </Menu>
      </div>
    )
  }

  const renderInput = () => {
    return (
      <input
        autoFocus={isEdit}
        disabled={!isEdit}
        style={{
          backgroundColor: "#fff",
          color: "black",
          fontSize: 14,
          width: 40,
          border: "1px solid #c4c4c4",
          height: 33,
          margin: 0,
          textAlign: "center",
          fontWeight: "bold",
        }}
        key={getState.counter}
        type="number"
        min="1"
        max={`${totalWidth}`}
        // defaultValue={getState.counter}
        value={getState.counter}
        onChange={(event) => {
          // let value = event.target.value
          // value = value === "" ? "1" : value
          // value = Number(value)
          // value = value === 0 ? 1 : value
          // if (value <= 0) {
          //   value = 1
          // } else {
          //   if (totalWidth < value) {
          //     value = totalWidth
          //   }
          // }

          let { value, min, max } = event.target
          value = Math.max(Number(min), Math.min(Number(max), Number(value)))
          let lcState = { counter: value }
          setState({ ...lcState })
          onUpdateQTY({ ...lcState })
        }}
      />
    )
  }

  return (
    <ButtonGroup size="small" aria-label="small outlined button group">
      <Button
        className="cls-minus-count"
        disabled={leastCheck}
        style={{
          backgroundColor: "#fff",
          color: "black",
          fontSize: 14,
          opacity: leastCheck ? 0.5 : 1,
        }}
        onClick={handleDecrement}
      >
        {`-`}
      </Button>
      {isEnableNumberDropdown ? renderDropdown() : renderInput()}
      <Button
        disabled={disabled}
        className="cls-plus-count"
        style={{
          backgroundColor: "#fff",
          color: "black",
          fontSize: 14,
          opacity: disabled ? 0.5 : 1,
        }}
        onClick={handleIncrement}
      >
        {`+`}
      </Button>
    </ButtonGroup>
  )
}

export { QTY }
export default QTY
