import * as React from "react"
import { TabelHeader } from "./TabelHeader"
import { TableLocation } from "./TableLocation"
import {
  Box
} from "@material-ui/core"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: `calc(100% - 120px)` }}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

function CustomTableUI(props) {
  const { isWriteAllowed, ShowCheckBox, menutype = 'master', historyPopup = true, isOnRowClick = true,
    hideCheckboxAll = false, loading, isLookup = false } = props;

  const {
    displayTableHeader = true
  } = props || {}
  const tableAttributes = {
    ...props,
  }


  const deleteAnId = (item) => {
    props.deleteAnId(item)
  }

  const [tabValue, setTabValue] = React.useState(0)
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const deleteMultipleId = (item) => {
    props.deleteMultipleId(item)
  }
  return (
    <>
      {!isLookup &&
        <TabelHeader dropDownList={props.dropDownList} locationId={props.locationId} handleDedupeModal={props.handleDedupeModal} showLocationicon={props.showLocationicon} {...tableAttributes} deleteMultipleId={deleteMultipleId} tabValue={tabValue} handleTabChange={handleTabChange} />}
      {props.tabWindows.map((item, idx) => {
        if (item.src === "Default") {
          return <TabPanel key={idx} value={tabValue} index={idx}>
            <TableLocation {...tableAttributes} deleteAnId={deleteAnId} isWriteAllowed={isWriteAllowed}
              ShowCheckBox={ShowCheckBox} menutype={menutype} isOnRowClick={isOnRowClick}
              hideCheckboxAll={hideCheckboxAll} isLookup={isLookup} loading={loading} historyPopup={historyPopup} /> </TabPanel>
        }
        return <TabPanel value={tabValue} index={idx}>
          <Box borderTop={1} style={{ borderColor: "#CCC", backgroundColor: "#FFF", height: "100%" }}>
            {item.src}
          </Box>
        </TabPanel>
      })}
    </>
  )
}

export { CustomTableUI }
export default CustomTableUI
