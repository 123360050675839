import React, { useEffect } from "react"
import styled from "styled-components/macro"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography as MuiTypography, IconButton, Card, Collapse, Tooltip } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { spacing } from "@material-ui/system"
import "./styles.css"
import Divider from "@material-ui/core/Divider"
import {
  CheckCircleIcon,
  ChevronUp,
  ChevronDown,
  Slash,
  Edit,
} from "@iconsGallery"
import * as Utils from "@utils"
import * as Features from "@features"
import * as Adm from "@adm"
import moment from "moment"
import { useState } from "react"

const MainWrapper = styled(Grid)`
  .cls-open-calender {
    display: flex;
    align-items: center;
  }
  .cls-timezone {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }
  .cls-close-calender {
    display: flex;
    align-items: center;
    padding-top: 0px;

    .cls-calender-date-native {
      margin-top: 0px;
    }
  }

  .cls-open-date-label {
    margin-right: 68px;
  }
  .cls-close-date-label {
    margin-right: 55px;
  }
`
const Typography = styled(MuiTypography)(spacing)
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 150,
  },
  contolChildGridSecond: {
    display: "flex",
    flexDirection: "row",
    marginTop: 15,
    marginBottom: 15,
  },
  contolChildGridFirst: (width) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
    marginTop: 15,
    paddingLeft: 20 + width,
    fontSize: "13px",
    marginRight: "15px",
  }),

  contolChildGridFirstdivSecSpan: (width) => ({
    paddingLeft: width,
    marginTop: "10px",
    paddingTop: "10px",
  }),
  contolChildGridSecdivFirstSpan: (width) => ({
    paddingLeft: 10 + width,
    fontSize: "14px",
  }),
  autoCompleteHeight: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        "borderColor": "var(--primaryColor)",
      }
    },
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
      width: "100%",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
  },
})

const OpenDate = ({
  isWriteAllowed = false,
  selectedOpenDat,
  selectedCloseDat,
  setAllowPrompt = () => { },
  timeZon = "",
  editMode,
  setEditMode,
  saveHandler,
  slashHandler,
  handleDateChange,
  handleTimeZoneChange,
  loading,
  isAnyChanges,
  setAnyChanges,
  defaultTimeZone,
  isTablet = false
}) => {
  console.log("open date", editMode)
  const { useWindowSize } = Utils || {}
  const [height, width] = useWindowSize()
  const [collapseOpen, setCollapseOpen] = React.useState(false)
  const classes = useStyles(width)
  const { t } = useTranslation()

  const [selectedOpenDate, setSelectedOpenDate] = React.useState(
    selectedOpenDat
  )
  const [selectedCloseDate, setSelectedCloseDate] = React.useState(
    selectedCloseDat
  )
  const [timeZone, setTimeZone] = React.useState()
  const [timeZoneOpen, setTimeZoneOpen] = React.useState(false)
  const [open, setOpen] = useState(false)
  const closeDateValidation =
    selectedCloseDate < selectedOpenDate && selectedCloseDate !== ""

  useEffect(() => {
    setTimeZone(timeZon)
  }, [timeZon])

  useEffect(() => {
    setSelectedOpenDate(selectedOpenDat)
    setSelectedCloseDate(selectedCloseDat)

    return () => {

    }
  }, [selectedCloseDat, selectedOpenDat])

  const onGlobalUpdate = (name, value = "") => {
    if (name === "openDate") {
      value === "" ? setAnyChanges(false) : setAnyChanges(true)
    } else {
      setAnyChanges(true)
    }
  }

  return (
    <MainWrapper item lg={12} md={12} sm={12} xs={12}>
      <Adm.BackdropOverlay open={loading} />
      <Card
        style={{
          width: "100%",
          boxShadow:
            " 0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
          borderRadius: "8px",
        }}
      >
        <Grid
          container
          // row="true"
          direction="row"
          alignContent="center"
          alignItems="center"
          style={{ justifyContent: "space-between", paddingLeft: "16px", height: "60px" }}
        >
          <Grid item>
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bold" }}
            >
              {t("Open Date")}
            </Typography>
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <div>
              {collapseOpen ? (
                <div>
                  {editMode ? (
                    <>
                      <Tooltip title="Cancel" placement="top">
                        <IconButton
                          onClick={(props) => {
                            slashHandler(props)
                            setTimeZone(defaultTimeZone)
                            setAnyChanges(false)
                            setAllowPrompt(false)
                          }}
                        >
                          <Slash
                            size={20}
                            style={{
                              color: "red",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Save" placement="top">
                        <IconButton
                          style={{
                            pointerEvents: `${isAnyChanges
                              ? !closeDateValidation
                                ? "unset"
                                : "none"
                              : "none"
                              }`,
                          }}
                          onClick={saveHandler}
                        >
                          <CheckCircleIcon
                            fontSize="small"
                            style={{
                              color: `${isAnyChanges
                                ? !closeDateValidation
                                  ? "#4CAF50"
                                  : "#CCC"
                                : "#CCC"
                                }`,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Edit" placement="top">
                        <IconButton disabled={isWriteAllowed === true ? false : true} onClick={() => {
                          setEditMode(true)
                          setAllowPrompt(true)
                        }}>
                          <Edit
                            size={20}
                            style={{
                              color: "rgba(158, 158, 158, 1)",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            {collapseOpen ? (
              <IconButton
                onClick={(props) => {
                  slashHandler(props)
                  setCollapseOpen(!collapseOpen)
                  setTimeZone(defaultTimeZone)
                  setAllowPrompt(false)
                }}
              >
                <ChevronUp style={{ color: "rgba(0, 0, 0, 1)" }} />
              </IconButton>
            ) : (
              <IconButton onClick={() => setCollapseOpen(!collapseOpen)}>
                <ChevronDown style={{ color: "rgba(0, 0, 0, 1)" }} />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Collapse in={collapseOpen} timeout="auto">
          {timeZone === "" && !editMode ? (
            <Features.NoRecordFound />
          ) : (
            <>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Divider />
                <Grid container direction="column">
                  <Grid container direction="row" item lg={12} md={12} sm={12} xs={12} style={{ padding: '16px 16px 25px 16px' }}>
                    <Grid container direction="row" item lg={6} md={9} sm={9} xs={12}>
                      <Grid item lg={3} md={2} sm={4} xs={3} style={{ alignSelf: 'center' }}>
                        <Typography style={{ fontWeight: 'bold' }}>Time Zone{editMode ? <span style={{ color: "red" }}>*</span> : ""}</Typography>
                      </Grid>
                      <Grid item lg={7} md={7} sm={8} xs={8}>
                        {!editMode ?
                          <Typography style={{ fontWeight: 500 }} >{timeZone}</Typography>
                          :
                          <Adm.DropdownFilter
                            list={Utils.JSUtils.getTimeZoneData()}
                            selectedValue={timeZone}
                            onChangeEvent={(e, details) => {
                              handleTimeZoneChange(e, details)
                              onGlobalUpdate()
                            }}
                            popupOpen={timeZoneOpen}
                            setPopupOpen={setTimeZoneOpen}
                            disableClearable={true}
                            classNameTextField={classes.autoCompleteHeight}
                            placeholder={'Select Time Zone'}
                          />
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid container direction="row" item lg={12} md={12} sm={12} xs={12} style={{ padding: '16px 16px 25px 16px', margin: "0px" }} spacing={isTablet ? 4 : 1}>
                    <Grid container direction="row" item lg={6} md={6} sm={9} xs={12} style={{ padding: "10px 0px" }}>
                      <Grid item lg={3} md={3} sm={4} xs={3} style={{ alignSelf: 'center' }}>
                        <Typography style={{ fontWeight: 'bold' }}>Open Date {editMode ? <span style={{ color: "red" }}>*</span> : ""}</Typography>
                      </Grid>
                      <Grid item lg={7} md={8} sm={8} xs={8} >
                        {!editMode ?
                          <Typography style={{ fontWeight: 500 }} >
                            {new Date(selectedOpenDate).toLocaleDateString('en-GB', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                          </Typography>
                          :
                          <div style={{ marginTop: '-16px' }}>
                            <Adm.Calender
                              fullWidth
                              style={{ width: '100%' }}
                              isTimeEnable={false}
                              allowFutureDate={true}
                              date={
                                selectedOpenDate != null
                                  ? moment(new Date(selectedOpenDate)).format(
                                    "yyyy-MM-DD"
                                  )
                                  : null
                              }
                              error={selectedOpenDate === "" ? "Select Open Date" : ""}
                              onUpdateCalender={({ date }) => {
                                handleDateChange(date, "openDate")
                                onGlobalUpdate("openDate", date)
                              }}
                            />
                          </div>
                        }
                      </Grid>
                    </Grid>
                    <Grid container direction="row" item lg={6} md={6} sm={9} xs={12} style={{ padding: "10px 0px" }}>
                      <Grid item lg={3} md={3} sm={4} xs={3} style={{ alignSelf: 'center' }}>
                        <Typography style={{ fontWeight: 'bold' }}>Close Date</Typography>
                      </Grid>
                      <Grid item lg={7} md={8} sm={8} xs={8}>
                        {!editMode ?
                          <Typography style={{ fontWeight: 500 }} >
                            {selectedCloseDate ? new Date(selectedCloseDate).toLocaleDateString('en-GB', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : '-'}
                          </Typography>
                          :
                          <div style={{ marginTop: '-16px' }} >
                            <Adm.Calender
                              fullWidth
                              style={{ width: '100%' }}
                              isTimeEnable={false}
                              allowFutureDate={true}
                              date={
                                selectedCloseDate !== null
                                  ? moment(new Date(selectedCloseDate)).format(
                                    "yyyy-MM-DD"
                                  )
                                  : ""
                              }
                              error={
                                selectedCloseDate < selectedOpenDate &&
                                  selectedCloseDate !== ""
                                  ? "Select valid Close Date"
                                  : ""
                              }
                              onUpdateCalender={({ date }) => {
                                handleDateChange(date, "closeDate")
                                onGlobalUpdate()
                              }}
                            />
                          </div>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Collapse>
      </Card>
    </MainWrapper>
  )
}

export { OpenDate }
export default OpenDate
