import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import _ from "lodash"
import * as constantsAPI from "@constants"
import { OpenDateMain } from './OpenDateMain'
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

const OpenDate = (props) => {
  const { setAllowPrompt = () => { }, isWriteAllowed = false, isTablet = false,
    storeId = "", edit = false, values = {}, handleDateChange = () => { },
    handleTimeZoneChange = () => { }, errors = {}, touched = {}, onGlobalUpdate = () => { }, timeZoneList = [] } = props
  const locationReducerData = useSelector((state) => state.locationReducer)
  const [selectedOpenDate, setSelectedOpenDate] = React.useState(_.get(props.data, "openDate", ""))
  const [selectedCloseDate, setSelectedCloseDate] = React.useState(_.get(props.data, "closeDate", ""))
  const defaultTimeZone = _.get(values, "locationTimezone", "")
  const [locationTimezone, setLocationTimezone] = React.useState(defaultTimeZone)
  const [editMode, setEditMode] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [isAnyChanges, setAnyChanges] = React.useState(false)

  useEffect(() => {
    setSelectedOpenDate(_.get(values, "openDate", ""))
    setSelectedCloseDate(_.get(values, "closeDate", ""))
    setLocationTimezone(_.get(values, "locationTimezone", ""))
  }, [props])

  const stateProps = { isAnyChanges, setAnyChanges }
  return (
    <>
      <OpenDateMain
        errors={errors}
        touched={touched}
        isWriteAllowed={isWriteAllowed}
        defaultTimeZone={defaultTimeZone}
        selectedOpenDat={selectedOpenDate}
        selectedCloseDat={selectedCloseDate}
        locationTimezone={locationTimezone}
        editMode={edit}
        onGlobalUpdate={onGlobalUpdate}
        setEditMode={() => {
          setEditMode(true)
        }}
        handleTimeZoneChange={handleTimeZoneChange}
        handleDateChange={handleDateChange}
        loading={loading}
        setAllowPrompt={setAllowPrompt}
        isTablet={isTablet}
        values={values}
        timeZoneList={timeZoneList}
        {...stateProps}
      />
    </>
  )
}
export { OpenDate }
export default OpenDate
