/* eslint-disable no-sequences */
import Axios from "axios"
import { LOC_HIERARCHY_URLS as URLS } from "../../../utils/url"
import UserService from "../../UserService"
const CancelToken = Axios.CancelToken

//PATH VARIABLE REPLACER
function bindPath(url, pathVal) {
  var newUrl = url
  var pathExpression = /:[a-z0-9]+/gi
  var pathVar
  while (((pathVar = pathExpression.exec(url)), pathVar != null)) {
    let pathVarName = pathVar[0]
    newUrl = newUrl.replace(
      pathVarName,
      pathVal[pathVarName.substring(1, pathVarName.length)]
    )
  }
  return newUrl
}

const locationAxios = Axios.create()

locationAxios.interceptors.request.use(
  async function (config) {
    config.baseURL = config?.baseURL || URLS.baseURL
    // //CHECK REQUEST NEED TO ADD AUTH TOKEN IN THE HEADER
    // if (config.headers.isAuthRequired) {
    //     const token = store.getState().authentication.authToken || config.headers.authKey; //GET TOKEN FROM REDUX STATE
    //     if (token) config.headers.Authorization = `Bearer ${token}`; //ADD AUTHORIZATION HEADER
    // }
    // //DELETE CUSTOM PROPERTY IN THE REQUEST HEADERS
    // delete config.headers.isAuthRequired;
    // delete config.headers.authKey;

    if (UserService.isLoggedIn()) {
      if (UserService.isAuthExpired() === true) {
        const myData = await UserService.updateRefreshToken()
        if (myData === true) {
          let mydataToken = UserService.getToken((res) => {
            return res
          })
          localStorage.setItem("token", mydataToken)
          config.headers["Authorization"] = "Bearer " + mydataToken
          let tenantId =
            localStorage.getItem("tenantId") === null
              ? ""
              : localStorage.getItem("tenantId")
          config.headers["X-Tenant-ID"] = tenantId
          config.headers["X-KC-Realm-ID"] = localStorage.getItem(
            "X-KC-Realm-ID"
          )
          config.headers["X-KC-Host"] = localStorage.getItem("X-KC-Host")
          // return Promise.resolve(config);
        } else {
          localStorage.clear()
          UserService.doLogout()
        }
      } else {
        let tenantId =
          localStorage.getItem("tenantId") === null
            ? ""
            : localStorage.getItem("tenantId")
        config.headers["X-Tenant-ID"] = tenantId
        config.headers["X-KC-Realm-ID"] = localStorage.getItem("X-KC-Realm-ID")
        config.headers["X-KC-Host"] = localStorage.getItem("X-KC-Host")
        config.headers["Authorization"] =
          localStorage.getItem("token") === null
            ? ""
            : "Bearer " + localStorage.getItem("token")
        // return Promise.resolve(config);
      }
    } else {
      localStorage.clear()
      UserService.doLogout()
    }

    //PATH VARIABLES IS AVAILABLE
    // config.headers['Authorization'] = localStorage.getItem('token') === null ? "" : "Bearer " + localStorage.getItem('token')

    if (config.headers.path) {
      try {
        config.url = bindPath(config.url, config.headers.path)
      } catch (e) {}
    }
    delete config.headers.path
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

const locationKcAxios = Axios.create()

locationKcAxios.interceptors.request.use(
  async function (config) {
    config.baseURL = config?.baseURL || URLS.baseURL_KC
    // //CHECK REQUEST NEED TO ADD AUTH TOKEN IN THE HEADER
    // if (config.headers.isAuthRequired) {
    //     const token = store.getState().authentication.authToken || config.headers.authKey; //GET TOKEN FROM REDUX STATE
    //     if (token) config.headers.Authorization = `Bearer ${token}`; //ADD AUTHORIZATION HEADER
    // }
    // //DELETE CUSTOM PROPERTY IN THE REQUEST HEADERS
    // delete config.headers.isAuthRequired;
    // delete config.headers.authKey;

    if (UserService.isLoggedIn()) {
      if (UserService.isAuthExpired() === true) {
        const myData = await UserService.updateRefreshToken()
        if (myData === true) {
          let mydataToken = UserService.getToken((res) => {
            return res
          })
          localStorage.setItem("token", mydataToken)
          config.headers["Authorization"] = "Bearer " + mydataToken
          let tenantId =
            localStorage.getItem("tenantId") === null
              ? ""
              : localStorage.getItem("tenantId")
          config.headers["X-Tenant-ID"] = tenantId
          config.headers["X-KC-Realm-ID"] = localStorage.getItem(
            "X-KC-Realm-ID"
          )
          config.headers["X-KC-Host"] = localStorage.getItem("X-KC-Host")
          // return Promise.resolve(config);
        } else {
          localStorage.clear()
          UserService.doLogout()
        }
      } else {
        let tenantId =
          localStorage.getItem("tenantId") === null
            ? ""
            : localStorage.getItem("tenantId")
        config.headers["X-Tenant-ID"] = tenantId
        config.headers["X-KC-Realm-ID"] = localStorage.getItem("X-KC-Realm-ID")
        config.headers["X-KC-Host"] = localStorage.getItem("X-KC-Host")
        config.headers["Authorization"] =
          localStorage.getItem("token") === null
            ? ""
            : "Bearer " + localStorage.getItem("token")
        // return Promise.resolve(config);
      }
    } else {
      localStorage.clear()
      UserService.doLogout()
    }

    //PATH VARIABLES IS AVAILABLE
    // config.headers['Authorization'] = localStorage.getItem('token') === null ? "" : "Bearer " + localStorage.getItem('token')

    if (config.headers.path) {
      try {
        config.url = bindPath(config.url, config.headers.path)
      } catch (e) {}
    }
    delete config.headers.path
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export const locationApiMethods = {
  getAllLocations: (bodyData) => {
    return locationAxios.post(URLS.endpoints.getLocations, bodyData)
  },
  getAllLocationGroups: (bodyData) => {
    return locationAxios.post(URLS.endpoints.getLocationGroups, bodyData)
  },
  getLocationHierarchy: (cancelSource = { current: undefined }) => {
    if (cancelSource?.current) {
      cancelSource.current()
    }
    return locationAxios.get(URLS.endpoints.getLocationHierarchy, {
      cancelToken: new CancelToken(function executor(c) {
        cancelSource.current = c
      }),
    })
  },
  createHierarchy: (bodyData) => {
    return locationAxios.post(URLS.endpoints.create, bodyData)
  },
  // LocationHierarchy: (bodyData) => {
  //     return locationAxios.post(URLS.endpoints.create, bodyData)
  // },
  // fetchCategory: (categoryID) => {
  //     return productAxios.get(URLS.endpoints.fetchCategory)
  // },
  updateHierarchy: (hierarchyID, bodyData) => {
    return locationAxios.post(URLS.endpoints.updateHierarchy, bodyData, {
      headers: { path: { hierarchyID } },
    })
  },
  updateLocationHierarchyMappings: (bodyData) => {
    return locationAxios.post(
      URLS.endpoints.updateLocationHierarchyMappings,
      bodyData
    )
  },
  updateStorageHierarchyMappings: (bodyData) => {
    return locationAxios.post(
      URLS.endpoints.updateStorageHierarchyMappings,
      bodyData
    )
  },
  // uploadImage: () => {
  //     return productAxios.post(URLS.endpoints.uploadImage)
  // },
  deleteHierarchy: (hierarchyID) => {
    return locationAxios.delete(URLS.endpoints.deleteHierarchy, {
      headers: { path: { hierarchyID } },
    })
  },
  deleteLocationGroups: (bodyData) => {
    return locationAxios.delete(URLS.endpoints.deleteLocationGroups, bodyData)
  },
  deleteLocation: (bodyData) => {
    return locationAxios.delete(URLS.endpoints.deleteLocation, {
      data: bodyData,
    })
  },
  elasticSearchKCLocation: (bodyData) => {
    const sort = bodyData.sort
    const sortValues = Object.entries(sort)
    const payload = {
      params: {
        searchParam: bodyData.searchTerm,
        sortpara: sortValues?.[0]?.[0],
        sortvalue: sortValues?.[0]?.[1] === "asc" ? 0 : 1,
        itemcount: bodyData.limit,
        pagenumber: bodyData.page,
      },
    }
    return locationKcAxios.get(URLS.endpoints.searchKcLocations, payload)
  },
  elasticSearchLocation: (bodyData) => {
    return locationAxios.post(URLS.endpoints.getLocations, bodyData)
  },
}

// export const productDataApiMethods = {
//     getAllProductsWithPagination: (page = 1, limit = 10) => {
//         return productAxios.get(PRODUCT_DATA_URLS.endpoints.getAllWithPagination, { baseURL: PRODUCT_DATA_URLS.baseURL, params: { page, limit } })
//     }
// }

export default locationApiMethods
