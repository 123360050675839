import _ from "lodash"
const { location = {} } = window || {}
let apiEndpoint = process.env.REACT_APP_API_ENDPOINT
apiEndpoint = location.origin.includes("localhost")
  ? apiEndpoint
  : "https://api" + location?.host + "/portal"

const API_BASE_URL = apiEndpoint
//const KC_API_BASE_URL = process.env.REACT_APP_KC_API_ENDPOINT;
const API_BASE_ROLE_URL = apiEndpoint

export const API_URL = `${API_BASE_URL}` //Content Modal

export const API_URL_STORES = `${API_BASE_URL}`

// export const API_URL_CUSTOMERS = `https://secret-ocean-49799.herokuapp.com/${API_BASE_URL}:3001`

export const API_URL_CUSTOMERS = `${API_BASE_URL}`

export const API_URL_CUSTOMERS_NO_PROXY = `${API_BASE_URL}`

export const API_URL_ROLES = `${API_BASE_ROLE_URL}`

export const API_URL_CUSTOMERS_COMMON = `${API_BASE_URL}`

export const API_URL_USER = `${API_BASE_URL}`

export const API_URL_PRICES = `${API_BASE_URL}`

export const API_URL_INVENTORY = `${API_BASE_URL}`

export const API_URL_LOC_HIERARCHY = `${API_BASE_URL}/store/api/stores/locationHierarchy`

export const API_URL_PRODUCT = `${API_BASE_URL}`

export const LOC_HIERARCHY_URLS = {
  baseURL: `${API_BASE_URL}/store/api/stores`,
  //baseURL_KC: `${API_BASE_URL}/api/kc`,
  endpoints: {
    getLocations: "/getlocations",
    getLocationGroups: "/getlocationgroups",
    getLocationHierarchy: "/locationHierarchy",
    create: "/createlocationHierarchy",
    updateHierarchy: "/updatelocationHierarchy/:hierarchyID",
    deleteHierarchy: "/deletelocationHierarchy/:hierarchyID",
    updateLocationHierarchyMappings: "/updateLocationHierarchyMappings",
    deleteLocationGroups: "/store/api/stores/deletelocationgroups",
    deleteLocation: "/store/api/stores/deletemany",
    searchLocations: "/searchlocations",
    searchKcLocations: "/searchLocations",
  },
}
export const STRG_HIERARCHY_URLS = {
  // baseURL: `${API_BASE_URL}/store/api/stores`,
  baseURL: `${API_BASE_URL}/product/api/storages`,
  // baseStorageURL: `${API_BASE_URL}/product/api/storages`,
  //baseURL_KC: `${API_BASE_URL}/api/kc`,
  endpoints: {
    getStorages: "/getStorageUnits",
    getLocations: "/getlocations",
    getLocationGroups: "/getlocationgroups",
    getLocationHierarchy: "/locationHierarchy",
    create: "/createlocationHierarchy",
    updateHierarchy: "/updatelocationHierarchy/:hierarchyID",
    deleteHierarchy: "/deletelocationHierarchy/:hierarchyID",
    updateLocationHierarchyMappings: "/updateLocationHierarchyMappings",
    deleteLocationGroups: "/store/api/stores/deletelocationgroups",
    deleteLocation: "/store/api/stores/deletemany",
    searchLocations: "/searchlocations",
    searchKcLocations: "/searchLocations",
  },
}

export const PRODUCT_CATEGORY_URLS = {
  baseURL: `${API_BASE_URL}/api/productcategory`,
  endpoints: {
    getAll: "/getall",
    create: "/create",
    fetchCategory: "/:categoryID",
    updateCategory: "/update/:categoryID",
    deleteCategory: "/delete/:categoryID",
  },
}

export const PRODUCT_DATA_URLS = {
  baseURL: `${API_BASE_URL}/product/api/products`,
  endpoints: {
    getProducts: "/getProducts",
    exportAllProduct: "/exportAllProduct",
    getProductCategories: "/productCategory",
    createCategory: "/createProductCategory",
    updateCategory: "/updateProductCategory",
    deleteCategory: "/deleteProductCategory",
    getAllWithPagination: "/paginate",
    getAllProductGroupsWithPagination: "/getproductgroups",
    deleteProductMany: "/product/api/products/deletemany",
    deleteProductGroups: "/product/api/products/deleteProductGroups",
    getProductById: "/getProductById",
    updateProduct: "/update",
    searchAttributes: "/searchAttributes",
    updateProductCategoryMappings: "/updateProductCategoryMappings",
    getProductCategoryById: "/getProductCategoryById",
    searchProducts: "/searchProducts",
    getProductsAttribute: "/getProductAttributes",
    createProductAttribute: "/createProductAttribute",
    getProductAttrById: "/productattribute",
    updateProductAttribute: "/updateProductAttribute",
    bulkUploadShekelProducts: "/bulkUploadShekelProducts",
  },
}

// export const TENANT_DATA_URLS = {
//   baseURL: `${API_BASE_URL}/api/products`,
//   endpoints: {
//   }
// }
