import * as React from "react";
import * as ReactDOM from "react-dom";
import { TreeView,handleTreeViewCheckChange,processTreeViewItems, TreeViewDragClue,
  moveTreeViewItem,
  TreeViewDragAnalyzer } from "@progress/kendo-react-treeview";
import { IconButton, TextField, Typography, Tooltip } from "@material-ui/core";
import styled, {css} from "styled-components"
import {MyItem} from "./MyItem"
import { SearchIcon, MenuIcon, AddCircleOutlineIcon, DeleteIcon } from "@iconsGallery"
import { defaultTree } from "./fixture"
import { expandHelper, collapseHelper } from "./renderProps";

const StyledTreeView = styled(TreeView)`
  .k-treeview .k-in {
    ${(props) =>
    !props.lookupMode
      ? css`
          border: 1px solid rgba(47, 72, 88, 0.15);
        `
      : css`
          border: 0px solid transparent;
        `};
  border-radius: 4px;
  padding: 0.2rem 0.2rem;
}
.k-treeview .k-in.k-state-selected {
  border-color: rgb(245, 40, 40) !important;
  color: #000000;
  background-color: #fff;
}
`

const SEPARATOR = '_'
const LocationTree = (props) => {
    const {
        tree = defaultTree,
        onItemChange = () => "",
        lookupMode = false,
        collapseAll,
        stores = [],
        fetchEdit = () => "",
        setCheckedArray = () => "",
        checkedNodes=[],
        onSelect = () => "",
      setSelectedStore = () => "",
        onExpandAction=()=>"",
        expandAll,
        editorSearchTerm = null
    } = props || {}
    const dragClue = React.useRef();
    const dragOverCnt = React.useRef(0);
    const isDragDrop = React.useRef(false);
  const [items, setItems] = React.useState(props.tree);
      const [check, setCheck] = React.useState({
            idField:"index",
            ids: [],
      });
    const [getLookupMode,setLookupMode] = React.useState(lookupMode)
    const [selected, setSelected] = React.useState(
        {
            ids: [],
            idField:"index"
        }
    )
    
    React.useEffect(() => {
        if (expandAll) {
        const tree = items
        const expandedTree = expandHelper(tree)
        setItems(expandedTree)
        }
    }, [expandAll])
  
  React.useEffect(() => {
    const idArr = checkedNodes.flat()
    setCheck({
      ...check,
      ids:idArr
    })
  },[checkedNodes])
    
    React.useEffect(() => {
        if (collapseAll) {
        const tree = items
        const collapsedTree = collapseHelper(tree)
        setItems(collapsedTree)
        }
    }, [collapseAll])
  
  React.useEffect(() => {
    if (editorSearchTerm) {
      setSelected({
        ...selected,
        ids:[editorSearchTerm]
      })
    } else {
      setSelected({
        ...selected,
        ids:[]
      })
    }
  },[editorSearchTerm])

    function getSiblings(itemIndex, data) {
        let result = data;
        const indices = itemIndex.split(SEPARATOR).map((index) => Number(index));

            for (let i = 0; i < indices.length - 1; i++) {
                result = result[indices[i]].items;
            }

            return result;
            }
  const getClueClassName = (event) => {
    const eventAnalyzer = new TreeViewDragAnalyzer(event).init();
    const { itemHierarchicalIndex: itemIndex } = eventAnalyzer.destinationMeta;

    if (eventAnalyzer.isDropAllowed) {
      switch (eventAnalyzer.getDropOperation()) {
        case "child":
          return "k-i-plus";

        case "before":
          return itemIndex === "0" || itemIndex.endsWith(`${SEPARATOR}0`)
            ? "k-i-insert-up"
            : "k-i-insert-middle";

        case "after":
          const siblings = getSiblings(itemIndex, tree);
          const lastIndex = Number(itemIndex.split(SEPARATOR).pop());
          return lastIndex < siblings.length - 1
            ? "k-i-insert-middle"
            : "k-i-insert-down";

        default:
          break;
      }
    }

    return "k-i-cancel";
  };
    const onExpandChange = (event) => {
      event.item.expanded = !event.item.expanded
      onExpandAction()
    }
    const onCheckChange = (e) => {
        const settings = { checkChildren: true, checkParents: true };
        setCheck(handleTreeViewCheckChange(e,check,items,settings))
    }
    const handleBlur = (e, item) => {
    item.edit = false;
    };

    const onItemDragEnd = (event) => {
    isDragDrop.current = dragOverCnt.current > 0;
    dragOverCnt.current = 0;
    dragClue.current.hide();
    const eventAnalyzer = new TreeViewDragAnalyzer(event).init();

    if (eventAnalyzer.isDropAllowed) {
      const updatedTree = moveTreeViewItem(
        event.itemHierarchicalIndex,
        tree,
        eventAnalyzer.getDropOperation(),
        eventAnalyzer.destinationMeta.itemHierarchicalIndex
      );
      setItems(updatedTree);
      
    }
    }
    
  const onItemDragOver = (event) => {
    dragOverCnt.current++;
    dragClue.current.show(
      event.pageY + 10,
      event.pageX,
      event.item.title,
      getClueClassName(event)
    );
  };
  

  const onItemClick = (event) => {
        if (!getLookupMode) {
            if (!isDragDrop.current) {
                let ids = selected.ids.slice();
                const index = ids.indexOf(event.item.index);
                index === -1 ? ids.push(event.item.index) : ids.splice(index, 1);
                setSelected({
                    ids,
                    idField: "index",
                });
      }
        } else {
          const hierarchyIndex = event.itemHierarchicalIndex.split(SEPARATOR)
          onSelect(stores[hierarchyIndex[0]])
    }
  };

    const search = (items, text) => {
        return items.reduce((acc, item) => {
            if (item.title.toLowerCase().indexOf(text.toLowerCase()) >= 0) {
                acc.push(item)
            }
            else if (item.items && item.items.length > 0) {
                let newItems = search(item.items, text)
                if (newItems && newItems.length > 0) {
                        acc.push({
                            title: item.title,
                            id: item.id,
                            items: newItems,
                            expanded: item.expanded,
                        });
                }
            }
            return acc
        },[])
    }
    return (<div>
{lookupMode?(<TextField
            variant="outlined"
            placeholder="  Search"
            size="small"
            onChange={(event) => {
                let text = event.target.value
                let filteredData = search(tree,text)
                setItems(filteredData)
            }}
            InputProps={{ startAdornment: <SearchIcon style={{color:"#CCC"}}/> }}
      />) : null}
        <StyledTreeView
            data={processTreeViewItems(items, { check: check, select: selected })}
            onItemDragOver={onItemDragOver}
            onItemDragEnd={onItemDragEnd}
            draggable={!lookupMode}
            lookupMode={lookupMode}
            expandIcons={true}
            item={(props) => <MyItem
              itemProps={props}
              setItems={setItems}
              stores={stores}
              fetchEdit={fetchEdit}
              maxHeight={5}
              items={items}
              getLookupMode={getLookupMode} />}
            onExpandChange={onExpandChange}
            aria-multiselectable={true}
            onCheckChange = {(event)=>onCheckChange(event)}
            checkboxes={lookupMode}
            onItemClick={onItemClick}
        />
        <TreeViewDragClue ref={dragClue} />
        </div>
    )
}

export default LocationTree
export {LocationTree}