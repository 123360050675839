import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS
const initialState = {
  step: 0,
}
export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.STEPPER_ACTIVE_STEP:
      return {
        ...state,
        step: actions.step,
      }
    default:
      return state
  }
}
