import { Switch, Box } from "@material-ui/core"
import React from "react"

import customTableStyles from "../../styles/components/customTable/styles"
import { Slash } from "react-feather"
/**
 * @To to handle switch toggle button on grid
 */
function SwitchCellDisabled(props) {
  const { showIconInSwitchCellDisplay = false, editable = true } = props
  const data = props.dataItem
  const dataValue = props.value
  const selectedItemIndex =
    props?.filterData?.findIndex(
      (a) => a?.value?.toString() === dataValue?.toString()
    ) || 0
  const classes = customTableStyles()

  console.log("SwitchCellDisabled", dataValue)

  let switchValue =
    typeof dataValue === "string"
      ? dataValue === "Enabled" || dataValue === "Disabled"
        ? dataValue === "Enabled"
          ? true
          : false
        : dataValue === "True" || dataValue === "False"
        ? dataValue === "True"
          ? true
          : false
        : dataValue
      : dataValue

  return (
    <td id="id-td-switch-cell-disable">
      <>
        {" "}
        {props.renderBox && selectedItemIndex === 0 ? (
          <Box className={classes.enabledBox}>{props?.filterData[0]?.tab}</Box>
        ) : (
          <Box className={classes.disabledBox}>{props?.filterData[1]?.tab}</Box>
        )}
      </>
      {/* {data.inEdit ? (
        <Switch
          checked={switchValue}
          onChange={props.onChange}
          value={switchValue}
          className={classes.switchCellCss}
          style={{ color: "var(--primaryColor)" }}
          disabled={editable === false}
        />
      )
        :
        <> {props.renderBox && selectedItemIndex === 0 ? (
          <Box className={classes.enabledBox}>{props?.filterData[0]?.tab}</Box>
        ) : (
          <Box className={classes.disabledBox}>{props?.filterData[1]?.tab}</Box>
        )}
        </>} */}
    </td>
  )
}

export default SwitchCellDisabled
