import React, { useEffect } from "react"

function useWindowSize() {
  const [size, setSize] = React.useState([
    window.innerHeight,
    window.innerWidth,
  ])
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerHeight, window.innerWidth])
    }
    window.addEventListener("resize", handleResize)
  }, [])
  return [(size[0] * 100) / 803, (size[1] * 100) / 1700]
}

export { useWindowSize }
export default useWindowSize
