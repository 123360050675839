import React from "react"
import ButtonMaterial from "@material-ui/core/Button"

function BrandLoadingBar(props) {

  const { DM_BRAND_NAME = "digitmart",
    THEME_ASSET = "dev-theme-assets",
    LOGO_FOLDER = "digitmart",
    DOMAIN_ENV = "dev",
    location = {},
    _kc = {} } = window || {}

  return (
    <div className="dm-loadBar" style={{ height: 120 }}>
      <div>
        <img
          alt="brandlogo"
          className="dm-loadBar-brand-logo"
          src={DOMAIN_ENV === "portal" ? `https://stdigitmartproddata.blob.core.windows.net/${THEME_ASSET}/${LOGO_FOLDER}/large_dark_logo.png` :
            `https://stdgrabdatanonprod001.blob.core.windows.net/${THEME_ASSET}/${LOGO_FOLDER}/large_dark_logo.png`}
        />

        <div className="dm-lds-ellipsis">
          <div className="dm-lds-ellipsis-dot"></div>
          <div className="dm-lds-ellipsis-dot"></div>
          <div className="dm-lds-ellipsis-dot"></div>
          <div className="dm-lds-ellipsis-dot"></div>
        </div>
      </div>
    </div>
  )
}

export { BrandLoadingBar }
export default BrandLoadingBar
