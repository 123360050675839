import React, { useState, useEffect } from "react"
import { withTheme } from "styled-components/macro"
import { Divider, Grid, Typography, Collapse, Card, IconButton, Tooltip } from "@material-ui/core"
import { CheckCircleIcon, ChevronUp, ChevronDown, Slash, Edit } from "@iconsGallery"
import { useTranslation } from "react-i18next"
import LocationTreeComponent from "./LocationTree"
import { useDispatch, useSelector } from "react-redux"
import * as ServiceAPI from "@services"
import _ from "lodash"
import * as Features from "@features"
import * as Adm from "@adm"
import * as constantsAPI from "@constants"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

function EditLocationHierarchyKit(props) {
  const { setFieldValue = () => { }, errors = {}, touched = {}, setAllowPrompt = () => { }, isWriteAllowed = false,
    locationType = {}, setLocationType = () => { },
    locationSize = {}, setLocationSize = () => { }, oldState = {},
    storeList = [], setStoreType = () => { }, storeType = {},
    paymentGatewayList = [], setPaymentType = () => { }, paymentType = {},
    accountList = [], accountType, setAccountType = () => { }, storeId = "", edit = false, setEdit = () => { },
    isAnyChanges = false, setAnyChanges = () => { }, locationTypeError = false, setLocationTypeError = () => { },
    paymentTypeError = false, setPaymentTypeError = () => { }, accountTypeError = false, setAccountTypeError = () => { },
    locationSizeError = false, setLocationSizeError = () => { }, locationId = "",values={}
  } = props
  console.log("EditLocationHierarchyKit", values)
  const [collapseOpen, setCollapseOpen] = useState(false)
  const { t } = useTranslation()
  //const [edit, setEdit] = useState(false)
  const [reset, setReset] = useState(false)
  // const [locationTypeError, setLocationTypeError] = useState(false)
  const [storeTypeError, setStoreTypeError] = useState(false)
  //const [locationSizeError, setLocationSizeError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  //const [isAnyChanges, setAnyChanges] = useState(false)
  const [defaultData, setDefaultData] = useState({})

  // const [paymentTypeError, setPaymentTypeError] = useState(false)
  // const [accountTypeError, setAccountTypeError] = useState(false)

  useEffect(() => {
    setLocationSize(props?.locationSize)
    setLocationType(props?.locationType)
    setStoreType(props?.storeType)
    setPaymentType(props?.paymentType)
    setDefaultData((e) => {
      return {
        locationSize: props.locationSize,
        locationType: props?.locationType,
        storeType: props?.storeType,
        paymentType: props?.paymentType
      }
    })
  }, [locationSize, locationType, storeType, paymentType])

  // useEffect(() => {
  //   setLocationSize(locationSize)
  //   setLocationType(locationType)
  // }, [defaultData])

  const resetData = () => {
    setEdit(false)
    setReset(true)
    // setLocationSize(defaultData.locationSize)
    // setLocationType(defaultData.locationType)
  }

  const updateData = async () => {
    //if (locationSize.lengthValue != 0 && locationSize.widthValue != 0 && locationSize.heightValue != 0) {
    if (locationType == {}) {
      setLocationTypeError(true)
      return
    }
    if (localStorage.getItem("tenantPaymentConfigEnable") === "true" && paymentType === "") {
      setPaymentTypeError(true)
      return
    }
    if (localStorage.getItem("tenantPaymentConfigEnable") === "true" && accountType === "") {
      setAccountTypeError(true)
      return
    }
    else if (!_.every(locationSize, (x, i, k) => k.lengthValue !== "" && k.heightValue !== "" && k.widthValue !== "")) {
      setLocationSizeError(true)
      return
    }
    else if (_.every(locationSize, (x, i, k) => k.lengthValue === "0" || k.heightValue === "0" || k.widthValue === "0")) {
      setLocationSizeError(true)
      return
    } else {
      setEdit(false)
      setLoading(true)
      setAlert(false)
      setLocationSizeError(false)
      setLocationTypeError(false)
      let body = {
        ...props.sendBackData,
        locationType: locationType,
        locationSize: locationSize,
        storeType: locationType?.name === 'Store' ? storeType : { "id": "", "name": "" },
        paymentMethodType: locationType?.name === 'Store' ? paymentType?.id : "",
        paymentAccountName: locationType?.name === 'Store' ? accountType?.id : "",
        paymentMode: locationType?.name === 'Store' ? accountType?.mode : ""
      }

      let response = await ServiceAPI.fetchStoreCommonData(
        API_END_POINTS.updateLocation,
        body,
      )
      await setDefaultData({
        locationSize: _.get(response, "data.data.locationSize", {}),
        locationType: _.get(response, "data.data.locationType", {}),
        storeType: _.get(response, "data.data.storeType", {}),
      })
      console.log("updateLocation", response?.response)
      await setLocationSize(_.get(response, "data.data.locationSize", {}))
      await setLocationType(_.get(response, "data.data.locationType", {}))
      await setAlertType(_.get(response, "data.status", "error"))
      await setAlertMessage(_.get(response, response?.status === 200 ? "data.message" : "response.data.message", "Update Failed"))
      await setAlert(true)
      await setLoading(false)
      setAnyChanges(false)
    }
    //}
    props.fetchData(locationId)
  }
  return (
    <>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Adm.BackdropOverlay open={loading} />
        <Card
          style={{
            width: "100%",
            // boxShadow:
            //   " 0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
            borderRadius: "8px",
          }}
        >
          <>
            <Grid
              container
              direction="row"
              // row="true"
              // justify="space-between"
              alignContent="center"
              alignItems="center"
              style={{
                justifyContent: "space-between",
                paddingLeft: "25px",
                height: "60px",
              }}
            >
              <Grid item>
                <Typography variant="h6" align="center" style={{ fontWeight: 'bold' }}>
                  {t("Location Type")}
                </Typography>
              </Grid>
            </Grid>

            <LocationTreeComponent
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              edit={edit}
              locationSize={locationSize}
              locationType={locationType}
              locations={props.locationTypeList}
              setLocationSize={setLocationSize}
              setLocationType={setLocationType}
              locationTypeError={locationTypeError}
              locationSizeError={locationSizeError}
              setAllowPrompt={setAllowPrompt}
              setAnyChanges={setAnyChanges}
              isDetailView={true}
              isAnyChanges={isAnyChanges}
              storeList={storeList}
              setStoreType={setStoreType}
              storeType={storeType}
              storeTypeError={storeTypeError}
              SDCConfigured={oldState?.SDCConfigured}

              paymentGatewayList={paymentGatewayList}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              paymentTypeError={paymentTypeError}

              accountList={accountList}
              accountType={accountType || ""}
              setAccountType={setAccountType}
              accountTypeError={accountTypeError}

              locationId={locationId}
              values={values}

              lg={2}
              lg1={4}
              lg2={6}
            />
          </>
        </Card>
        <>
          {alert ? (
            <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
          ) : null}
        </>
      </Grid>
    </>
    // <Grid item lg={12} md={12} sm={12} xs={12}>
    //   <Adm.BackdropOverlay open={loading} />
    //   <Card
    //     style={{
    //       width: "100%",
    //       boxShadow:
    //         " 0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
    //       borderRadius: "8px",
    //     }}
    //   >
    //     <Grid
    //       container
    //       direction="row"
    //       // row="true"
    //       // justify="space-between"
    //       alignContent="center"
    //       alignItems="center"
    //       style={{
    //         justifyContent: "space-between",
    //         paddingLeft: "16px",
    //         height: "60px",
    //       }}
    //     >
    //       <Grid item>
    //         <Typography variant="h6" align="center" style={{ fontWeight: 'bold' }}>
    //           {t("Location Type")}
    //         </Typography>
    //       </Grid>
    //       <Grid item style={{ display: "flex", alignItems: "center" }}>
    //         <div>
    //           {collapseOpen ? (
    //             <div>
    //               {edit ? (
    //                 <>
    //                   <Tooltip title="Cancel" placement="top">
    //                     <IconButton
    //                       onClick={() => {
    //                         resetData()
    //                         setEdit(false)
    //                         setAnyChanges(false)
    //                         setAllowPrompt(false)
    //                         setLocationType(oldState?.locationType)
    //                         setStoreType(oldState?.storeType)
    //                         setLocationSize(oldState?.locationSize)
    //                         setPaymentType({ id: oldState?.paymentMethodType, name: oldState?.paymentMethodType })
    //                         setAccountType({ id: oldState?.paymentAccountName, name: oldState?.paymentAccountName, mode: oldState?.paymentMode })
    //                       }}
    //                     >
    //                       <Slash
    //                         size={20}
    //                         style={{
    //                           marginRight: "1px",
    //                           color: "red",
    //                         }}
    //                       />
    //                     </IconButton>
    //                   </Tooltip>
    //                   <Tooltip title="Save" placement="top">
    //                     <IconButton
    //                       type="submit"
    //                       style={{
    //                         marginRight: "2px",
    //                         pointerEvents: `${isAnyChanges ? "unset" : "none"}`,
    //                       }}
    //                       onClick={() => {
    //                         updateData()
    //                         //setAnyChanges(false)
    //                       }}
    //                     >
    //                       <CheckCircleIcon
    //                         fontSize="small"
    //                         style={{
    //                           color: `${isAnyChanges ? "#4CAF50" : "#CCC"}`,
    //                         }}
    //                       />
    //                     </IconButton>
    //                   </Tooltip>
    //                 </>
    //               ) : (
    //                 <>
    //                   <Tooltip title="Edit" placement="top">
    //                     <IconButton
    //                       onClick={() => {
    //                         setEdit(true)
    //                       }}
    //                       disabled={isWriteAllowed ? false : true}
    //                     >
    //                       <Edit
    //                         size={20}
    //                         style={{
    //                           color: "rgba(158, 158, 158, 1)",
    //                         }}
    //                       />
    //                     </IconButton>
    //                   </Tooltip>
    //                 </>
    //               )}
    //             </div>
    //           ) : (
    //             ""
    //           )}{" "}
    //         </div>
    //         {collapseOpen ? (
    //           <IconButton onClick={() => {
    //             setCollapseOpen(!collapseOpen)
    //             setLocationType(oldState?.locationType)
    //             setLocationSize(oldState?.locationSize)
    //             setStoreType(oldState?.storeType)
    //             setPaymentType({ id: oldState?.paymentMethodType, name: oldState?.paymentMethodType })
    //             setAccountType({ id: oldState?.paymentAccountName, name: oldState?.paymentAccountName, mode: oldState?.paymentMode })
    //             setAllowPrompt(false)
    //           }}>
    //             <ChevronUp style={{ color: "rgba(0, 0, 0, 1)" }} />
    //           </IconButton>
    //         ) : (
    //           <IconButton onClick={() => { resetData(); setAnyChanges(false); setCollapseOpen(!collapseOpen) }}>
    //             <ChevronDown style={{ color: "rgba(0, 0, 0, 1)" }} />
    //           </IconButton>
    //         )}
    //       </Grid>
    //     </Grid>
    //     <Grid item lg={12} md={12} sm={12} xs={12}>
    //       <Divider />
    //       <Collapse in={collapseOpen} timeout="auto">
    //         {locationType === '' && locationType === "" ? (
    //           <Features.NoRecordFound />
    //         ) : (
    //           <LocationTreeComponent
    //             edit={edit}
    //             locationSize={locationSize}
    //             locationType={locationType}
    //             locations={props.locationTypeList}
    //             setLocationSize={setLocationSize}
    //             setLocationType={setLocationType}
    //             locationTypeError={locationTypeError}
    //             locationSizeError={locationSizeError}
    //             setAllowPrompt={setAllowPrompt}
    //             setAnyChanges={setAnyChanges}
    //             isDetailView={true}
    //             isAnyChanges={isAnyChanges}
    //             storeList={storeList}
    //             setStoreType={setStoreType}
    //             storeType={storeType}
    //             storeTypeError={storeTypeError}
    //             SDCConfigured={oldState?.SDCConfigured}

    //             paymentGatewayList={paymentGatewayList}
    //             paymentType={paymentType}
    //             setPaymentType={setPaymentType}
    //             paymentTypeError={paymentTypeError}

    //             accountList={accountList}
    //             accountType={accountType || ""}
    //             setAccountType={setAccountType}
    //             accountTypeError={accountTypeError}

    //             lg={2}
    //             lg1={4}
    //             lg2={6}
    //           />
    //         )}
    //       </Collapse>
    //     </Grid>
    //   </Card>
    //   <>
    //     {alert ? (
    //       <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
    //     ) : null}
    //   </>
    // </Grid>
  )
}
const EditLocationHierarchy = withTheme(EditLocationHierarchyKit)
export { EditLocationHierarchy }
export default EditLocationHierarchy
