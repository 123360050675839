const StorageLookupFixture = [
  {
    field: "storageunit_id",
    title: "Storage Id",
    border: true,
    filter: false,
    show: true,
    min_width: 20,
  },
  {
    field: "name",
    title: "Storage Name",
    border: true,
    filter: false,
    show: true,
    min_width: 20,
  },
  {
    field: "manager",
    title: "Manager",
    border: true,
    filter: false,
    show: true,
    min_width: 15,
  },
  {
    field: "is_active",
    title: "Status",
    editable: false,
    editor: "text",
    switchCell: true,
    cell: true,
    border: true,
    filter: true,
    show: true,
    min_width: 15,
    filterType: "boolean",
    customFilter: true,
    filterData: [
      { tab: "Active", value: true },
      { tab: "Inactive", value: false },
    ],
    primary: true,
  },
]
export default StorageLookupFixture
