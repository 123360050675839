import { makeStyles, useTheme } from "@material-ui/core/styles"

export const useLocationFormContentStyles = makeStyles({
  AddNew: {
    minWidth: "275px",
    background: "#0000ff12",
    border: 0,
    textAlign: "center",
    outline: "none",
    borderRadius: "8px",
  },
  FormPopup: {
    paddingTop: "3px",
  },
  popupTitle: {
    paddingLeft: "30px",
    paddingBottom: "20px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
    minWidth: "100vh",
    maxWidth: "100vh",
    overflowX: "auto",
  },
})
