import React from 'react'

const ShowCircleBasedOnProp = ({ showColor }) => {
    return (
        <div
            style={{
                height: "40px",
                width: "40px",
                backgroundColor: "#376fd0",
                borderRadius: "50%",
                display: "inline-block",
                color: "white",
                position: "relative"
            }}
        >
        </div>
    )
}

export default ShowCircleBasedOnProp