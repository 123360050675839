import {
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { withStyles } from "@material-ui/styles"
import { Autocomplete } from "@material-ui/lab"
import styled from "styled-components"

export const useStyles = makeStyles((theme) => ({
  option: {
    borderBottom: `1px dashed #ccc`,
    paddingBottom: "0px !important",
    minHeight: 100,
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: "#dfdfdf",
      borderColor: "transparent",
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: "#dfdfdf",
      borderColor: "transparent",
    },
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  productBtn: {
    margin: "0px",
    "@media(min-width:1200px)": {
      "& .MuiGrid-grid-lg-1": {
        maxWidth: "14%",
        flexBasis: "14%",
      },
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textInput: {
    // marginBottom: '8px',
    fontWeight: 600,
    fontSize: "14px",
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
  disabledTextInput: {
    width: "100%",
    "& input": {
      backgroundColor: "#f6f6f6",
      color: "gray",
      width: "100%",
    },
  },
  text: {
    marginBottom: "45px",
    paddingLeft: "20px",
    marginLeft: "0px",
  },
  text1: {
    marginBottom: "45px",
    paddingLeft: "0px",
    marginLeft: "0px",
  },
  textemail: {
    marginBottom: "45px",
    paddingLeft: "20px",
    marginLeft: "0px",
  },
  textHeader: {
    marginBottom: "15px",
    // fontSize: '16px'
  },
  icon: {
    marginRight: "5px",
    color: "rgba(21, 101, 192, 1)",
    marginBottom: "-5px",
    fontSize: "30px",
  },
  actionIcons: {
    color: "rgba(158, 158, 158, 1)",
    fontSize: "22px",
  },
  textHeader1: {
    marginBottom: "15px",
    marginTop: "10px",
  },
  displayText: {
    fontSize: "18px",
    fontWeight: 500,
  },
  header2: {
    marginBottom: "15px",
    paddingLeft: "0px",
    marginLeft: "0px",
    marginTop: "10px",
  },
  divider: {
    borderRight: "0.1em solid rgba(0, 0, 0, 0.1)",
    padding: "15px 15px",
    margin: "10px 0px",
  },
  noDivider: {
    padding: "15px 15px",
    margin: "10px 0px",
  },
  bottomDivider: {
    borderBottom: "0.1em solid rgba(0, 0, 0, 0.1)",
  },
  divPadding: {
    padding: "2px",
  },
  autoCompleteHeight: {
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
  },
  valuesStyle: {
    alignSelf: "center",
  },
  keyboardDatePicker: {
    marginTop: "0px",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      height: "36px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
}))

export const StyledAutocomplete = styled(Autocomplete)`
  li {
    background: red;
    padding-bottom: 0px !important;
  }
`
export const StyledOverAllMainWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 455px;
  border-bottom: 1px solid #ccc;
`
export const StyledSideWrapper = styled(Grid)`
  width: 30%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ccc;
  padding: 20px;
`
export const StyledSideRightWrapper = styled(Grid)`
  width: 70%;
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const StyledMainWrapper = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  row-gap: 0px;
  column-gap: 20px;
  width: 100%;
  padding: 20px;
  padding-top: 10px;

  .cls-field-form-kit {
    min-height: 80px;
    width: 100%;
  }
`
export const ProDialog = withStyles((theme) => ({
  paper: {
    // maxWidth: "calc(100% - 120px)",
    maxWidth: "1200px",
    width: "100%",
    minHeight: "600px",
    maxHeight: "600px",
    // minHeight: "80vh !important",
    // maxHeight: "80vh !important",
    // minWidth: "80vh !important",
    // maxWidth: "80vh !important",
    // padding: "0px",
    // overflowY: "auto",
  },
}))(Dialog)
