import * as React from "react"
import { Grid, Button, IconButton, Tooltip, Drawer } from "@material-ui/core"
import styled from "styled-components"
import _ from "lodash"
import * as IconsGallery from "@iconsGallery"
import * as StyledDOM from "./styles"
import * as FIXTURE from "./fixture"
import QTY from "./QTY"
import Position from "./Position"
import camera from "./images/camera.png"
import nocamera from "./images/nocamera.png"
import digitShelf from "./images/digitshelf.png"
import digitShekele from "./images/shekele.png"
import digitYilai from "./images/yilai.png"

export const renderCellItem = (stateShekelProps = {}) => {
  const {
    currentRowItem = {},
    currentCellItem = {},
    onHandleClickAddCamera,
    onHandleClickAddProduct,
    onHandleClickRemoveCell,
    onHandleClickQTYCell,
    onHandleClickQTYProductCell,
    onHandleClickRemoveCamera,
    onHandleClickUpdateProduct,
    onHandleClickRemoveProduct,
    onHandleClickUpdateLocationPositionProduct,
    onHandleClickInfoCell,
    currentCellIndex,
    currentRowIndex,
    cellWidthSUM = 0,
    cellCount = 0,
    isEdit = false,
    getRemainingWidth = 0,
    getPlanogramDetail = {},
    getIsAnyEditView = false,
    getIsMultipleProduct = true,
  } = stateShekelProps || {}

  const {
    row_no = 0,
    name = 0,
    totalWidth = 0,
    remainingWidth = 0,
    cells = [],
    isAssociateShelf = false,
    minCellWidth = 0,
    maxCellWidth = 0,
  } = currentRowItem || {}
  const { max_cell_width = 0, gindex = "" } = getPlanogramDetail || {}
  const { isCellAvailable = false, remainingCellWidth = 0 } =
    currentCellItem || {}
  const isYilaiType = stateShekelProps?.getPlanogramDetail?.shelfType.includes(
    "Yilai"
  )

  const renderEmptyExceed = () => {
    return (
      <Grid
        style={{
          fontWeight: "bold",
          color: "red",
          textAlign: "center",
          padding: 8,
        }}
      >
        {`Cell Width Exceed, Please remove the cell`}
      </Grid>
    )
  }
  const renderEmptyCamera = () => {
    return getIsAnyEditView && isEdit ? (
      <>
        <StyledDOM.StyledShekelCardCameraWrapper>
          <Button
            style={{ opacity: 0 > getRemainingWidth ? 0.5 : 1 }}
            disabled={0 > getRemainingWidth}
            onClick={() => onHandleClickAddCamera(stateShekelProps)}
          >{`+ Add Camera`}</Button>
        </StyledDOM.StyledShekelCardCameraWrapper>
      </>
    ) : (
      <>
        <StyledDOM.StyledShekelCardCameraValWrapper className="cls-no-camera">
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: 24, height: 24, marginRight: 8, opacity: 0.7 }}
              src={nocamera}
              alt="nocamera"
            />
            <span>{`No Camera(s) Mapped`}</span>
          </Grid>
        </StyledDOM.StyledShekelCardCameraValWrapper>
      </>
    )
  }
  const renderEmptyProduct = () => {
    let StyledDOMShekelCardHeadProductWrapper =
      StyledDOM.StyledShekelCardProductValWrapper
    return (
      <StyledDOMShekelCardHeadProductWrapper>
        <Grid className="cls-ShekelCardHeadProductWrapper">
          {renderCommonHeaderAssociateCellInfo()}
        </Grid>
        <Grid
          className={`cls-ShekelCardContProductWrapper cls-empty ${getIsMultipleProduct ? "cls-empty-multiple" : ""
            }`}
        >
          {0 > getRemainingWidth ? (
            renderEmptyExceed()
          ) : (
            <Button
              onClick={() => onHandleClickAddProduct(stateShekelProps)}
              className="cls-action-item cls-ShekelCardAddProductWrapper"
            >{`+ Add Product`}</Button>
          )}
        </Grid>
      </StyledDOMShekelCardHeadProductWrapper>
    )
  }

  const renderValueProduct = () => {
    return (
      <StyledDOM.StyledShekelCardProductValWrapper>
        <Grid className="cls-ShekelCardHeadProductWrapper">
          {renderCommonHeaderAssociateCellInfo()}
        </Grid>
        <Grid className="cls-ShekelCardContProductWrapper cls-value">
          <Grid
            className={`cls-ShekelCardContProductimage ${getIsMultipleProduct ? "cls-multiple-image" : ""
              }`}
          >
            <img
              src={
                currentCellItem?.productImage || "/static/img/noproducts.png"
              }
              alt={
                currentCellItem?.productTitle || "/static/img/noproducts.png"
              }
            />
          </Grid>
          <Grid className="cls-ShekelCardContProductInfokit">
            <Grid className="cls-ShekelCardContProducttitle">
              {currentCellItem?.productTitle}
            </Grid>
            <Grid className="cls-ShekelCardContProductitemid">
              {window["isEnableItemId"] === true && (
                <>
                  {currentCellItem?.productItemId.map((itm) => {
                    return <Grid>{itm}</Grid>
                  })}
                </>
              )}
            </Grid>
            <Grid className="cls-ShekelCardContProductweight">
              {currentCellItem?.productWeight}
            </Grid>
          </Grid>

          <Grid className="cls-ShekelCardContProductInfoAction">
            <Tooltip title={"Remove Product"} placement="top">
              <IconButton
                className="cls-action-item"
                style={{ padding: 0 }}
                onClick={() => onHandleClickRemoveProduct(stateShekelProps)}
              >
                <IconsGallery.UnlinkIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </StyledDOM.StyledShekelCardProductValWrapper>
    )
  }
  const renderValueMultipleProduct = () => {
    const { min_cell_width = 0 } = currentCellItem

    let isDisableAddButton =
      remainingCellWidth < min_cell_width || 0 >= remainingCellWidth
    //0 >= remainingCellWidth
    return (
      <StyledDOM.StyledShekelCardProductValWrapper>
        <Grid className="cls-ShekelCardHeadProductWrapper">
          {renderCommonHeaderAssociateCellInfo()}
        </Grid>
        <Grid className="cls-ShekelCardContProductMultipleWrapper">
          {currentCellItem?.multipleProduct.map((itemSet, index) => {
            const { locationPosition = [] } = itemSet || {}
            const [x = 0, y = 0, z = 0] = locationPosition || {}
            let lcCurrentCellProductIndex = index + 1
            return (
              <Grid className="cls-ShekelCardContProductWrapper cls-value cls-multiple">
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Grid
                      className={`cls-ShekelCardContProductimage ${getIsMultipleProduct ? "cls-multiple-image" : ""
                        }`}
                    >
                      <img
                        src={itemSet?.productImage}
                        alt={itemSet?.productTitle}
                      />
                    </Grid>
                    <Grid className="cls-ShekelCardContProductInfokit">
                      <Grid className="cls-ShekelCardContProducttitle cls-multiple">
                        {itemSet?.productTitle}
                      </Grid>
                      <Grid className="cls-ShekelCardContProductitemid cls-multiple">
                        {window["isEnableItemId"] === true && (
                          <>
                            {itemSet?.productItemId.map((itm) => {
                              return <Grid>{itm}</Grid>
                            })}
                          </>
                        )}
                      </Grid>
                      <Grid className="cls-ShekelCardContProductweight cls-multiple">
                        {itemSet?.productWeight}
                      </Grid>
                      <Grid
                        className="cls-ShekelCardContProductweight"
                        style={{ marginTop: 6 }}
                      >
                        <Grid
                          style={{
                            fontWeight: "bold",
                            marginBottom: 3,
                            fontSize: 12,
                            color: "black",
                          }}
                        >{`Width:`}</Grid>
                        <QTY
                          // cellsCount={cells.length}
                          maxCellWidth={
                            currentCellItem?.digitshelfId?.shelf_width
                          }
                          // remainingWidth={getRemainingWidth}
                          // isEdit={isEdit}
                          // cellWidthSUM={cellWidthSUM}
                          minCellWidth={currentCellItem?.min_cell_width}
                          totalWidth={
                            currentCellItem?.digitshelfId?.shelf_width
                          }
                          disabled={1 > remainingCellWidth}
                          // disabled={!(totalWidth - 1 >= cellWidthSUM)}
                          count={itemSet?.productWidth || 0}
                          onUpdateQTY={(props) => {
                            onHandleClickQTYProductCell({
                              ...stateShekelProps,
                              QTY: props,
                              currentCellProductIndex: lcCurrentCellProductIndex,
                            })
                          }}
                        />
                      </Grid>
                      <Grid style={{ width: "100%", marginTop: 6 }}>
                        <Grid
                          style={{
                            fontWeight: "bold",
                            marginBottom: 3,
                            fontSize: 12,
                            color: "black",
                          }}
                        >{`Position:`}</Grid>
                        <Position
                          defaultPosition={{ x, y, z }}
                          disabled={false}
                          onUpdate={(innProps) => {
                            onHandleClickUpdateLocationPositionProduct({
                              ...stateShekelProps,
                              locationPositionUpdate: innProps,
                              currentCellProductIndex: lcCurrentCellProductIndex,
                            })
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid
                      className="cls-ShekelCardContProductweight"
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "10px",
                        padding: "3px 4px",
                        minWidth: "40px",
                        background: "#a62991",
                        borderRadius: "1px 12px 12px 1px",
                        height: "24px",
                        color: "white",
                        fontWeight: "bold",
                        width: "fit-content",
                      }}
                    >
                      {`${gindex}-${row_no}-${itemSet?.productIndex}`}
                    </Grid>

                    <Grid className="cls-ShekelCardContProductInfoAction cls-alone">
                      <Tooltip title={"Remove Product"} placement="top">
                        <IconButton
                          className="cls-action-item"
                          style={{ padding: 0 }}
                          onClick={() =>
                            onHandleClickRemoveProduct({
                              ...stateShekelProps,
                              currentCellProductIndex: lcCurrentCellProductIndex,
                              currentCellShelfProductIndex:
                                itemSet?.productIndex,
                            })
                          }
                        >
                          <IconsGallery.UnlinkIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
        <Grid className="cls-ShekelCardContProductInfoAction cls-multiple">
          <Grid style={{ padding: 10, fontWeight: "bold", color: "#a62991" }}>
            {`Added Products: `}
            <span style={{ color: "black" }}>{` ${currentCellItem?.multipleProduct
              ? currentCellItem?.multipleProduct.length
              : 0
              }`}</span>
          </Grid>
          <Tooltip title={"Add Product"} placement="top">
            <IconButton
              disabled={isDisableAddButton}
              className="cls-action-item"
              style={{
                padding: 0,
                marginLeft: 10,
                background: isDisableAddButton ? "#b3b3b3" : "#a62991",
                borderRadius: 6,
                paddingRight: 8,
                paddingLeft: 8,
                height: 30,
                marginTop: 2,
                marginRight: 2,
              }}
              onClick={() => {
                onHandleClickAddProduct(stateShekelProps)
              }}
            >
              <IconsGallery.AddIcon style={{ color: "#fff" }} />
              <span
                style={{ fontSize: "12px", fontWeight: "bold", color: "#fff" }}
              >{`Add Product`}</span>
            </IconButton>
          </Tooltip>
        </Grid>
      </StyledDOM.StyledShekelCardProductValWrapper>
    )
  }
  const renderEmpty = () => {
    return (
      <StyledDOM.StyledShekelCellCardWrapper
        className={getIsMultipleProduct ? "cls-multiproduct-kit" : ""}
      >
        {renderEmptyCamera()}
        {renderEmptyProduct()}
      </StyledDOM.StyledShekelCellCardWrapper>
    )
  }

  const renderValueCamera = () => {
    let cameraCount = currentCellItem?.productCamera?.length
    return (
      <StyledDOM.StyledShekelCardCameraValWrapper
        className={cameraCount === 2 ? "cls-two-camera" : ""}
      >
        <Grid style={{ display: "flex", alignItems: "center" }}>
          <img
            style={{ width: 24, height: 24, marginRight: 8 }}
            src={camera}
            alt="camera"
          />

          <Grid style={{ fontSize: 12 }}>
            {`Product Camera Count (${cameraCount}) ${isYilaiType
              ? " - Id: " + currentCellItem?.productCamera[0]?.cameraId
              : ""
              }`}
          </Grid>
        </Grid>
        <Grid>
          {getIsMultipleProduct
            ? 1
            : 2 > cameraCount && (
              <Tooltip title={"Add Camera"} placement="top">
                <IconButton
                  className="cls-action-item"
                  style={{ padding: 0 }}
                  onClick={() => onHandleClickAddCamera(stateShekelProps)}
                >
                  <IconsGallery.AddIcon />
                </IconButton>
              </Tooltip>
            )}
          <Tooltip title={"Remove All Camera"} placement="top">
            <IconButton
              className="cls-action-item"
              style={{ padding: 0 }}
              onClick={() => onHandleClickRemoveCamera(stateShekelProps)}
            >
              <IconsGallery.UnlinkIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </StyledDOM.StyledShekelCardCameraValWrapper>
    )
  }

  const renderAssociateCellCount = () => {
    return (
      <Grid className="cls-ShekelCardCellValue">
        <Grid className="cls-cell-key">{`Cell`}</Grid>
        <Grid className="cls-cell-value">{currentCellItem?.name || "0"}</Grid>
      </Grid>
    )
  }

  const renderProductIcon = (Innerprops) => {
    const { isEmpty = false } = Innerprops || {}
    return (
      <Grid style={{ display: "flex" }}>
        {!isEmpty && (
          <Tooltip title={"Information"} placement="top">
            <IconButton
              style={{ padding: 0, marginRight: 4 }}
              onClick={() => onHandleClickInfoCell(stateShekelProps)}
            >
              <IconsGallery.InfoIconKit />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={"Delete Cell"} placement="top">
          <IconButton
            className="cls-action-item"
            style={{ padding: 0 }}
            onClick={() => onHandleClickRemoveCell(stateShekelProps)}
          >
            <IconsGallery.DeleteIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    )
  }
  let renderCellWidth = () => (
    <Grid className="cls-ShekelCardCellInfo">
      <Grid className="cls-cell-key cls-text-kit">{`${`Cell Width`}:`}</Grid>
      <Grid className="cls-cell-value cls-text-kit">{`${isYilaiType
        ? currentCellItem?.digitshelfId?.shelf_width
        : currentCellItem?.cellWidth
        }`}</Grid>
    </Grid>
  )
  const renderCommonHeaderAssociateCellInfo = () => {
    return (
      <>
        {getIsMultipleProduct === false && (
          <Grid className="cls-ShekelCardCellValue">
            <Grid className="cls-cell-key">{`Cell`}</Grid>
            <Grid className="cls-cell-value">
              {currentCellItem?.name || "0"}
            </Grid>
          </Grid>
        )}
        <Grid
          className="cls-ShekelCardCellInfo"
          style={
            getIsMultipleProduct
              ? {
                marginLeft: 10,
              }
              : {}
          }
        >
          <Grid className="cls-cell-key cls-text-kit">{`${`Cell Id`}:`}</Grid>
          <Grid className="cls-cell-value cls-text-kit">
            {`${currentCellItem.cellId}`}{" "}
          </Grid>
        </Grid>
        <Grid className="cls-ShekelCardCellInfo">
          <Grid className="cls-cell-key cls-text-kit">{`${`Cell Name`}:`}</Grid>
          <Grid className="cls-cell-value cls-text-kit">{`${currentCellItem.cellName}`}</Grid>
        </Grid>
        {isAssociateShelf ? (
          renderCellWidth()
        ) : (
          <>
            {isYilaiType ? (
              renderCellWidth()
            ) : (
              <Grid className="cls-ShekelCardCellInfo-parent cls-non-Associate">
                <Grid className="cls-ShekelCardCellInfo">
                  <Grid className="cls-cell-value cls-text-kit">
                    <QTY
                      cellsCount={cells.length}
                      maxCellWidth={max_cell_width}
                      remainingWidth={getRemainingWidth}
                      minCellWidth={minCellWidth}
                      isEdit={isEdit}
                      cellWidthSUM={cellWidthSUM}
                      totalWidth={totalWidth}
                      disabled={!(totalWidth - 1 >= cellWidthSUM)}
                      count={currentCellItem?.cellWidth}
                      onUpdateQTY={(props) => {
                        onHandleClickQTYCell({
                          ...stateShekelProps,
                          QTY: props,
                        })
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {isYilaiType && (
          <Grid className="cls-ShekelCardCellInfo cls-remaining-width">
            <Grid
              className="cls-cell-key cls-text-kit"
              style={{ color: "#c53228" }}
            >{`${`Remaining Width`}:`}</Grid>
            <Grid className="cls-cell-value cls-text-kit">
              {0 > remainingCellWidth ? (
                <span
                  style={{
                    fontSize: 10,
                    color: "#c53329c2",
                    borderRadius: 2,
                  }}
                >
                  {0 > remainingCellWidth ? "ℹ️ Width Exceed!" : ""}
                </span>
              ) : (
                remainingCellWidth
              )}
            </Grid>
          </Grid>
        )}
        <Grid className="cls-ShekelCardCellInfoRow">
          <Tooltip title={"Information"} placement="top">
            <IconButton
              style={{ padding: 0, marginRight: 4 }}
              onClick={() => onHandleClickInfoCell(stateShekelProps)}
            >
              <IconsGallery.InfoIconKit />
            </IconButton>
          </Tooltip>
          <Tooltip title={"Delete Cell"} placement="top">
            <IconButton
              className="cls-action-item"
              style={{ padding: 0 }}
              onClick={() => onHandleClickRemoveCell(stateShekelProps)}
            >
              <IconsGallery.DeleteIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </>
    )
  }

  const renderCard = () => {
    let renderProduct = getIsMultipleProduct
      ? renderValueMultipleProduct
      : renderValueProduct

    const { multipleProduct = [] } = currentCellItem

    let isCheckProduct = getIsMultipleProduct
      ? multipleProduct.length > 0
      : currentCellItem?.productItemId.length > 0
    return (
      <StyledDOM.StyledShekelCellCardWrapper
        className={getIsMultipleProduct ? "cls-multiproduct-kit" : ""}
      >
        {currentCellItem?.productCamera?.length > 0
          ? renderValueCamera()
          : renderEmptyCamera()}
        {isCheckProduct ? renderProduct() : renderEmptyProduct()}
      </StyledDOM.StyledShekelCellCardWrapper>
    )
  }
  return isCellAvailable ? renderCard() : renderEmpty()
}

export const renderRowItem = (stateShekelProps = {}) => {
  const {
    currentCellItem = {},
    currentRowItem = {},
    onHandleClickAddCell,
    onHandleClickAssociateCell,
    currentCellIndex,
    currentRowIndex,
    onHandleClickRemoveAllCell,
    gondolaTheme,
    renderChild = () => "",
    getRemainingWidth,
    getIsAnyEditView,
    getIsMultipleProduct = false,
  } = stateShekelProps || {}

  const {
    name = 0,
    totalWidth = 0,
    remainingWidth = 0,
    cells = [],
    isAssociateShelf = false,
    minCellWidth = 0,
    maxCellWidth = 0,
  } = currentRowItem || {}

  let lcMinCellWidth = minCellWidth

  if (isAssociateShelf && cells.length > 0) {
    const { cellWidth = 0 } = cells[cells.length - 1]
    lcMinCellWidth = cellWidth
  }

  let cellCount =
    cells.length > 0
      ? 1
      : Math.round(Number(totalWidth) / Number(lcMinCellWidth))

  let cellWidthSUM = 0
  let cellWidthItem = cells.map((itm) => itm.cellWidth || 0)

  if (cellWidthItem.length > 0) {
    for (let i = 0; i < cellWidthItem.length; i++) {
      cellWidthSUM += Number(cellWidthItem[i])
    }
  }
  const isCellDisable = _.isEmpty(cells)

  const renderLeftHeaderPanel = () => {
    return (
      <Grid className="cls-skl-left-panl">
        <Grid style={{ marginRight: 20, color: "#000" }}>{`Row: ${name}`}</Grid>
        <Grid
          style={{ marginRight: 20, color: "#3F0E49" }}
        >{`Total Width: ${totalWidth}`}</Grid>
        <Grid style={{ color: "#C53228" }}>
          {`Remaining Width: ${getRemainingWidth}`}
        </Grid>
        {0 > getRemainingWidth && (
          <div
            style={{
              marginLeft: 10,
              background: "#c53329c2",
              color: "#ffffef",
              padding: "3px 3px",
              borderRadius: "4px",
              border: "1px solid #ca3c32",
              fontSize: 10,
            }}
          >
            {0 > getRemainingWidth ? (
              <>
                {"Cell Width Exceed, "}
                {"Please complete remaining number!"}
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </Grid>
    )
  }

  const renderRightHeaderPanel = () => {
    let sum = totalWidth - cellWidthSUM
    const isDigitShelfCndition = sum <= 10
    return (
      <Grid className="cls-skl-left-panl">
        <Button
          className="cls-action-item"
          disabled={isCellDisable}
          onClick={() => onHandleClickRemoveAllCell(stateShekelProps)}
          style={{
            marginRight: 10,
            border: isCellDisable ? "1px solid #8B8B8B" : "1px solid #99368D",
            color: isCellDisable ? "#8B8B8B" : "#99368D",
            height: 32,
            fontSize: 14,
            width: 150,
            opacity: isCellDisable ? 0.5 : 1,
          }}
        >{`CLEAR ALL CELL`}</Button>
        <Button
          disabled={isDigitShelfCndition}
          className="cls-action-item"
          onClick={() => onHandleClickAssociateCell(stateShekelProps)}
          style={{
            background: isDigitShelfCndition ? "#C4C4C4" : "#99368D",
            color: "#fff",
            height: 32,
            fontSize: 14,
            width: 150,
          }}
        >
          {`+ Associate CELL`}
        </Button>
        {renderChild()}
      </Grid>
    )
  }

  const renderRowContentWrapper = () => {
    return (
      <>
        {isCellDisable ? (
          <StyledDOM.StyledShekelRowEmptyWrapper>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h3>
                {`No Records Found, ${FIXTURE?.defaultEmptyCell.replace(
                  "Please",
                  isAssociateShelf
                    ? getIsMultipleProduct
                      ? `Insert Yilai Shelf`
                      : "Insert Digit Shelf"
                    : "Insert cells"
                )}`}
              </h3>
              <img
                style={{
                  width: "100%",
                  height: "auto",
                  marginTop: 18,
                  maxWidth: 400,
                  opacity: 0.5,
                }}
                src={
                  isAssociateShelf
                    ? getIsMultipleProduct
                      ? digitYilai
                      : digitShelf
                    : digitShekele
                }
                alt="digit"
              />
            </div>
          </StyledDOM.StyledShekelRowEmptyWrapper>
        ) : (
          <StyledDOM.StyledShekelRowInnerCellWrapper>
            {cells.map((currentCellItem, currentCellIndex) => {
              return (
                <React.Fragment key={`cell_${currentCellIndex}`}>
                  {renderCellItem({
                    ...stateShekelProps,
                    currentCellItem,
                    currentCellIndex,
                    cellWidthSUM,
                    cellCount,
                  })}
                </React.Fragment>
              )
            })}
          </StyledDOM.StyledShekelRowInnerCellWrapper>
        )}
      </>
    )
  }

  return (
    <StyledDOM.StyledShekelRowMainWrapper
      className={
        gondolaTheme
          ? "cls-theme-1"
          : `cls-theme-2 ${getIsMultipleProduct ? "cls-theme-2-multiproduct" : ""
          }`
      }
    >
      <StyledDOM.StyledShekelRowWrapper className="cls-StyledShekelRowWrapper">
        <StyledDOM.StyledShekelRowHeaderWrapper className="cls-StyledShekelRowHeaderWrapper">
          {renderLeftHeaderPanel({ cellCount })}
          {renderRightHeaderPanel({ cellCount })}
        </StyledDOM.StyledShekelRowHeaderWrapper>
        <StyledDOM.StyledShekelRowContentWrapper
          className={`${getIsMultipleProduct ? "cls-wrapper-multiproduct" : ""
            }`}
        >
          {renderRowContentWrapper({ cellCount })}
        </StyledDOM.StyledShekelRowContentWrapper>
      </StyledDOM.StyledShekelRowWrapper>
    </StyledDOM.StyledShekelRowMainWrapper>
  )
}
