import * as Yup from "yup"
import _, { values } from "lodash"
import {
  renderFieldInventoryOnHand,
  renderFieldStorageIOH,
  // renderFieldTaxRate,
  // renderFieldTaxValue,
  renderFieldPackageType,
  renderFieldReorderQuantity,
  renderFieldPackageReceived,
  renderFieldOrderQuantity,
  renderFieldOrderReceived,
  renderFieldUnitPrice,
  renderFieldTotalPrice,
  renderFieldExpiryDate,
  renderFieldBatchNumber,
  renderFieldAdjustmentReason,
  renderFieldAdjustmentType,
  renderFieldProjectedInventory,
} from "./renderProps"

export const doDataInitialValues = (
  editData,
  isStorage = false,
  viewPage = "edit"
) => {
  // let taxData = {
  //   taxRate: _.get(editData, "taxRate", "") || "",
  //   taxValue: _.get(editData, "taxValue", "") || "",
  // }

  let commonData = {
    projected_inventory: _.get(editData, "projected_inventory", "") || "",
    inventoryOnHand: isStorage
      ? _.get(editData, "storage_unit_inventory_on_hand", "")
      : _.get(editData, "inventory_on_hand", ""),
    _id: _.get(editData, "_id", "") || "",
    linkedStorageIOH: _.get(editData, "storage_unit_inventory_on_hand", ""),
  }

  /**
   * @LOCATION_STORE_DATA
   */
  let storeData = {
    adjustment_type: _.get(editData, "adjustment_type", "") || "",
    adjustment_reason: _.get(editData, "adjustment_reason", "") || "",
    quantity: _.get(editData, "quantity", "") || "",
    // ...taxData,
  }

  /**
   * @STORAGE_DATA
   */

  let storageKit = {
    adjustment_type: _.get(editData, "adjustment_type", "") || "",
    package_type: _.get(editData, "package_type", "") || "",
    adjustment_reason: _.get(editData, "adjustment_reason", "") || "",
    package_received: _.get(editData, "package_received", "") || "",
    order_quantity: _.get(editData, "order_quantity", "") || "",
    order_received: _.get(editData, "order_received", "") || "",
    unit_price: _.get(editData, "unit_price", "") || "",
    total_price: _.get(editData, "total_price", "") || "",
    expiry_date: _.get(editData, "expiry_date", "") || "",
    batch_number: _.get(editData, "batch_number", "") || "",
    quantity: _.get(editData, "quantity", "") || "",
  }
  let storageCreate = storageKit
  let storageData = {
    ...storageCreate,
  }

  let directFormData = isStorage ? storageData : storeData

  const config = {
    ...commonData,
    ...directFormData,
  }

  return config
}

export const doValidationSchema = (
  props,
  isStorage = false,
  viewPage = "edit"
) => {
  const { isTax = false } = props

  // let taxSchema = isTax
  //   ? {
  //     taxRate: Yup.string()
  //       .trim("White spaces cannot be allowed")
  //       .strict(true)
  //       .required("Enter Tax Rate")
  //       .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
  //     taxValue: Yup.string()
  //       .trim("White spaces cannot be allowed")
  //       .strict(true)
  //       .required("Enter Tax Value")
  //       .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
  //   }
  //   : {}

  let commonSchema = {
    // inventoryOnHand: Yup.string()
    //   .required("Enter Available on Hand")
    //   .trim("White spaces cannot be allowed")
    //   .strict(true)
    //   .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
    projected_inventory: Yup.string().required("Auto Projected inventory"),
    // .trim("White spaces cannot be allowed")
    // .strict(true)
    // .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
  }

  let storeSchema = {
    // ...taxSchema,
    adjustment_type: Yup.string().required("Select Adjustment Type"),

    quantity: Yup.string()
      .trim("White spaces cannot be allowed")
      .strict(true)
      .required("Enter Quantity")
      .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
    adjustment_reason: Yup.string().required("Select Adjustment Reason"),
  }

  let storageKit = {
    adjustment_reason: Yup.string().required("Select Adjustment Reason"),
    adjustment_type: Yup.string().required("Select Adjustment Type"),
    package_type: Yup.string().required("Select Package Received"),
    package_received: Yup.string()
      .trim("White spaces cannot be allowed")
      .strict(true)
      .required("Enter Package Received")
      .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
    order_quantity: Yup.string()
      .trim("White spaces cannot be allowed")
      .strict(true)
      .required("Enter Order Quantity")
      .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
    order_received: Yup.string()
      .trim("White spaces cannot be allowed")
      .strict(true)
      .required("Enter Order Received")
      .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
    unit_price: Yup.string()
      .trim("White spaces cannot be allowed")
      .strict(true)
      .required("Enter Unit Price")
      .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`)
      .matches(
        /^\d+(\.\d{1,2})?$/,
        `Please enter a valid decimal value with up to two decimal places`
      ),
    total_price: Yup.string()
      .trim("White spaces cannot be allowed")
      .strict(true)
      .required("Enter Total Price")
      .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
    batch_number: Yup.string()
      .required("Enter Available on Hand")
      .trim("White spaces cannot be allowed")
      .strict(true),
    quantity: Yup.string()
      .trim("White spaces cannot be allowed")
      .strict(true)
      .required("Enter Quantity")
      .matches(/^(\d*\.{0,1}\d{0,2}$)/, `Please enter Valid Format`),
  }

  let storageCreate = storageKit

  let storageSchema = {
    ...storageCreate,
  }

  let formSchema = isStorage ? storageSchema : storeSchema

  const config = {
    ...commonSchema,
    ...formSchema,
  }

  return config
}

export const doRenderList = (
  isStorage = false,
  viewPage = "",
  packageList = []
) => {
  let store = [
    renderFieldStorageIOH,
    renderFieldInventoryOnHand,
    renderFieldAdjustmentType,
    renderFieldReorderQuantity,
    renderFieldAdjustmentReason,
    // renderFieldTaxRate,
    // renderFieldTaxValue,
    renderFieldProjectedInventory,
  ]

  const storageSet = [
    renderFieldStorageIOH,
    renderFieldInventoryOnHand,
    renderFieldAdjustmentType,
    renderFieldReorderQuantity,
    renderFieldAdjustmentReason,
    renderFieldPackageType,
    renderFieldPackageReceived,
    renderFieldOrderQuantity,
    renderFieldOrderReceived,
    renderFieldUnitPrice,
    renderFieldTotalPrice,
    renderFieldExpiryDate,
    renderFieldBatchNumber,
    renderFieldProjectedInventory,
  ]
  let storage = viewPage === "edit" ? storageSet : storageSet
  //let storage = [...storageCreate]
  let direct = isStorage ? storage : store
  return [...direct]
}
