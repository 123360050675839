import React, { useState } from "react"
import { Checkbox, CircularProgress } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemText from "@material-ui/core/ListItemText"
import { SearchIcon } from "@iconsGallery"
import { Button, OutlinedInput, FormControl } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import styled from "styled-components"
import _ from "lodash"

const useStyle = makeStyles({
    disableTextHover: {
        "& .MuiListItem-button:hover": {
            background: "transparent !important",
        },
    },
    formControl: {
        "& .MuiFormLabel-root.Mui-focused": {
            color: "var(--primaryColor)",
        },
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "var(--primaryColor)",
            },
        },
    },
    textInput: {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--primaryColor)",
        },
    },
})
const FormControlWrapper = styled(FormControl)`
  .cls-empty-textfield input {
    background: rgb(224, 224, 224);
  }
  position: relative;
  &.cls-error {
    fieldset {
      border-color: rgb(244 67 53) !important;
    }
    .MuiSelect-selectMenu,
    .MuiSelect-icon {
      color: rgb(244 67 53);
    }
  }
`

function AutocompleteDropdown(props) {
    const {
        label = "",
        fetchData = () => "",
        data: dropdownData,
        filteredItems = [],
        handleDropdownChange = () => "",
        handleSearchTerm = () => { },
        totalDocs = "",
        handleShowMore = () => { },
        searchTerm = "",
        isLookup = true,
        isDisabled = false,
        style = {
            backgroundColor: "transparent",
            height: "38px",
            width: isLookup ? "10vw" : "255px",
        },
        disabledData = [],
        searchBox = true
    } = props || {}
    // console.log("AutocompleteDropdown", props)
    const classes = useStyle()
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = useState(false)
    const [selectedItems, setSelectedItems] = React.useState(filteredItems)
    const [searchText, setSearchText] = React.useState(searchTerm)

    React.useEffect(() => {
        setSelectedItems(selectedItems)
    }, [selectedItems])

    React.useEffect(() => {
        setSearchText(searchText)
    }, [searchText])

    React.useEffect(() => {
        setSearchText(searchTerm)
    }, [searchTerm])

    React.useEffect(() => {
        setSelectedItems(filteredItems)
    }, [filteredItems])

    const ITEM_HEIGHT = 70
    const ITEM_PADDING_TOP = 5

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
                transform: "translate(-85px , 0)",
            },
        },
        getContentAnchorEl: null,
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
    }

    const handleChange = (event) => {
        if (!event.target.value.includes("")) {
            setSelectedItems(event.target.value)
            handleDropdownChange(event.target.value, label)
        }
    }

    const handleSearch = (val) => {
        setLoading(true)
        setSearchText(val)
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        handleSearchTerm(val, label)
    }

    return (
        <FormControlWrapper className={classes.formControl}>
            <Select
                multiple
                value={selectedItems}
                onChange={(event) => {
                    handleChange(event)
                }}
                defaultValue="none"
                displayEmpty
                input={
                    <OutlinedInput
                        margin="dense"
                        style={{ width: "30vw", height: "36px" }}
                    />
                }
                renderValue={(selected) => {
                    if (selected.length > 1) {
                        const remaining = selected.length - 1
                        return (
                            <b>
                                {label && `${label}:`} {selected[0]} and {remaining} more
                            </b>
                        )
                    } else if (selected.length === 1) {
                        return (
                            <b>
                                {label && `${label}:`}{selected[0]}
                            </b>
                        )
                    } else {
                        return <b>{label}</b>
                    }
                }}
                style={style}
                MenuProps={MenuProps}
                disabled={isDisabled}
            >
                {searchBox &&
                    <MenuItem
                        value=""
                        onKeyDown={(e) => e.stopPropagation()}
                        style={{
                            padding: "5px 10px 10px 10px",
                            backgroundColor: "#ffffff",
                            position: "sticky",
                            top: "0px",
                            zIndex: "2",
                        }}
                    >
                        <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Search"
                            value={searchText}
                            InputProps={{
                                endAdornment: (
                                    <React.Fragment>
                                        <SearchIcon style={{ color: "#CCC" }} />
                                    </React.Fragment>
                                ),
                            }}
                            className={classes.textInput}
                            onChange={(event) => {
                                handleSearch(event.target.value)
                            }}
                        />
                    </MenuItem>}
                {loading ? (
                    <CircularProgress size={30} style={{ margin: "80px" }} />
                ) : dropdownData !== undefined && dropdownData.length > 0 ? (
                    dropdownData.map((name, idx) => (
                        <MenuItem
                            key={name.id}
                            value={name.name}
                            style={{ height: "36px", position: "relative", zIndex: 1 }}
                            disabled={_.includes(disabledData, name.name)}
                        >
                            <Checkbox
                                checked={selectedItems.indexOf(name.name) > -1}
                                className={classes.checkBoxSelect}
                                style={{ marginRight: "10%", color: "var(--primaryColor)" }}
                            />
                            <ListItemText primary={name.name} style={{ fontSize: "14px" }} />
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled style={{ height: "30px" }}>
                        {" "}
                        No Items found{" "}
                    </MenuItem>
                )}

                {loading ? (
                    <CircularProgress size={30} style={{ margin: "80px" }} />
                ) : totalDocs > dropdownData.length > 0 ? (
                    <MenuItem
                        style={{ background: "transparent" }}
                        value=""
                        onKeyDown={(e) => e.stopPropagation()}
                    >
                        <Button
                            color="primary"
                            style={{ fontWeight: 700 }}
                            onClick={() => {
                                setLoading(true)
                                handleShowMore(label)
                                setTimeout(() => {
                                    setLoading(false)
                                }, 2000)
                            }}
                        >
                            Show More
                        </Button>
                    </MenuItem>
                ) : null}
                <MenuItem
                    disabled
                    style={{
                        background: "#bcb9b9",
                        width: "100%",
                        display: "flex",
                        justifyContent: "end",
                        height: "30px",
                    }}
                >
                    {dropdownData.length + " of " + totalDocs}
                </MenuItem>
            </Select>
        </FormControlWrapper>
    )
}
export default AutocompleteDropdown
