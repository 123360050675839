import React, { useState, useContext } from "react"
import styled, { createGlobalStyle } from "styled-components/macro"
import Sidebar from "../components/SideBar"
import Header from "../components/AppBar"
import Footer from "../components/Footer"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"

import { spacing } from "@material-ui/system"
import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  withWidth,
  Grid,
} from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import { isWidthUp } from "@material-ui/core/withWidth"
import { DashboardContext } from "@utils"
//import ChangeTheme from "../components/changeTheme";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
  .MuiPopover-paper {
    border: 1px solid #c4c4c4;
  }
  .MuiInputBase-root.Mui-disabled {
    color: rgba(0,0,0,1)
  }
    .MuiSwitch-track {
    background-color: rgba(158, 158, 158, 1);
  }
  .MuiSwitch-switchBase {
    color: rgba(158, 158, 158, 1);
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #4782da;
  }
  .MuiPaper-elevation8.MuiPaper-rounded {
    max-height: 200px;
  }
`

const Root = styled.div`
  display: flex;
  min-height: 100vh;

  // max-width: 100%;
`

const Drawer = styled.div`
  position: relative;
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${(props) => props.drawerWidth}px;
    flex-shrink: 0;
  }
`

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% - ${(props) => props.width}px);
  position: relative;
  /* z-index: -9999; */
`

const Paper = styled(MuiPaper)(spacing)

const MainContent = styled(Paper)`
  flex: 1;
  padding: 20px;
  padding-top: 18px;
  background: ${(props) => props.theme.palette.background.default};
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
  .k-list-optionlabel.k-state-selected {
    color: #ffffff;
    background-color: #1565c0;
  }
`

const Dashboard = ({ children, routes, width }) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  // const [showMore, setShowMore] = useState(false)
  const { showMore, setShowMore } = useContext(DashboardContext)

  // const [showMore, setShowMore] = useState(() => {
  //   const showBanner = localStorage.getItem('showBanner');
  //   // if showBanner null/undefined fallback to true
  //   return JSON.parse(showBanner) ?? true;
  // });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const theme = useTheme()
  const isXLDevice = useMediaQuery(theme.breakpoints.up("xl"))

  let drawerWidth = showMore ? 67 : isXLDevice ? 250 : 230
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {/* <Grid container xs={12} sm={12} md={12}
       lg={12} xl={12}>
        <Grid> */}
      <Drawer drawerWidth={drawerWidth} id="id-showed-sidebar">
        <Hidden mdUp implementation="js">
          <Sidebar
            routes={routes}
            PaperProps={{
              style: {
                width: drawerWidth,
                overflowX: "hidden",
                transition: "1s ease-in-out",
                top: "0px",
              },
            }}
            variant="temporary"
            open={mobileOpen}
            mobileOpen={!mobileOpen}
            onClose={handleDrawerToggle}
            setShowMore={setShowMore}
            showMore={showMore}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Sidebar
            setShowMore={setShowMore}
            showMore={showMore}
            routes={routes}
            PaperProps={{
              style: {
                width: drawerWidth,
                overflowX: "hidden",
                transition: "0.1s ease-in-out",
                top: "0px",
              },
            }}
          />
        </Hidden>
      </Drawer>
      {/* </Grid>
        <Grid> */}
      <AppContent width={drawerWidth}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent p={isWidthUp("lg", width) ? 12 : 5}>
          {children}
        </MainContent>
        <Footer />
      </AppContent>
      {/* </Grid>
      </Grid> */}
      {/* to enable theme selection ,un comment below component */}
      {/* <ChangeTheme /> */}
    </Root>
  )
}

export default withWidth()(Dashboard)
