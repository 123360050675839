import { Checkbox } from "@material-ui/core"
import React from "react"

function CheckboxCell(props) {
  const data = props.dataItem
  const dataValue = props.value
  return (
    <td>
      {data.inEdit ? (
        <Checkbox
          defaultChecked={dataValue}
          onClick={props.onClick}
          value={dataValue}
          style={{ color: "var(--primaryColor)" }}
        />
      ) : props.renderText ? (
        "Default"
      ) : (
        <span style={{ color: "#afafaf" }}>{"Not Default"}</span>
      )}
    </td>
  )
}

export default CheckboxCell
