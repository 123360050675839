import React from "react"

function useSearchListState(props) {
  const { defaultFetchList = [], isMultipleSelect } = props || {}
  const [fetchListData, setFetchListData] = React.useState(defaultFetchList)
  const [removedFetchListData, setRemovedFetchListData] = React.useState(
    defaultFetchList
  )
  const [searchText, setSearchText] = React.useState("")
  const [buttonTrigger, setButtonTrigger] = React.useState(false)
  const [isSelectIndex, setSelectIndex] = React.useState(-1)

  React.useEffect(() => {
    let localFetchList = defaultFetchList
    if (isMultipleSelect === false) {
      const getIndex = localFetchList.findIndex((x) => x.selected === true)
      const updatedLocalFetchList = localFetchList.map(
        ({ selected, ...rest }, index) => {
          let dataKit = { ...rest }
          if (index === getIndex) {
            dataKit = { ...dataKit, selected: true }
          }
          return dataKit
        }
      )
      setSelectIndex(getIndex)
      setFetchListData(updatedLocalFetchList)
    } else {
      setFetchListData(localFetchList)
    }
  }, [defaultFetchList, isMultipleSelect])

  return {
    fetchListData,
    setFetchListData,
    searchText,
    setSearchText,
    buttonTrigger,
    setButtonTrigger,
    isSelectIndex,
    setSelectIndex,
    removedFetchListData,
    setRemovedFetchListData,
  }
}

export { useSearchListState }
