import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS
export const profileUpdateData = (data) => (dispatch) => {
    dispatch({
        type: types.PROFILE_DATA,
        profileData: data,
    })
}
