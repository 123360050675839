import React,{useState,useEffect} from 'react'
import { Button, Grid, IconButton, TextField, Tooltip, Typography } from "@material-ui/core"
import { CloseIcon, SearchIcon, ChevronLeft, ChevronRight } from "@iconsGallery"
import * as Adm from "@adm"
import { ConfirmationDialog } from "@features"
import { useHistory, Prompt } from "react-router-dom"

import styled from "styled-components/macro"
// import update from 'immutability-helper'
import { CircularProgress, makeStyles } from "@material-ui/core"
import { Helmet } from "react-helmet"
import { Card, Collapse, Card as MuiCard, CardMedia } from "@material-ui/core"
import { DropzoneArea, DropzoneDialog } from "material-ui-dropzone"
import { spacing } from "@material-ui/system"
import Paper from "@material-ui/core/Paper"
import Divider from "@material-ui/core/Divider"
import { CheckCircleIcon, ChevronUp, ChevronDown, Save, Slash, Edit } from "@iconsGallery"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "@utils"
import * as ServiceAPI from "@services"
import { useDispatch, useSelector } from "react-redux"
import * as constantsAPI from "@constants"
import { getTreeFromFlatData, getFlatDataFromTree } from "react-sortable-tree"
import _, { set } from "lodash"
// import * as Adm from "@adm"
import * as Features from "@features"
import { APIUrl } from "@utils"
import DraggableTreeView from "../../components/DraggableTreeView"
import { CategoryForm } from "../../FeaturesKit/ProductHierarchy/CategoryForm"
import productsAPI from '../../services/masterData/products/api';

import { getProcessedFlatData, getNewFlatDataWithOldExpandedStatus, getNodeId, getNodeKey, getParentNodeId, getTreeData, getFlatData, getAllChildrenIds } from '../../components/DraggableTreeView/treeDataUtils'
import { fetchAllHierarchy } from "../../services/masterData/locations/actions"

const { API_URL_STORES } = APIUrl || {}
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {};
const initialFormProps = { isOpen: false, data: {} };
const initSnackbarProps = { isOpen: false, message: '', type: 'info', autoHideDuration: 2500 };


const HierarchyEditorUI = (props) => {
    // let history = useHistory()
    // const {
    //     treeData = [],
    //     stores = [],
    //     fetchEdit = () => "",
    // } = props || {}
    // const [getTreeData, setTreeData] = React.useState(props.treeData)
    // const [expand, setExpand] = React.useState(false)
    // const [collapseAll, setCollapseAll] = React.useState(false)
    // const [searchResults, setSearchResults] = React.useState([])
    // const [resultCounter, setResultsCounter] = React.useState(0)
    // const [text,setText] = React.useState("")
    // const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
    // const [reDirect, setReDirect] = useState(false)
    // const [pathName, setPathName] = useState("/")
    // const [allowPrompt, setAllowPrompt] = useState(true)
    // const onExpandAction = () => {
    //     setCollapseAll(false)
    //     setExpand(false)
    // }
    
   const dispatch = useDispatch()
  const { categories } = useSelector(state => _.get(state, 'locationHierarchyReducer', {}))
  const [formProps, setFormProps] = useState({ ...initialFormProps });
  const [flatTreeData, setFlatTreeData] = useState(() => {  return categories?.list || [] });
  const [treeData, setTreeData] = useState(getTreeData(flatTreeData));
  const [processedFlatData, setProcessedFlatData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [snackbarProps, setSnackbarProps] = useState(initSnackbarProps)
  const [height, width] = useWindowSize()
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [data, setData] = useState([])
  // const formData = useSelector((state) => state.addLocationReducer)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [allowAlcoholUpdate, setAllowAlcoholUpdate] = useState(false)
  const [allowUpdate, setAllowUpdate] = useState(false);
  const [open, setOpen] = useState(false)
  const [selectedNodeId, setSelectedNodeId] = useState()



//     React.useEffect(() => {
//         if (searchResults.length&&searchResults.length>0) {
//             setResultsCounter(1)
//         } else {
//             setResultsCounter(0)
//         }
//     }, [searchResults])
    
//     const expandHelper = (tree) => {
//         return tree&&tree.map((item, idx) => {
//             if (item.items) {
//                 return {title:item.title,id:item.id,expanded:true,edit:item.edit,items:item.items&&expandHelper(item.items)}
//             } else {
//                 return {title:item.title,id:item.id,edit:item.edit}
//             }
//         })
//     }

//     const collapseHelper = (tree) => {
//         return tree&&tree.map((item, idx) => {
//             if (item.items) {
//                 return {title:item.title,id:item.id,expanded:false,edit:item.edit,items:item.items&&collapseHelper(item.items)}
//             } else {
//                 return {title:item.title,id:item.id,edit:item.edit}
//             }
//         })
//     }
    
//     const onExpandClick = () => {
//         setExpand(true)
//         setCollapseAll(false)
//     }

//     const onCollapseClick = () => {
//         setExpand(false)
//         setCollapseAll(true)
//     }

//     const search = (items, text) => {
//         return items.reduce((acc, item) => {
//             if (item.title.toLowerCase().indexOf(text.toLowerCase()) >= 0) {
//                 acc.push(item.index)
//             }
//             else if (item.items && item.items.length > 0) {
//                 let newItems = search(item.items, text)
//                 if (newItems && newItems.length > 0) {
//                         acc.push(item.index);
//                 }
//             }
//             if (text.length === 0) {
//                 return []
//             } else {
//                 return acc
//             }
//         },[])
//     }

//     const icon = <SearchIcon color="disabled" />

//     const handleReset = (pathName) => {
//     if (pathName === "/") {
//       history.push("/master-data/product")
//     } else {
//       history.push(pathName)
//     }
//   }

//    React.useEffect(() => {
//     if (reDirect) {
//       handleReset(pathName)
//     }
//   }, [reDirect])

    //  return (
    //      <>
    //      <ConfirmationDialog
    //     openConfirmationDialog={openConfirmationDialog}
    //     setOpenConfirmationDialog={setOpenConfirmationDialog}
    //     setReDirect={setReDirect}
    //   />
    //   <Prompt
    //     when={allowPrompt}
    //     message={(location, action) => {
    //       setPathName(location.pathname)
    //       setOpenConfirmationDialog(true)
    //       return reDirect
    //     }}
    //   />
    //     <Grid
    //         container
    //         direction="column"
    //     >
    //         <Grid
    //             container
    //             direction="row"
    //             style={{padding:"1vh"}}
    //             spacing={4}
    //         >
    //             <Grid item>
    //             <Button
    //                     variant="outlined"
    //                     disabled={expand}
    //                 color="primary"
    //                     onClick={() => {
    //                     onExpandClick()
    //                 }}    
    //             > EXPAND ALL
    //                 </Button>
    //             </Grid>
    //             <Grid item>
    //             <Button
    //                 variant="outlined"
    //                     color="primary"
    //                     disabled={collapseAll}
    //                 onClick={()=>{onCollapseClick()}}    
    //             > COLLAPSE ALL
    //                 </Button>
    //             </Grid>
    //             <Grid item>
    //                 <TextField
    //                     placeholder=" Search"
    //                     variant="outlined"
    //                     size="small"
    //                     value={text}
    //                     onChange={(event) => {
    //                         let text = event.target.value
    //                         setText(event.target.value)
    //                     let filteredData = search(treeData, text)
    //                     setSearchResults(filteredData)
    //                     }}
    //                     InputProps={{startAdornment:icon}}
    //                 />
    //             </Grid>
    //             <Grid item>
    //                 <Tooltip title = "Prev">
    //                 <IconButton
    //                     disabled={searchResults.length === 0 || resultCounter === 1}
    //                     onClick={() => {
    //                         setResultsCounter(resultCounter-1)
    //                     }}
    //                 >
    //                         <ChevronLeft/>
    //                     </IconButton>
    //                 </Tooltip>
    //                 <Typography style={{ display: "inline-flex", color: "#000" }}>{resultCounter}/{searchResults.length}</Typography>
    //                 <Tooltip title = "Next">
    //                 <IconButton
    //                     disabled={searchResults.length === 0 || resultCounter === searchResults.length}
    //                     onClick={() => {
    //                         setResultsCounter(resultCounter+1)
    //                     }}
    //                 >
    //                         <ChevronRight/>
    //                     </IconButton>
    //                 </Tooltip>
    //             </Grid>
    //             <Grid item>
    //                 <Button
    //                     variant="filled"
    //                     style={{ backgroundColor: "transparent", color: "#1565C0", paddingTop: "12px" }}
    //                     onClick={() => {
    //                         setText("")
    //                         setSearchResults([])
    //                     }}
    //                 >
    //                     <b>Clear</b>
    //                 </Button>
    //             </Grid>

    //         </Grid>
    //         <Grid style={{height:"60vh",overflowY:"scroll"}}>
    //         <Adm.CheckboxTree
    //             treeData={getTreeData}
    //             stores={stores}
    //             fetchEdit={fetchEdit}
    //             collapseAll={collapseAll}
    //             expandAll={expand}
    //             onExpandAction={onExpandAction}
    //             editorSearchTerm={searchResults.length > 0 ? searchResults[resultCounter - 1] : null}
    //             checkedNodes={[]}
    //             />
    //         </Grid>
    //         </Grid>
    //         </>
    // ) 
            
            const handleFormClose = () => {
    setFormProps({ ...initialFormProps });
  }
   
  const handleFormOpen = () => {
    setFormProps({ ...initialFormProps, isOpen: true, mode: 'create',openNewNode:{} })
  }

  const handleViewOpen = (categoryData) => {
    if (props.isComInProductMapping) {
      setSelectedNodeId(categoryData._id);
      props.setCategory(categoryData)
    } else setFormProps({ isOpen: true, mode: 'view', data: categoryData,openNewNode:{} })
  }

  const handleAddNode = (categoryID) => {
    setFormProps({ isOpen: true, mode: 'create', data: { mapped_parent_categories: _.isArray(categoryID) ? categoryID : [categoryID] } })
  }

  const handleNodeDelete = (id) => {
    //setSnackbarProps({ ...initSnackbarProps, isOpen: true, message: 'Hold On! Deletion in process...', type: 'info', icon: <CircularProgress color="warning" size={20} /> })
    productsAPI.deleteCategory(id).then(resp => {
      fetchNewFlatTreeData();
      setSnackbarProps({ ...initSnackbarProps, isOpen: true, message: 'Successfully Deleted!', type: 'success' })
    }).catch(err => {
      setSnackbarProps({ ...initSnackbarProps, isOpen: true, message: _.get(err, 'data.message', `Sorry! Delete operation failed.`), type: 'error' })
    })
  }

  const fetchNewFlatTreeData = (oldFlatData = getFlatData(treeData)) => {
    dispatch(fetchAllHierarchy())
    // productsAPI.getAllProductCategories().then(resp => {
    //   let tempTreeData = getNewFlatDataWithOldExpandedStatus(resp?.data?.categories, oldFlatData);
    //   setFlatTreeData(tempTreeData);
    // })
  }

  const handleNodeMove = (newParentData, nodeData) => {
    let tempData = nodeData;
    let tempParentID = _.compact([newParentData?.category_id, ...(newParentData?.mapped_parent_categories || [])]); //_.isNil(newParentID) ? [null] : [newParentID];
    nodeData.mapped_parent_categories = tempParentID;
    //setSnackbarProps({ ...initSnackbarProps, isOpen: true, message: 'Hold On! Updating...', type: 'info', icon: <CircularProgress color="secondary" /> })
    productsAPI.updateCategory(nodeData?.category_id, nodeData).then(resp => {
      fetchNewFlatTreeData(getFlatData(treeData))
      setSnackbarProps({ ...initSnackbarProps, isOpen: true, message: 'Updated Successfully!', type: 'success' })
    }).catch(err => {
      setSnackbarProps({ ...initSnackbarProps, isOpen: true, message: _.get(err, 'data.message', `Sorry! Update operation failed.`), type: 'error' })
    })
  }

  const handleCheckboxClick = (node, parentNode) => {
    let exstIdx = _.findIndex(selectedCategories, o => o === node?.category_id);
    let childrenIds = getAllChildrenIds(node) || [];
    let allIdsOfNode = [node?.category_id, ...childrenIds];
    let temp;
    if (exstIdx === -1) {
      // temp = update(selectedCategories, { $push: allIdsOfNode });
    } else {
      temp = _.without(selectedCategories, ...(allIdsOfNode || []))
      // temp = update(selectedCategories, { $splice: [[exstIdx, 1]] })
    }
    setSelectedCategories(temp);
    setProcessedFlatData(getProcessedFlatData({ data: getFlatData(treeData), checkedList: temp }))

  }

  

  useEffect(fetchNewFlatTreeData, []);

  useEffect(() => {
    setProcessedFlatData(getProcessedFlatData({ data: flatTreeData, checkedList: selectedCategories }));
  }, [flatTreeData]);

  useEffect(() => {
    setFlatTreeData(getNewFlatDataWithOldExpandedStatus(categories?.list || [], getFlatData(treeData)))
  }, [categories?.list])

  useEffect(() => {
    let temp = getTreeData(processedFlatData)
    setTreeData(temp)
  }, [processedFlatData])

  return (
    <>
      <Grid container item xs={12} style={{ border: `1px solid #e0e0e0`, height: `100%` }}>
        <Adm.BackdropOverlay open={loading} />
        <Grid container>
          <Grid item xs={formProps?.isOpen ? 4 : 12}>
            <DraggableTreeView
              isViewMode={false}
              treeData={treeData} onChangeInTreeData={setTreeData}
              handleCheckboxClick={handleCheckboxClick}
              onAddClick={handleFormOpen} flatTreeData={processedFlatData}
              handleClickNode={handleViewOpen} handleAddNode={handleAddNode}
              handleNodeMove={handleNodeMove} handleNodeDelete={handleNodeDelete}
              selectedNodeId={selectedNodeId}
              isComInProductMapping={props.isComInProductMapping && props.isComInProductMapping}
              canDragPropInProductMapping={props.canDragPropInProductMapping && props.canDragPropInProductMapping}
              showAddRootNodeButton={props.showIcon === false ? false : true} />
          </Grid>
          {
            formProps?.isOpen && <Grid item xs={8} style={{ borderLeft: `1px solid #e0e0e0` }}> <CategoryForm formProps={formProps} setFormProps={setFormProps} onClose={handleFormClose} data={formProps?.data || {}} fetchNewFlatTreeData={fetchNewFlatTreeData} /> </Grid>
          }
        </Grid>
      </Grid>
      {snackbarProps?.isOpen && <Adm.SnackBar open message={snackbarProps?.message || 'Uh! May be wait!?'} type={snackbarProps?.type || 'info'} icon={snackbarProps?.icon || undefined} handleClose={() => setSnackbarProps(initSnackbarProps)} />}
    </>
  )
}

export default HierarchyEditorUI
export {HierarchyEditorUI}