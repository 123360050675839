import Keycloak from "keycloak-js"
import { createBrowserHistory } from "history"
import _ from "lodash"
import { useSelector } from "react-redux"
const history = createBrowserHistory()
const {
  THEME_ASSET = "dev-theme-assets",
  LOGO_FOLDER = "digitmart",
  DOMAIN_ENV = "dev",
  location = {} } = window || {}
let companyName = "digitmart"
window["brandName"] = "DIGITMART"
let KC_DOMAIN_URL = process.env.REACT_APP_KEYCLOAK_URL

let keycloakConfigKit = {
  realm: process.env.REACT_APP_REALM_NAME,
  clientId: process.env.REACT_APP_CLIENTID,
  url: KC_DOMAIN_URL,
}

// let keycloakConfigKit = {
//   realm: "digitsquare",
//   clientId: "digitsquare-client",
//   url: KC_DOMAIN_URL,
// }


const onTriggerKCMappingConfig = () => {
  let domainNameKit = "digitmart"
  let tempKeycloakConfigKit = {
    realm: `digitsquaredev`,
    url: `https://authdevdm.digitsquare.ai`,
    clientId: `digitsquaredev-client`,
  }
  if (location.origin.includes("localhost")) {
    keycloakConfigKit = location.search.includes("ds")
      ? tempKeycloakConfigKit
      : keycloakConfigKit
  } else {
    let isDomainDM = location.origin.includes(companyName)
    let domainOrginal = companyName
    const hostName = location?.host?.split(".")

    if (hostName.length === 2) {
      const [lcdomainOrginal = companyName] = location?.host?.split(".")
      domainOrginal = lcdomainOrginal
    } else if (hostName.length === 3) {
      const [_x, lcdomainOrginal = companyName] = location?.host?.split(".")
      domainOrginal = lcdomainOrginal
    } else {
    }

    if (location?.host) {
      let realm = isDomainDM
        ? process.env.REACT_APP_REALM_NAME
        : process.env.REACT_APP_REALM_NAME.replace(companyName, domainOrginal)
      let url = isDomainDM
        ? KC_DOMAIN_URL
        : KC_DOMAIN_URL.replace(companyName, domainOrginal)

      url.split(".")[0] = "https://auth" + location?.host?.split(".")[0]

      //console.log("User services", url.split(".")[0] === "https://auth" + location?.host?.split(".")[0], url.split(".")[0] = "https://auth" + location?.host?.split(".")[0])

      let clientId = isDomainDM
        ? process.env.REACT_APP_CLIENTID
        : process.env.REACT_APP_CLIENTID.replace(companyName, domainOrginal)

      if (location.host.includes("portal")) {
        realm = `${domainOrginal}portal`
        clientId = `${domainOrginal}portal-client`
      }
      domainNameKit = domainOrginal
      keycloakConfigKit = {
        realm,
        url,
        clientId,
      }
    }

    //   if (location.origin.includes("portal.digitsquare.ai")) {
    //     keycloakConfigKit = tempKeycloakConfigKit
    //   }
  }

  let isFavDome = document.querySelector('link[rel="shortcut icon"]')
  if (isFavDome) {
    isFavDome.setAttribute(
      "href",
      DOMAIN_ENV === "portal" ?
        `https://https://stdigitmartproddata.blob.core.windows.net/${THEME_ASSET}/${LOGO_FOLDER}/small_light_logo.png` :
        `https://stdgrabdatanonprod001.blob.core.windows.net/${THEME_ASSET}/${LOGO_FOLDER}/small_light_logo.png`
    )
  }
}
onTriggerKCMappingConfig()

localStorage.setItem("X-KC-Realm-ID", keycloakConfigKit?.realm)
localStorage.setItem(
  "X-KC-Host",
  _.replace(keycloakConfigKit?.url, "https://", "")
)
window["brandName"] = _.upperCase(keycloakConfigKit?.realm)
//localStorage.setItem("X-KC-Host", "keycloakd.digitmart.ai");

const keycloakConfig = {
  realm: keycloakConfigKit?.realm,
  url: keycloakConfigKit?.url,
  clientId: keycloakConfigKit?.clientId,
  "ssl-required": "external",
  "public-client": true,
  "verify-token-audience": true,
  "use-resource-role-mappings": true,
  "confidential-port": 0,
}

const _kc = new Keycloak(keycloakConfig)

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */

const initKeycloak = (onAuthenticatedCallback) => {

  const { pathname = "" } = window.location
  window["_kc"] = _kc
  // if (pathname === `/${_kc.realm}/auth/forgetpassword`) {
  if (pathname === `/auth/forgetpassword`) {
    onAuthenticatedCallback()
  } else {
    _kc
      .init({
        onLoad: "check-sso",
        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
        pkceMethod: "S256",
        checkLoginIframe: false,
      })
      .then((authenticated) => {
        if (!authenticated) {
          // history.push("/dashboard")
          console.log(_kc, "loginnnnnnn")
          doLogin()
        } else {
          let mydata = getToken((res) => {
            return res
          })
          // _kc.updateToken(180)
          //     .then((res) => {
          //     })
          //     .catch((err) => {
          //     })
          // _kc.updateToken(5)
          //     .then(successCallback)
          //     .catch(doLogin);
          // const timeExpired = () => {
          // _kc.onTokenExpired = () => {
          // }
          // }
          localStorage.setItem("token", mydata)
          // history.push('/dashboard')
          onAuthenticatedCallback()
        }
      })
      .catch()
  }
}

const doLogin = _kc?.login

const doLogout = () => {
  const { historyW = {} } = window
  historyW.push("/")
  setTimeout(() => {
    _kc.logout()
  }, 100)
}

const getToken = () => _kc.token

const isLoggedIn = () => !!_kc.token

const isAuthExpired = () => _kc.isTokenExpired()

const updateRefreshToken = () => {
  return new Promise((resolve, reject) => {
    _kc
      .updateToken()
      .success(() => {
        resolve(true)
      })
      .error(() => {
        reject(false)
      })
  })
}

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin)

const getUsername = () => _kc.tokenParsed?.preferred_username

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role))

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  isAuthExpired,
  updateRefreshToken,
}

export default UserService
