import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Card, CardMedia, Grid, Typography } from "@material-ui/core"
import styled from "styled-components"
import { PdfIcon } from "@iconsGallery"

const CardWrapper = styled(Card)`
  width: fit-content;
`
const CardMediaPdfWrapper = styled(Grid)`
  width: 290px;
  height: 190px;
`
const CardMediaWrapper = styled(CardMedia)`
  width: 290px;
  height: 190px;
`
const CardMediaVideoWrapper = styled.video`
  width: 290px;
  height: 190px;
`
function Media(props) {
  const {
    type = "pdf",
    title = "test",
    url = "//via.placeholder.com/290x190",
    onClick = () => {},
  } = props || {}

  return (
    <CardWrapper title={title} onClick={onClick}>
      {type === "pdf" && (
        <CardMediaPdfWrapper>
          <PdfIcon />
          <Typography>{title}</Typography>
        </CardMediaPdfWrapper>
      )}
      {type === "image" && <CardMediaWrapper image={url} title={title} />}
      {type === "video" && (
        <CardMediaVideoWrapper controls={false}>
          <source src={url} type="video/mp4" />
        </CardMediaVideoWrapper>
      )}
    </CardWrapper>
  )
}

export { Media }
export default Media
