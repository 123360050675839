import React from "react"
import styled from "styled-components/macro"
import * as Adm from "@adm"
import { CircularProgress } from "@material-ui/core"
function Spinner() {
  return <Adm.BrandLoadingBar />
  // return (
  //   <div
  //     style={{
  //       alignItems: "center",
  //       display: "flex",
  //       justifyContent: "center",
  //       height: "100vh",
  //       width: "100vw",
  //     }}
  //   >
  //     <CircularProgress m={9} color="secondary" size={60} />
  //   </div>
  // )
}
export default Spinner
