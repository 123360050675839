import * as React from "react"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  CardHeader,
  Collapse,
  TextField,
  Button,
  FormLabel,
  Grid,
  ListItemText,
  Chip,
  Box,
  Typography,
  MenuItem,
  Switch,
  Checkbox,
  Tooltip,
} from "@material-ui/core"
import _ from 'lodash'
import styled from "styled-components"
import * as Adm from "@adm"
import * as IconsGallery from "@iconsGallery"
import * as Utils from "@utils"
import { SearchIcon } from "../../IconsGallery/IconsGallery"
const FormList = styled(Grid)`
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 50px;
  .cls-error p {
    bottom: -9px;
    left: 11px;
   }
  
`
const OptionalTextLabel = styled(Typography)`
  font-size: 12px;
  padding-left: 8px;
  color: #676767;
`
const SubTitleTextLabel = styled(Typography)`
  font-size: 12px;
  padding-left: 8px;
  color: #4782da;
`
const IconWrapper = styled(Grid)`
  padding-right: 10px;
`
const StyledTextField = styled(TextField)`
  &.disabled {
    input {
      background: #e4e4e4;
    }
  }
`
const FormLabelWrapper = styled.div.attrs({
  className: "base-form-label-main-wrapper",
})`
  display: flex;
  width: 200px;
  color: black;
  padding: 10px 30px;
  align-items: center;
`
const DetailedList = styled(Grid)`
  display: flex;
`

const renderIcon = (iconProps) => {
  let IconComponent = () => <React.Fragment />

  if (!Utils.JSUtils.isEmpty(iconProps)) {
    IconComponent = IconsGallery[iconProps]
  }

  return (
    !Utils.JSUtils.isEmpty(iconProps) && (
      <IconWrapper>
        <IconComponent />
      </IconWrapper>
    )
  )
}

const handleWhiteSpace = (e) => {
  if (e.which === 32 && e.target.value === '') {
    e.preventDefault();
  }
}

const handleEventPaste = (event) => {
  if (event.type === "paste") {
    let clipboardData = event.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData("Text")
    if (isNaN(pastedData)) {
      event.preventDefault()
    } else {
      return
    }
  }
  let keyCode = event.keyCode || event.which
  if (keyCode >= 96 && keyCode <= 105) {
    keyCode -= 48
  }
  const charValue = String.fromCharCode(keyCode)
  if (isNaN(parseInt(charValue)) && event.keyCode !== 8) {
    event.preventDefault()
  }
}

const renderLabel = ({
  label,
  icon,
  classes,
  infoMessage,
  isOptional,
  subTitle,
  isDetailView = false,
}) => {
  return (
    <FormLabelWrapper>
      {renderIcon(icon)}
      <FormLabel className={classes.formLabel}>{label}</FormLabel>
      {isOptional === false && !isDetailView && (
        <span style={{ color: 'red' }}>*</span>
      )}
      {Utils.JSUtils.isEmpty(subTitle) === false && (
        <SubTitleTextLabel className="cls-subTitle">{`${subTitle}`}</SubTitleTextLabel>
      )}
      {/* {isOptional === true && (
        <OptionalTextLabel className="cls-Optional">{`(Optional)`}</OptionalTextLabel>
      )} */} 
      {infoMessage.length > 0 && (
        <Tooltip title={infoMessage}>
          <IconButton>
            <IconsGallery.InfoMaterial />
          </IconButton>
        </Tooltip>
      )}
    </FormLabelWrapper>
  )
}

export const renderFormList = ({ formUIProps }) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    values,
    classes,
    formList,
    resultFormList,
    setResultFormList,
    localFormList,
    setLocalFormList,
    isDetailView,
    setFieldValue,
  } = formUIProps


  const defaultDynamic = () => "Dynamic"

  const renderList = ({ list = [], prefix = "default" }) =>
    list &&
    list.map((fieldItem, index) => {
      const {
        defaultValue,
        defaultChecked = false,
        identifer,
        inputType,
        label,
        labelPath = "",
        placeholder,
        children = [],
        list = [],
        renderDynamic = defaultDynamic,
        renderTextTrue = "Yes",
        renderTextFalse = "No",
        disabled = false,
        icon = "",
        infoMessage = "",
        textBoxType,
        activeValue = "text",
        isOptional,
        subTitle = "",
        renderModalOpen = () => { },
        isDecimalNotAllowed = false,
        isDynamic = false,
      } = fieldItem

      let IconComponent = () => <React.Fragment />

      if (!Utils.JSUtils.isEmpty(icon)) {
        IconComponent = IconsGallery[icon]
      }

      if (inputType === "text") {
        return (
          <FormList
            key={`base-form-${index}`}
            className={isDetailView ? `cls-flexDirectionRow` : `cls-${prefix}-${identifer} base-form-list-wrapper cls-form-${identifer.toLocaleLowerCase()}`}
          >
            {renderLabel({
              label,
              icon,
              classes,
              infoMessage,
              isOptional,
              subTitle,
              isDetailView
            })}
            {isDetailView ? (
              <Typography>{defaultValue}</Typography>
            ) : (
              <StyledTextField
                type="text"
                name={identifer}
                placeholder={placeholder || `Enter ${label}`}
                defaultValue={defaultValue}
                //value={defaultValue || values[identifer]}
                error={Boolean(touched[identifer] && errors[identifer])}
                onKeyDown={(e) => handleWhiteSpace(e)}
                helperText={touched[identifer] && errors[identifer]}
                onBlur={(props) => {
                  handleBlur(props)
                }}
                onChange={(props) => {                  
                  props.persist()
                  setTimeout(() => {
                    handleChange(props)
                  }, 1000)
                  const obj = {}
                  obj[identifer] = props.target.value
                  setFieldValue(identifer, props.target.value)
                  setResultFormList({ ...resultFormList, ...obj })
                }}
                variant="outlined"
                size="small"
                style={{ width: "300px" }}
                disabled={disabled}
                className={`${disabled ? "disabled" : ""}`}
              />
            )}
          </FormList>
        )
      }
      else if (inputType === "disableText") {
        return (
          <FormList
            key={`base-form-${index}`}
            className={isDetailView ? `cls-flexDirectionRow` : `cls-${prefix}-${identifer} base-form-list-wrapper cls-form-${identifer.toLocaleLowerCase()}`}
          >
            {renderLabel({
              label,
              icon,
              classes,
              infoMessage,
              isOptional,
              subTitle,
              isDetailView
            })}
            {isDetailView ? (
              <Typography>{defaultValue}</Typography>
            ) : (
              <StyledTextField
                type="text"
                name={identifer}
                placeholder={placeholder || `Enter ${label}`}
                value={defaultValue || values[identifer]}
                error={Boolean(touched[identifer] && errors[identifer])}
                onKeyDown={(e) => handleWhiteSpace(e)}
                helperText={touched[identifer] && errors[identifer]}
                onBlur={(props) => {
                  handleBlur(props)
                }}
                onChange={(props) => {
                  props.persist()
                  setTimeout(() => {
                    handleChange(props)
                  }, 1000)
                  const obj = {}
                  obj[identifer] = props.target.value
                  setFieldValue(identifer, props.target.value)
                  setResultFormList({ ...resultFormList, ...obj })
                }}
                variant="outlined"
                size="small"
                style={{ width: "300px" }}
                disabled={disabled}
                className={`${disabled ? "disabled" : ""}`}
              />
            )}
          </FormList>
        )
      }
      else if (inputType === "onlyViewText") {
        return (
          <FormList
            key={`base-form-${index}`}
            style={{ padding: '0px 10px' }}
            className={`cls-${prefix}-${identifer} base-form-fieldset-list-wrapper cls-form-${identifer.toLocaleLowerCase()}`}
          >
            <div className="base-form-fieldset-list-innerwrapper">
              {renderLabel({
                label,
                icon,
                classes,
                infoMessage,
                isOptional,
                subTitle,
                isDetailView
              })}
              <Typography style={{ alignSelf: 'center' }}>{defaultValue !== "" ? defaultValue : '-'}</Typography>
            </div>
          </FormList>
        )
      } else if (inputType === "textFieldSearch") {
        return (
          <FormList
            key={`base-form-${index}`}
            className={`cls-${prefix}-${identifer} base-form-list-wrapper`}
          >
            {renderLabel({
              label,
              icon,
              classes,
              infoMessage,
              isOptional,
              subTitle,
              isDetailView
            })}
            {isDetailView ? (
              <Typography>{defaultValue}</Typography>
            ) : (
              <div>
                <StyledTextField
                  type="text"
                  name={identifer}
                  placeholder={placeholder || `Enter ${label}`}
                  // defaultValue={defaultValue || values[identifer]}
                  value={defaultValue || values[identifer]}
                  error={Boolean(touched[identifer] && errors[identifer])}
                  onKeyDown={(e) => handleWhiteSpace(e)}
                  helperText={touched[identifer] && errors[identifer]}
                  onBlur={(props) => {
                    handleBlur(props)
                  }}
                  onChange={(props) => {
                    props.persist()
                    setTimeout(() => {
                      handleChange(props)
                    }, 1000)
                    const obj = {}
                    obj[identifer] = props.target.value
                    setFieldValue(identifer, props.target.value)
                    setResultFormList({ ...resultFormList, ...obj })

                  }}
                  variant="outlined"
                  size="small"
                  style={{ width: "300px" }}
                  disabled={disabled}
                  className={`${disabled ? "disabled" : ""}`}
                />
                <IconButton style={{ padding: '10px' }} onClick={() => { renderModalOpen() }}>
                  <SearchIcon />
                </IconButton>
              </div>
            )}
          </FormList>
        )
      } else if (inputType === "fieldset") {
        return (
          <fieldset
            key={`base-form-${index}`}
            className={`cls-${prefix}-${identifer} base-form-fieldset-list-wrapper cls-form-${identifer.toLocaleLowerCase()}`}
          >
            <legend>{label}</legend>
            <div className={isDynamic ? "" :"base-form-fieldset-list-innerwrapper"}>
              {children && renderList({ list: children, prefix: "fieldset" })}
            </div>
          </fieldset>
        )
      } else if (inputType === "tabtextfield") {
        const attr = {
          activeMenuIdx: fieldItem.list.indexOf(activeValue),
          defaultValue: defaultValue,
          list,
          internalValidation: false,
          error:
            Boolean(touched[identifer] && errors[identifer]) === true
              ? errors[identifer]
              : "",
          onUpdate: (tabtextfieldProps) => {
            const obj = {}
            obj[identifer] = tabtextfieldProps
            setResultFormList({ ...resultFormList, ...obj })
            setFieldValue(identifer, tabtextfieldProps.value)
          },
        }
        return (
          <FormList
            key={`base-form-${index}`}
            className={`cls-${prefix}-${identifer} base-form-list-wrapper`}
          >
            {renderLabel({
              label,
              icon,
              classes,
              infoMessage,
              isOptional,
              subTitle,
              isDetailView
            })}
            {isDetailView ? (
              <Typography>{`${localFormList[identifer].type} ${localFormList[identifer].value}`}</Typography>
            ) : (
              <Adm.TabTextField {...attr} />
            )}
          </FormList>
        )
      } else if (inputType === "tabDropdown") {
        const { tabName = "" } = defaultValue || {}
        const tabIndex = fieldItem.list.findIndex(
          (item) => item.tabName === tabName
        )

        const attr = {
          activeMenuIdx: tabIndex,
          list,
          onUpdate: (tabDropdownProps) => {
            const obj = {}
            obj[identifer] = tabDropdownProps
            setResultFormList({ ...resultFormList, ...obj })
          },
        }
        return (
          <FormList
            key={`base-form-${index}`}
            className={`cls-${prefix}-${identifer} base-form-list-wrapper`}
          >
            {renderLabel({
              label,
              icon,
              classes,
              infoMessage,
              isOptional,
              subTitle,
              isDetailView
            })}
            {isDetailView ? (
              <Typography>{"todo tabDropdown"}</Typography>
            ) : (
              <Adm.TabDropdown {...attr} />
            )}
          </FormList>
        )
      } else if (inputType === "textFieldDropdown") {
        const attr = {
          textBoxType,
          activeMenuIdx: fieldItem.list.indexOf(activeValue),
          defaultValue: values[identifer],
          list,
          internalValidation: false,
          error:
            Boolean(touched[identifer] && errors[identifer]) === true
              ? errors[identifer]
              : "",
          onUpdate: (tabtextfieldProps) => {
            const obj = {}
            obj[identifer] = tabtextfieldProps
            setResultFormList({ ...resultFormList, ...obj })
            setFieldValue(identifer, tabtextfieldProps)
          },
        }
        return (
          <>
            <FormList
              key={`base-form-${index}`}
              className={`cls-${prefix}-${identifer} base-form-list-wrapper`}
            >
              {renderLabel({
                label,
                icon,
                classes,
                infoMessage,
                isOptional,
                subTitle,
                isDetailView
              })}
              {isDetailView ? (
                <Typography>{`${defaultValue} - ${activeValue} `}</Typography>
              ) : (
                <Adm.TextFieldDropdown {...attr} />
              )}
            </FormList>
          </>
        )
      } else if (inputType === "quantity") {
        return (
          <FormList
            key={`base-form-${index}`}
            className={`cls-${prefix}-${identifer} base-form-list-wrapper`}
          >
            {renderLabel({
              label,
              icon,
              classes,
              infoMessage,
              isOptional,
              subTitle,
              isDetailView
            })}
            {isDetailView ? (
              <Typography>{defaultValue}</Typography>
            ) : (
              <Adm.Quantity
                minLimt={defaultValue}
                onUpdate={({ isQuantity = 1 }) => {
                  const obj = {}
                  obj[identifer] = isQuantity
                  setResultFormList({ ...resultFormList, ...obj })
                }}
              />
            )}
          </FormList>
        )
      } else if (inputType === "calender") {
        return (
          <FormList
            key={`base-form-${index}`}
            className={`cls-${prefix}-${identifer} base-form-list-wrapper`}
          >
            {renderLabel({
              label,
              icon,
              classes,
              infoMessage,
              isOptional,
              subTitle,
              isDetailView
            })}
            {isDetailView ? (
              <Typography>{"todo calender"}</Typography>
            ) : (
              <>
                <Adm.Calender
                  date={localFormList[identifer].date}
                  time={localFormList[identifer].time}
                  error={
                    Boolean(touched[identifer] && errors[identifer]) === true
                      ? errors[identifer]
                      : ""
                  }
                  onUpdateCalender={(calenderProps) => {
                    const obj = {}
                    obj[identifer] = calenderProps
                    setResultFormList({ ...resultFormList, ...obj })
                    setLocalFormList({ ...localFormList, ...obj })
                  }}
                />
              </>
            )}
          </FormList>
        )
      } else if (inputType === "dynamic") {
        return renderDynamic({ fieldItem, formUIProps })
      } else if (inputType === "toggle") {
        return (
          <FormList
            key={`base-form-${index}`}
            className={`cls-${prefix}-${identifer} base-form-list-wrapper`}
          >
            {renderLabel({
              label,
              icon,
              classes,
              infoMessage,
              isOptional,
              subTitle,
              isDetailView
            })}
            {isDetailView ? (
              <Typography>
                {localFormList[identifer] ? renderTextTrue : renderTextFalse}
              </Typography>
            ) : (
              <Switch
                checked={localFormList[identifer]}
                value={localFormList[identifer]}
                onChange={(switchProps) => {
                  const obj = {}
                  obj[identifer] = switchProps.currentTarget.checked
                  setResultFormList({ ...resultFormList, ...obj })
                  setLocalFormList({ ...localFormList, ...obj })
                }}
              />
            )}
          </FormList>
        )
      } else if (inputType === "disableText") {
        return (
          <FormList
            key={`base-form-${index}`}
            className={`cls-${prefix}-${identifer} base-form-list-wrapper`}
          >
            {renderLabel({
              label,
              icon,
              classes,
              infoMessage,
              isOptional,
              subTitle,
              isDetailView
            })}
            {isDetailView ? (
              <Typography>{defaultValue}</Typography>
            ) : (
              <>
                <StyledTextField
                  type="text"
                  name={identifer}
                  placeholder={placeholder || `Enter ${label}`}
                  value={defaultValue}
                  error={Boolean(touched[identifer] && errors[identifer])}
                  helperText={touched[identifer] && errors[identifer]}
                  variant="outlined"
                  size="small"
                  style={{ width: "300px" }}
                  disabled={true}
                  className={`${disabled ? "disabled" : ""}`}
                />
              </>
            )}
          </FormList>
        )
      } else if (inputType === "number") {
        return (
          <FormList
            key={`base-form-${index}`}
            className={isDetailView ? `cls-flexDirectionRow` : `cls-${prefix}-${identifer} base-form-list-wrapper`}
          >
            {renderLabel({
              label,
              icon,
              classes,
              infoMessage,
              isOptional,
              subTitle,
              isDetailView
            })}
            {isDetailView ? (
              <Typography>{defaultValue}</Typography>
            ) : (

              <StyledTextField
                type="number"
                name={identifer}
                placeholder={placeholder || `Enter ${label}`}
                defaultValue={defaultValue}
                //value={defaultValue || values[identifer]}
                onPaste={(evt) => handleEventPaste(evt)}
                error={Boolean(touched[identifer] && errors[identifer])}
                helperText={touched[identifer] && errors[identifer]}
                onBlur={(props) => {
                  handleBlur(props)
                }}
                onChange={(e) => {
                  const re = /^[0-9\b]+$/;
                  e.target.value = Math.max(0, e.target.value).toString().slice(0, 15)
                  e.persist()
                  if ((isDecimalNotAllowed && re.test(e.target.value)) || (!isDecimalNotAllowed) || e.target.value === "") {
                    setTimeout(() => {
                      handleChange(e)
                    }, 1000)
                    const obj = {}
                    obj[identifer] = Number(e.target.value)
                    setFieldValue(identifer, Number(e.target.value))
                    setResultFormList({ ...resultFormList, ...obj })
                  }
                }}
                variant="outlined"
                size="small"
                style={{ width: "300px" }}
                disabled={disabled}
                className={`${disabled ? "disabled" : ""}`}
              />
            )}
          </FormList>
        )
      } else if (inputType === "checkBox") {
        const checkAttr = {
          color: "primary",
          name: identifer,
          defaultChecked,
          inputProps: { "aria-label": "secondary checkbox" },
          onChange: (e) => {
            setFieldValue(identifer, e.target.checked)
            const obj = {}
            obj[identifer] = e.target.checked
            setResultFormList({ ...resultFormList, ...obj })
          },
        }
        return (
          <FormList
            key={`base-form-${index}`}
            className={`cls-${prefix}-${identifer} base-form-list-wrapper base-form-checkbox`}
          >
            <FormLabelWrapper>
              <FormLabel className={classes.formLabelD}>
                <Checkbox {...checkAttr} />
                {label}
              </FormLabel>
            </FormLabelWrapper>
          </FormList>
        )
      } else if (inputType === "dropdown") {
        return (
          <FormList
            key={`base-form-${index}`}
            className={isDetailView ? `cls-flexDirectionRow` : `cls-${prefix}-${identifer} base-form-list-wrapper`}
          >
            {renderLabel({
              label,
              icon,
              classes,
              infoMessage,
              isOptional,
              subTitle,
              isDetailView
            })}
            {isDetailView ? (
              <Typography>{typeof defaultValue === 'object' ? defaultValue.name : defaultValue}</Typography>
            ) : (
              <Adm.DropdownKit
                list={list}
                label={label}
                labelPath={labelPath}
                className={classes.menuItemTxt}
                placeholder={placeholder}
                name={identifer}
                error={
                  Boolean(touched[identifer] && errors[identifer]) === true
                    ? errors[identifer]
                    : ""
                }
                // keyForValueReference=""
                defaultValue={values[identifer]}
                onUpdate={(dropdownKitProps) => {

                  setFieldValue(identifer, dropdownKitProps)
                  const obj = {}
                  obj[identifer] = dropdownKitProps
                  setResultFormList({ ...resultFormList, ...obj })
                  setLocalFormList({ ...localFormList, ...obj })
                }}
                keyForValueReference={_.get(fieldItem, 'keyForValueReference')}
                isPlainObj={_.get(fieldItem, 'isPlainObj', false)}
              // defaultValue={defaultValue}
              // onUpdate={(dropdownKitProps) => {
              //   setFieldValue(identifer, dropdownKitProps)
              //   const obj = {}
              //   obj[identifer] = dropdownKitProps
              //   setResultFormList({ ...resultFormList, ...obj })
              // }}
              />
            )}
          </FormList>
        )
      } else {
        return <React.Fragment key={`base-form-${index}`} />
      }
    })

  return <>{renderList({ list: formList })}</>
}
