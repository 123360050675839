import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';
import {  Grid, Table, TableBody, TableCell, TableContainer,  TableHead,  TableRow, TextField,
} from "@material-ui/core"
import _ from "lodash"
import { makeStyles, useTheme } from "@material-ui/core/styles"

 const useStyles = makeStyles({
    tableCellStyle: {
       borderRight: "1px solid rgba(0, 0, 0, 0.1)", 
    },
    tableRowStyle: {
      backgroundColor: "#fff"

    }
})
const SkeletonTab = (props) => {
   const { height, width, loading,column,row } = props;
    const classes = useStyles()
   return (
      <Grid item lg={12} md={12} sm={12} xs={12} >
         {loading ?
            <TableContainer>
               <Table>
                  <TableHead style={{backgroundColor:"#f4f7fa"}}>
                     <TableRow>
                         {_.times(row, () => (<TableCell><Skeleton width={"100px"} height={"20px"} /></TableCell>))}
                     </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableRowStyle}>
                     {_.times(row, () => (<TableRow >
                         {_.times(row, () => (<TableCell><Skeleton width={"100px"} height={"20px"} /></TableCell>))}
                     </TableRow>))}
                  </TableBody>
               </Table>
            </TableContainer>
            : null}
      </Grid>
   )
    }
export { SkeletonTab }
export default SkeletonTab
// const StyledSkeleton = styled(Skeleton)`
// width:${({ props }) => props?.widths}%;
// height:500px;
// `
    //  <StyledSkeleton variant="wave" widths={props.width} heights={props.height} />
