import React from "react"
import {
  Box,
  Card,
  Grid,
  Divider,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from "@material-ui/core"
import {
  Typography,
  CardContent,
  GreenText,
  RedText,
  useStyles,
  BlackText,
} from "./styles"
import _ from "lodash"
import * as IconsGallery from "@iconsGallery"

const RevenueCard = (props) => {
  const classes = useStyles()

  const {
    isLoading = false,
    isColor = true,
    style = {},
    isIconEnable = false,
    icon: DynamicIcon = () => "",
    custom = false,
    list = [],
    color = ""
  } = props

  const GreenTypo = isColor ? GreenText : BlackText
  const RedTypo = isColor ? RedText : BlackText
  return (
    <Card className={classes.cardStyles} style={style}>
      <Grid container>
        <Grid>
          <Typography
            variant="h4"
            style={{
              paddingLeft: 16,
              paddingTop: 16,
              fontSize: 16,
              fontWeight: "500",
            }}
          >
            {props?.title}
          </Typography>
        </Grid>
      </Grid>
      <CardContent>
        {
          custom ?
            <Grid container>
              {_.map(list, (x, i) => {
                return (
                  <>
                    <Grid item xs key={i}>
                      <div >
                        <Box textAlign="center" justifyContent="space-between" style={{ color: [x.color] }}>
                          <Typography variant="h4" className={classes.values}>
                            {isLoading ? (
                              <IconsGallery.DotLoading />
                            ) : x.value}
                          </Typography>
                          <Typography>
                            {x.label}
                          </Typography>
                        </Box>
                      </div>
                    </Grid>
                    {!(list?.length === i + 1) && <Divider
                      orientation="vertical"
                      flexItem
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    />}
                  </>
                )
              })}
            </Grid>
            :
            <Grid container>
              {isIconEnable && (
                <Grid item xs>
                  <div>
                    <Box textAlign="center" justifyContent="space-between">
                      <DynamicIcon />
                    </Box>
                  </div>
                </Grid>
              )}
              {!_.isEmpty(props?.total) && (
                <Grid item xs>
                  <div>
                    <Box textAlign="center" justifyContent="space-between">
                      <Typography variant="h4" className={classes.values}>
                        {isLoading ? (
                          <IconsGallery.DotLoading />
                        ) : (
                          props?.total.value
                        )}
                      </Typography>
                      <Typography
                        style={{ mt: 1, paddingTop: "1px" }}
                        variant="h6"
                        className={classes.headerValue}
                      >
                        {props?.total.label}
                      </Typography>
                    </Box>
                  </div>
                </Grid>
              )}
              {!_.isEmpty(props?.total) && !_.isEmpty(props?.active) && (
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                />
              )}
              {!_.isEmpty(props?.active) && (
                <Grid item xs>
                  <div>
                    <Box textAlign="center" justifyContent="space-between">
                      <Typography variant="h4" className={classes.values}>
                        {isLoading ? (
                          <IconsGallery.DotLoading />
                        ) : (
                          props?.active.value
                        )}
                      </Typography>
                      <GreenTypo className={classes.textColorstyle}>
                        {props?.active.label}
                      </GreenTypo>
                    </Box>
                  </div>
                </Grid>
              )}
              {!_.isEmpty(props?.inActive) && !_.isEmpty(props?.active) && (
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                  }}
                />
              )}
              {!_.isEmpty(props?.inActive) && (
                <Grid item xs>
                  <div>
                    <Box textAlign="center" justifyContent="space-between">
                      <Typography variant="h4" className={classes.values}>
                        {isLoading ? (
                          <IconsGallery.DotLoading />
                        ) : (
                          props?.inActive.value
                        )}
                      </Typography>
                      <RedTypo style={{ paddingLeft: "10px" }}>
                        {props?.inActive.label}
                      </RedTypo>
                    </Box>
                  </div>
                </Grid>
              )}
            </Grid>}
      </CardContent>
    </Card>
  )
}
export default RevenueCard
export { RevenueCard }
