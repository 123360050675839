export const transformValidationSchema = (list) => {
  let obj = {}
  if (list.length > 0) {
    for (let i = 0; i < list.length; i++) {
      const identifer = list[i].identifer
      const validationSchema = list[i].validationSchema
      obj[identifer] = validationSchema
    }
  }
  return obj
    ? {
        validationSchema: obj,
      }
    : {}
}

export const initialValues = (list) => {
  let obj = {}
  if (list.length > 0) {
    for (let i = 0; i < list.length; i++) {
      const identifer = list[i].identifer
      const value = list[i].value
      obj[identifer] = value
    }
  }
  return obj
    ? {
        value: obj,
      }
    : {}
}
