import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip, Grid, TextField } from "@material-ui/core"
import { columnsTenant, columsSassAdmin, excelExportColumns, columsPopup } from "./fixture"
import { makeStyles } from '@material-ui/core/styles';
import { AddIcon } from "@iconsGallery"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import * as Adm from "@adm"
import { Formik } from "formik"
import _, { values } from "lodash"
import * as commonStylesKit from "@commonStyles"
import SharedTable from "../../masterData/sharedComponents/Table/index"
import { ConfirmationDialog } from "@features"
import { PageHeaderBreadcrumbKit, TemplateExport } from "./styles"
import userApi from "../../../services/user/api"
import * as ServiceAPI from "@services"
import { formatISO, isValid, format } from "date-fns";
import * as constantsAPI from "@constants"
import { ImportButtonUI, ExportButtonUI } from "@features"
import excelSheet from './excelSheet';
import * as XLSX from "xlsx";
import { excelSheetValidation } from "../../../FeaturesKit/PortButton/renderProps"
import ExcelErrorTemplate from "../../masterData/sharedComponents/ExcelErrorTemplate"
import SearchByDate from "../../../pages/transactions/AllTransactions/searchComponents/SearchByDate/index"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { async } from "q";
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}
const { headerAxios, API_BASE_URL, bulkUploadProducts } = ServiceAPI;

const { commonStyles } = commonStylesKit || {}
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        // width: 300,
    },
    productBtn: {
        margin: "0px",
        '@media(min-width:1200px)': {
            '& .MuiGrid-grid-lg-1': {
                maxWidth: '14%',
                flexBasis: '14%',
            }
        }
    },
    autoCompleteHeight: {
        '& .MuiAutocomplete-inputRoot': {
            height: "45px",
            // minWidth: "85%",
            // maxWidth: "85%",
            // width: "85%"
        },
        '& .MuiAutocomplete-input': {
            padding: "0px 4px !important"
        },

        "& .MuiFormControl-fullWidth": {
            width: "10%",
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            "borderColor": "var(--primaryColor)",
          }
        }
    },
}))

function AuditLogPopup(props) {
    console.log("AuditLogPopup", props)
    const { isWriteAllowed } = props
    const commonClasses = commonStyles()
    let history = useHistory()
    const classes = useStyles()
    const { t } = useTranslation()
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
    const [reDirect, setReDirect] = useState(false)
    const [isOpen, setOpen] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [editData, setEditData] = useState({})
    const [reRender, set_reRender] = useState(props?.reRender || false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [params, setParams] = useState({
        page: 1,
        limit: 10,
        searchBy: "",
        searchTerm: "",
        sort: "",
        filter: [
            {
                field: "userType",
                operator: "eq",
            },
        ],
    })
    const [searchBy, setSearchBy] = useState("")
    const [datafilter, setDateFilter] = useState({})
    const [searchObj, setSearchObj] = useState(_.get(props, "params.filter", {}))
    let tenantAdmin = localStorage.getItem("role")
    const [date, setDate] = useState("")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [tenantNameList, setTenantNameList] = useState([])
    const [moduleNameList, setModuleNameList] = useState([])
    const [subModuleNameList, setSubModuleNameList] = useState([])
    const [moduleCompleteList, setModuleCompleteList] = useState([])
    const [tenantOpen, setTenantOpen] = useState(false)
    const [moduleOpen, setModuleOpen] = useState(false)
    const [subModuleOpen, setSubModuleOpen] = useState(false)
    const [selectTenantName, setSelectTenantName] = useState("")
    const [selectModuleName, setSelectModuleName] = useState("")
    const [exportGridData, setExportGridData] = useState()
    const [openPopupDialog, setOpenPopupDialog] = React.useState(false);
    const [fileData, setFileData] = React.useState({
        files: [],
    })
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [isDialogOpen, setDialogOpen] = React.useState(false)
    // const [displayExcelException, setDisplayExcelException] = useState([]);
    // const [excelErrorCountDetails, setExcelErrorCountDetails] = useState({});
    // const [fileSize, setFileSize] = useState('');
    // const [isCustomFilter, setCustomFilter] = useState(false)
    //const [listDetails, setListDetails] = useState();
    // const [workbookDetails, setWorkbookDetails] = useState()
    // let fileTypeList = [
    //     "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //     "application/vnd.ms-excel",
    //     "text/csv"
    // ];
    // let product_List = [];
    // // let location_List = [];
    // // let attribute_List = [];
    // // let specification_List = [];
    // let secret_Key = [];
    // let productSendBackData = [];
    // let formatProductList = '';
    // let excelErrorProduct = '';

    useEffect(() => {
        fetchAuditLog();
    }, [])

    const logMassageStyle = {
        width: "300px",
        overflow: "hidden",
        display: "block",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    }

    const fetchAuditLog = async () => {
        // let dataObj = {}
        // let formatChange =
        await userApi.getPopupAuditLogs(props?.recordId).then(async (resp) => {
            console.log(resp, "RespOnseData")
            await setData(_.get(resp, "data.data", ""))
            // await setLoading(false)
            // await set_reRender(false)
            // dataObj = {
            //     status: resp?.data?.status,
            //     message: resp?.data?.message,
            //     data: {
            //         "data": {
            //             ...resp?.data?.data,
            //             docs: resp?.data?.data?.docs,
            //         }
            //     },
            // }
            // return dataObj
        })
        // console.log("bodyObj", formatChange)
        // return formatChange
    }

    // const handleDialogClose = () => {
    //     set_reRender(true);
    //     setOpenPopupDialog(false);
    // };

    // const downloadExcelError = () => {
    //     //let formattxtfile = displayExcelException.join('\r\n');
    //     const element = document.createElement("a");
    //     const file = new Blob([displayExcelException.join('\n')], { type: 'text/plain' });
    //     //displayExcelException
    //     element.href = URL.createObjectURL(file);
    //     element.download = "ProductException.txt";
    //     document.body.appendChild(element); // Required for this to work in FireFox
    //     element.click();
    //     return false;
    // }
    // const importButtonOnSubmit = async () => {
    //     setAlert(false);
    //     set_reRender(false);
    //     formatProductList = '';
    //     excelErrorProduct = '';
    //     setExcelErrorCountDetails({});
    //     workbookDetails.SheetNames.forEach(function (sheetName) {
    //         // Here is your object
    //         var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbookDetails.Sheets[sheetName]);
    //         var json_object = JSON.stringify(XL_row_object);
    //         setLoading(true);
    //         if (sheetName === 'Products') {
    //             product_List = JSON.parse(json_object);
    //         }
    //         else if (sheetName === 'Secret_Key') {
    //             secret_Key = JSON.parse(json_object);
    //         }
    //     })
    //     let findKey = _.isArray(secret_Key) && _.toString(_.values(secret_Key[0])) || '';
    //     let excelPassword = process.env.REACT_APP_LOCATION_PRODUCT_MAPPING_EXCEL_PASSWORD;
    //     if (excelPassword !== findKey) {
    //         await setAlertType("error");
    //         await setAlertMessage("Invalid Excel Template. Please download from Application");
    //         await setAlert(true);
    //         setLoading(false);
    //         return false;
    //     }
    //     else if (product_List.length === 0) {
    //         await setAlertType("error");
    //         await setAlertMessage("Excel on Empty File");
    //         await setAlert(true);
    //         setLoading(false);
    //         return false;
    //     }
    //     //To call common method to format
    //     var excelValResult = excelSheetValidation(_.cloneDeep(excelSheet), product_List, "Products Sheet - Row No - ");

    //     if (_.isObject(excelValResult)) {
    //         formatProductList = excelValResult?.formatList;
    //         excelErrorProduct = excelValResult?.excelErrorProduct;
    //     }
    //     if (excelErrorProduct.length > 0) {
    //         setDisplayExcelException(excelErrorProduct)
    //         setOpenPopupDialog(false);
    //         setOpenPopupDialog(true);
    //         setLoading(false);
    //     }
    //     else {
    //         //To format the Exception Details End
    //         _.map(formatProductList, (o, idx) => {
    //             let productObj = {
    //                 "rowId": idx + 1,
    //                 "item_id": _.toString(_.trim(o["item_id"])) || '',
    //                 "inventory_on_hand": _.toString(_.trim(o["inventory_on_hand"])) || '',
    //                 "msrp": _.toString(_.trim(o["msrp"])) || '',
    //                 "offerPrice": _.toString(_.trim(o["offerPrice"])) || 0,
    //             };
    //             productSendBackData.push(productObj)
    //             return o
    //         })
    //         //Waiting for Back end API
    //         try {
    //             let response = await bulkUploadProducts(
    //                 // API_END_POINTS.bulkUploadProductMappings + "/" + "",
    //                 productSendBackData
    //             )
    //             if (response?.data?.status) {
    //                 if (response?.data?.data?.isSuccess) {
    //                     setOpenPopupDialog(false);
    //                     await setAlertType("success")
    //                     await setAlertMessage(_.get(response, "data.message", "successfully uploaded"))
    //                     await setAlert(true);
    //                     set_reRender(true);
    //                     // await fetchData()
    //                 }
    //                 else {
    //                     let error_List = _.get(response, "data.data.errorList", []);
    //                     let error_Product_arr = [];
    //                     _.map(error_List, (prod, index) => {
    //                         error_Product_arr.push("Products Sheet - Row No - " + _.get(prod, "rowId", []) + " - " + _.get(prod, "errors", []));
    //                     })
    //                     // _.map(error_List, (lst, idx) => {
    //                     //     let error_Product_List = _.get(lst, "productErrors", []);
    //                     //     _.map(error_Product_List, (prod, index) => {
    //                     //         error_Product_arr.push("Products Sheet - Row No - " + _.get(prod, "rowId", []) + " - " + _.join(_.get(prod, "errors", []), ", "));
    //                     //     })
    //                     // })
    //                     excelErrorProduct.push(...error_Product_arr);
    //                     if (excelErrorProduct.length > 0) {
    //                         setDisplayExcelException(excelErrorProduct)
    //                         setExcelErrorCountDetails(_.get(response, "data.data.count", {}));
    //                         setOpenPopupDialog(false);
    //                         setOpenPopupDialog(true);
    //                         setLoading(false);
    //                         set_reRender(true);
    //                         // await fetchData()
    //                     }
    //                 }
    //             }
    //             else {
    //                 await setAlertType("error")
    //                 await setAlertMessage(_.get(response, "response.data.message", "Try again Later"))
    //                 await setAlert(true)
    //             }

    //         }
    //         catch (error) {
    //             await setAlertType("error")
    //             await setAlertMessage(error)
    //             await setAlert(true)
    //         }
    //         setFileData({
    //             files: [],
    //         });
    //         set_reRender(false);
    //         setLoading(false);
    //     }
    // }
    // const handleExportFormat = async (body, type) => {
    //     let dataFormat = []
    //     let apiMethod = type === 'currentView' ? ServiceAPI.fetchCommonData(
    //         API_END_POINTS.getProductMappingDetails,
    //         // bodyData,
    //     ) : ServiceAPI.getProductCommonData(
    //         // API_END_POINTS.exportAllProductMappings + "/" + locationId
    //     );
    //     setLoading(true)
    //     setAlert(false)
    //     await apiMethod.then((resp) => {
    //         let responsedata = type === 'currentView' ? resp?.data?.data?.docs : resp?.data?.data;
    //         dataFormat = responsedata.map((x) => {
    //             return ({
    //                 ...x,
    //                 // item_dimensions_size: `${x?.item_dimensions_size?.heightValue}x${x?.item_dimensions_size?.widthValue}x${x?.item_dimensions_size?.lengthValue}`,
    //                 // item_package_dimensions_size: `${x?.item_package_dimensions_size?.heightValue}x${x?.item_package_dimensions_size?.widthValue}x${x?.item_package_dimensions_size?.lengthValue}`,
    //                 // status: x.isActive === true ? "Enabled" : "Disabled",
    //             })
    //         })
    //         setAlertType("success")
    //         setAlertMessage("Successfully downloaded")
    //         setAlert(true)
    //         setLoading(false)
    //     })
    //     return dataFormat
    // }

    // let getTimeSuffixed = (d, noSuffix = false, suffix = 'T00:00:00.000Z') => {
    //     if (isValid(new Date(d))) {
    //         if (noSuffix) {
    //             return `${formatISO(new Date(d), { representation: 'complete' })}`
    //         }
    //         return `${formatISO(new Date(d), { representation: 'date' })}${suffix}`
    //     }
    //     return ''
    // }


    // const handleCustomCloseRedirect = () => {
    //     setOpen(false)
    //     setEditData({})
    //     setIsEdit(false)
    //     setOpenConfirmationDialog(false)
    // }

    // const searchByOptions = [
    //     {
    //         id: "txn_date_time",
    //         label: "Transaction Date-Time(UTC)",
    //         component: "datetime",
    //     },
    // ]

    // const onClickClose = () => {
    //     setOpen(false)
    //     setEditData({})
    //     setIsEdit(false)
    //     setOpenConfirmationDialog(false)
    // }

    // const downloadTemplateClick = () => {
    //     window.location.href = process.env.REACT_APP_PRODUCT_LIST_EXCEL_TEMPLATE;
    // }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow style={{ backgroundColor: "var(--gray)" }}>
                                    <TableCell align="left">User Email</TableCell>
                                    <TableCell align="left">Activity</TableCell>
                                    <TableCell align="left">Log Message</TableCell>
                                    <TableCell align="left">Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((row) => (
                                    <TableRow key={row._id}>
                                        <TableCell align="left">{row.userEmail}</TableCell>
                                        <TableCell align="left">{row.activity}</TableCell>
                                        <TableCell align="left">
                                            <Tooltip title={row.logMessage}>
                                                <span style={logMassageStyle}>{row.logMessage}</span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="left">{row.changedAt}</TableCell>
                                    </TableRow>
                                ))}
                                <>
                                    {data?.length === 0 ?
                                        <TableRow>
                                            <TableCell colspan={4} align="center" >
                                                {data?.length === 0 ? "No Record(s) Found" : ""}
                                            </TableCell>
                                        </TableRow>
                                        : ""}
                                </>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    )

    // return (
    //     <Grid>
    //         <Helmet title="Audit Log " />
    //         <Adm.BackdropOverlay open={loading} />
    //         <ConfirmationDialog
    //             openConfirmationDialog={openConfirmationDialog}
    //             setOpenConfirmationDialog={setOpenConfirmationDialog}
    //             // isdelete={isdelete}
    //             setReDirect={setReDirect}
    //             handleCustomCloseRedirect={handleCustomCloseRedirect}
    //             deleteContent="Are you sure you want to close?"
    //         />

    //         <Formik
    //             // initialValues={{
    //             //     moduleName: "",
    //             //     subModuleName: "",
    //             //     startDate: "",
    //             //     endDate: "",
    //             // }}
    //             // validationSchema={Yup.object().shape({
    //             //     moduleName: Yup.string().required("Enter module Name"),
    //             //     subModuleName: Yup.string().required("Enter Sub Module Name"),
    //             //     startDate: Yup.date().required("Enter start date"),
    //             //     endDate: Yup.date().required("Enter end date")
    //             //         .min(Yup.ref('startDate'), "End date can't be before Start date")
    //             // })}
    //             onSubmit={async (values, { resetForm }) => {
    //                 console.log(values, "onSubmit")
    //                 setCustomFilter(true)
    //                 set_reRender(true);
    //             }}
    //         >
    //             {({
    //                 errors,
    //                 handleBlur,
    //                 handleChange,
    //                 handleSubmit,
    //                 isSubmitting,
    //                 touched,
    //                 values,
    //                 setFieldValue,
    //                 resetForm,
    //                 handleReset,
    //             }) => {
    //                 // const dataFetchMethod = async (body = {}, type = "") => {
    //                 //     setLoading(true)
    //                 //     let bodyObj = {}
    //                 //     if (isCustomFilter) {
    //                 //         bodyObj = {
    //                 //             "page": 1,
    //                 //             "limit": 10,
    //                 //             "filter": [
    //                 //                 {
    //                 //                     "field": "module",
    //                 //                     "operator": "eq",
    //                 //                     "value": values?.moduleName
    //                 //                 },
    //                 //                 {
    //                 //                     "field": "subModule",
    //                 //                     "operator": "eq",
    //                 //                     "value": values?.subModuleName
    //                 //                 },
    //                 //                 {
    //                 //                     "field": "changedAt",
    //                 //                     "value": [
    //                 //                         {
    //                 //                             "type": "gte",
    //                 //                             "date": _.replace(values.startDate + "T00:00:00.000Z")
    //                 //                         },
    //                 //                         {
    //                 //                             "type": "lte",
    //                 //                             "date": _.replace(values.endDate + 'T23:59:59.999Z')
    //                 //                         }
    //                 //                     ],
    //                 //                     "operator": "date"
    //                 //                 }
    //                 //             ],
    //                 //             "sort": {}
    //                 //         }
    //                 //     } else {
    //                 //         bodyObj = {
    //                 //             ...body
    //                 //         }
    //                 //     }

    //                 //     let dataObj = {}
    //                 //     let formatChange = await userApi.getPopupAuditLogs("a8c649c8-5747-4d60-af0c-75711b83c607", "644a73efaf9c5c00110c8a84").then(async (resp) => {
    //                 //         console.log(resp, "RespOnseData")
    //                 //         await setData(_.get(resp, "data.data", ""))
    //                 //         await setLoading(false)
    //                 //         await set_reRender(false)
    //                 //         dataObj = {
    //                 //             status: resp?.data?.status,
    //                 //             message: resp?.data?.message,
    //                 //             data: {
    //                 //                 "data": {
    //                 //                     ...resp?.data?.data,
    //                 //                     docs: resp?.data?.data?.docs,
    //                 //                 }
    //                 //             },
    //                 //         }
    //                 //         return dataObj
    //                 //     })
    //                 //     console.log("bodyObj", formatChange)
    //                 //     return formatChange
    //                 // }
    //                 return (
    //                     <>
    //                         <form noValidate onSubmit={handleSubmit} className={classes.cardContent}>
    //                             <Grid container>
    //                                 <Grid item xs={12}>
    //                                     <TableContainer component={Paper}>
    //                                         <Table className={classes.table} aria-label="simple table">
    //                                             <TableHead>
    //                                                 <TableRow>
    //                                                     <TableCell align="right">User Email</TableCell>
    //                                                     <TableCell align="right">Activity</TableCell>
    //                                                     <TableCell align="right">Log Message</TableCell>
    //                                                     <TableCell align="right">Date</TableCell>
    //                                                 </TableRow>
    //                                             </TableHead>
    //                                             <TableBody>
    //                                                 {data.map((row) => (
    //                                                     <TableRow key={row._id}>
    //                                                         <TableCell align="right">{row.userEmail}</TableCell>
    //                                                         <TableCell align="right">{row.activity}</TableCell>
    //                                                         <TableCell align="right">{row.logMessage}</TableCell>
    //                                                         <TableCell align="right">{row.changedAt}</TableCell>
    //                                                     </TableRow>
    //                                                 ))}
    //                                             </TableBody>
    //                                         </Table>
    //                                     </TableContainer>
    //                                     {/* <SharedTable
    //                                         title='Audit Log'
    //                                         set_reRender={set_reRender}
    //                                         noShowPrimaryWithoutSelect
    //                                         additionalButtons={[]}
    //                                         dataFetchMethod={dataFetchMethod}
    //                                         responseDataPath='data.data'
    //                                         additionalFilters={[]}
    //                                         columns={columsPopup}
    //                                         methodType="get"
    //                                         isStatic
    //                                         isLookUp
    //                                         displayAssociate={false} // not display the associate and clear button
    //                                         tableFilter={false}
    //                                         isWriteAllowed={true}
    //                                         deleteMultiple={true}
    //                                         setOpenConfirmationDialog={setOpen}
    //                                         customEditFunc={true}
    //                                         datafilter={datafilter}
    //                                         menutype="master"
    //                                         reRender={reRender}
    //                                         ShowCheckBox={false}
    //                                     /> */}
    //                                 </Grid>
    //                             </Grid>
    //                         </form>

    //                     </>
    //                 )
    //             }}

    //         </Formik>
    //     </Grid>


    // )
}

export { AuditLogPopup }
export default AuditLogPopup
