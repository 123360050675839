import React, { useEffect } from "react"
import * as render from "./renderProps"
import * as StyledDOM from "./styles"
import userApi from "../../../src/services/user/api"
import _ from "lodash"
import * as Adm from "@adm"
import { useState } from "react"
import { ConfirmationDialog } from "@features"

function SwappingKit(props) {
  console.log("SwappingKit", props)
  const classes = StyledDOM?.useStyles()
  const [getTriggerSearchImag, setTriggerSearchImag] = React.useState({
    flag: false,
    search: "",
  })
  const [getVendorId, setVendorId] = React.useState(props?.match?.params?.vendorId || '')
  const [getSearchImage, setSearchImage] = React.useState("")
  const [getVendorOpen, setVendorOpen] = React.useState(false)
  const [getInitLoad, setInitLoad] = React.useState(true)
  const [getCurrentVendorItem, setCurrentVendorItem] = React.useState([])
  const [getCurrentImageList, setCurrentImageList] = React.useState([])
  const [getCurrentStorageItem, setCurrentStorageItem] = React.useState([])
  const [getOverAllImageList, setOverAllImageList] = React.useState([])
  const [getStorageUnitId, setStorageUnitId] = React.useState('') // props?.match?.params?.vendorId || ''
  const [getToVendorList, setToVendorList] = useState([])
  const [getToVendorIdList, setToVendorIdList] = useState([])
  const [getSelectedItem, setSelectedItem] = useState([])
  const [isRerender, setRerender] = useState(false)
  const [storageGridTableData, setStorageGridTableData] = useState({ data: [] })
  const [productGridTableData, setProductGridTableData] = useState({ data: [] })

  const [alert, setAlert] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [reDirect, setReDirect] = useState(false)
  const handleCloseButtonTrigger = () => {
    setOpenConfirmationDialog(false)
  }
  const handleCustomCloseRedirect = () => {
    setLoading(true)
    updateCompanyonStorage(getSelectedItem)
  }

  useEffect(() => {
    fetchVendorList()
  }, [getVendorId])

  const fetchVendorList = () => {
    let body = {
      "company_ids": [getVendorId]
    }
    let headers = {
      "realm-id": localStorage.getItem("X-KC-Realm-ID"),
      "tenant-id": localStorage.getItem("tenantId"),
    }

    userApi.getVendorList(body, headers, true)
      .then((resp) => {
        setToVendorList(resp?.data)
        let tempVendorList = [];
        resp?.data?.map((o) => {
          tempVendorList.push(o?.company_id)
        })
        setToVendorIdList(tempVendorList)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSwapVendor = () => {
    // let selectedItems = []
    // _.map(getSelectedItem, (x, i) => {
    //   if (x?.isConnected) selectedItems.push(x?.product_item_id)
    // })
    console.log("clicked!!!", getCurrentVendorItem, getStorageUnitId)
    setOpenConfirmationDialog(true)
    // updateCompanyonStorage(getSelectedItem)
  }

  const updateCompanyonStorage = (selectedItems) => {
    let body = {
      "company_id": getCurrentVendorItem && getCurrentVendorItem[0],
      "item_ids": selectedItems
    }
    let headers = {
      "realm-id": localStorage.getItem("X-KC-Realm-ID"),
      "tenant-id": localStorage.getItem("tenantId"),
    }
    setAlert(false)
    setLoading(true)
    userApi.updateCompanyonStorageUnitMapping(body, headers, getStorageUnitId)
      .then((resp) => {
        if (resp?.status === 200) {
          setAlert(true)
          setAlertType("success")
          setLoading(false)
          setAlertMessage("updated successfully")
          setRerender(true)
        }
        else {

        }

      })
      .catch((err) => {
        setAlert(true)
        setAlertType("error")
        setAlertMessage(err?.response?.data?.message)
        console.log(err)
      })
  }

  const clickedSelectAll = (checked = true) => {
    let selectedData = productGridTableData?.data?.map(obj => ({ ...obj, isConnected: checked }))
    let obj = {
      ...productGridTableData,
      data: selectedData,
    }
    let selectedItems = []
    _.map(selectedData, (x, i) => {
      if (x?.isConnected) selectedItems.push(x?.product_item_id)
    })
    setSelectedItem(selectedItems)
    setProductGridTableData(obj)
  }

  const stateProps = {
    getCurrentImageList,
    setCurrentImageList,
    getOverAllImageList,
    setOverAllImageList,
    getCurrentStorageItem,
    setCurrentStorageItem,
    getInitLoad,
    setInitLoad,
    getVendorOpen,
    setVendorOpen,
    classes,
    getCurrentVendorItem,
    setCurrentVendorItem,
    handleSwapVendor,
    getSearchImage,
    setSearchImage,
    getTriggerSearchImag,
    setTriggerSearchImag,
    getVendorId,
    setVendorId,
    getStorageUnitId,
    setStorageUnitId,
    getToVendorList,
    setToVendorList,
    getToVendorIdList,
    setToVendorIdList,
    getSelectedItem,
    setSelectedItem,
    isRerender,
    setRerender,
    storageGridTableData,
    setStorageGridTableData,
    productGridTableData,
    setProductGridTableData,
    clickedSelectAll
  }
  console.log("productGridTableData--", (getCurrentVendorItem?.length === 0 || getSelectedItem?.length))
  return (
    <>
      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        setReDirect={setReDirect}
        handleCloseButtonTrigger={handleCloseButtonTrigger}
        handleCustomCloseRedirect={handleCustomCloseRedirect}
        popupTitle={`You are about to Swap Vendor for the Selected Storage or Products`}
        deleteContent={`Continuing will remap the Selected Storage or Products under the Chosen Vendor`}
      />
      <StyledDOM.StyledSwappingKitMainWrapper>
        {render.renderHeader(stateProps)}
        <div className="cls-swap-kit-sub-wrapper">
          {render.renderLeftSide(stateProps)}
          {render.renderRightSide(stateProps)}
        </div>
      </StyledDOM.StyledSwappingKitMainWrapper>
      {alert ? (
        <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
      ) : null}
    </>

  )
}

export { SwappingKit }

export default SwappingKit
