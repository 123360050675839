import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"
import { Grid } from "@material-ui/core";
import { getProductList } from "../../../../reduxSagas/admin/actions";
import update from 'immutability-helper';
import _ from 'lodash';
import productsAPI, { productDataApiMethods } from '../../../../services/masterData/products';
import DraggableTreeView from "../../../../components/DraggableTreeView";
import { getProcessedFlatData, getNewFlatDataWithOldExpandedStatus, getNodeId, getNodeKey, getParentNodeId, getTreeData, getFlatData, getAllChildrenIds } from '../../../../components/DraggableTreeView/treeDataUtils'
import CustomTable from "./CustomTable"

const initialProductsData = { product_groups: [], totalPages: 0, totalDocs: 0, page: 1, limit: 10, hasNextPage: false, hasPrevPage: false, isLoading: false };

function ProductGroupLookup(props) {

  const [productsData, setProductsData] = useState(initialProductsData);
  const { list = [], apiName = 'buy_product', onUpdateList = () => null } = props;

  const fetchProductsList = (params) => {
    const { page = 1, limit = 10 } = params;
    setProductsData({ ...productsData, isLoading: true })
    productDataApiMethods.getAllProductGroupsWithPagination(page, limit).then(resp => {
      setProductsData({ ...(resp?.data || initialProductsData), isLoading: false });
    }).catch(err => {
      setProductsData(c => ({ ...c, isError: true, isLoading: false }))
    })
  }

  useEffect(() => {
    fetchProductsList({})
  }, []);

  return (
    <Grid container item xs={12} style={{ border: `1px solid #e0e0e0`, height: `100%` }}>
      <CustomTable list={list} apiName={apiName} onUpdateList={onUpdateList} data={productsData} fetchDataMethod={d => fetchProductsList(d)} />
    </Grid>
  )
}
export default ProductGroupLookup;