export const columnsTenant = [
  {
    field: "module",
    title: "Module",
    show: true,
    min_width: 15,
    filter: false,
  },
  {
    field: "subModule",
    title: "Sub Module",
    show: true,
    min_width: 25,
    filter: false,
  },
  {
    field: "userEmail",
    title: "User Email",
    editable: false,
    editor: "text",
    border: true,
    filter: false,
    show: true,
    min_width: 30,
  },
  {
    field: "activity",
    title: "Activity",
    editable: false,
    editor: "text",
    border: true,
    filter: false,
    show: true,
    min_width: 10,
  },
  {
    field: "changedAt",
    title: "Date & Time Stamp (UTC)",
    editable: false,
    editor: "text",
    border: true,
    filter: false,
    show: true,
    min_width: 25,
  },
  {
    field: "logMessage",
    title: "Log Message",
    editable: false,
    toolTip: true,
    editor: "text",
    border: true,
    filter: false,
    show: true,
    min_width: 50,
    logType: true,
  },
]
export const columsSassAdmin = [
  {
    field: "module",
    title: "Module",
    show: true,
    min_width: 15,
    filter: false,
  },
  {
    field: "subModule",
    title: "Sub Module",
    show: true,
    min_width: 25,
    filter: false,
  },
  {
    field: "userEmail",
    title: "User Email",
    editable: false,
    editor: "text",
    border: true,
    filter: false,
    show: true,
    min_width: 30,
  },
  {
    field: "activity",
    title: "Activity",
    editable: false,
    editor: "text",
    border: true,
    filter: false,
    show: true,
    min_width: 15,
  },
  {
    field: "changedAt",
    title: "Date & Time Stamp (UTC)",
    editable: false,
    editor: "text",
    border: true,
    filter: false,
    show: true,
    min_width: 25,
  },
  {
    field: "logMessage",
    title: "Log Message",
    editable: false,
    toolTip: true,
    editor: "text",
    border: true,
    filter: false,
    show: true,
    min_width: 50,
    logType: true,
  },
]

export const columsPopup = [
  {
    field: "activity",
    title: "Activity",
    editable: false,
    editor: "text",
    border: true,
    filter: false,
    show: true,
    min_width: 15,
  },
  {
    field: "changedAt",
    title: "Date & Time Stamp (UTC)",
    editable: false,
    editor: "text",
    border: true,
    filter: false,
    show: true,
    min_width: 25,
  },
  {
    field: "logMessage",
    title: "Log Message",
    editable: false,
    toolTip: true,
    editor: "text",
    border: true,
    filter: false,
    show: true,
    min_width: 50,
    logType: true,
  },
]

export const excelExportColumns = [
  {
    field: "item_id",
    title: "Item ID",
    show: true,
  },
  {
    field: "stockCode",
    title: "Stockcode [SKU]",
    show: true,
  },
  {
    field: "barcode.name",
    title: "Barcode Type",
    show: true,
  },
  {
    field: "title",
    title: "Product Name",
    show: true,
  },
  {
    field: "taxCode",
    title: "Tax code",
    show: true,
  },
  {
    field: "brand",
    title: "Brand",
    show: true,
  },
  {
    field: "unit_of_measure.name",
    title: "Unit of measure[UOM]",
    show: true,
  },
  {
    field: "item_storage",
    title: "Item storage",
    show: true,
  },
  {
    field: "item_weight",
    title: "Item weight",
    show: true,
  },
  {
    field: "item_weight_measure",
    title: "Item weight - Metric",
    show: true,
  },
  {
    field: "item_dimensions_size",
    title: "Item dimension[Height x width x Depth]",
    show: true,
  },
  {
    field: "item_package_dimensions_size",
    title: "Item package dimension[Height x width x Depth]",
    show: true,
  },
  {
    field: "manufacturer",
    title: "Manufacturer",
    show: true,
  },
  {
    field: "status",
    title: "Status",
    show: true,
  },
]
