import * as React from "react"

function useCommonFormUIState(props) {
  const [edit, setEdit] = React.useState(true)
  const [attribute, setAttribute] = React.useState([])
  const [alert, setAlert] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState("")
  const [alertType, setAlertType] = React.useState("")
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [collapseOpen, setCollapseOpen] = React.useState(false)
  const [expanded, setExpanded] = React.useState(false)
  return {
    edit,
    setEdit,
    attribute,
    setAttribute,
    alert,
    setAlert,
    alertMessage,
    setAlertMessage,
    alertType,
    setAlertType,
    data,
    setData,
    loading,
    setLoading,
    collapseOpen,
    setCollapseOpen,
    expanded,
    setExpanded,
  }
}
function useFacetUIViewState(props) {
  const { defaultData } = props || {}
  const [edit, setEdit] = React.useState(true)
  const [collapseOpen, setCollapseOpen] = React.useState(false)
  const [data, setData] = React.useState(defaultData)
  const [expanded, setExpanded] = React.useState(false)
  return {
    edit,
    setEdit,
    collapseOpen,
    setCollapseOpen,
    data,
    setData,
    expanded,
    setExpanded,
  }
}

export { useCommonFormUIState, useFacetUIViewState }
