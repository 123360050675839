import React from "react"
import { Grid } from "@material-ui/core"
import { AddCircleOutlineIcon } from "@iconsGallery"
import MultiSearchSearchBox from "./MultiSearchSearchBox"
import MultiSearchTable from "./MultiSearchTable"

import {
  StyledButton,
  StyledDivider,
  buttonWrapper,
  PopWrapperGrid,
} from "./styles"

function MultiSearchUI(props) {
  const defaultTabs = [
    { name: "Fruit", attr: "fruit" },
    { name: "Family", attr: "family" },
  ]
  const contentData = [
    { id: "1", fruit: "Apple", family: "Rosaceae" },
    { id: "2", fruit: "Banana", family: "Musaceae" },
    { id: "3", fruit: "Cherry", family: "Rosaceae" },
  ]

  const defaultFetchSearchData = () => {}

  const {
    tabs = defaultTabs,
    tableData = contentData,
    onUpdate = () => "",
    idName = "id",
    selectedItems = [],
    fetchSearchData = defaultFetchSearchData,
    elasticSearchEndpoint = "",
    onClose = () => "",
  } = props || {}

  const [dropdownValue, setDropdownValue] = React.useState(0)
  const [tableDataState, setTableDataState] = React.useState({
    data: tableData,
  })
  const [selected, setSelected] = React.useState({
    selectedItems: selectedItems,
  })
  const [textboxValue, setTextboxValue] = React.useState("")
  const [defaultTableData, setDefaultTableData] = React.useState({ data: [] })
  const customOnUpdate = (props) => {
    const idx = tabs.findIndex((item) => item.name === props.value)
    setDropdownValue(idx)
  }

  let tabNames = []
  tabs.forEach((tab) => {
    tabNames.push(tab.name)
  })
  React.useEffect(() => {
    setTableDataState({ data: tableData })
    let tmp = []
    tableData.map((row) => {
      tmp.push(row[idName])
      return row
    })
    setSelected({ selectedItems: tmp })
    fetchSearchData("", setDefaultTableData, elasticSearchEndpoint)
  }, [tableData])

  const dropDownAttr = {
    defaultValue: tabs[dropdownValue].name,
    list: tabNames,
    onUpdate: customOnUpdate,
  }

  const onSearchBoxChange = (event) => {
    setTextboxValue(event.target.value)
  }

  const isSelected = (id) => {
    if (selected.selectedItems.length > 0) {
      return selected.selectedItems.indexOf(id) !== -1
    }
    return false
  }

  const onSelect = (event, id) => {
    if (event.target.checked === true) {
      const newSelected = { ...selected }
      newSelected.selectedItems.push(id)
      setSelected(newSelected)
    } else {
      if (selected.selectedItems.length > 0) {
        const newSelected = selected.selectedItems.filter((item) => item !== id)
        const newSelectedState = { ...selected }
        newSelectedState.selectedItems = newSelected
        setSelected(newSelectedState)
      }
    }
  }

  const onClickSubmit = () => {
    const updatedData = { data: [] }
    selected.selectedItems.forEach((res) => {
      const idx = defaultTableData.data.findIndex((item) => item[idName] === res)
      updatedData.data.push(defaultTableData.data[idx])
    })
    onUpdate(updatedData)
  }

  React.useEffect(() => {
    fetchSearchData(textboxValue, setTableDataState, elasticSearchEndpoint)
  }, [textboxValue, dropdownValue])

  const searchBoxAttr = {
    textboxValue: textboxValue,
    onSearchBoxChange: onSearchBoxChange,
    dropDownAttr: dropDownAttr,
    tabs: tabs,
    tableDataState: tableDataState,
    onClose: onClose,
    dropdownValue: dropdownValue,
  }

  const tableAttr = {
    tableDataState: tableDataState,
    isSelected: isSelected,
    idName: idName,
    tabs: tabs,
    dropdownValue: dropdownValue,
    onSelect: onSelect,
  }

  return (
    <PopWrapperGrid container direction="column" justify="space-between">
      <MultiSearchSearchBox {...searchBoxAttr} />
      <Grid container direction="column">
        <StyledDivider />
      </Grid>
      <MultiSearchTable {...tableAttr} />
      <div style={buttonWrapper}>
        <StyledButton
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => {
            onClickSubmit()
          }}
          style={{}}
          color="primary"
        >
          SUBMIT
        </StyledButton>
      </div>
    </PopWrapperGrid>
  )
}

export { MultiSearchUI }
export default MultiSearchUI
