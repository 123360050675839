import styled from "styled-components"
import { Grid } from "@material-ui/core"
import left_pano from "./images/left_pano.png"
import middle_pano from "./images/middle_pano.png"
import right_pano from "./images/right_pano.png"

/* svg {
    color: #0000007a !important;
    fill: #0000007a !important;
  } */
export const StyledShekelMainWrapper = styled(Grid)`
  font-family: "arial";
  position: relative;
  &.cls-theme-1 {
    max-width: 1194px;
  }
  &.cls-theme-2 {
    max-width: 2350px;
    width: 100%;
  }

  &.editmode {
  }
  &.viewmode {
    .cls-action-item {
      display: none !important;
      cursor: none !important;
    }

    .cls-qty-dropdown-btn-kit {
      pointer-events: none !important;
    }
    .cls-input-count {
      pointer-events: none !important;
      background: #d8d8d8 !important;

      input {
        font-weight: bold !important;
      }
    }
    .cls-minus-count,
    .cls-plus-count {
      opacity: 0.5 !important;
      pointer-events: none !important;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`
export const StyledShekelRowMainWrapper = styled(Grid)`
  width: 100%;
  border-radius: 6px;
  min-height: 300px;
  padding-top: 5px;
  margin-bottom: 15px;
  box-sizing: border-box;
  &.cls-theme-1 {
    background-image: url(${right_pano}), url(${left_pano}), url(${middle_pano});
    background-repeat: no-repeat, no-repeat, repeat;
    background-position: left, right, center;
    height: 360px;
    .cls-StyledShekelRowWrapper {
      max-width: 1000px !important;
      margin: auto;
    }
  }
  &.cls-theme-2 {
    height: 300px;
    &.cls-theme-2-multiproduct {
      height: 480px;
      min-height: 480px;
    }
    .cls-StyledShekelRowWrapper {
      max-width: 2350px !important;
      width: 100% !important;
      border: 1px solid #e8e8e8;
      border: 2px solid #e5d8e6;
    }
  }
`
export const StyledShekelRowWrapper = styled(Grid)`
  width: 100%;
  max-width: 1100px;
  border-radius: 6px;
  min-height: 280px;
  border: 0px solid #c1c1c1;
  margin-top: 6px;
  background: #fff;
`
export const StyledShekelRowHeaderWrapper = styled(Grid)`
  width: 100%;
  border-radius: 4px;
  min-height: 45px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  background: #e8e8e8;
  background: #e5d8e6;

  .cls-skl-left-panl {
    font-weight: bold;
    font-size: 14px;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
  }
`
export const StyledShekelRowEmptyWrapper = styled("h2")`
  font-weight: normal !important;
  font-size: 17px !important;
  padding: 24px;
  color: #818181;
  text-align: center;
`
export const StyledShekelRowInnerCellWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  width: fit-content;
`

export const StyledShekelRowContentWrapper = styled(Grid)`
  width: auto;
  height: 100%;
  min-height: 235px;
  padding: 10px;
  overflow: auto;
  padding-bottom: 0px;
  &.cls-wrapper-multiproduct {
    min-height: 390px;
  }
`

export const StyledShekelCellCardWrapper = styled(Grid)`
  padding: 10px;
  background: #fff;
  border-radius: 6px;
  width: 430px;
  min-height: 200px;
  border: 1px solid #c1c1c166;
  font-family: "arial";
  margin-right: 10px;
  margin-right: 10px;
  box-shadow: 0px 2px 3px #00000033;
  margin-bottom: 9px;

  &.cls-multiproduct-kit {
    width: 550px;
    min-height: 383px;
  }

  .cls-ShekelCardContProductMultipleWrapper {
    display: flex;
    flex-direction: row;
    overflow: auto;
    min-height: 250px;
    height: 250px;
    background: #f3f7fa;
  }
  .cls-ShekelCardContProductWrapper.cls-value {
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    &.cls-multiple {
      margin: 10px;
      max-width: 416px;
      padding-right: 10px;
      margin-right: 10px;
      min-height: 212px !important;
      max-height: 212px !important;
      background: #fff;
      border-radius: 10px;
      border: 1px solid #c9b5cb;
    }
  }
  .cls-ShekelCardContProductInfoAction {
    position: absolute;
    right: 5px;
    bottom: 5px;
    &.cls-alone {
      top: 3px;
      bottom: unset;
    }
    &.cls-multiple {
      width: 100%;
      background: #fafbfd;
      height: 35px;
      bottom: 0px;
      right: 0px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-shadow: 10px 10px 18px #000;
      position: relative;
    }
  }
  .cls-ShekelCardContProductimage {
    min-width: 100px;
    min-height: 100px;
    max-width: 100px;
    max-height: 100px;
    border-radius: 6px;
    border: 1px solid #ccc;
    padding: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &.cls-multiple-image {
      min-width: 188px;
      min-height: 188px;
      max-width: 188px;
      max-height: 188px;
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      box-shadow: inset 0px 0px 12px #00000047;
      position: absolute;
      left: 0;
      top: 0;
    }

    img {
      width: auto;
      height: auto;
      margin: auto;
      display: flex;
      max-width: 150px;
      max-height: 150px;
      border-radius: 5px;
    }
  }
  .cls-ShekelCardContProductInfokit {
    padding: 10px;
    box-sizing: border-box;
    padding-top: 0px;
  }
  .cls-ShekelCardContProducttitle {
    font-size: 14px;
    color: #000;
    font-weight: bold;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
    &.cls-multiple {
      width: 126px;
      margin-bottom: 8px;
    }
  }
  .cls-ShekelCardContProductitemid {
    font-size: 12px;
    color: #656565;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
    &.cls-multiple {
      width: 126px;
      margin-bottom: 4px;
    }
  }
  .cls-ShekelCardContProductweight {
    font-size: 12px;
    color: #656565;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
    &.cls-multiple {
      width: 126px;
    }
  }
  .cls-ShekelCardContProductWrapper.cls-empty {
    justify-content: center;
    align-items: center;
    min-height: 108px;
    height: 108px;
    &.cls-empty-multiple {
      min-height: 208px;
      height: 208px;
    }
  }
`
export const StyledShekelCardCameraWrapper = styled(Grid)`
  background: #eff2f1;
  border-radius: 6px;
  width: 100%;
  height: 35px;
  font-family: "arial";
  margin-right: 10px;

  button {
    display: flex;
    margin: auto;
    width: 100%;
  }
`
export const StyledShekelCardCameraValWrapper = styled(Grid)`
  background: #bcd6cf;
  border-radius: 6px;
  width: 100%;
  height: 35px;
  font-family: "arial";
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  box-sizing: border-box;

  &.cls-two-camera {
    background: #c8ee77;
  }
  &.cls-no-camera {
    background: #ddcaca;
  }

  button {
    width: 24px;
    height: 24px;
    padding: 0px;
    margin-left: 4px;
  }
`
export const StyledShekelCardProductWrapper = styled(Grid)`
  background: #fafbfd;
  border-radius: 6px;
  width: 100%;
  min-height: 158px;
  font-family: "arial";
  margin-right: 10px;
  margin-top: 10px;
  border: 1px solid #e5d9e6;
  overflow: hidden;

  .cls-ShekelCardHeadProductWrapper {
    width: 100%;
    min-height: 40px;
    background: #e5d9e6;

    .cls-delete-emptyicon-kit {
      display: flex;
      margin: auto;
    }
  }

  .cls-ShekelCardContProductWrapper {
    display: flex;
    min-height: 102px;
    width: 100%;
  }
  .cls-ShekelCardAddProductWrapper {
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    height: 40px;
  }
`
export const StyledShekelCardProductValWrapper = styled(Grid)`
  background: #fafbfd;
  border-radius: 6px;
  width: 100%;
  min-height: 173px;
  font-family: "arial";
  margin-right: 10px;
  margin-top: 10px;
  border: 1px solid #e5d9e6;
  overflow: hidden;
  position: relative;

  .cls-ShekelCardHeadProductWrapper {
    width: 100%;
    min-height: 40px;
    background: #e5d9e6;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
  .cls-ShekelCardContProductWrapper {
    display: flex;
    min-height: 115px;
    width: 100%;
  }
  .cls-ShekelCardAddProductWrapper {
    border: 1px solid #c1c1c1;
    border-radius: 6px;
    height: 40px;
  }
  .cls-ShekelCardCellValue {
    background: #99378d;
    width: 50px;
    height: 100%;
    color: #fff;
    padding: 3px;
    box-sizing: border-box;
  }
  .cls-ShekelCardCellInfo {
    height: 100%;
    color: #000;
    padding: 3px;
    box-sizing: border-box;
    display: flex;
    margin-right: 3px;
    flex-direction: column;
    max-width: 80px;
    &.cls-remaining-width {
      max-width: 120px;
      div {
        color: #99378d !important;
        font-weight: bold !important;
      }
    }
  }
  .cls-ShekelCardCellInfoRow {
    width: 75px;
    height: 100%;
    color: #000;
    padding: 3px;
    box-sizing: border-box;
    display: flex;
    margin-right: 3px;
    flex-direction: row;
    max-width: 80px;
    padding-top: 8px;
    justify-content: flex-end;
  }
  .cls-ShekelCardCellInfo-parent {
    width: fit-content;
    height: 100%;
    display: flex;
    &.cls-non-Associate {
      width: 210px;

      .cls-ShekelCardCellInfo {
        width: 100% !important;
        max-width: 100% !important;
        flex-direction: row !important;
        align-items: center !important;

        .cls-cell-key {
          margin-right: 8px;
        }
      }
    }
  }

  .cls-cell-key {
    text-align: center;
    font-size: 10px;
    margin-bottom: 0px;
    &.cls-text-kit {
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .cls-cell-value {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    &.cls-text-kit {
      font-size: 12px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`
