import React, { useState } from "react"
import { CheckBoxIcon, CheckBoxOutlineBlankIcon } from "@iconsGallery"
import { IconButton } from "@material-ui/core"
function useActiveState(props) {
  const { defaultActive = false, onUpdate } = props
  const [isActive, setActive] = React.useState(defaultActive)

  React.useEffect(() => {
    setActive(defaultActive)
  }, [defaultActive])

  const handleClick = () => {
    onUpdate({ active: !isActive, defaultActive })
    setActive(!isActive)
  }

  return {
    isActive,
    setActive,
    handleClick,
  }
}

function CheckBoxButton(props) {
  const { defaultActive = false, onUpdate = () => {} } = props || {}
  const { isActive, handleClick } = useActiveState({
    defaultActive: defaultActive,
    onUpdate,
  })
  const { name = "Button" } = props || {}

  const buttonAttrs = {
    variant: "contained",
    onClick: handleClick,
    isActive,
  }

  return (
    <>
      <IconButton {...buttonAttrs}>
        {isActive ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
      </IconButton>
    </>
  )
}
export { CheckBoxButton }
export default CheckBoxButton
