import React, { useState } from "react"
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormHelperText,
    TextField,
    Typography,
    Tooltip
} from "@material-ui/core"
import * as Utils from "@utils"
import styled from "styled-components"
import * as IconsGallery from "@iconsGallery"
import { makeStyles } from "@material-ui/core/styles"
import { useStyles } from "@material-ui/pickers/views/Calendar/Day"
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from 'lodash'
import { render } from "@testing-library/react"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';


const usestyles = makeStyles({
    paper: {
        border: "1px solid grey"
    }

})

function LabelCopyboard(props) {
    let {
        label = "",
        title = "Copy",
        setTitle = () => { },
        style={
            paddingLeft: "7px",
            fontSize: "34px",
            cursor: "pointer",
            color: "rgba(166, 41, 145, 1)"
        }
    } = props;

    return (
        <>
            <Tooltip title={title}>
                <CopyToClipboard text={label}
                    onCopy={() => setTitle('Copied')}>
                    <FileCopyOutlinedIcon 
                    style={style}>
                    </FileCopyOutlinedIcon>
                </CopyToClipboard>
            </Tooltip>
        </>)

}
export { LabelCopyboard }
export default LabelCopyboard