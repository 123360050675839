import React, { useState, useEffect } from "react"
import {
  Button,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  Typography,
  Checkbox,
  Collapse,
  Tooltip,
  IconButton
} from "@material-ui/core"
import { Filter, XCircle, ChevronUp, ChevronDown, MapPin } from "react-feather"
import TableFilterStyles from "../../styles/components/tableColumnFilter/styles.js"
import RefreshSharpIcon from '@material-ui/icons/RefreshSharp';
import { PlusCircle, EditIcon, InfoIconKit } from "@iconsGallery";
import { useHistory } from "react-router-dom";
import styled from "styled-components"
import * as Adm from "@adm"


// const FilterButton = styled(Button)`
//   border: 1px solid #e0e0e0;
//   margin-right: 15px;

//   svg {
//     margin-right: 10px;
//   }
// `
function TableFilter({ tableFilterData, updateTableFilterData, showLocationicon,
  handleDedupeModal, locationId, dropDownList, reset = false, resetFilterMethod = () => { }
}) {
  const classes = TableFilterStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState(true)
  const { location = {} } = window || {}
  let history = useHistory()
  const toggleDrawer = (drawerOpen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }

    setDrawerOpen(!drawerOpen)
  }

  const FilterIcon = () => (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.32943 10H9.66276V8.33333H6.32943V10ZM0.496094 0V1.66667H15.4961V0H0.496094ZM2.99609 5.83333H12.9961V4.16667H2.99609V5.83333Z"
        fill="currentColor"
      />
    </svg>
  )

  return (
    <React.Fragment>
      {showLocationicon && <span style={{ marginRight: 20 }}>
        {locationId && "Location: " + locationId}
      </span>}
      {showLocationicon &&
        // <Button style={{ margingRight: 20 }} variant="text" color='secondary' onClick={handleDedupeModal} startIcon={<MapPin />}>
        //   {locationId ? locationId : "Select Location"}
        // </Button>
        <Adm.DropdownKit
          className={classes.dropDownStyles}
          defaultValue={"Select"}
          list={dropDownList}
          onUpdate={(val) => {
            handleDedupeModal(val)
          }}
        />
      }
      {
        reset &&
        <Button variant="text"
          style={{
            border: "1px solid #af2b99",
            padding: "0px 20px 0px 20px",
            fontWeight: "bold",
            height: "35px",
            marginRight: "10px",
            color: "#af2b99"
          }}
          // onClick={() => {
          //   window.location.href = `${location.pathname}?filter=${false}`;
          // }}
          onClick={() => {
            resetFilterMethod()
            // console.log("tablefilter---->", { dataState: gridPayload })
            // history.push(`${location.pathname}?filter=${false}`);
            // setGridState({ dataState: gridPayload });
          }}
          startIcon={<RefreshSharpIcon />}>
          {`Clear Filter`}
        </Button>
      }
      <Button variant="text" style={{
        border: "1px solid #EEEEEE",
        padding: "0px 20px 0px 20px",
        fontWeight: "bold",
        height: "35px",
      }} onClick={toggleDrawer(drawerOpen)} startIcon={<FilterIcon />}>
        {/* color='secondary' */}
        {`Column Options`}
      </Button>
      <Tooltip
        title={`You can view more information by adding columns`}
        placement="top"
      >
        <IconButton style={{ padding: 5 }}>
          <InfoIconKit fill="#af2b99" />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(drawerOpen)}
        className={classes.drawer}
        style={{
          paper: {
            width: "19%",
          },
        }}
      >
        <Grid
          container
          row
          justify="space-between"
          style={{ padding: "16px", width: "100%" }}
        >
          <Typography className={classes.headerText}>{`Column Options`}</Typography>
          <XCircle
            size={20}
            onClick={() => {
              setDrawerOpen(false)
            }}
          />
        </Grid>
        <Divider />
        <List>
          {
            tableFilterData &&
            tableFilterData.map((item, index) => (
              !item?.hideDisplay &&
              <ListItem button key={index}>
                <Checkbox
                  style={{ color: item?.primary ? null : "var(--primaryColor)" }}
                  checked={item.show}
                  onChange={() => {
                    updateTableFilterData(item)
                  }}
                  disabled={item?.primary || false}
                />
                <Typography className={classes.listText}>
                  {item.title}
                </Typography>
              </ListItem>
            ))
          }
          {/* <ListItem onClick={() => setCollapseOpen(!collapseOpen)}>
            <Grid
              container
              row
              justify="space-between"
              style={{ minWidth: "200px" }}
            >
              <Typography className={classes.subHeaderText}>Columns</Typography>
              {collapseOpen ? <ChevronUp /> : <ChevronDown />}
            </Grid>
          </ListItem> */}
          {/* <Collapse
            component="li"
            in={collapseOpen}
            timeout="auto"
            unmountOnExit
            style={{ marginLeft: "-10px" }}
          >
            {tableFilterData &&
              tableFilterData.map((item, index) => (
                <ListItem button key={index}>
                  <Checkbox
                    style={{ color: "var(--primaryColor)" }}
                    checked={item.show}
                    onChange={() => {
                      updateTableFilterData(item)
                    }}
                  />
                  <Typography className={classes.listText}>
                    {item.title}
                  </Typography>
                </ListItem>
              ))}
          </Collapse> */}
        </List>
      </Drawer>
    </React.Fragment>
  )
}

export default TableFilter
