const defaultSearchList = [
  { id: "73649846", subTitle: "fruits", title: "apple" },
  { id: "93745149", subTitle: "fruits", title: "orange", selected: true },
  { id: "78742587", subTitle: "fruits", title: "graphs" },
  { id: "02500445", subTitle: "eletronic", title: "laptop" },
  { id: "75878742", subTitle: "eletronic", title: "mobile" },
  { id: "02500412", subTitle: "recipe", title: "taste" },
]

export { defaultSearchList }
