import React from "react"
import { Grid, Button } from "@material-ui/core"
import styled from "styled-components"

const TextViewMoreWrapper = styled(Grid)`
  width: calc(100% - 2px);
  display: flex;
  position: relative;
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  code, .code {
    width: calc(100% - 1px);
    color: #000;
    font-size: 14px;
    height: 60px;
    padding: 1px;
    text-wrap: wrap;
    transition: height 400ms ease;
    box-sizing: border-box;
    padding-top: 10px;
    word-wrap: break-word;
    &.active {
      height: 200px;
      overflow: auto;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #de127f;
      border-radius: 10px;
    }
  }

  Button {
    background: transparent;
    color: #000;
    font-weight: bold;
    text-decoration: underline;
    background: transparent;
    font-style: italic;
    margin: auto;
    margin-right: -1px;
  }
`

function ContentDescription(props) {
  const { defaultValue = "", lengthWeight = 50 } = props || {}
  const [getIsShowMore, setIsShowMore] = React.useState(false)
  let isOverLength = defaultValue.length >= lengthWeight
  let cropText = isOverLength
    ? defaultValue.substring(0, lengthWeight).trim() + "..."
    : defaultValue

  return (
    <TextViewMoreWrapper>
      <div className={`code ${getIsShowMore ? "active" : ""}`}>
        {getIsShowMore ? defaultValue : cropText}

        {getIsShowMore && (
          <>
            <br />
            <br />
          </>
        )}
      </div>
      {isOverLength && (
        <Button
          onClick={() => {
            setIsShowMore(!getIsShowMore)
          }}
        >
          {getIsShowMore ? "Less" : "Show"}
        </Button>
      )}
    </TextViewMoreWrapper>
  )
}

export { ContentDescription }
export default ContentDescription
