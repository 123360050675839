import * as React from "react"
import styled from "styled-components"
import { Grid, TextField, Chip, Button } from "@material-ui/core"

const StyledChipWrapper = styled(Grid)`
  min-width: 100%;
  padding: 10px;
  background: #e1e1e1;
  border-radius: 10px;
  min-height: 70px;
  border: 1px solid #c1c1c1;
`
const StyledButton = styled(Button)`
  background: #cc327d !important;
  color: #fff !important;
  width: 108px !important;
  height: 44px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;

  &.Mui-disabled {
    background: gray !important;
  }
`
const StyledChip = styled(Chip)`
  background: #cc327d !important;
  color: #fff !important;
  margin-right: 5px !important;
  margin-bottom: 5px !important;
  font-weight: bold !important;

  svg {
    color: #fff !important;
  }
`
const StyledFieldWrapper = styled(Grid)`
  display: flex !important;
  flex-direction: column !important;
  font-size: 12px !important;
  margin-top: 10px !important;
  max-width: 378px !important;
  .cls-field-bottom-kit {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    margin-top: 6px !important;
  }
  font-family: "arial";

  .cls-lable-kit-ui {
    color: gray !important;
  }
  .cls-lable-kit-ui-duplicate {
    color: red !important;
  }
`
const StyledTextField = styled(TextField)`
  border: 1px solid #cc327d !important;
  color: #cc327d !important;
  font-size: 13px !important;
  padding: 5px !important;
  border-radius: 6px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  max-width: 220px !important;
  width: 220px !important;

  padding-left: 10px !important;
  &.cls-disable {
    background-color: #ccc;
  }
  .MuiInput-underline:after {
    display: none !important;
  }
  .MuiInput-underline:before {
    display: none !important;
  }
  input {
    color: #cc327d !important;
  }
  input:placeholder {
    color: #cc327d !important;
    opacity: 1 !important;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`

const removeItemOnce = (arr, value) => {
  var index = arr.indexOf(value)
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr
}

function ChipCreate(props) {
  const {
    defaultChipList = [],
    onUpdate = () => "",
    chipPrefix = "grm",
    isEdit = false,
    maxLength = 15,
  } = props || {}

  const [getIsDuplicate, setIsDuplicate] = React.useState(false)
  const [getChipList, setChipList] = React.useState([])
  const [getNumber, setNumber] = React.useState("")

  React.useEffect(() => {
    setChipList(defaultChipList)
  }, [defaultChipList])

  const renderChipViewMode = () => {
    return (
      <StyledChipWrapper>
        {getChipList.length > 0 ? (
          getChipList.map((currentChipItem = "", index) => {
            let innerProps = {
              key: index,
              label: `${currentChipItem} ${chipPrefix}`,
            }
            if (isEdit) {
              innerProps = {
                ...innerProps,
                onDelete: () => {
                  let updatedList = [
                    ...removeItemOnce(getChipList, currentChipItem),
                  ]
                  onUpdate({ list: updatedList })
                  setChipList(updatedList)
                },
              }
            }
            return <StyledChip {...innerProps} />
          })
        ) : (
          <span>Not Added the Weight Sample </span>
        )}
      </StyledChipWrapper>
    )
  }

  const renderTextField = () => {
    return (
      <StyledFieldWrapper>
        <Grid>
          <StyledTextField
            className={getChipList?.length >= maxLength ? "cls-disable" : ""}
            disabled={getChipList?.length >= maxLength}
            value={getNumber}
            type="number"
            placeholder={
              getChipList?.length >= maxLength
                ? "Maximum Limit Exceeded"
                : "New Samples"
            }
            variant="standard"
            onKeyDown={(e) => {
              if (e.which === 13) {
                let value = e.target.value.trim()
                setNumber(value)
                if (value.length > 0) {
                  let updatedList = [...getChipList, value]
                  setChipList(updatedList)
                  onUpdate({ list: updatedList })
                  setNumber("")

                  // setIsDuplicate(getChipList?.includes(value))
                  // if (!getChipList?.includes(value)) {
                  //   let updatedList = [...getChipList, value]
                  //   setChipList(updatedList)
                  //   onUpdate({ list: updatedList })
                  //   setNumber("")
                  // }
                }
                e.preventDefault()
              }
            }}
            onChange={(e) => {
              const value = e?.target?.value
              const regex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/
              if (regex.test(value.toString()) || value.toString() === "") {
                let value = e.target.value.trim()
                setNumber(value)
                // if (value.length > 0) {
                //   setIsDuplicate(getChipList?.includes(value))
                // }
              }
            }}
          />
          {!(getChipList?.length >= maxLength) ? (
            <StyledButton
              // disabled={
              //   getNumber.length > 0 ? getChipList?.includes(getNumber) : true
              // }
              disabled={!(getNumber.length > 0)}
              onClick={() => {
                let updatedList = [...getChipList, getNumber]
                setChipList(updatedList)
                onUpdate({ list: updatedList })
                setNumber("")
              }}
            >{`+ Add Gram`}</StyledButton>
          ) : (
            <StyledButton disabled={true}>{`+ Add Gram`}</StyledButton>
          )}
        </Grid>
        <Grid className="cls-field-bottom-kit">
          <span className="cls-lable-kit-ui">{`(Minimum: 5 Samples | Maximum: 15 Samples)`}</span>
          {/* {getIsDuplicate && (
            <span className="cls-lable-kit-ui-duplicate">{`Duplicate!`}</span>
          )} */}
        </Grid>
      </StyledFieldWrapper>
    )
  }

  return (
    <Grid style={{ fontFamily: "arial" }}>
      <Grid>{renderChipViewMode()}</Grid>
      {isEdit && renderTextField()}
      {getChipList?.length >= maxLength && (
        <div style={{ color: "#f44336", fontSize: 11, marginLeft: 0 }}>
          {`Maximum Limits Exceedeed!`}
        </div>
      )}
    </Grid>
  )
}

export { ChipCreate }
export default ChipCreate
