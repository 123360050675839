import styled from "styled-components"
import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
export const useStyles = makeStyles((theme) => ({
  option: {
    borderBottom: `1px dashed #ccc`,
    paddingBottom: "0px !important",
    minHeight: 100,
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: "#dfdfdf",
      borderColor: "transparent",
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: "#dfdfdf",
      borderColor: "transparent",
    },
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  productBtn: {
    margin: "0px",
    "@media(min-width:1200px)": {
      "& .MuiGrid-grid-lg-1": {
        maxWidth: "14%",
        flexBasis: "14%",
      },
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textInput: {
    // marginBottom: '8px',
    fontWeight: 600,
    fontSize: "14px",
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
  disabledTextInput: {
    width: "100%",
    "& input": {
      backgroundColor: "#f6f6f6",
      color: "gray",
      width: "100%",
    },
  },
  text: {
    marginBottom: "45px",
    paddingLeft: "20px",
    marginLeft: "0px",
  },
  text1: {
    marginBottom: "45px",
    paddingLeft: "0px",
    marginLeft: "0px",
  },
  textemail: {
    marginBottom: "45px",
    paddingLeft: "20px",
    marginLeft: "0px",
  },
  textHeader: {
    marginBottom: "15px",
    // fontSize: '16px'
  },
  icon: {
    marginRight: "5px",
    color: "rgba(21, 101, 192, 1)",
    marginBottom: "-5px",
    fontSize: "30px",
  },
  actionIcons: {
    color: "rgba(158, 158, 158, 1)",
    fontSize: "22px",
  },
  textHeader1: {
    marginBottom: "15px",
    marginTop: "10px",
  },
  displayText: {
    fontSize: "18px",
    fontWeight: 500,
  },
  header2: {
    marginBottom: "15px",
    paddingLeft: "0px",
    marginLeft: "0px",
    marginTop: "10px",
  },
  divider: {
    borderRight: "0.1em solid rgba(0, 0, 0, 0.1)",
    padding: "15px 15px",
    margin: "10px 0px",
  },
  noDivider: {
    padding: "15px 15px",
    margin: "10px 0px",
  },
  bottomDivider: {
    borderBottom: "0.1em solid rgba(0, 0, 0, 0.1)",
  },
  divPadding: {
    padding: "2px",
  },
  autoCompleteHeight: {
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
  },
  valuesStyle: {
    alignSelf: "center",
  },
}))
export const StyledSwappingKitMainWrapper = styled(Grid)`
  width: 100%;
  background: #fff;
  padding: 20px;

  .cls-header-left-kit {
    background-color: var(--primaryColor);
    &.cls-disabled {
      background-color: #bbbbbb;
    }
  }
  .cls-main-header-kit {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 60px;
    margin-bottom: 10px;
    background: white;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    .cls-field-form-kit {
      width: 100%;
      min-width: 300px;
      max-width: 350px;

      fieldset {
        border-radius: 5px 0px 0px 5px !important;
        border-color: var(--primaryColor) !important;
      }
    }

    .cls-header-right-kit {
      button.cls-button-kit {
        background-color: var(--primaryColor);
        border-radius: 0px 5px 5px 0px !important;
        &.cls-disabled {
          background-color: #bbbbbb;
          border-radius: 0px 5px 5px 0px !important;
        }
      }
    }

    .cls-selected-storage-list-kit-disable {
      opacity: 0.5;
      pointer-event: none;
      fieldset {
        border-radius: 5px 0px 0px 5px !important;
        border-color: #bbbbbb !important;
      }
      button.cls-button-kit {
        background-color: #bbbbbb;
        border-radius: 0px 5px 5px 0px !important;
      }
    }
  }

  .cls-swap-kit-sub-wrapper {
    display: flex;
    flex-direction: row;
  }
  .cls-swap-left-panel {
    width: 350px;
    box-shadow: 0px 1px 11px #0000003d;

    display: flex;
    flex-direction: row;

    .k-grid {
      border-color: transparent;
      border-width: 0;
    }
    .k-grid-header thead tr:first-child th {
      background-color: #eef2ff;
      .k-column-title {
        color: #787878;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .cls-selected-isconnected,
    .cls-selected-isconnected + td,
    .cls-selected-isconnected + td + td {
      background: #fbe8f8 !important;
      border-right: 1px solid #fbe8f8 !important;
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
      color: var(--primaryColor);
    }
    .k-pager-info.k-label {
      display: none;
    }
    .k-pager-sizes.k-label {
      font-size: 0px;
      margin-left: auto;
      margin-right: 0;
    }

    .k-pager-sizes.k-label span {
      font-size: 1rem;
    }

    .cls-kcustom-select {
      background: pink !important;
    }
    .k-grid .k-grid-container {
      min-height: 400px;
    }
  }
  .cls-swap-right-panel {
    box-shadow: inset -8px 0px 12px #d4c0d0;
    padding: 20px;
    width: calc(100% - 360px);
    background: #fbe8f8;

    .cls-swap-right-sub-panel {
      padding: 10px;
      background: #fff;
      border-radius: 10px;
      height: 100%;
    }

    .cls-swap-right-header {
      padding: 15px;
      display: flex;
      flex-row: row;
      align-items: center;
    }

    .k-grid {
      border-color: transparent;
      border-width: 0;
    }
    .k-grid-header thead tr:first-child th {
      background-color: #eef2ff;
      .k-column-title {
        color: #787878;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .cls-selected-isconnected,
    .cls-selected-isconnected + td,
    .cls-selected-isconnected + td + td {
      background: #fff !important;
      border-right: 1px solid #fbe8f8 !important;
      box-shadow: 0px 0px 10px var(--primaryColor);
    }

    .MuiCheckbox-colorSecondary.Mui-checked {
      color: var(--primaryColor);
    }
    .k-pager-info.k-label {
      display: none;
    }
    .k-pager-sizes.k-label {
      font-size: 0px;
      margin-left: auto;
      margin-right: 0;
    }

    .k-pager-sizes.k-label span {
      font-size: 1rem;
    }

    .cls-kcustom-select {
      background: pink !important;
    }
    .k-grid .k-grid-container {
      min-height: 300px;
      background: #fff;

      td {
        border: 0 !important;
        width: 100%;
        height: 100%;
        display: flex;
        padding: 0;
        border-radius: 5px;
        overflow: hidden;

        .cls-img-wrapper-kit-connect {
          width: 100% !important;
          height: 100% !important;
          margin: auto !important;
          display: flex !important;
          min-width: 70px !important;
          min-height: 70px !important;
          max-width: 120px !important;
          max-height: 120px !important;
          border-radius: 5px !important;
        }
        img {
          width: 100% !important;
          height: 100% !important;
          margin: auto !important;
          display: flex !important;
          min-width: 70px !important;
          min-height: 70px !important;
          max-width: 120px !important;
          max-height: 120px !important;
          border-radius: 5px !important;
        }
      }

      colgroup[role="presentation"] {
        display: none !important;
      }

      tbody[role="presentation"] {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-row-gap: 15px;
        row-gap: 15px;
        column-gap: 15px;
        padding: 15px;
      }
    }
    .k-grid .k-grid-header {
      display: none !important;
    }

    .k-master-row {
      position: relative;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 5px;
      height:150px
    }
    .k-master-row .cls-checkbox-connect-kit {
      border: none !important;
      position: absolute;
      right: 3px;
      top: -8px;
      padding: 0;
      margin: 0;
      background: transparent !important;
      box-shadow: none;
      width: 30px !important;
      height: 30px !important;
    }

    .k-pager-wrap {
      background: #fff !important;
    }
    &.cls-selected-list .k-pager-wrap {
      opacity: 0.5 !important;
      pointer-event: none !important;
    }
  }
`
