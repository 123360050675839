import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS
export const loginFlagDashBoard = (data) => (dispatch) => {
  dispatch({
    type: types.LOGIN_FLAG,
    loginFlag: data,
  })
}
