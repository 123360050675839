import { Card, Grid } from "@material-ui/core"
import styled from "styled-components"
export const PageHeaderBreadcrumbKit = styled(Grid)`
  button {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 18px;
    padding-right: 18px;
    max-width: unset;
  }
  margin-bottom: 15px;
  border-bottom: 1px #e0e0e0 solid;
  padding-bottom: 5px;
`

export const TemplateExport = styled(Grid)`
  Button {
    margin-right: 18px;
  }
`

export const CardWrapper = styled(Card)`
  width: 100%;
  box-shadow: none;
  border-radius: 0px !important;
  border: transparent !important;
`