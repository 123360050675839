import React from "react"
import Styled from "styled-components"
import * as Utils from "@utils"
import * as SearchListDom from "./styles"
import { SearchListUIContext } from "./context"
import { defaultRenderViewList, defaultRenderViewHeader } from "./renderProps"

export const SearchViewWrapper = Styled.div`
 min-height:200px;

 .cls-search-list-value-parent-wrapper{
   max-height: 240px;
  overflow: scroll;
 }
  
`
function ViewListUI(props) {
  const { renderViewList = () => {}, renderViewHeader = () => {} } = props || {}
  const searchListUIContextState = React.useContext(SearchListUIContext) || {}
  const { isMultipleSelect } = searchListUIContextState || {}

  return (
    <SearchViewWrapper className="cls-search-list-parent-wrapper">
      {renderViewHeader({ ...searchListUIContextState })}
      <div className="cls-search-list-value-parent-wrapper">
        {renderViewList({ ...searchListUIContextState })}
      </div>
      {isMultipleSelect === false && (
        <SearchListDom.ListNoteResponse>{`Note: Only One Product Allowed`}</SearchListDom.ListNoteResponse>
      )}
    </SearchViewWrapper>
  )
}

export { ViewListUI }
