import React, { useEffect } from "react"
import _ from "lodash"
import { Grid } from "@material-ui/core"
import * as Adm from "@adm"
import * as Library from "@compLib"
import * as Fixture from "./fixture"
import * as ServiceAPI from "@services"
let isLocal = false
const columnData = Fixture?.defaultDigitTableColumnData
const isSearchSeperate = false

const getDigitShelf = (props) => {
  const { type = "post", cbk = () => "", data = {}, headers = {}, getIsLocal = false } =
    props || {}

  let Services =
    type === "put" ? ServiceAPI.doPutFetchAPI : ServiceAPI.doPostFetchAPI
  Services(
    data,
    headers,
    getIsLocal ? getIsLocal : type === "put" ? isLocal : false
  )
    .then((response) => {
      cbk({ status: "success", response })
    })
    .catch((error) => {
      cbk({ status: "error", response: { ...error } })
    })
}

function DigitTable(props) {
  const {
    getInitLoad = true,
    setInitLoad = () => "",
    revertChange = () => "",
    getRawList = () => "",
    currentList = [],
    getParamData = { locationId: "", planogramId: "" },
    setStorageUnitId = () => { },
    getStorageUnitId = "",
    setSelectedItem = () => { },
    isRerender = false,
    setRerender = () => { },
    storageGridTableData = "",
    setStorageGridTableData = () => { },
    getVendorId = "",
  } = props || {}

  console.log("DigitTable", props)

  const { hideCheckBoxAll = false, onUpdate = () => "" } = props || {}
  const defaultDataTableState = {
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [],
    take: 10,
    skip: 0,
  }

  const [gridTableState, setGridTableState] = React.useState({
    dataState: defaultDataTableState,
  })
  //const [gridTableData, setGridTableData] = React.useState({ data: [] })
  const [rawTableData, setRawTableData] = React.useState({})
  const [getTableLoading, setTableLoading] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState("")

  const getPostAPIfetchData = async (innerProps) => {
    setTableLoading(true)
    setRerender(false)
    const { searchTerm = "" } = searchObj || {}
    const { filter = {} } = innerProps || {}
    const { filters = [] } = filter || {}
    let filterObj = {}

    // if (filters.length > 0) {
    //   filters.forEach((itm) => {
    //     filterObj[itm?.field] = itm?.value
    //   })
    // }

    // let searchKit = filterObj?.name || ""
    let body = {
      "filter_by": filters?.length > 0 ? [...filters] : [],
      "pagination": {
        "no_of_records_per_page": innerProps?.take,
        "page_no": 1
      }
    }
    let response = await ServiceAPI.fetchCommonData(
      Fixture?.EndPointAPI?.getVendorAPI + "/" + getVendorId,
      body,
      {
        headers: {
          "Realm-ID": localStorage.getItem('X-KC-Realm-ID'),
          "Tenant-ID": localStorage.getItem('tenantId')
        },
      }
    )

    // let data = {
    //   END_POINTS: Fixture?.EndPointAPI?.getStorageAPI,
    //   body: {
    //     page:
    //       Math.floor(
    //         gridTableState.dataState.skip / gridTableState.dataState.take
    //       ) + 1 || 1,
    //     limit: gridTableState.dataState.take,
    //     filter:
    //       searchKit.length > 0
    //         ? [
    //           {
    //             field: "name",
    //             operator: "contains",
    //             value: filterObj?.name || "",
    //           },
    //         ]
    //         : [],
    //     sort: {},
    //   },
    // }
    const { status = "" } = response
    if (status === 200) {
      setInitLoad(false)
      let destructure = _.get(response, "data.records", [])
      if (destructure.length > 0) {
        destructure = destructure.map((itm) => {
          return {
            ...itm,
          }
        })
      }

      console.log("destructure", destructure)
      setStorageGridTableData({ data: [...destructure] })
      setRawTableData({
        totalDocs: _.get(response, "data.total_no_of_pages"),
      })
    } else {
      revertChange()
    }
    setTableLoading(false)
  }

  // const getPostAPIfetchData = async (innerProps) => {
  //   setTableLoading(true)
  //   const { searchTerm = "" } = searchObj || {}
  //   const { filter = {} } = innerProps || {}
  //   const { filters = [] } = filter || {}
  //   let filterObj = {}

  //   if (filters.length > 0) {
  //     filters.forEach((itm) => {
  //       filterObj[itm?.field] = itm?.value
  //     })
  //   }
  //   // if (searchTerm.length > 0) {
  //   //   filterObj = {
  //   //     cameraName: searchTerm,
  //   //   }
  //   // }

  //   let searchKit = filterObj?.name || ""

  //   let data = {
  //     END_POINTS: Fixture?.EndPointAPI?.getStorageAPI,
  //     body: {
  //       page:
  //         Math.floor(
  //           gridTableState.dataState.skip / gridTableState.dataState.take
  //         ) + 1 || 1,
  //       limit: gridTableState.dataState.take,
  //       filter:
  //         searchKit.length > 0
  //           ? [
  //             {
  //               field: "name",
  //               operator: "contains",
  //               value: filterObj?.name || "",
  //             },
  //           ]
  //           : [],
  //       sort: {},       
  //     },
  //   }
  //   getDigitShelf({
  //     type: "post",
  //     data,
  //     headers: {
  //       "test-bk":"testshelf",
  //       "Realm-ID": localStorage.getItem('X-KC-Realm-ID'),
  //       "Tenant-ID": localStorage.getItem('tenantId')
  //     },
  //     cbk: (innProps) => {
  //       const { response = {}, status = "" } = innProps
  //       if (status === "success") {
  //         setInitLoad(false)
  //         let destructure = _.get(response, "data.data.docs", [])
  //         if (destructure.length > 0) {
  //           destructure = destructure.map((itm) => {
  //             return {
  //               ...itm,
  //             }
  //           })
  //         }

  //         // destructure = destructure.map((itm) => {
  //         //   let iSAvailableFilterCellId = currentList.filter((fItm) =>
  //         //     fItm?.productCameraGroupId.includes(itm?.cameraId)
  //         //   )

  //         //   return {
  //         //     ...itm,
  //         //     isLocked: iSAvailableFilterCellId.length > 0,
  //         //     isConnected: iSAvailableFilterCellId.length > 0,
  //         //   }
  //         // })
  //         // if (getInitLoad === false) {
  //         //   getRawList({ data: destructure })
  //         // }
  //         console.log("destructure",destructure)
  //         setStorageGridTableData({ data: [...destructure] })
  //         setRawTableData({
  //           totalDocs: _.get(response, "data.data.totalDocs"),
  //         })
  //       } else {
  //         revertChange()
  //       }
  //       setTableLoading(false)
  //     },
  //   })
  // }

  React.useEffect(() => {
    let destructure = _.get(storageGridTableData, "data", [])
    destructure = destructure.map((itm) => {
      return {
        ...itm,
        isConnected: currentList.includes(itm?.storage_unit_id),
      }
    })
    setStorageGridTableData({ data: destructure })
  }, [currentList])

  React.useEffect(() => {
    setGridTableState((c) => ({ dataState: { ...c.dataState, skip: 0 } }))
    setSearchTerm(searchTerm)
  }, [searchTerm])

  React.useEffect(() => {
    if (getInitLoad === false) {
      getRawList({ data: [] })
    }
    getPostAPIfetchData(gridTableState.dataState)
  }, [gridTableState.dataState, isRerender])

  // useEffect(() => {
  //   isRerender && getPostAPIfetchData()
  // }, [isRerender])

  const handleSearchTerm = (val) => setSearchTerm(val)
  const handleClear = () => setSearchTerm("")
  let searchObj = { searchTerm: searchTerm }
  const handleConnect = (innerEvent) => {
    setSelectedItem([])
    setStorageUnitId(innerEvent?.currentItem?.dataItem?.storage_unit_id);
    const {
      isConnected: currentIsConnected = false,
      currentItem = {},
    } = innerEvent
    const { dataItem = {} } = currentItem

    let data = storageGridTableData?.data.map((itm) => {
      const { isConnected = false } = itm
      return {
        ...itm,
        isConnected: itm?.storage_unit_id === dataItem.storage_unit_id ? currentIsConnected : false,
        // isConnected:
        //   itm?._id === dataItem._id ? currentIsConnected : isConnected,
      }
    })

    let obj = {
      ...storageGridTableData,
      data,
    }
    console.log("handleConnect", obj)
    setStorageGridTableData(obj)
    onUpdate({
      raw: data,
      data: data.filter((itm) => {
        const { isConnected = false } = itm
        return isConnected === true
      }),
    })
  }

  return (
    <Grid
      style={{
        //height: "",
        overflowY: "auto",
        background: "#fff",
        height: "100%",
      }}
    >
      <Grid
        style={{
          border: "0px solid red",
          display: "flex",
          flexGrow: 1,
          flexWrap: "wrap",
          alignContent: "flex-start",
          height: "100%",
        }}
      >
        <Adm.BackdropOverlay open={getTableLoading} />
        <Grid
          container
          style={{
            //height: "",
            overflow: "hidden",
            height: "100%",
          }}
        >
          {isSearchSeperate && (
            <Grid
              style={{
                padding: "10px",
                borderBottom: "1px solid #EEEEEE",
                position: "relative",
              }}
            >
              <Adm.SearchLookup
                placeholder="By Name"
                searchObj={searchObj}
                handleClear={handleClear}
                handleSearchTerm={handleSearchTerm}
              />
            </Grid>
          )}
          <Grid
            style={{
              minHeight: "480px",
              overflow: "auto",
              height: "100%",
            }}
          >
            <Library.MaterialDataGrid
              otherProps={{ style: { height: "100%" } }}
              buttonCount={1}
              gridNoRecordsText={"No records found"}
              checkBoxWidth={""}
              hideCheckboxAll={hideCheckBoxAll}
              gridState={gridTableState}
              columnData={columnData}
              rowData={storageGridTableData}
              number_of_items={_.toFinite(rawTableData?.totalDocs || 0)}
              onConnectedChecked={(e) => handleConnect(e)}
              setRowData={setStorageGridTableData}
              setGridState={setGridTableState}
              isLookup={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DigitTable
export { DigitTable }
