import React from "react"
import styled from "styled-components/macro"
import { Link, useHistory } from "react-router-dom"
import {
  ErrorFourZeroThreeIcon
} from "@iconsGallery"

import Helmet from "react-helmet"

import { Button as MuiButton, Typography } from "@material-ui/core"
import { spacing } from "@material-ui/system"

import { useSelector } from "react-redux"

const Button = styled(MuiButton)(spacing)

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`

function Page403() {
  const history = useHistory()

  const userProfileDetails = useSelector((state) => state.adminReducer.userProfileDetails) || {};

  const returnWeb = () => {
    let getRoleName = localStorage.getItem("sourceRole")
    let getTenantId = localStorage.getItem("tenantId")
    getRoleName = getRoleName.toLocaleLowerCase()
    const isSassRole = getRoleName.includes("saas")

    let dynamicRoutes = isSassRole
      ? `/${userProfileDetails?.roleType}/dashboard?page=home`
      : `/${userProfileDetails?.roleType}/dashboard?page=autonomous&retailer=${getTenantId}&subpage=0`
    window?.location?.replace(dynamicRoutes)
  }

  return (
    <Wrapper>
      <Helmet title="403 Forbidden" />
        <ErrorFourZeroThreeIcon/>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Access Denied / Forbidden
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        You don't have permission to access this page
        {/* Contact an administrator to get permissions or go to the home page  */}
      </Typography>

      <Button
        component={Link}
        to="/"
        variant="contained"
        color="secondary"
        mt={2}
        onClick={() => returnWeb()}
      >
        Return to website
      </Button>
    </Wrapper>
  )
}

export default Page403
