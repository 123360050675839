import React, { useContext } from "react"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  CardHeader,
  Collapse,
  TextField,
  Button,
  ListItemText,
  Grid,
  Typography,
  Checkbox,
} from "@material-ui/core"
import { CloseIcon } from "@iconsGallery"
import Styled from "styled-components"
import * as Utils from "@utils"
import * as Adm from "@adm"
import * as SearchListDom from "./styles"

export const defaultRenderSuggesionUIList = (props) => {
  const {
    searchText,
    fetchListData,
    getSearchFilterList,
    setFetchListData,
    isSelectIndex,
    setSelectIndex,
    isMultipleSelect,
    resetInput,
    setButtonTrigger,
    buttonTrigger,
  } = props || {}

  const filterList = fetchListData.filter(
    (item) =>
      item["id"].includes(searchText) ||
      item["title"].toLowerCase().includes(searchText.toLowerCase())
  )

  const onHandleChange = ({ value, selected, index, id }) => {
    const getIndex = fetchListData.findIndex((x) => x.id === id)
    if (isMultipleSelect === true) {
      fetchListData[getIndex].selected = !selected
      setFetchListData(fetchListData)
    } else {
      if (value) {
        setSelectIndex(getIndex)
        const dataFiltered =
          fetchListData &&
          fetchListData.map((item) => {
            const { selected, ...restprops } = item
            return { ...restprops }
          })
        dataFiltered[getIndex].selected = true
        setFetchListData([...dataFiltered])
        setButtonTrigger(!buttonTrigger)
        resetInput()
      }
    }
  }

  return (
    <>
      {Utils.JSUtils.isEmpty(filterList) === true ? (
        <SearchListDom.SearchNotResponse>
          Search not found
        </SearchListDom.SearchNotResponse>
      ) : (
        Utils.JSUtils.isEmpty(filterList) === false &&
        filterList.map((item, index) => {
          const {
            id = "73649846",
            title = "apple",
            selected = false,
            subTitle = "",
          } = item || {}
          const getIndex = fetchListData.findIndex((x) => x.id === id)
          const isChecked = getIndex === isSelectIndex

          let checkBoxobj = {
            name: "checkedB",
            color: "primary",
            onChange: (e) => {
              onHandleChange({
                value: e.target.checked,
                selected,
                index,
                id,
                isChecked,
              })
            },
          }

          let isCheckedActive = ""
          if (isMultipleSelect === true) {
            checkBoxobj = { ...checkBoxobj, defaultChecked: selected }
            isCheckedActive = `${selected ? "active" : ""}`
          } else {
            isCheckedActive = `${isChecked ? "active" : ""}`
            checkBoxobj = {
              ...checkBoxobj,
              isChecked: isChecked,
              checked: isChecked,
            }
          }
          return (
            <SearchListDom.SearchSuggesionItemWrapper
              className={`${isCheckedActive}`}
            >
              {isMultipleSelect === true && (
                <Adm.CheckBoxButton
                  defaultActive={selected}
                  onUpdate={({ active = false }) => {
                    onHandleChange({
                      value: active,
                      selected,
                      index,
                      id,
                      isChecked,
                    })
                  }}
                />
              )}
              {isMultipleSelect === false && <Checkbox {...checkBoxobj} />}
              <SearchListDom.SearchSuggesionItemProductId>
                {id}
              </SearchListDom.SearchSuggesionItemProductId>
              <div>
                <SearchListDom.TitleWrapper>{title}</SearchListDom.TitleWrapper>
                {Utils.JSUtils.isEmpty(subTitle) === false && (
                  <SearchListDom.SubTitleWrapper className="cls-view-subtitle-item">
                    {subTitle}
                  </SearchListDom.SubTitleWrapper>
                )}
              </div>
            </SearchListDom.SearchSuggesionItemWrapper>
          )
        })
      )}
    </>
  )
}

export const defaultRenderViewList = ({
  fetchListData,
  setButtonTrigger,
  setFetchListData,
  isEdit,
  buttonTrigger,
  setSelectIndex,
  isMultipleSelect,
  setRemovedFetchListData,
}) => {
  const onRemove = ({ selected, index, id }) => {
    const data = JSON.stringify(fetchListData)
    setRemovedFetchListData(JSON.parse(data))
    const getIndex = fetchListData.findIndex((x) => x.id === id)
    fetchListData[getIndex].selected = false
    setFetchListData([...fetchListData])
    if (isMultipleSelect === false) {
      setSelectIndex(-1)
    }
  }

  return (
    <>
      {Utils.JSUtils.isEmpty(fetchListData) === false &&
        fetchListData.map((item, index) => {
          const { id, title, selected = false, subTitle = "" } = item || {}
          return (
            selected && (
              <SearchListDom.ViewItemWrapper className="cls-search-item-wrapper">
                <SearchListDom.ViewProductIdItemWrapper className="cls-search-item-key-wrapper">
                  {id}
                </SearchListDom.ViewProductIdItemWrapper>
                <SearchListDom.ViewProductNameItemWrapper
                  className={`cls-search-item-value-wrapper `}
                >
                  <div>
                    <Typography
                      className={`cls-view-title-item ${
                        Utils.JSUtils.isEmpty(subTitle) === false
                          ? "cls-addtional-beautify"
                          : ""
                      }`}
                    >
                      {title}
                    </Typography>
                    {Utils.JSUtils.isEmpty(subTitle) === false && (
                      <Typography className="cls-view-subtitle-item">
                        {subTitle}
                      </Typography>
                    )}
                    {isEdit && (
                      <IconButton
                        onClick={() => {
                          onRemove({ selected, index, id })
                          setButtonTrigger(!buttonTrigger)
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                  </div>
                </SearchListDom.ViewProductNameItemWrapper>
              </SearchListDom.ViewItemWrapper>
            )
          )
        })}
    </>
  )
}

export const defaultRenderViewHeader = (props) => {
  const { fetchListData, titleName, idName } = props || {}
  const filterIsSelected = fetchListData.filter((item) => {
    return item.selected === true
  })
  return Utils.JSUtils.isEmpty(filterIsSelected) === false ? (
    <SearchListDom.ViewHeaderItemWrapper className="cls-search-list-header-wrapper">
      <SearchListDom.ViewHeaderProductIdItemWrapper className="cls-search-item-header-id">
        {idName}
      </SearchListDom.ViewHeaderProductIdItemWrapper>
      <SearchListDom.ViewHeaderProductNameItemWrapper className="cls-search-item-header-title">
        {titleName}
      </SearchListDom.ViewHeaderProductNameItemWrapper>
    </SearchListDom.ViewHeaderItemWrapper>
  ) : (
    <></>
  )
}
