import React from "react"
import styled from "styled-components/macro"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography as MuiTypography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { spacing } from "@material-ui/system"
const Typography = styled(MuiTypography)(spacing)

function NoRecordFound(props) {
  //   const { t } = useTranslation()
  return (
    <Grid
      container
      justify="center"
      style={{ paddingBottom: "20px", paddingTop: "20px" }}
    >
      <Typography variant="h6">No Record Found</Typography>
    </Grid>
  )
}

export { NoRecordFound }
export default NoRecordFound
