import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

// const types = REDUX_CONSTANTS

export const updateTree = (treeData) => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.UPDATE_TREE,
    treeData: treeData,
  })
}

export const resetTree = (treeData) => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.RESET_TREE,
    treeData: treeData,
  })
}
