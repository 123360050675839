import { Checkbox } from "@material-ui/core"
import _ from "lodash"
import React from "react"

function CustomText(props) {
    const data = props.dataItem
    const dataValue = props.value
    let renderTextFalseVal = (_.filter(props?.filterData, _.matches({ value: "false" }))?.[0]?.tab || "Custom")
    let renderTextTrueVal = (_.filter(props?.filterData, _.matches({ value: "true" }))?.[0]?.tab || "Default")
    return (
        <td>
            {
                (data._id === "new" || !(props.renderText))
                    ?
                    <span style={{ color: "#afafaf" }}>{renderTextFalseVal}</span>
                    : <span>{renderTextTrueVal}</span>
            }
        </td>
    )
}

export default CustomText
