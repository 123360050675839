export const defaultEmptyRow = `Please add the row count in gondola page!`
export const defaultEmptyCell = `Please`
export const defaultShekelConfig = [
  {
    name: 1,
    totalWidth: 122,
    remainingWidth: 0,
    cells: [
      //   {},
      //   {},
      //   {},
      //   {},
      {},
      {
        isCellAvailable: true,
        name: 1,
        cellWidth: 24,
        productImage:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXd8aui-t9j4_VjMyQ4cye4IB5vEcVLi20y2TSpXLhmmKWqAsE8osA759wg2YXJ9jzIuY&usqp=CAU",
        productTitle: "Bisleri Spycy",
        productItemId: "264527",
        productCamera: [{ name: "1" }],
      },
      {
        isCellAvailable: true,
        name: 1,
        cellWidth: 24,
        productImage:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXd8aui-t9j4_VjMyQ4cye4IB5vEcVLi20y2TSpXLhmmKWqAsE8osA759wg2YXJ9jzIuY&usqp=CAU",
        productTitle: "Bisleri Spycy",
        productItemId: "264527",
        productCamera: [{ name: "1" }, { name: "2" }],
      },
    ],
  },
  {
    name: 2,
    totalWidth: 122,
    remainingWidth: 0,
    cells: [],
  },
]
