import * as React from "react"
import {
  Typography,
  Card,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Input,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CardActions,
  CardHeader,
  CardContent,
  Collapse,
} from "@material-ui/core"
import {
  CheckCircleIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
  Edit,
  Slash,
} from "@iconsGallery"
import { Formik } from "formik"
import styled from "styled-components"
import _ from "lodash"
import { useFacetUIState } from "./state"
import { useFacetUIStyles } from "./styles"
import { View } from "./View"
import * as Features from "@features"
import * as Adm from "@adm"
import * as Utils from "@utils"
import { formList } from "./fixture"

export const customRenderAccordionHeader = (props) => {
  const {
    theme,
    classes,
    edit,
    setEdit,
    collapseOpen,
    setCollapseOpen,
    expanded,
    isAnyChanges = false,
    setAnyChanges,
    isError = false,
    setError,
    initialCount = 0,
    setInitialCount,
    setAllowPrompt=()=>{},
    setExpanded,
    handleExpandClick,
    updateData,
    isEditMode = true,
    facetData,
    getValidationEdit,
    setValidationEdit,
    facetSetData,
    defaultList,
    reset,
    resetData,
    isWriteAllowed
  } = props || {}
  if (facetData) {
    const noValueArray =
      facetData.filter((item) => {
        return item.value === ""
      }) || []

    setValidationEdit(
      Utils.JSUtils.isEmpty(noValueArray) === false ? true : false
    )
  }
  
  return (
    <CardHeader
      className={classes.dividerBottom}
      title={`${props.label}`}
      action={
        <>
          {props.edit ? (
            <>
              {expanded === true && isEditMode === true && (
                <IconButton
                  onClick={() => {
                    setEdit(false)
                  }}
                  style={{ marginRight: "13px" }}
                  disabled={isWriteAllowed ? false : true}
                >
                  <Edit className={classes.basicIconProperty} />
                </IconButton>
              )}
            </>
          ) : (
            <>
              {expanded === true ? (
                <>
                  <IconButton
                      onClick={() => {
                      setEdit(true)
                      // if (isAnyChanges) {
                      //   reset()
                      // }
                      // setInitialCount(0)
                        setAnyChanges(false)
                        resetData()
                      // facetSetData()
                    }}
                    style={{
                      // pointerEvents: getValidationEdit ? "none" : "auto",
                      marginRight: "20px",
                    }}
                  >
                    <Slash
                        style={{ color: "red" }}
                        className={classes.basicIconProperty}
                    />
                  </IconButton>
                  <IconButton
                      style={{
                        marginRight: "18px",
                        pointerEvents: `${isAnyChanges ? "unset" : "none"}`,
                      }}
                      onClick={() => {
                        if (facetData[0].label === 'Customer Acquisition Source' && facetData[0].value === '-') {
                          setError(true)
                        } else {
                          updateData(props)
                           setEdit(true)
                          setAnyChanges(false)

                          // if (
                          //   getValidationEdit === false &&
                          //   isAnyChanges === true
                          // ) {
                          //   setEdit(true)
                          // }
                        }
                      }}
                  >
                    <CheckCircleIcon
                      style={{
                        color: getValidationEdit
                          ? "grey"
                          : isAnyChanges
                          ? "#4CAF50"
                          : "#ccc",
                      }}
                    />
                  </IconButton>
                </>
              ) : (
                ""
              )}
            </>
          )}
          
          {expanded === true ? (
            <IconButton onClick={() => {
              handleExpandClick();
              setEdit(true)
              if (isAnyChanges) {
                reset()
                resetData();
              }
 }}>
              <ExpandLessIcon className={classes.iconUpDown} />
            </IconButton>
          ) : (
              <IconButton onClick={() => {
                setEdit(true)
                if (isAnyChanges) {
                  reset()
                }
                 setInitialCount(0); setAnyChanges(false); handleExpandClick()
              }}>
              <ExpandMoreIcon className={classes.iconUpDown} />
            </IconButton>
          )}
        </>
      }
    ></CardHeader>
  )
}

export const customRenderContainer = (props) => {
  const { facetData,setAllowPrompt=()=>{}, } = props
  return Utils.JSUtils.isEmpty(facetData) ? (
    <Features.NoRecordFound />
  ) : (
    <View {...props}  setAllowPrompt={setAllowPrompt} />
  )
}
