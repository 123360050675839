import React, { useState } from "react"
import { Card } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import _ from "lodash"
import * as RenderProps from "./renderProps"

import {
  Loop as LoopIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons"
import { useStyles, StyledTabNumber } from "./styles"

function GridSearchList(props) {
  const {
    title = "",
    // rows = [],
    searchGridListTriggerApi = () => "",
    mainStateDashboard,
  } = props || {}
  const classes = useStyles()

  // const [rowItem, setRowItem] = React.useState([])
  // const [searchFieldValue, setSearchFieldValue] = React.useState()
  // React.useEffect(() => {
  //   if (!_.isEqual(rows, mainStateDashboard.rowItem)) {
  //     mainStateDashboard.setRowItem(rows)
  //   }
  // }, [rows])
  const capitalizeText = (text) => {
    return text.replace(/\b(\w)/g, (s) => s.toUpperCase())
  }
  // let rowItem = rows
  const handleListClick = (itm) => {
    let rowItemUpdate =
      mainStateDashboard.retailerList &&
      mainStateDashboard.retailerList.map((item) => {
        if (item.id === itm.id) {
          item.isActive = true
        } else {
          item.isActive = false
        }
        return item
      })
    const { id = "", address = "", title = "", status = "" } = itm
    const data = { tenantId: id, address, title, status }

    mainStateDashboard.setRetailerList(rowItemUpdate)
    let splitQS = mainStateDashboard.pageLocation?.search.split("&")

    mainStateDashboard.setLocalStorageValues({ value: data })
    mainStateDashboard.pageHistory.push(
      `${mainStateDashboard.pageLocation?.pathname}${splitQS[0]}&retailer=${itm.id}&subpage=${mainStateDashboard.subPageIndex}`
    )
  }

  const handleSearchField = (event) => {
    mainStateDashboard.setSearchFieldValue(event.target.value)
    if (event.target.value.length > 2) {
      handleSearchIconButton()
    } else if (event.target.value.length === 0) {
      searchGridListTriggerApi("")
    }
  }
  const handleSearchBluredField = (event) => {
    mainStateDashboard.setSearchFieldValue(event.target.value)
    searchGridListTriggerApi(event.target.value)
  }

  const handleSearchIconButton = () => {
    const value = mainStateDashboard.refFieldValue.current.querySelector(
      "input"
    ).value
    mainStateDashboard.setSearchFieldValue(value)
    searchGridListTriggerApi(value)
  }

  const handleClearSearch = () => {
    mainStateDashboard.setSearchFieldValue("")
    searchGridListTriggerApi("")
  }

  const leftSideBarProps = {
    title,
    mainStateDashboard,
    handleSearchField,
    handleSearchIconButton,
    handleSearchBluredField,
    // rowItem,
    handleListClick,
    capitalizeText,
    // searchFieldValue,
    // rows,
    handleClearSearch,
  }

  return (
    <div>
      <Card className={classes.cardStyles}>
        {RenderProps.RetailerLeftSideBar(leftSideBarProps)}
      </Card>
    </div>
  )
}

export default GridSearchList
export { GridSearchList }
