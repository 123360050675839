import * as React from "react"

function useFacetUIState(props) {
  const [edit, setEdit] = React.useState(true)
  const [data, setData] = React.useState([])
  const [getValidationEdit, setValidationEdit] = React.useState(false)
  return {
    edit,
    setEdit,
    data,
    setData,
    getValidationEdit,
    setValidationEdit,
  }
}
function useFacetUIViewState(props) {
  const { defaultData } = props || {}
  const [edit, setEdit] = React.useState(true)
  const [data, setData] = React.useState(defaultData)
  return {
    edit,
    setEdit,
    data,
    setData,
  }
}

export { useFacetUIState, useFacetUIViewState }
