import { makeStyles, useTheme } from "@material-ui/core/styles"
import { green, red } from "@material-ui/core/colors"
import { spacing } from "@material-ui/system"
import styled from "styled-components/macro"
import {
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Button,
  Grid,
} from "@material-ui/core"

export const StyledMainWrapperCardView = styled(Grid)`
  display: flex;
  flex-direction: row;
  padding: 8px;
  height: 90px;
  align-items: center;
  padding-left: 15px;
  border-radius: 5px;
  cursor: pointer;
  background: ${({ background }) => background};
  box-shadow: ${({ selected }) =>
    selected ? "0 4px 8px rgba(0.4, 0.4, 0.4, 0.4)" : ""};
  border: ${({ selected, background, iconCircleColor }) =>
    selected ? `2px solid ${iconCircleColor}` : `2px solid ${background}`};
  position: relative;
  &:hover {
    background-color: #f0f0f0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .cls-icon-left {
    width: 50px;
    height: 50px;
    background: pink;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
