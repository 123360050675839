export const renderSVG = (props) => {
  const { tierType = "Gold", tierLevel = "1" } = props || {}

  const config = {
    Platinum: {
      background: ["#7071FF", "#AA6BFF"],
      smallBackground: ["#FDFDFF", "#CEC7FF"],
      textColor: ["#A562FF", "#756FFF"],
    },
    Gold: {
      background: ["#FFDD41", "#FAB401"],
      smallBackground: ["#FFF34F", "#FFE64B"],
      textColor: ["#FDA713", "#FFBE1E"],
    },
    Silver: {
      background: ["#BFD6E8", "#87A5C6"],
      smallBackground: ["#EBF2F7", "#C8DBEB"],
      textColor: ["#768CA2", "#879FB6"],
    },
    Bronze: {
      background: ["#D6BB71", "#BE9354"],
      smallBackground: ["#E7D1B3", "#E0C49E"],
      textColor: ["#C39D63", "#BDA25F"],
    },
  }

  const mappingStyled = config[tierType]

  return (
    <svg width="70" height="70" viewBox="0 0 70 70" fill="none">
      <path
        d="M31 2.3094C33.4752 0.880338 36.5248 0.880339 39 2.3094L61.3109 15.1906C63.7861 16.6197 65.3109 19.2607 65.3109 22.1188V47.8812C65.3109 50.7393 63.7861 53.3803 61.3109 54.8094L39 67.6906C36.5248 69.1197 33.4752 69.1197 31 67.6906L8.68911 54.8094C6.2139 53.3803 4.68911 50.7393 4.68911 47.8812V22.1188C4.68911 19.2607 6.2139 16.6197 8.68911 15.1906L31 2.3094Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M30.9998 15.4913C33.475 14.0622 36.5246 14.0622 38.9998 15.4913L49.8949 21.7816C52.3701 23.2106 53.8949 25.8517 53.8949 28.7098V41.2904C53.8949 44.1485 52.3701 46.7895 49.8949 48.2186L38.9998 54.5088C36.5246 55.9379 33.475 55.9379 30.9998 54.5088L20.1047 48.2186C17.6295 46.7895 16.1047 44.1485 16.1047 41.2904V28.7098C16.1047 25.8517 17.6295 23.2106 20.1047 21.7816L30.9998 15.4913Z"
        fill="url(#paint1_linear)"
      />
      <text
        style={{ fontSize: "22px", fontFamily: "Roboto", fontWeight: "700" }}
        x="28"
        y="42"
        fill="url(#paint2_linear)"
      >
        {tierLevel}
      </text>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="35"
          y1="1.81818"
          x2="35"
          y2="68.4091"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={mappingStyled.background[0]} />
          <stop offset="1" stop-color={mappingStyled.background[1]} />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="34.9998"
          y1="14.5455"
          x2="34.9998"
          y2="55.4546"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={mappingStyled.smallBackground[0]} />
          <stop offset="1" stop-color={mappingStyled.smallBackground[1]} />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="34.7731"
          y1="24.0908"
          x2="34.7731"
          y2="46.3635"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={mappingStyled.textColor[0]} />
          <stop offset="1" stop-color={mappingStyled.textColor[1]} />
        </linearGradient>
      </defs>
    </svg>
  )
}
