import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Paper,
  Typography,
} from "@material-ui/core"
import _, { forEach } from "lodash"
import { useAddressCardUIState } from "./state"
import { useAddAddressContentStyles, CardWrapper } from "./styles"
import * as Features from "@features"
import * as Adm from "@adm"
import * as Utils from "@utils"
import { AddIcon, BorderColorOutlinedIcon } from "@iconsGallery"
import { customRenderContainer } from "./renderProps"
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

function AddressCardList(props) {
  const {
    addressList,
    setAddressList,
    countryList,
    stateList,
    isOpenMode = false,
    isEditMode = true,
    editMode = false,
    onUpdate = () => "",
    resetData = () => "",
    deleteAddress = () => "",
    changeDefaultStatus = () => "",
    editAddress,
    setAllowPrompt = () => "",
    isWriteAllowed = false,
    onChangeForm = () => { },
    ...remainingProps
  } = props

  const classes = useAddAddressContentStyles()
  const addressCardState = useAddressCardUIState()
  const { edit, setEdit, data, setData } = addressCardState

  const addNewCard = (value) => {
    value.default = addressList.length === 0 ? true : value.default
    setData([...data, value])
    setAddressList([...data, value])
  }

  React.useEffect(() => {
    setData(addressList || [])
  }, [addressList])

  const onUpdateData = async () => {
    onUpdate({ ...addressCardState })
  }

  return (
    <CardWrapper>
      <Adm.AccordianPanel
        isOpen={isOpenMode}
        isEditMode={isEditMode}
        isWriteAllowed={isWriteAllowed}
        renderContainer={(containerProps) =>
          customRenderContainer({
            ...containerProps,
            addressCardData: data,
            addressCardSetData: setData,
            addressList,
            addNewCard,
            setAllowPrompt,
            editAddress,
            changeDefaultStatus,
            classes,
            countryList,
            stateList,
            onChangeForm,
            deleteAddress,
          })
        }
        label={"Address"}
        updateData={onUpdateData}
        resetData={resetData}
        {...remainingProps}
      />
    </CardWrapper>
  )
}
export { AddressCardList }
export default AddressCardList
