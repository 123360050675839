import { makeStyles, useTheme } from "@material-ui/core/styles"
import { green, red } from "@material-ui/core/colors"
import { spacing } from "@material-ui/system"
import styled from "styled-components/macro"
import {
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Button,
  TextField,
} from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  cardStyles: {
    boxShadow: "0px 0px 14px rgba(53, 64, 82, 0.05)",
    background: "#FFFFFF",
    minHeight: "280px",
  },
  imageIcon: {
    height: "6%",
    width: "6%",
  },
}))

export const styledTextField = styled(TextField)`
  .MuiInput-underline:before {
    border-bottom: 0px;
  }
`
