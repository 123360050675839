import React from "react"
import IconButton from "@material-ui/core/IconButton"
import { EditIcon, DeleteIcon, SaveIcon, CancelIcon, VisibilityIcon } from "@iconsGallery"

function IconMenu(props) {
  console.log("IconMenu", props)
  const { isDisableViewIcon = false, isDisableSaveButton = false, actionEdit = true, actionDelete = true } = props || {}
  return (
    <div>
      {props.data.inEdit ? (
        <>
          <IconButton
            color="primary"
            aria-label="Save"
            component="span"
            onClick={(...e) => !props.data.saveBTNdisabled && props.save(e)}
            style={
              props.data.saveBTNdisabled
                ? {
                  color: "rgba(0, 0, 0, 0.5)",
                  cursor: "not-allowed",
                }
                : {}
            }
          >
            <SaveIcon />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="Cancel changes"
            component="span"
            onClick={props.cancel}
          >
            <CancelIcon />
          </IconButton>
          {!props.data.inDeleteIcon ? null :
            <IconButton
              color="primary"
              aria-label="Delete"
              component="span"
              onClick={props.delete}
            >
              <DeleteIcon />
            </IconButton>}
        </>
      ) : (
        <>
          {isDisableViewIcon === false && (
            <IconButton
              color="primary"
              aria-label="Visiblity"
              component="span"
              onClick={props.view}
            >
              <VisibilityIcon
                style={{ color: "#1565C0" }}
              />
            </IconButton>
          )}
          {
            actionEdit &&
            <IconButton
              color="primary"
              aria-label="Edit"
              component="span"
              onClick={props.in_edit}
              disabled={!props.isInlineEditAllowed || props?.data?.isDefault}

            >
              <EditIcon />
            </IconButton>
          }

          {
            actionDelete &&
            <IconButton
              color="primary"
              aria-label="Delete"
              component="span"
              onClick={props.delete}
              disabled={!props.isInlineEditAllowed || props?.data?.isDefault}

            >
              <DeleteIcon />
            </IconButton>
          }
        </>
      )}
    </div>
  )
}

export default IconMenu
