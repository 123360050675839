import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
  },
  header: {
    display: "flex",
    width: "100%",
    height: 48,
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
  },
  headerActionsContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(2.5),
  },
  content: {
    width: "100%",
    height: `calc(100% - 48px)`,
   
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
  },
}))

export default useStyles
