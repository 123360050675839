import React, { useEffect } from "react"
import UseDaysRadio from "../../components/UseDaysRadio/UseDaysRadio"
import Divider from "@material-ui/core/Divider"
import styled from "styled-components/macro"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import { Minus, Plus, CloseIcon } from "@iconsGallery"
import {
  Grid,
  Card,
  Typography as MuiTypography,
  IconButton,
  FormHelperText,
} from "@material-ui/core"
import { spacing } from "@material-ui/system"
import moment from "moment"
import _ from "lodash"

const Typography = styled(MuiTypography)(spacing)
const MainWrapper = styled(Grid)`
  max-width: 100%;
  flex-basis: 100%;

  form[class^="makeStyles-Box-"] {
    width: 100% !important;
  }
  .MuiInput-underline {
    &::after,
    &::before {
      border-bottom: none !important;
    }
    input {
      padding: 10px 10px;
    }
  }
`
const useStyles = makeStyles({
  textFeildStyles: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "15px",
    "@media(max-width:640px)": {
      display: "block",
    },
  },
  iconGridStyles: {
    textAlign: "center",
    border: "1px solid #209da30d",
    backgroundColor: "var(--lightColor)",
    cursor: "pointer",
    margin: "10px",
  },
  daysAndHoursStyles: {
    fontSize: "17px",
    paddingLeft: "10px",
    fontWeight: "500",
  },
  displayTextStyles: {
    fontSize: "17px",
    paddingLeft: "5px",
    fontWeight: "800",
  },
  displayTextRootGridStyles: { marginTop: "15px", marginBottom: "42px" },
  Box: {
    border: "1px solid  #9E9E9E",
    borderRadius: "5px",
    borderBottom: "1px solid #9E9E9E",
    width: "fit-content",
    paddingLeft: "3px",
  },
  rowWrap: {
    "@media(max-width:640px)": {
      width: "800px",
      overflow: "scroll",
    },
  },
  columnWidth: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: "7px",
    "@media(max-width:640px)": {
      columnWidth: "25px",
      display: "block",
    },
  },
  SetectDateTime: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
})

const fromAndToTimeChecker = ({
  fromTime = "00:00",
  toTime = "00:00",
  lowerLimit = "00:00",
  upperLimit = "00:00",
}) => {
  let fromTimeCheck = moment(fromTime, "hh:mm").isBetween(
    moment(lowerLimit, "hh:mm").subtract(1, "s"),
    moment(upperLimit, "hh:mm").add(1, "s")
  )
  let toTimeCheck = moment(toTime, "hh:mm").isBetween(
    moment(lowerLimit, "hh:mm").subtract(1, "s"),
    moment(upperLimit, "hh:mm").add(1, "s")
  )
  let fromTimeCheckReverse = moment(lowerLimit, "hh:mm").isBetween(
    moment(fromTime, "hh:mm").subtract(1, "s"),
    moment(toTime, "hh:mm").add(1, "s")
  )
  let toTimeCheckReverse = moment(upperLimit, "hh:mm").isBetween(
    moment(fromTime, "hh:mm").subtract(1, "s"),
    moment(toTime, "hh:mm").add(1, "s")
  )
  return [fromTimeCheck, toTimeCheck, fromTimeCheckReverse, toTimeCheckReverse]
}

const validateTime = ({
  timeArrState: timeArrStateFromProps = [],
  daysArrState: daysArrStateFromProps = [],
  daysLimitsArray = [],
  timeLimitsArray = [],
  rowIdx = -1,
  newVal,
  timeCheckKey = "between",
  setDaysError,
  isTimeLimitCheck = false,
}) => {
  let timeArrState = isTimeLimitCheck ? timeLimitsArray : timeArrStateFromProps
  let daysArrState = isTimeLimitCheck ? daysLimitsArray : daysArrStateFromProps
  return timeArrState.reduce((acc, c, i) => {
    if (
      i === rowIdx &&
      _.isEmpty(daysLimitsArray) &&
      _.isEmpty(timeLimitsArray)
    ) {
      acc.push(_.last(acc))
      return acc
    }

    if (!daysArrState[rowIdx].some((x) => x.selected === true)) {
      let errorArray = _.isArray(daysArrState[rowIdx].error)
        ? daysArrState[rowIdx].error
        : []
      errorArray[i] = "Please select atleast a day"
      let temp = _.merge(daysArrState[rowIdx], { error: errorArray })
      daysArrState[rowIdx] = temp
      acc.push(false)
      setDaysError(daysArrState)
      return acc
    }
    let filteredDaysArray = _.cloneDeep(daysArrState)
    let selectedDaysArray = isTimeLimitCheck
      ? (() => {
          let deepClonedDaysArrStateFromProps = _.cloneDeep(
            daysArrStateFromProps
          )
          let tempSelectedDaysArray = _.pullAt(
            deepClonedDaysArrStateFromProps,
            rowIdx
          )
          return tempSelectedDaysArray
        })()
      : _.pullAt(filteredDaysArray, rowIdx)

    let filteredDaysArrayOnlySelected = filteredDaysArray.reduce(
      (mainAcc, o, idx) => {
        let tempSelected = o.reduce((subAcc, subO) => {
          if (subO.selected) {
            subAcc.push(subO.day)
          }
          return subAcc
        }, [])

        let filteredMainAcc = _.uniq(_.concat(mainAcc, tempSelected))
        return filteredMainAcc
      },
      []
    )

    let selectedDaysArrayOnlySelected =
      _.isArray(selectedDaysArray) &&
      selectedDaysArray?.[0].reduce((acc, o, idx) => {
        if (o.selected) {
          acc.push(o.day)
        }
        return acc
      }, [])

    let isSameDaySelected = !_.isEmpty(
      _.intersection(
        filteredDaysArrayOnlySelected,
        selectedDaysArrayOnlySelected
      )
    )
    let onlySelectedDays = (_.isArray(daysArrState[i])
      ? daysArrState[i]
      : []
    ).reduce((acc, o) => {
      if (o.selected) {
        acc.push(o.day)
      }
      return acc
    }, [])
    let isCurrentIdxHaveSelectedDay = !_.isEmpty(
      _.intersection(onlySelectedDays, selectedDaysArrayOnlySelected)
    )
    let isAcceptableTimeEntry = false
    let isAcceptableReverseTimeEntry = false
    let fromTimeCheck = false
    let toTimeCheck = false
    let fromTimeCheckReverse = false
    let toTimeCheckReverse = false
    let isTimeLimitCheckNeeded =
      !_.isEmpty(daysLimitsArray) &&
      !_.isEmpty(timeLimitsArray) &&
      !isTimeLimitCheck
    let timeLimitCheck = false
    let asku = () => {
      let temp = _.reduce(
        daysArrState,
        (idxAcc, o, tempIdx) => {
          let tempSelectedArray = o.reduce((arrayAcc, x) => {
            if (
              x.selected &&
              _.includes(selectedDaysArrayOnlySelected, x.day) &&
              tempIdx < i &&
              acc[
                tempIdx
              ] /* && _.findIndex(_.get(o, 'error', []), y => !_.isEmpty(y))===-1 */
            ) {
              arrayAcc.push(x.day)
            }
            return arrayAcc
          }, [])
          if (!_.isEmpty(tempSelectedArray)) {
            idxAcc.push({ idx: tempIdx, obj: o })
          }
          return idxAcc
        },
        []
      )
      return temp
    }
    let alreadyPassedForTheday = !_.isEmpty(asku())
    if (timeCheckKey === "between") {
      let timeCheck = fromAndToTimeChecker({
        fromTime: isTimeLimitCheck
          ? timeArrStateFromProps[rowIdx].time_from
          : timeArrState[rowIdx].time_from,
        toTime: isTimeLimitCheck
          ? _.get(timeArrStateFromProps, `[${rowIdx}].time_to`)
          : _.get(timeArrState, `[${rowIdx}].time_to`),
        lowerLimit: timeArrState[i].time_from,
        upperLimit: timeArrState[i].time_to,
      })
      fromTimeCheck = timeCheck[0]
      toTimeCheck = timeCheck[1]
      fromTimeCheckReverse = timeCheck[2]
      toTimeCheckReverse = timeCheck[3]
    } else if (timeCheckKey === "from") {
      let timeCheck = fromAndToTimeChecker({
        fromTime: newVal,
        toTime: isTimeLimitCheck
          ? _.get(timeArrStateFromProps, `[${rowIdx}].time_to`)
          : _.get(timeArrState, `[${rowIdx}].time_to`),
        lowerLimit: timeArrState[i].time_from,
        upperLimit: timeArrState[i].time_to,
      })
      fromTimeCheck = timeCheck[0]
      toTimeCheck = timeCheck[1]
      fromTimeCheckReverse = timeCheck[2]
      toTimeCheckReverse = timeCheck[3]
    } else if (timeCheckKey === "to") {
      let timeCheck = fromAndToTimeChecker({
        fromTime: isTimeLimitCheck
          ? _.get(timeArrStateFromProps, `[${rowIdx}].time_from`)
          : _.get(timeArrState, `[${rowIdx}].time_from`),
        toTime: newVal,
        lowerLimit: timeArrState[i].time_from,
        upperLimit: timeArrState[i].time_to,
      })
      fromTimeCheck = timeCheck[0]
      toTimeCheck = timeCheck[1]
      fromTimeCheckReverse = timeCheck[2]
      toTimeCheckReverse = timeCheck[3]
    }

    if (!fromTimeCheck && !toTimeCheck && !isTimeLimitCheck) {
      isAcceptableTimeEntry = true
    } else {
      isAcceptableTimeEntry = false
    }

    if (!fromTimeCheckReverse && !toTimeCheckReverse && !isTimeLimitCheck) {
      isAcceptableReverseTimeEntry = true
    } else {
      isAcceptableReverseTimeEntry = false
    }

    if (isTimeLimitCheck && fromTimeCheck && toTimeCheck) {
      isAcceptableTimeEntry = true
      isAcceptableReverseTimeEntry = true
    }
    if (isTimeLimitCheckNeeded) {
      timeLimitCheck = _.last(
        validateTime({
          timeArrState: timeArrStateFromProps,
          daysArrState: daysArrStateFromProps,
          daysLimitsArray,
          timeLimitsArray,
          rowIdx,
          newVal,
          timeCheckKey,
          setDaysError,
          isTimeLimitCheck: isTimeLimitCheckNeeded,
        })
      )
    }
    if (
      !isAcceptableTimeEntry &&
      isSameDaySelected &&
      isCurrentIdxHaveSelectedDay &&
      !isTimeLimitCheck
    ) {
      let errorArray = _.isArray(daysArrState[rowIdx].error)
        ? daysArrState[rowIdx].error
        : []
      errorArray[i] = "Selected Date and Time is already Choosen"
      let temp = _.merge(daysArrState[rowIdx], { error: errorArray })
      daysArrState[rowIdx] = temp
      acc.push(false)
      setDaysError(daysArrState)
      return acc
    } else if (
      !isAcceptableReverseTimeEntry &&
      isSameDaySelected &&
      isCurrentIdxHaveSelectedDay &&
      !isTimeLimitCheck
    ) {
      let errorArray = _.isArray(daysArrState[rowIdx].error)
        ? daysArrState[rowIdx].error
        : []
      errorArray[i] =
        "Already Selected Date and Time falls between this chosen timeline!!!"
      let temp = _.merge(daysArrState[rowIdx], { error: errorArray })
      daysArrState[rowIdx] = temp
      acc.push(false)
      setDaysError(daysArrState)
      return acc
    } else if (
      isTimeLimitCheck &&
      ((!isAcceptableTimeEntry &&
        isCurrentIdxHaveSelectedDay &&
        !alreadyPassedForTheday) ||
        !isSameDaySelected)
    ) {
      let tempDaysArrState = _.cloneDeep(daysArrStateFromProps)
      let errorArray = _.isArray(tempDaysArrState[rowIdx].error)
        ? tempDaysArrState[rowIdx].error
        : []
      errorArray[i] = "Timeline must be within the limits of Operating Hours!!"
      let temp = _.merge(tempDaysArrState[rowIdx], { error: errorArray })
      tempDaysArrState[rowIdx] = temp
      acc.push(false)
      setDaysError(_.cloneDeep(tempDaysArrState))
      return acc
    } else if (
      isTimeLimitCheckNeeded &&
      !timeLimitCheck &&
      selectedDaysArrayOnlySelected.length > 0
    ) {
      let tempDaysArrState = _.cloneDeep(daysArrStateFromProps)
      let errorArray = _.isArray(tempDaysArrState[rowIdx].error)
        ? tempDaysArrState[rowIdx].error
        : []
      errorArray[i] = "Timeline must be within the limits of Operating Hours"
      let temp = _.merge(tempDaysArrState[rowIdx], { error: errorArray })
      tempDaysArrState[rowIdx] = temp
      acc.push(false)
      setDaysError(tempDaysArrState)
      return acc
    } else if (
      (!isCurrentIdxHaveSelectedDay ||
        (isCurrentIdxHaveSelectedDay &&
          !alreadyPassedForTheday &&
          selectedDaysArrayOnlySelected.length > 1)) &&
      isTimeLimitCheck
    ) {
      acc.push(_.last(acc))
      return acc
    } else {
      let errorArray = _.isArray(
        _.get(daysArrState, `[${isTimeLimitCheck ? i : rowIdx}].error`)
      )
        ? _.get(daysArrState, `[${isTimeLimitCheck ? i : rowIdx}].error`)
        : []
      errorArray[i] = ""
      let temp = _.merge(daysArrState[isTimeLimitCheck ? i : rowIdx], {
        error: errorArray,
      })
      daysArrState[isTimeLimitCheck ? i : rowIdx] = temp
      acc.push(true)
      setDaysError(daysArrState)
      return acc
    }
  }, [])
}

const CommonComponent = ({
  daysArrState,
  timeArrState,
  timeLimitsArray = [],
  clickHandler,
  textChangeHandler,
  timeArrErr = [],
  setUpdate,
  setTimeArrErr,
  addHandler,
  displayText,
  closeHandler,
  editMode,
  setDaysArrErr,
  disabledDays = [],
  disableDateSelection = false,
  isAllOkayRef = { current: true },
  isTimeMandatory = false,
  markAsRequired = false,
  errors = {}
}) => {
  const { businessHrs = {} } = errors;
  // const { days = "" } = businessHrs;
  // console.log("OperatingHours11",businessHrs.days)
  const classes = useStyles()

  useEffect(() => {
    timeArrErr.includes(true)
      ? setUpdate(false)
      : setUpdate(true && daysArrState.length > 0 && timeArrState?.length > 0)
  }, [timeArrErr, daysArrState, timeArrState])

  return (
    <MainWrapper item lg={12} md={12} sm={12} xs={12}>
      <Grid>
        <Card
          className="cls-comman-comp-oh"
          style={{ width: "100%", borderRadius: "8px" }}
        >
          <Grid
            container
            direction="row"
            alignContent="center"
            alignItems="center"
            style={{ justifyContent: "space-between" }}
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.displayTextRootGridStyles}
          >
            <Grid item className={classes.displayTextStyles}>
              {displayText}
              {markAsRequired && (
                <Typography
                  style={{
                    display: "inline",
                    color: "red",
                    fontSize: "16px",
                    paddingBottom: "5px",
                  }}
                >
                  {" "}
                  *{" "}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignContent="center"
            alignItems="center"
            style={{
              paddingLeft: "10px",
              marginBottom: "4px",
              justifyContent: "space-between",
            }}
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <Grid
              item
              className={classes.daysAndHoursStyles}
              lg={6}
              md={6}
              sm={6}
              xs={6}
            >
              Days
            </Grid>
            <Grid
              item
              className={classes.daysAndHoursStyles}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              style={{ paddingLeft: "25px" }}
            >
              Hours
            </Grid>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.rowWrap}
          >

            <Divider style={{ width: "100%" }} />
            
            {daysArrState.map((val, ind) => (
              <Grid
                key={ind}
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                container
                direction="row"
                alignContent="center"
                alignItems="center"
                style={{
                  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                  paddingBottom: "4px",
                  alignItems: "start",
                  justifyContent: "space-between",
                }}
              >
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  {editMode ? (
                    <UseDaysRadio
                      disableDateSelection={disableDateSelection}
                      daysArr={val}
                      disabledDays={disabledDays}
                      clickHandler={(index) => clickHandler(ind, index)}
                      className={classes.columnWidth}
                    />
                  ) : (
                    <UseDaysRadio
                      disableDateSelection={disableDateSelection}
                      daysArr={val}
                      disabledDays={disabledDays}
                      clickHandler={() => {}}
                      className={classes.columnWidth}
                    />
                  )}
                  {_.isArray(daysArrState[ind]?.error) &&
                  daysArrState[ind]?.error?.filter((o) => !_.isEmpty(o)) ? (
                    <FormHelperText style={{ color: "red", padding: "0px" }}>
                      {(() => {
                        let t =
                          daysArrState[ind]?.error[
                            _.findIndex(
                              daysArrState[ind]?.error,
                              (o) => !_.isEmpty(o)
                            )
                          ]
                        if (_.isEmpty(t)) {
                          isAllOkayRef.current = true
                          return null
                        } else {
                          isAllOkayRef.current = false
                          return t
                        }
                      })()}
                    </FormHelperText>
                  ) : (
                    (() => {
                      if (isAllOkayRef.current || ind === 0) {
                        isAllOkayRef.current = true
                      }
                      return null
                    })()
                  )}
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={6}
                  xs={6}
                  className={classes.textFeildStyles}
                  style={{ marginTop: editMode ? "" : "10px" }}
                >
                  <Grid
                    container
                    direction="row"
                    item
                    lg={12}
                    md={6}
                    sm={8}
                    xs={6}
                  >
                    {editMode ? (
                      <>
                        <form noValidate className={classes.Box}>
                          <TextField
                            id="time"
                            type="time"
                            className={classes.SetectDateTime}
                            error={timeArrErr[ind]}
                            value={_.get(
                              timeArrState,
                              `[${ind}].time_from`,
                              ""
                            )}
                            onChange={(e) => {
                              let newVal = e.target.value
                              let newTimeArrErr = [...timeArrErr]
                              textChangeHandler(newVal, "time_from", ind)
                              if (newVal === "") {
                                textChangeHandler(newVal, "time_to", ind)
                                if (!_.isEmpty(timeLimitsArray)) {
                                  newTimeArrErr[ind] = false
                                }
                                if (isTimeMandatory) {
                                  newTimeArrErr[ind] = true
                                }
                              } else {
                                if (_.isEmpty(timeLimitsArray)) {
                                  isAllOkayRef.current = true
                                }
                                let isValidTime = _.isEmpty(timeLimitsArray)
                                  ? _.last(
                                      validateTime({
                                        daysArrState,
                                        timeArrState,
                                        rowIdx: ind,
                                        newVal,
                                        timeCheckKey: "from",
                                        setDaysError: setDaysArrErr,
                                      })
                                    )
                                  : (() => {
                                      let t = fromAndToTimeChecker({
                                        fromTime: newVal,
                                        toTime: _.get(
                                          timeArrState,
                                          `[${ind}].time_to`
                                        ),
                                        lowerLimit: _.get(
                                          timeLimitsArray,
                                          `[${ind}].time_from`
                                        ),
                                        upperLimit: _.get(
                                          timeLimitsArray,
                                          `[${ind}].time_to`
                                        ),
                                      })
                                      return t[0] && t[1]
                                    })()
                                if (!isValidTime && timeArrState.length > 1) {
                                  newTimeArrErr[ind] = true
                                } else if (
                                  !_.isEmpty(timeLimitsArray) &&
                                  (newVal <
                                    _.get(
                                      timeLimitsArray,
                                      `[${ind}].time_from`
                                    ) ||
                                    newVal >
                                      _.get(
                                        timeLimitsArray,
                                        `[${ind}].time_to`
                                      )) &&
                                  (_.get(timeArrState, `[${ind}].time_to`) <
                                    _.get(
                                      timeLimitsArray,
                                      `[${ind}].time_from`
                                    ) ||
                                    _.get(timeArrState, `[${ind}].time_to`) >
                                      _.get(
                                        timeLimitsArray,
                                        `[${ind}].time_to`
                                      ))
                                ) {
                                  newTimeArrErr[ind] = true
                                } else if (
                                  newVal >
                                  _.get(timeArrState, `[${ind}].time_to`)
                                ) {
                                  newTimeArrErr[ind] = true
                                } else {
                                  newTimeArrErr[ind] = false
                                }
                              }
                              setTimeArrErr(newTimeArrErr)
                            }}
                          />
                        </form>
                        {timeArrErr[ind] ? (
                          <FormHelperText style={{ color: "red" }}>
                            {_.isEmpty(
                              _.get(timeArrState, `[${ind}].time_from`)
                            )
                              ? isTimeMandatory
                                ? "Enter Opening Time"
                                : _.isEmpty(
                                    _.get(timeArrState, `[${ind}].time_to`)
                                  )
                                ? null
                                : "Opening Time cannot be empty"
                              : _.isEmpty(
                                  _.get(timeArrState, `[${ind}].time_to`)
                                )
                              ? null
                              : `* Opening time should be before Closing time ${
                                  !_.isEmpty(timeLimitsArray)
                                    ? ` and must be within limits ${_.get(
                                        timeLimitsArray,
                                        `[${ind}].time_from`,
                                        "--:--"
                                      )} - ${_.get(
                                        timeLimitsArray,
                                        `[${ind}].time_to`,
                                        "--:--"
                                      )}`
                                    : ""
                                }`}
                          </FormHelperText>
                        ) : null}
                      </>
                    ) : (
                      _.get(timeArrState, `[${ind}].time_from`, "--:--")
                    )}
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    style={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "center",
                    }}
                    item
                    lg={12}
                    md={6}
                    sm={6}
                    xs={6}
                  >
                    <Minus
                      color="var(--primaryColor)"
                      style={{ paddingLeft: "7px", marginTop: "10px" }}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    lg={12}
                    md={6}
                    sm={6}
                    xs={6}
                  >
                    {editMode ? (
                      <>
                        <form noValidate className={classes.Box}>
                          <TextField
                            id="time"
                            type="time"
                            value={_.get(timeArrState, `[${ind}].time_to`, "")}
                            error={timeArrErr[ind]}
                            disabled={_.isEmpty(
                              _.get(timeArrState, `[${ind}].time_from`, "")
                            )}
                            onChange={(e) => {
                              let newVal = e.target.value
                              let newTimeArrErr = [...timeArrErr]
                              textChangeHandler(newVal, "time_to", ind)
                              if (newVal === "") {
                                textChangeHandler(newVal, "time_from", ind)
                                if (!_.isEmpty(timeLimitsArray)) {
                                  newTimeArrErr[ind] = false
                                }
                                if (isTimeMandatory) {
                                  newTimeArrErr[ind] = true
                                }
                              } else {
                                if (_.isEmpty(timeLimitsArray)) {
                                  isAllOkayRef.current = true
                                }
                                let isValidTime = _.isEmpty(timeLimitsArray)
                                  ? _.last(
                                      validateTime({
                                        daysArrState,
                                        timeArrState,
                                        rowIdx: ind,
                                        newVal,
                                        timeCheckKey: "to",
                                        setDaysError: setDaysArrErr,
                                      })
                                    )
                                  : (() => {
                                      let t = fromAndToTimeChecker({
                                        fromTime: _.get(
                                          timeArrState,
                                          `[${ind}].time_from`
                                        ),
                                        toTime: newVal,
                                        lowerLimit: _.get(
                                          timeLimitsArray,
                                          `[${ind}].time_from`
                                        ),
                                        upperLimit: _.get(
                                          timeLimitsArray,
                                          `[${ind}].time_to`
                                        ),
                                      })
                                      return t[0] && t[1]
                                    })()

                                if (!isValidTime && timeArrState.length > 1) {
                                  newTimeArrErr[ind] = true
                                } else if (
                                  !_.isEmpty(timeLimitsArray) &&
                                  (newVal <
                                    _.get(
                                      timeLimitsArray,
                                      `[${ind}].time_from`
                                    ) ||
                                    newVal >
                                      _.get(
                                        timeLimitsArray,
                                        `[${ind}].time_to`
                                      )) &&
                                  (_.get(timeArrState, `[${ind}].time_from`) <
                                    _.get(
                                      timeLimitsArray,
                                      `[${ind}].time_from`
                                    ) ||
                                    _.get(timeArrState, `[${ind}].time_from`) >
                                      _.get(
                                        timeLimitsArray,
                                        `[${ind}].time_to`
                                      ))
                                ) {
                                  newTimeArrErr[ind] = true
                                } else if (
                                  newVal < timeArrState[ind].time_from
                                ) {
                                  newTimeArrErr[ind] = true
                                  setUpdate(!newTimeArrErr.includes(true))
                                } else {
                                  newTimeArrErr[ind] = false
                                  setUpdate(!newTimeArrErr.includes(true))
                                }
                              }
                              setTimeArrErr(newTimeArrErr)
                            }}
                          />
                        </form>
                        {timeArrErr[ind] ? (
                          <FormHelperText
                            style={{ color: "red", padding: "0px" }}
                          >
                            {_.isEmpty(_.get(timeArrState, `[${ind}].time_to`))
                              ? isTimeMandatory
                                ? "Enter Closing Time"
                                : _.isEmpty(
                                    _.get(timeArrState, `[${ind}].time_from`)
                                  )
                                ? null
                                : "Closing Time cannot be empty"
                              : `* Closing time should be after Opening time ${
                                  !_.isEmpty(timeLimitsArray)
                                    ? ` and must be within limits ${_.get(
                                        timeLimitsArray,
                                        `[${ind}].time_from`,
                                        "--:--"
                                      )} - ${_.get(
                                        timeLimitsArray,
                                        `[${ind}].time_to`,
                                        "--:--"
                                      )}`
                                    : ""
                                }`}
                          </FormHelperText>
                        ) : null}
                      </>
                    ) : (
                      _.get(timeArrState, `[${ind}].time_to`, "--:--")
                    )}
                  </Grid>
                  
                  {editMode && !disableDateSelection ? (
                    <Grid container direction="row">
                      {ind >= 1 ? (
                        <IconButton
                          onClick={() => {
                            closeHandler(ind)
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </Grid>
                  ) : (
                    <Grid container direction="row"></Grid>
                  )}
                </Grid>
                <></>
              </Grid>
            ))}
          </Grid>
          {
            (businessHrs && businessHrs !== undefined) && (
              <FormHelperText style={{ color: "red", padding: "0px" }}>{
                typeof businessHrs === 'object' ? businessHrs.days : businessHrs
              }</FormHelperText>
            )
          }
          {editMode && !disableDateSelection && (
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid
                item
                className={classes.iconGridStyles}
                onClick={() => addHandler(daysArrState.length - 1)}
              >
                <IconButton
                  style={{
                    border: "1px solid #fff",
                    padding: "2px",
                    margin: "9px",
                  }}
                >
                  <Plus style={{ color: "#fff" }} />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
    </MainWrapper>
  )
}
export { CommonComponent, validateTime, fromAndToTimeChecker }
export default CommonComponent
