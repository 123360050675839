import { all } from "redux-saga/effects"
import adminSaga from "./admin/sagas";
import productSagas from '../services/masterData/products/sagas';
import locationSagas from '../services/masterData/locations/sagas'
export default function* rootSaga() {
  yield all([
    adminSaga(),
    ...productSagas,
    ...locationSagas
  ])
}
