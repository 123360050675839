import { commonFormUIYupSchema } from "./yupSchema"

export const transformValidationSchema = (list) => {
  let obj = {}
  if (list.length > 0) {
    for (let i = 0; i < list.length; i++) {
      const identifer = list[i].name
      const validationSchema = list[i].validationSchema
      obj[identifer] = validationSchema
    }
  }
  return obj ? { validationSchema: commonFormUIYupSchema(obj) } : {}
}
export const transformIntialValues = (list) => {
  let obj = {}
  if (list.length > 0) {
    for (let i = 0; i < list.length; i++) {
      const identifer = list[i].name
      const values = ""
      obj[identifer] = values
    }
  }

  return obj
    ? {
        initialValues: obj,
      }
    : {}
}
