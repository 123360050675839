import { ReduxConstants } from '@constants';
import _ from 'lodash';
import update from 'immutability-helper';

const { LOC_ACTION_TYPES } = ReduxConstants.REDUX_CONSTANTS;

const initState = {
    categories: {
        isLoading: false,
        error: '',
        list: []
    },
}

// const locations = (state = initState, action) => {
//     switch (action.type) {
//         case LOC_ACTION_TYPES.FETCH_LOC_HIERARCHY_PENDING: {
//             let tempState = update(state, { $merge: { isLoading: true, error: '' } } );
//             return tempState;
//         }
//         case LOC_ACTION_TYPES.FETCH_LOC_HIERARCHY_FULFILLED: {
//             let tempState = update(state,{ $merge: { isLoading: false, error: '', list: action.payload } } );
//             return tempState;
//         }
//         case LOC_ACTION_TYPES.FETCH_LOC_HIERARCHY_REJECTED: {
//             let tempState = update(state,{ $merge: { isLoading: false, error: action.payload } } );
//             return tempState;
//         }
//         default: {
//             return state
//         }
//     }
// }


const locations = (state = initState, action) => {
    switch (action.type) {
        case LOC_ACTION_TYPES.FETCH_LOC_HIERARCHY_PENDING: {
            let tempState = update(state, { categories: { $merge: { isLoading: true, error: '' } } });
            return tempState;
        }
        case LOC_ACTION_TYPES.FETCH_LOC_HIERARCHY_FULFILLED: {
            let tempState = update(state, { categories: { $merge: { isLoading: false, error: '', list: action.payload } } });
            return tempState;
        }
        case LOC_ACTION_TYPES.FETCH_LOC_HIERARCHY_REJECTED: {
            let tempState = update(state, { categories: { $merge: { isLoading: false, error: action.payload } } });
            return tempState;
        }
        default: {
            return state
        }
    }
}

export default locations;

