import * as React from "react"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Chip,
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Switch,
  Checkbox,
} from "@material-ui/core"
import { CloseIcon } from "@iconsGallery"
import { Formik } from "formik"
import * as Yup from "yup"
import { Edit, Slash } from "@iconsGallery"
import _ from "lodash"
import { FormCommonFormUI } from "../form"
import { formInput } from "../fixture"
import { useCommonFormUIContentStyles } from "../styles"
import { useCommonFormUIViewState } from "../state"
import * as Utils from "@utils"
import styled from "styled-components"
import { he } from "date-fns/locale"

const ListWrapper = styled(List)`
  &.list-split {
    display: flex;
    flex-direction: initial;
    flex-wrap: wrap;
  }
`
const ListItemWrapper = styled(ListItem)`
  &.list-split-sub {
    width: 50%;
  }
`

const DynamicListItemKit = (props) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    values,
    list,
    doDataUpdate,
    listColumnSplit,
    commonFormEdit,
  } = props
  const classes = useCommonFormUIContentStyles()

  const updateData = (item) => {
    var tempObj = item.data
    tempObj.value = item.value
    doDataUpdate(tempObj)
  }

  function isOdd(num) {
    return num % 2
  }

  const renderInputField = ({ header, updateData }) => {
    switch (header.type) {
      case "number":
        return (
          <>
            <TextField
              type="number"
              name={header.name}
              placeholder={header.title}
              value={header.value}
              error={Boolean(touched[header.name] && errors[header.name])}
              helperText={touched[header.name] && errors[header.name]}
              onBlur={handleBlur}
              onChange={(event) => {
                updateData({ value: event.target.value, data: header })
              }}
              variant="outlined"
              size="small"
              className={classes.textFiled1}
            />
          </>
        )
      case "text":
        return (
          <>
            <TextField
              type="text"
              name={header.name}
              placeholder={header.title}
              value={header.value}
              error={Boolean(touched[header.name] && errors[header.name])}
              // error={Boolean(true)}
              helperText={true}
              onBlur={handleBlur}
              onChange={(event) => {
                updateData({ value: event.target.value, data: header })
              }}
              variant="outlined"
              size="small"
              className={classes.textFiled1}
            />
          </>
        )
      case "checkBox":
        return (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
          >
            <Checkbox
              checked={header.value}
              onChange={() => {
                updateData({ value: !header.value, data: header })
              }}
              name="checkedB"
              color="primary"
              style={{ paddingLeft: "0px" }}
            />
            <Typography variant="body1" style={{ marginRight: "30px" }}>
              {header.checkBoxLabel}
            </Typography>
          </Grid>
        )
      default:
        return header.value
    }
  }

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <ListWrapper className={"list-split"}>
        {!Utils.JSUtils.isEmpty(list) &&
          list.map((header, index) => (
            <ListItemWrapper
              key={`ListKeyKit-${index}`}
              className={`${classes.listItem} ${header.inputType === "checkBox" ? "list-split-sub" : ""
                }`}
              listColumnSplit={listColumnSplit}
              style={{
                paddingTop: "0px !important",
                paddingBottom: "0px !important",
              }}
            >
              {header.inputType === "checkBox" ? null : (
                <ListItem>
                  <Typography style={{ fontSize: "14px", fontWeight: "500" }}>
                    {header.title}
                  </Typography>
                </ListItem>
              )}
              <ListItem>{renderInputField({ header, updateData })}</ListItem>
            </ListItemWrapper>
          ))}
      </ListWrapper>
    </div>
  )
}
export { DynamicListItemKit }
export default DynamicListItemKit
