import * as React from "react"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Chip,
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Switch,
  Checkbox,
  Tooltip,
} from "@material-ui/core"
import { CloseIcon, Edit, Slash, InfoMaterial } from "@iconsGallery"
import _ from "lodash"
import { useFacetUIContentStyles } from "../styles"
import { useFacetUIViewState } from "../state"
import * as Utils from "@utils"
import styled from "styled-components"
import { he } from "date-fns/locale"
import { ListWrapper, ListItemWrapper } from "./styles"
import { defaultRenderInputField, defaultRenderText } from "./renderProps"

const OptionaTypography = styled(Typography)`
  font-size: 12px;
  padding-left: 8px;
  color: #676767;
`

const FormUI = (props) => {
  const {
    list,
    listColumnSplit,
    facetEdit,
    doDataUpdate,
    renderInputField = defaultRenderInputField,
    renderText = defaultRenderText,
    headingTitle,
    isError,
    setAllowPrompt=()=>{},
    isAnyChanges,
  } = props
  const [getTextField, setTextField] = React.useState({})

  const classes = useFacetUIContentStyles()
  const updateData = (item) => {
    let obj = item.data
    obj.value = item.value
    obj.tabTypevalue = item.tabTypevalue
    doDataUpdate(obj)
  }

  function isOdd(num) {
    return num % 2
  }

  function isEven(num) {
    if (num % 2 === 0) {
      return 1
    } else {
      return 0
    }
  }
  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <ListWrapper
        className={`base-ul-itemwrapper-kit cls-${headingTitle
          .toLocaleLowerCase()
          .replaceAll("&", "")
          .replaceAll(",", "")
          .replaceAll(" ", "")}-kit ${
          listColumnSplit === true ? "list-split" : ""
        }`}
        style={{ paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px" }}
      >
        {!Utils.JSUtils.isEmpty(list) &&
          list?.map((item, index) => {
            const { identifer = "test", infoMessage = "" } = item || {}
            return (
              <ListItemWrapper
                key={`ListKeyKit-${index}`}
                className={`base-itemwrapper-kit cls-${identifer.toLocaleLowerCase()} ${
                  classes.listItem
                } ${listColumnSplit === true ? "list-split-sub" : ""}`}
                listColumnSplit={listColumnSplit}
                style={{
                  paddingTop: "0px !important",

                  paddingBottom: "0px !important",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  borderBottom:
                    listColumnSplit && index === list.length - 2
                      ? isOdd(index) + " solid rgba(0, 0, 0, 0.1)"
                      : "0px",
                  borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                  borderLeft: "0px",
                  borderRight: listColumnSplit
                    ? isEven(index) + " solid rgba(0, 0, 0, 0.1)"
                    : "0px",
                }}
              >
                <Typography style={{ minWidth: "250px", padding: "5px",paddingRight: "30px",fontWeight:'bold' }}>
                  <div style={{display:"flex"}}>{item.label}{facetEdit===false&&item.isOptional===true?(<OptionaTypography>(Optional)</OptionaTypography>):("")}
                  {infoMessage?.length > 0 && (
                      <Tooltip title={infoMessage}>
                      <IconButton style={{paddingTop:"0px",paddingBottom:"10px"}}>
                        <InfoMaterial />
                      </IconButton>
                    </Tooltip>
                    )}
                    </div>
                </Typography>

                {facetEdit === false
                  ? renderInputField({
                      item,
                      updateData,
                      getTextField,
                    setTextField,
                    setAllowPrompt,
                    isError,
                    isAnyChanges,
                    value: _.get(props, 'value', '')
                    })
                  : renderText(item)}
                {/* <ListItem style={{ backgroundColor: 'red' }}>
                  {facetEdit === false
                    ? renderInputField({item, updateData })
                    : renderText(item)}
                </ListItem> */}
              </ListItemWrapper>
            )
          })}
      </ListWrapper>
    </div>
  )
}
export { FormUI }
export default FormUI
