import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS
const initialState = {
  country: "United States",
}
export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.UPDATE_COUNTRY:
      return {
        ...state,
        country: actions.country,
      }
    default:
      return state
  }
}
