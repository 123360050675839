import React, { useState, useContext, useEffect } from "react"
import styled, { withTheme } from "styled-components/macro"
import {
  Box,
  Card,
  Grid,
  Divider,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from "@material-ui/core"
import {
  Typography,
  CardContent,
  GreenText,
  RedText,
  useStyles,
  BlackText,
} from "./styles"
import _, { values } from "lodash"
import * as IconsGallery from "@iconsGallery"
import { Chart } from "react-google-charts"
import { green, red } from "@material-ui/core/colors"
import { DashboardContext } from "@utils"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

// export const data = [
//     ["Severity", "Ticket"],
//     ["Low", 10],
//     ["Medium", 20],
//     ["High", 30],
// ];

const ChartWrapper = styled(Grid)`
  width: calc(100% - 20px);
  height: 340px;
  padding: 10px;
  position: relative;

  .cls-emptyKit {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 200px;
    width: 200px;
  }
`

const PieChart = (props) => {
  const classes = useStyles()
  const { showMore, setShowMore } = useContext(DashboardContext)
  const [piechartData, setPiechartData] = useState([])
  const {
    title = "Severity",
    isLoading = false,
    isColor = true,
    style = {},
    isIconEnable = false,
    icon: DynamicIcon = () => "",
    custom = false,
    summaryData = [],
    detailsData = [],
    keyName = "",
    keyId = "",
    keyValue = "",
    color = "",
    isSingleRow = false,
    piechartWidth = "",
    piechartHeight = "",
    tenantId = "",
    monitorReDirect = false,
  } = props

  const userProfileDetails =
    useSelector((state) => state.adminReducer.userProfileDetails) || {}

  const history = useHistory()

  // console.log("Adm PieChart", title, tenantId)

  let piechartOptions = {
    //sliceVisibilityThreshold: 0.2, // 20%
    //legend: 'none',
    legend: {
      position: "bottom", // You can change this to 'bottom', 'left', 'right', 'in', 'none', etc.
    },
    pieSliceText: "value",
    colors: ["#ef5359", "#ff7043", "#e7c66b", "#b9c37d"],
    pieHole: 0.5,
    is3D: false,
    tooltip: {
      trigger: "none",
    },
  }

  const severityHeaderContent = (value) => {
    const { severity_id = "", alert_count = 0 } = value
    let detailContent = _.filter(detailsData, function (o) {
      return o.severity_id === severity_id
    })
    switch (severity_id) {
      case 1:
        return (
          <>
            <h6 className={classes.pieChartListHeadCritical}>
              Critical ({alert_count})
            </h6>
            <ul className={classes.pieChartListbox}>
              {severityDetailContent(
                detailContent[0]?.[keyName] || [],
                severity_id,
                "Critical"
              )}
            </ul>
          </>
        )
      case 2:
        return (
          <>
            <h6 className={classes.pieChartListHeadHigh}>
              High ({alert_count})
            </h6>
            <ul className={classes.pieChartListbox}>
              {severityDetailContent(
                detailContent[0]?.[keyName] || [],
                severity_id,
                "High"
              )}
            </ul>
          </>
        )
      case 3:
        return (
          <>
            <h6 className={classes.pieChartListHeadMedium}>
              Medium ({alert_count})
            </h6>
            <ul className={classes.pieChartListbox}>
              {severityDetailContent(
                detailContent[0]?.[keyName] || [],
                severity_id,
                "Medium"
              )}
            </ul>
          </>
        )
      case 4:
        return (
          <>
            <h6 className={classes.pieChartListHeadLow}>Low ({alert_count})</h6>
            <ul className={classes.pieChartListbox}>
              {severityDetailContent(
                detailContent[0]?.[keyName] || [],
                severity_id,
                "Low"
              )}
            </ul>
          </>
        )
      case "":
        // do something
        break
    }
  }

  const severityDetailContent = (detailsValue, severityId, serverityName) => {
    return _.map(detailsValue, (values, index) => {
      // console.log("severityDetailContent", values, serverityName)
      return (
        <li className={classes.pieChartList}>
          {monitorReDirect ? (
            <a
              href={
                tenantId
                  ? `/${userProfileDetails?.roleType
                  }/alert/monitor/${encodeURIComponent(
                    tenantId
                  )}/${encodeURIComponent(
                    serverityName
                  )}/${encodeURIComponent(values?.location_id)}`
                  : `/${userProfileDetails?.roleType
                  }/alert/monitor/${encodeURIComponent(
                    values?.tenant_id
                  )}/${encodeURIComponent(
                    serverityName
                  )}/${encodeURIComponent("")}`
              }
            >
              {`${values?.[keyValue]} (${values?.alert_count})` || ""}
            </a>
          ) : (
            <a>{`${values?.[keyValue]} (${values?.alert_count})` || ""}</a>
          )}
        </li>
      )
    })
  }

  useEffect(() => {
    !_.isUndefined(summaryData) &&
      setPiechartData(piechartSeverityData(summaryData))
  }, [summaryData])

  const piechartSeverityData = (summaryData) => {
    let piechartArr = [["Severity", "Ticket"]]
    {
      _.map(summaryData, (value, i) => {
        piechartArr = [
          ...piechartArr,
          [value?.severity_name, value?.alert_count],
        ]
      })
    }
    return piechartArr
  }

  // const GreenTypo = isColor ? GreenText : BlackText
  // const RedTypo = isColor ? RedText : BlackText
  // const theme = useTheme()
  // const isXLDevice = useMediaQuery(theme.breakpoints.up("xl"))
  // let drawerWidth = showMore ? 67 : isXLDevice ? 250 : 230

  // let isSAASRole = _.includes(localStorage.getItem("sourceRole"), "SaaS-Site-Admin")
  //     || _.includes(localStorage.getItem("sourceRole"), "Sub-SaaS-Admin")

  // console.log("isSAASRole", isSAASRole)

  return (
    <>
      {isLoading ? (
        <>
          <Card className={classes.cardStyles} style={style}>
            <Grid container>
              <Grid item xs>
                <div>
                  <Box textAlign="center" justifyContent="space-between">
                    <Typography variant="h4" className={classes.values}>
                      <IconsGallery.DotLoading />
                    </Typography>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Card>
        </>
      ) : (
        <>
          {_.sumBy(summaryData, function (o) {
            return o.alert_count
          }) > 0 ? (
            <>
              <Card className={classes.cardStyles} style={style}>
                <Grid
                  container
                  direction={isSingleRow ? "row" : "column"}
                  xs={12}
                >
                  <Grid xs={isSingleRow ? 3 : 12} direction="row" item>
                    <Grid item>
                      <Typography
                        variant="h4"
                        style={{
                          paddingLeft: 16,
                          paddingTop: 16,
                          fontSize: 16,
                          fontWeight: "500",
                        }}
                      >
                        {title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Chart
                        chartType="PieChart"
                        data={piechartData}
                        options={piechartOptions}
                        width={piechartWidth}
                        height={piechartHeight}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={isSingleRow ? 9 : 12} item>
                    <div style={{ marginTop: "25px", padding: "20px" }}>
                      <Grid container spacing={2}>
                        {summaryData &&
                          summaryData?.length > 0 &&
                          _.map(summaryData, (value, index) => {
                            return (
                              <>
                                <Grid item xs={3}>
                                  <div className={classes.pieChartListCritical}>
                                    {severityHeaderContent(value)}
                                  </div>
                                </Grid>
                              </>
                            )
                          })}
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </>
          ) : (
            <>
              <Grid item>
                <Typography
                  variant="h4"
                  style={{
                    paddingLeft: 16,
                    paddingTop: 16,
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  {title}
                </Typography>
              </Grid>
              <ChartWrapper>
                <Grid className="cls-emptyKit">
                  <IconsGallery.EmptyGraph />
                  <Typography
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      marginTop: 20,
                      color: "var(--primaryColor)",
                    }}
                  >
                    No Data Available
                  </Typography>
                </Grid>
              </ChartWrapper>
            </>
          )}
        </>
      )}
    </>
  )
}
export default PieChart
export { PieChart }
