import React, { useState } from "react"
import * as FIXTURE from "./fixture"
import _ from "lodash"
const moment = require("moment")

function useOperationHoursKit(props) {
  const { maxTimeLimit = 3, defaultList, onUpdate = () => "", edit = false, errors = {}, touched = {} } = props
  const [initStartTime, setStartTime] = React.useState("1990-01-01T08:00:00.000Z");
  const [initEndTime, setEndTime] = React.useState("1990-01-01T10:00:00.000Z");
  const [getOperationHoursList, setOperationHoursList] = useState([])

  React.useEffect(() => {
    setOperationHoursList(defaultList)
  }, [defaultList])

  /**
   * 
   * @param Handle_Time_Change
   */
  const handleTimeChange = (eProps) => {
    let lcGetOperationHoursList = JSON.parse(
      JSON.stringify(getOperationHoursList)
    )
    const { e, type = "", dayName, timeIndex, weekIndex, dayIndex } = eProps
    const currentTime = moment(e).format("HH:mm");
    lcGetOperationHoursList[weekIndex][dayIndex]["times"][timeIndex][
      type
    ] = `1990-01-01T${currentTime}:00.000Z`;
    let updateTimeObj = lcGetOperationHoursList[weekIndex][dayIndex]["times"][timeIndex];
    let timeArr = lcGetOperationHoursList[weekIndex][dayIndex]["times"];
    let isUpdateTime = true;
    let cusStartTime = moment(updateTimeObj.time_from).unix();
    let cusEndTime = moment(updateTimeObj.time_to).unix();
    let arrTimeData = _.filter(timeArr,function(val,index) { return index !== timeIndex })
    if(cusStartTime > cusEndTime || cusStartTime === cusEndTime) {
      touched.days = false
      lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex]['errFrom'] = "Time missmatch";
      lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex]['errTo'] = "Time missmatch";
    } else {
      lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex]['errFrom'] = "";
      lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex]['errTo'] = "";
      if(timeArr.length > 1) {
        _.forEach(arrTimeData,function(item){
          let startTime = moment(item.time_from).unix();
          let endTime = moment(item.time_to).unix();
          if((startTime >= cusStartTime && startTime <= cusEndTime) || 
            (endTime >= cusStartTime && endTime <= cusEndTime) || 
            (endTime <= cusStartTime && endTime >= cusEndTime)) {
              isUpdateTime = false
          }
        })
        if(isUpdateTime) {
          touched.days = true
          lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex]['errFrom'] = '';
          lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex]['errTo'] = '';
        } else {
          touched.days = false
          lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex]['errFrom'] = "Time missmatch";
          lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex]['errTo'] = "Time missmatch";
        }
      } else {
        touched.days = true
      }
    }
    setOperationHoursList([...lcGetOperationHoursList])
    let lc = [...lcGetOperationHoursList]
    onUpdate(lc)
  }

  /**
   * 
   * @param Handle_Time_Delete
   */
  const handleTimeDelete = (eProps) => {
    touched.days = true
    const { e, type = "", dayName, timeIndex, weekIndex, dayIndex } = eProps
    let lcGetOperationHoursList = JSON.parse(
      JSON.stringify(getOperationHoursList)
    )
    lcGetOperationHoursList[weekIndex][dayIndex]["times"].pop(timeIndex);
    lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"].pop(timeIndex);
    if(lcGetOperationHoursList[weekIndex][dayIndex]["times"]?.length === 0) {
      lcGetOperationHoursList[weekIndex][dayIndex]["selected"] = false;
    } 
    setOperationHoursList([...lcGetOperationHoursList])
    let lc = [...lcGetOperationHoursList]
    onUpdate(lc)
  }

  /**
   * 
   * @param Handle_Time_Add
   */
  const handleTimeAdd = (eProps) => {
    const { e, type = "", dayName, timeIndex, weekIndex, dayIndex } = eProps
    let lcGetOperationHoursList = JSON.parse(
      JSON.stringify(getOperationHoursList)
    )
    lcGetOperationHoursList[weekIndex][dayIndex]["selected"] = true;
    let timesData = lcGetOperationHoursList[weekIndex][dayIndex]["times"];
    if(timesData.length > 0) {
      let isCreateTime = true;
      _.forEach(timesData,function(item){
        let startTime = moment(item.time_from).unix();
        let endTime = moment(item.time_to).unix();
        let cusStartTime = moment(initStartTime).unix();
        let cusEndTime = moment(initEndTime).unix();
        if((startTime >= cusStartTime && startTime <= cusEndTime) || 
          (endTime >= cusStartTime && endTime <= cusEndTime) || 
          (endTime <= cusStartTime && endTime >= cusEndTime)) {
            isCreateTime = false
        }
      })
      if(isCreateTime) {
        lcGetOperationHoursList[weekIndex][dayIndex]["times"].push({
          time_from: initStartTime,
          time_to: initEndTime,
        })
        lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"].push({
          errFrom: "",
          errTo: "",
        })
        touched.days = true
      } else {
        lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"].push({
          errFrom: "Time missmatch",
          errTo: "Time missmatch",
        })
        lcGetOperationHoursList[weekIndex][dayIndex]["times"].push({
          time_from: initStartTime,
          time_to: initEndTime,
        })
        
      }
    } else {
      lcGetOperationHoursList[weekIndex][dayIndex]["times"].push({
        time_from: initStartTime,
        time_to: initEndTime,
      })
      lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"].push({
        errFrom: "",
        errTo: "",
      })
      touched.days = true
    }
    setOperationHoursList([...lcGetOperationHoursList])
    let lc = [...lcGetOperationHoursList]
    onUpdate(lc)
  }

  return {
    getOperationHoursList,
    setOperationHoursList,
    handleTimeChange,
    handleTimeDelete,
    handleTimeAdd,
    maxTimeLimit,
    edit,
    errors
  }
}

export { useOperationHoursKit }
export default useOperationHoursKit
