import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  collapse: {
    backgroundColor: "var(--secondaryColor)",
    color: "var(--secondaryTextColor)",
    filter: "brightness(95%)",
  },

  chevronRighticon: {
    height: "24px",
    display: "grid",
    placeItems: "center",
    width: "24px",
    borderRadius: "100%",
    cursor: "pointer",
    position: "fixed",
    top: "52px",
    left: "54px",
    backgroundColor: "#F9F9FC",
    transition: "1s ease-in-out",
    zIndex: ({ isMobile }) => (isMobile ? "1302" : "1202"),
    boxShadow: "0px 0px 4px 0px #0000001C",
  },
  chevronLefticon: {
    height: "24px",
    display: "grid",
    placeItems: "center",
    width: "24px",
    cursor: "pointer",
    borderRadius: "100%",
    position: "fixed",
    top: "52px",
    transition: "1s ease-in-out",
    left: "238px",
    zIndex: ({ isMobile }) => (isMobile ? "1302" : "1202"),
    backgroundColor: "#F9F9FC",
    boxShadow: "0px 0px 4px 0px #0000001C",
    "@media only screen and (max-width: 1430px)": {
      left: "219px",
    },
  },
  tooltip: {
    backgroundColor: "var(--secondaryColor)",
    color: "#b7b7b7",
    width: "230px",
    borderRadius: "8px",
    marginLeft: "22px",
  },
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED",
    },
  },
  SearchFeildBox: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
}))
