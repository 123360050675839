import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { renderSVG } from "./renderProps"
import styled from "styled-components"

const LoyaltyTierMainWrapper = styled(Grid)`
  display: flex;
  align-items: center;
`
const LoyaltyTierIconWrapper = styled(Grid)`
  display: flex;
  margin-right: 20px;
`

function LoyaltyTier(props) {
  const { tierType = "Gold", tierLevel = "1" } = props
  return (
    <LoyaltyTierMainWrapper>
      <LoyaltyTierIconWrapper>{renderSVG(props)}</LoyaltyTierIconWrapper>
      <Grid>
        <Typography variant="h6" style={{fontWeight:'bold'}}>{tierType}</Typography>
        <Typography style={{ color: '#9E9E9E'}}>{`TIER-${tierLevel}`}</Typography>
      </Grid>
    </LoyaltyTierMainWrapper>
  )
}

export { LoyaltyTier }
export default LoyaltyTier
