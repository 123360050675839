import { makeStyles } from "@material-ui/core/styles";

const TableFilterStyles = makeStyles((theme) => ({
  drawer: {
    backgroundColor: "transparent",
  },
  headerText: {
    color: "black",
    fontSize: "16px",
    fontWeight: "500",
  },
  subHeaderText: {
    color: "#2F4858",
    fontSize: "16px",
    fontWeight: "500",
  },
  listText: {
    color: "black",
    fontSize: "16px",
    fontWeight: "400",
  },
  dropDownStyles: {
    marginRight: "20px",
    width: "190px !important",
  }
}));

export default TableFilterStyles;
