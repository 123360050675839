const columns = [
   {
      field: "attribute_name",
      title: "Attribute",
      editable: false,
      editor: "text",
      border: true,
      filter: false,
      show: true,
      min_width: 25
   },
   {
      field: "attribute_field_type",
      title: "Value Field Type",
      editable: false,
      editor: "text",
      border: true,
      filter: false,
      show: true,
      min_width: 18

   },
   {
      field: "attribute_values.name",
      iteratorName: "attribute_values",
      title: "Values",
      editable: false,
      editor: "text",
      border: true,
      filter: false,
      show: true,
      feildCircle: true,
      sortable: false,
      min_width: 15

   },
   {
      field: "defaultValue",
      iteratorName: "defaultValue",
      title: "Default",
      editable: false,
      editor: "text",
      switchCell: true,
      border: true,
      filter: false,
      show: true,
      feildCircle: true,
      sortable: false,
      min_width: 15

   }
]

export default columns