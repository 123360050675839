import { makeStyles, withStyles } from "@material-ui/core/styles"
import Styled from "styled-components"
import {
  Button,
  Divider,
  TableRow,
  TableCell,
  TableContainer,
  TextField,
  Typography,
  Grid,
  IconButton,
} from "@material-ui/core"

export const useMultiSearchStyles = makeStyles({
  AddNew: {
    minWidth: "275px",
    background: "#0000ff12",
    border: 0,
    textAlign: "center",
    outline: "none",
    borderRadius: "8px",
  },
  FormPopup: {
    paddingTop: "3px",
  },
  popupTitle: {
    paddingLeft: "30px",
    paddingBottom: "20px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  dialogPaper: {
    minHeight: "80vh",
    maxHeight: "80vh",
    minWidth: "800px",
    maxWidth: "800px",
    overflowX: "auto",
  },
})

export const StyledButton = withStyles((theme) => ({
  root: {
    width: "120px",
    float: "right",
    marginRight: "0px",
  },
}))(Button)

export const StyledDivider = withStyles((theme) => ({
  root: {
    marginTop: "24px",
    marginBottom: "5px",
  },
}))(Divider)

export const buttonWrapper = {
  position: "relative",
  padding: "2em",
  left: "53%",
  bottom: "-38%",
  transform: "translate(-50%,-50%)",
}

export const StyledTableRow = withStyles((theme) => ({
  selected: {
    backgroundColor: "#c3bfbf9e !important",
  },
}))(TableRow)
export const StyledTableCell = withStyles((theme) => ({
  root: {
    height: 6,
    padding: "2px",
    borderBottom: "transparent",
  },
}))(TableCell)

export const StyledTableContainer = withStyles((theme) => ({
  root: {
    minHeight: 280,
    paddingTop: "20px",
  },
}))(TableContainer)

export const StyledTextField = withStyles((theme) => ({
  textbox: {
    height: "5px !important",
    font: "arial",
  },
  root: {
    paddingLeft: "0px",
    width: "60%",
    borderRadius: "0px",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: `4px 0px 0px 4px`,
        borderRight: `0.5 px`,
      },
    },
  },
}))(TextField)

export const DialogHeadingWrapper = Styled(Grid)`
  padding-top: 10px
`
export const SearchBoxWrapper = Styled(Grid)`
  width: 80%;
  .cls-base-dropdown-kit{
    .MuiOutlinedInput-root{
      border-radius: 0px 4px 4px 0px;
      border-left: 0.5px;
    }
  }
`
export const PopWrapperGrid = Styled(Grid)`
  
`
export const ResultsWrapper = Styled(Typography)`
  paddingRight: 20px
`

export const CloseWrapper = { paddingTop: "10px", paddingLeft: "10px" }

export const StyledCloseIcon = withStyles((theme) => ({
  root: {
    cursor: "pointer",
    float: "right",
    marginBottom: "5px",
    padding: "0px",
    marginLeft: "20px !important",
  },
}))(IconButton)
// {
//   cursor: "pointer",
//   float: "right",
//   marginBottom: "5px",
//   padding: "0px",
//   marginLeft: "20px !important",
// }
