import {
  Box,
  colors,
  Container,
  Dialog,
  Divider,
  makeStyles,
  Tab,
  Tabs,
  Card,
  CardHeader,
  CardContent,
  Button,
  Chip,
  Grid,
  Typography,
  Menu,
  FormControlLabel,
  FormControl,
  FormGroup,
  Radio,
  RadioGroup,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  TableHead,
  TableContainer,
  Table,
} from "@material-ui/core"
import { green, red } from "@material-ui/core/colors"

import { default as MuiDialogContent } from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import { withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import React, { useState } from "react"
import * as Features from "@features"
import { MoreVertical } from "react-feather"
import styled from "styled-components/macro"
import _ from "lodash"
import * as IconsGallery from "@iconsGallery"
import {
  Loop as LoopIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons"
import { useStyles } from "./styles"

function TableListView(props) {
  const { title = "", rows = [], columns = [], style = {}, isLoading = false } =
    props || {}
  const classes = useStyles()

  return (
    <Card className={classes.cardStyles} style={style}>
      <Grid container>
        <Grid
          item
          md={5}
          style={{ padding: "10px", paddingLeft: 16, paddingTop: 16 }}
        >
          <Typography
            variant="body1"
            style={{
              color: "#000000",
              fontWeight: "500",
              fontSize: 16,
            }}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>

      <CardContent style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell> Levels </TableCell>
                  <TableCell> No of Devices </TableCell> */}
                {!_.isEmpty(columns) &&
                  columns.map((col, index) => {
                    const { color = "#000" } = col

                    return (
                      <TableCell style={{ color }} key={index}>
                        {col.columnName}
                      </TableCell>
                    )
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {columns.map((cols, indexCols) => {
                    const { color = "#000" } = cols

                    return (
                      <TableCell
                        key={indexCols}
                        style={{ color: color }}
                        component="th"
                        scope="row"
                      >
                        {cols.isIconShow && row[cols.id] === "60% - 100%" ? (
                          <img
                            className={classes.imageIcon}
                            src="/static/img/green.png" alt=""
                          />
                        ) : cols.isIconShow && row[cols.id] === "20% - 59%" ? (
                          <img
                            className={classes.imageIcon}
                            src="/static/img/yellow.png" alt=""
                          />
                        ) : (
                          cols.isIconShow && (
                            <img
                              className={classes.imageIcon}
                              src="/static/img/red.png" alt=""
                            />
                          )
                        )}
                        {isLoading ? (
                          <IconsGallery.DotLoading style={{ height: 20 }} />
                        ) : row[cols.id] ? (
                          row[cols.id]
                        ) : (
                          row[cols.id]
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  )
}

export default TableListView
export { TableListView }
