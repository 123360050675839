import React, { useEffect, useState } from "react"
import { withTheme } from "styled-components/macro"
import { makeStyles } from "@material-ui/core/styles"
import {
  Divider,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  TextField,
  InputLabel,
} from "@material-ui/core"
import * as ReduxHelper from "@reduxHelper"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import _ from "lodash"
import * as Adm from "@adm"
import { react } from "@babel/types"
import { async } from "q"

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    "& .MuiFormHelperText-contained": {
      margin: "0px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
  locSizeWidth: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "var(--primaryColor)",
    },
    "& .MuiOutlinedInput-root": { width: "100%", height: "36px" },
    "& .MuiFormHelperText-contained": {
      margin: "0px",
    },
  },
  mulSymbol: {
    fontSize: "18px",
    fontWeight: 400,
    padding: "5px",
    alignSelf: "flex-start",
  },
  errorMsgDiv: { width: "160px" },
  disabledTextInput: {
    "& input": {
      backgroundColor: "#f6f6f6",
    },
  },
  autoCompleteHeight: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        "borderColor": "var(--primaryColor)",
      }
    },
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
      width: "100%",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
  },
}))

function LocationTreeComponent(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    setFieldValue = () => { },
    errors = {},
    touched = {},
    isLegacy = true,
    edit,
    locationSize = {},
    locationType,
    locations,
    locationTypeError,
    locationSizeError,
    setLocationSize,
    setLocationType,
    setAnyChanges,
    isDetailView = false,
    setAllowPrompt = () => { },
    storeList = [],
    setStoreType = () => { },
    storeType = {},
    storeTypeError,
    SDCConfigured,
    isDesktop = false,
    isTablet = false,
    paymentGatewayList = [],
    paymentType = {},
    setPaymentType = () => { },
    paymentTypeError,
    accountList = [],
    accountType = "",
    setAccountType = () => { },
    accountTypeError,
    locationId = "",
    values = {}
  } = props || {};

  const [paymentOpen, setPaymentOpen] = useState(false);
  const [paymentModeOpen, setPaymentModeOpen] = useState(false);



  const getSelectedValue = (list, val, key = "id") =>
    _.find(list, (o) => _.get(o, key) === _.get(val, key, "-"))

  function onlyDotsAndNumbers(txt, event) {
    var charCode = event.which ? event.which : event.keyCode
    if (charCode === 46) {
      if (txt.value.indexOf(".") < 0) return true
      else return false
    }

    if (txt.value.indexOf(".") > 0) {
      var txtlen = txt.value.length
      var dotpos = txt.value.indexOf(".")
      if (txtlen - dotpos > 2) return false
    }

    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false

    return true
  }

  return (
    <Grid container row="true">
      {/* <Grid
        container
        item
        row="true"
        alignContent="center"
        alignItems="center"
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          padding: isDetailView ? "16px" : "8px 0px",
          height: edit ? "" : "60px",
          marginTop: isDetailView ? "" : "20px",
          borderBottom: isDetailView ? "1px solid #e5e5e5" : "",
          paddingLeft: "30px"
        }}
      > */}
      {/* <Grid
          item
          lg={isDetailView ? 2 : 3}
          md={isDetailView ? 3 : 3}
          sm={isDetailView ? 4 : 3}
          xs={isDetailView ? 4 : 12}
        >
          <Typography
            style={{
              fontWeight: "800",
              marginBottom: isDetailView ? "" : "16px",
            }}
            variant="h5"
          >
            {t(isLegacy ? "Location Type" : "Store Type")} {' '}
            <span style={{ color: "red" }}>{edit ? "*" : ""} </span>
          </Typography>
        </Grid> */}
      {/* <Grid
          item
          lg={isDetailView ? 4 : 5}
          md={6}
          sm={6}
          xs={isDetailView ? 8 : 12}
          style={isLegacy ? {} : { maxWidth: 400 }}
        >
          <Typography
            style={{
              fontWeight: "800",
              marginBottom: isDetailView ? "" : "16px",
            }}
            variant="h5"
          >
            {locationType?.name}
          </Typography> 

        </Grid>
      </Grid>
      {isDetailView ? <Divider /> : ""}*/}
      {locationType && locationType?.name === "Store" ? (
        <>
          <Grid
            container
            row="true"
            alignContent="center"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              padding: isDetailView ? "16px" : "8px 0px",
              height: edit ? "" : "60px",
              marginTop: isDetailView ? "" : "20px",
              borderBottom: isDetailView ? "1px solid #e5e5e5" : "",
              paddingLeft: "30px"
            }}
          >
            <Grid
              item
              lg={isDetailView ? 2 : 3}
              md={isDetailView ? 3 : 3}
              sm={isDetailView ? 4 : 3}
              xs={isDetailView ? 4 : 12}
            >
              <Typography
                style={{
                  fontWeight: "800",
                  marginBottom: isDetailView ? "" : "16px",
                }}
                variant="h5"
              >
                {t(isLegacy ? "Store Type" : "Store Control Type")}{' '}
                <span style={{ color: "red" }}>{edit ? "*" : ""} </span>
              </Typography>
            </Grid>
            <Grid
              item
              lg={isDetailView ? 4 : 5}
              md={6}
              sm={6}
              xs={isDetailView ? 8 : 12}
            >
              <Typography
                style={{
                  fontWeight: "800",
                  marginBottom: isDetailView ? "" : "16px",
                }}
                variant="h5"
              >
                {`Autonomous Store`}
              </Typography>

            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}

      {isDetailView && locationType && locationType?.name === "Store" && (
        <Divider />
      )}
      <Grid
        container
        item
        row="true"
        alignContent="center"
        alignItems="center"
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        style={{
          padding: isDetailView ? "16px" : "8px 0px",
          height: edit ? "" : "60px",
          marginTop: isDetailView ? "" : "20px",
          borderBottom: isDetailView ? "1px solid #e5e5e5" : "",
          paddingLeft: "30px"
        }}
      >
        <Grid
          item
          xl={isDetailView ? 2 : 3}
          lg={isDetailView ? 2 : 3}
          md={isDetailView ? 3 : 3}
          sm={isDetailView ? 4 : 3}
          xs={isDetailView ? 4 : 12}
        >
          <Typography
            style={{
              fontWeight: "800",
              marginBottom: isDetailView ? "" : "16px",
            }}
            variant="h5"
          >
            {t(isLegacy ? "Location Size" : "Store Size")}{''}
            <span style={{ color: "#0000008a", marginLeft: "5px" }}>
              (in Feet) {' '}
            </span>
            <span style={{ color: "red" }}>{edit ? "*" : ""} </span>
          </Typography>
        </Grid>
        <Grid
          item
          xl={8}
          lg={8}
          md={8}
          sm={8}
          xs={isDetailView ? 8 : 12}
          style={isLegacy ? {} : { maxWidth: 400 }}
        >
          {edit ? (
            <Grid
              container
              direction="row"
              alignContent="center"
              alignItems="center"
              item
              xl={12}
              lg={8}
              sm={12}
              xs={12}
              md={12}
            >
              {/* <div style={{ height: "30px" }}> */}
              <Grid
                item
                lg={isLegacy ? 4 : 4}
                sm={isLegacy ? 3 : 4}
                xs={isLegacy ? 4 : 4}
                md={isLegacy ? 3 : 4}
                xl={isLegacy ? 2 : 4}
                style={{ alignSelf: "flex-start" }}
              >
                <TextField
                  type="number"
                  placeholder="Length"
                  variant="outlined"
                  label="Length"
                  inputProps={{
                    minLength: 1,
                  }}
                  className={classes.locSizeWidth}
                  value={locationSize.lengthValue}
                  error={Boolean(touched.locationSizeLength && errors.locationSizeLength)}
                  helperText={touched.locationSizeLength && errors.locationSizeLength}

                  onChange={(event) => {
                    event.target.value =
                      event.target.value === ""
                        ? 0
                        : parseFloat(event.target.value)
                    if (
                      event.target.value.length < 0 ||
                      event.target.value.length > 10
                    ) {
                      return 0
                    }


                    setFieldValue("locationSizeLength", event.target.value)

                    if (
                      event.target.value === "" ||
                      /[0-9]/.test(event.target.value)
                    ) {
                      setLocationSize({
                        ...locationSize,
                        lengthValue:
                          event.target.value < 0 ? 0 : event.target.value,
                      })
                    }
                    if (locationSize.lengthValue !== null) {
                      setAllowPrompt(true)
                    }
                    if (isDetailView) {
                      setAnyChanges(true)
                    }
                    dispatch(
                      ReduxHelper.addLocationHierarchy({
                        locationType: locationType,
                        storeType: storeType,
                        locationSize: {
                          ...locationSize,
                          lengthValue:
                            event.target.value < 0 ? 0 : event.target.value,
                        },
                        paymentType: paymentType,
                        accountType: accountType,
                      })
                    )
                  }}
                  size="small"
                />
                {/* </div> */}
              </Grid>
              {/* <span className={classes.mulSymbol}>x</span> */}
              {/* <div style={{ height: "30px", }}> */}
              <Grid
                item
                lg={isLegacy ? 4 : 4}
                sm={isLegacy ? 3 : 4}
                xs={isLegacy ? 4 : 4}
                md={isLegacy ? 3 : 4}
                xl={isLegacy ? 2 : 4}
                style={{ alignSelf: "flex-start", padding: "0px 5px" }}
              >
                <TextField
                  type="number"
                  placeholder="Width"
                  label="Width"
                  variant="outlined"
                  inputProps={{
                    minLength: 1,
                  }}
                  error={Boolean(touched.locationSizeWidth && errors.locationSizeWidth)}
                  helperText={touched.locationSizeWidth && errors.locationSizeWidth}

                  className={classes.locSizeWidth}
                  value={locationSize.widthValue}

                  onChange={(event) => {
                    event.target.value =
                      event.target.value === ""
                        ? 0
                        : parseFloat(event.target.value)
                    if (
                      event.target.value.length < 0 ||
                      event.target.value.length > 10
                    ) {
                      return 0
                    }

                    setFieldValue("locationSizeWidth", event.target.value)
                    if (
                      event.target.value === "" ||
                      /[0-9]/.test(event.target.value)
                    ) {
                      setLocationSize({
                        ...locationSize,
                        widthValue:
                          event.target.value < 0 ? 0 : event.target.value,
                      })
                    }
                    if (locationSize.widthValue !== null) {
                      setAllowPrompt(true)
                    }
                    if (isDetailView) {
                      setAnyChanges(true)
                    }
                    dispatch(
                      ReduxHelper.addLocationHierarchy({
                        locationType: locationType,
                        storeType: storeType,
                        locationSize: {
                          ...locationSize,
                          widthValue:
                            event.target.value < 0 ? 0 : event.target.value,
                        },
                        paymentType: paymentType,
                        accountType: accountType,
                      })
                    )
                  }}
                  size="small"
                />
              </Grid>

              <Grid
                item
                lg={isLegacy ? 4 : 4}
                sm={isLegacy ? 3 : 4}
                xs={isLegacy ? 4 : 4}
                md={isLegacy ? 3 : 4}
                xl={isLegacy ? 2 : 4}
                style={{ alignSelf: "flex-start" }}
              >
                <TextField
                  type="number"
                  placeholder="Height"
                  label="Height"
                  variant="outlined"
                  inputProps={{
                    minLength: 1,
                  }}
                  className={classes.locSizeWidth}
                  value={locationSize.heightValue}
                  error={Boolean(touched.locationSizeHeight && errors.locationSizeHeight)}
                  helperText={touched.locationSizeHeight && errors.locationSizeHeight}

                  onChange={(event) => {
                    event.target.value =
                      event.target.value === ""
                        ? 0
                        : parseFloat(event.target.value)
                    if (
                      event.target.value.length < 0 ||
                      event.target.value.length > 10
                    ) {
                      return 0
                    }

                    setFieldValue("locationSizeHeight", event.target.value)
                    if (
                      event.target.value === "" ||
                      /[0-9]/.test(event.target.value)
                    ) {
                      setLocationSize({
                        ...locationSize,
                        heightValue:
                          event.target.value < 0 ? 0 : event.target.value,
                      })
                    }
                    if (locationSize.heightValue !== null) {
                      setAllowPrompt(true)
                    }
                    if (isDetailView) {
                      setAnyChanges(true)
                    }
                    if (event.target.value === 0) {
                      setTimeout(() => {
                        setLocationSize((e) => {
                          return {
                            ...e,
                            heightValue: 0,
                          }
                        })
                      }, 500)
                    }

                    dispatch(
                      ReduxHelper.addLocationHierarchy({
                        locationType: locationType,
                        storeType: storeType,
                        locationSize: {
                          ...locationSize,
                          heightValue:
                            event.target.value < 0 ? 0 : event.target.value,
                        },
                        paymentType: paymentType,
                        accountType: accountType,
                      })
                    )
                  }}
                  size="small"
                />
                {/* </div> */}
              </Grid>
              {/* <Typography variant="body1" style={{ paddingLeft: "10px", position: "relative" }}>
                {t("sq.ft.")}
              </Typography> */}
            </Grid>
          ) : (
            <Typography variant="body1" style={{ fontWeight: 500 }}>
              {`${locationSize.lengthValue} x ${locationSize.widthValue} x ${locationSize.heightValue}`}
            </Typography>
          )}
        </Grid>
      </Grid>

      {isDetailView ? <Divider /> : ""}
      {localStorage.getItem("tenantPaymentConfigEnable") === "true" &&
        locationType &&
        locationType?.name === "Store" && (
          <>

            <Grid
              container
              item
              row="true"
              alignContent="center"
              alignItems="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                padding: isDetailView ? "16px" : "8px 0px",
                height: edit ? "" : "60px",
                marginTop: isDetailView ? "" : "20px",
                borderBottom: isDetailView ? "1px solid #e5e5e5" : "",
                paddingLeft: "30px"
              }}
            >
              <Grid
                item
                lg={isDetailView ? 2 : 3}
                md={isDetailView ? 3 : 3}
                sm={isDetailView ? 4 : 3}
                xs={isDetailView ? 4 : 12}
              >
                <Typography
                  style={{
                    fontWeight: "800",
                    marginBottom: isDetailView ? "" : "16px",
                  }}
                  variant="h5"
                >
                  {t("Payment Type")}{' '}
                  <span style={{ color: "red" }}>{edit ? "*" : ""} </span>
                </Typography>
              </Grid>
              <Grid
                item
                lg={isDetailView ? 4 : 5}
                md={6}
                sm={6}
                xs={isDetailView ? 8 : 12}
              >
                {edit ? (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    size="small"
                  >
                    <Grid container direction="row">
                      <Adm.DropdownFilter
                        list={paymentGatewayList || []}
                        defaultValue={values?.paymentMethodType}
                        selectedValue={values?.paymentMethodType}
                        onChangeEvent={(e, val) => {

                          if (values?.paymentMethodType !== null) {
                            setAllowPrompt(true)
                          }
                          if (isDetailView) {
                            setAnyChanges(true)
                          }
                          setFieldValue("paymentMethodType", val)
                          setPaymentType(val)


                        }}
                        popupOpen={paymentOpen}
                        setPopupOpen={setPaymentOpen}
                        disableClearable={true}
                        classNameTextField={classes.autoCompleteHeight}
                        touchedControl={touched?.paymentMethodType}
                        errorControl={errors?.paymentMethodType}
                        placeholder={'Select Payment Type'}
                        style={{ width: '350px' }}
                      />
                    </Grid>
                  </FormControl>
                ) : (
                  <Typography
                    variant="body1"
                    style={{ padding: "19px 0px", fontWeight: 500 }}
                  >
                    {values?.paymentMethodType?.name}
                  </Typography>
                )}
              </Grid>
            </Grid>


          </>
        )}

      {isDetailView ? <Divider /> : ""}
      {localStorage.getItem("tenantPaymentConfigEnable") === "true" &&
        locationType &&
        locationType?.name === "Store" &&
        values?.paymentMethodType !== "" && (
          <>

            <Grid
              container
              item
              row="true"
              alignContent="center"
              alignItems="center"
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                padding: isDetailView ? "16px" : "8px 0px",
                height: edit ? "" : "60px",
                marginTop: isDetailView ? "" : "20px",
                borderBottom: isDetailView ? "1px solid #e5e5e5" : "",
                paddingLeft: "30px"
              }}
            >
              <Grid
                item
                lg={isDetailView ? 2 : 3}
                md={isDetailView ? 3 : 3}
                sm={isDetailView ? 4 : 3}
                xs={isDetailView ? 4 : 12}
              >
                <Typography
                  style={{
                    fontWeight: "800",
                    marginBottom: isDetailView ? "" : "16px",
                  }}
                  variant="h5"
                >
                  {t("Account Name")}{' '}
                  <span style={{ color: "red" }}>{edit ? "*" : ""} </span>
                </Typography>
              </Grid>
              <Grid
                item
                lg={isDetailView ? 4 : 5}
                md={6}
                sm={6}
                xs={isDetailView ? 8 : 12}
              >
                {edit ? (
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    size="small"
                  >
                    {/* {values?.paymentMode === "" ? (
                      <InputLabel>Select Account Name</InputLabel>
                    ) : null} */}
                    <Grid container direction="row">
                      <Adm.DropdownFilter
                        list={accountList || []}
                        defaultValue={values?.paymentMode}
                        selectedValue={values?.paymentMode}

                        onChangeEvent={(e, val) => {
                          setFieldValue("paymentMode", val)
                          setAnyChanges(true)
                        }}
                        popupOpen={paymentModeOpen}
                        setPopupOpen={setPaymentModeOpen}
                        disableClearable={true}
                        classNameTextField={classes.autoCompleteHeight}
                        touchedControl={touched?.paymentMode}
                        errorControl={errors?.paymentMode}
                        placeholder={'Select Payment Mode'}
                        style={{ width: '350px' }}
                      />
                    </Grid>
                  </FormControl>
                ) : (
                  <>
                    <Typography
                      variant="body1"
                      style={{ padding: "19px 0px", fontWeight: 500 }}
                    >
                      {values?.paymentMode?.name || values?.paymentMode}
                      {/* <span style={{ color: "#0000008a", marginLeft: "5px" }}>
                        {!_.isUndefined(values?.accountType?.mode)
                          ? "(" + values?.accountType?.mode + ")"
                          : ""}
                      </span> */}
                    </Typography>
                  </>
                )}
              </Grid>

            </Grid>


          </>
        )}
    </Grid>
  )
}

const LocationTree = withTheme(LocationTreeComponent)
export { LocationTree }
export default LocationTree
