import { makeStyles } from "@material-ui/core";
import './animations.css';

const chevronRight = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iOS41IiBmaWxsPSIjOTk5OTk5IiBzdHJva2U9IiM5OTk5OTkiLz4KPHBhdGggZD0iTTEwLjQxIDZMOSA3LjQxTDEzLjU4IDEyTDkgMTYuNTlMMTAuNDEgMThMMTYuNDEgMTJMMTAuNDEgNloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=`;
const chevronDown = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iOS41IiB0cmFuc2Zvcm09InJvdGF0ZSg5MCAxMiAxMikiIGZpbGw9IiM5OTk5OTkiIHN0cm9rZT0iIzk5OTk5OSIvPgo8cGF0aCBkPSJNMTggMTAuNDFMMTYuNTkgOUwxMiAxMy41OEw3LjQxIDlMNiAxMC40MUwxMiAxNi40MUwxOCAxMC40MVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=`;

const collapseIconChevron = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjU4NSA4LjU4NTA4TDEyIDEzLjE3MDFMNy40MTUgOC41ODUwOEw2IDEwLjAwMDFMMTIgMTYuMDAwMUwxOCAxMC4wMDAxTDE2LjU4NSA4LjU4NTA4WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==`;
const collapseIconMinus = `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiM5ODk4OTgiLz48ZyBzdHJva2U9IiNGRkYiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==`
const expandIconChevron = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuOTk5OTYgNkw4LjU4NDk2IDcuNDE1TDEzLjE3IDEyTDguNTg0OTYgMTYuNTg1TDkuOTk5OTYgMThMMTYgMTJMOS45OTk5NiA2WiIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==`
const expandIconPlus = `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+CiAgICA8Y2lyY2xlIGN4PSI5IiBjeT0iOSIgcj0iOCIgZmlsbD0iIzk4OTg5OCIvPgogICAgPGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjEuOSIgPgogICAgICAgIDxwYXRoIGQ9Ik00LjUgOWg5Ii8+CiAgICAgICAgPHBhdGggZD0iTTkgNC41djkiLz4KICAgIDwvZz4KPC9zdmc+`

export const useStyles = makeStyles((theme) => ({
  '&:root': (props) => {
    return ({
      '--transparentLines': props?.isViewMode ? 'red' : 'blue'
    })
  },
  "@keyframes pointFaded": {
    "0%, 18.999%, 100%": {
      "opacity": "0"
    },
    "20%": {
      "opacity": "1"
    }
  },
  "@keyframes arrowPulse": {
    "0%": {
      "transform": "translate(0, 0)",
      "opacity": "0"
    },
    "30%": {
      "transform": "translate(0, 300%)",
      "opacity": "1"
    },
    "70%": {
      "transform": "translate(0, 700%)",
      "opacity": "1"
    },
    "100%": {
      "transform": "translate(0, 1000%)",
      "opacity": "0"
    }
  },
  checkBoxRoot: {
    padding: 4
  },
  selectedNode: {
    border: `1px solid var(--primaryColor)`,
    boxShadow: `0px 0px 4px 0px var(--primaryColor)`,
    color: theme.palette.primary.main,
  },
  global: (props) => {
    let transparentLines = props?.isViewMode ? '#e0e0e000' : '#e0e0e0';
    let wrapperPadding = props?.isViewMode ? "2px 4px 2px 0" : "4px 4px 4px 0";
    let collapseIcon = props?.isViewMode ? collapseIconChevron : chevronDown; //collapseIconMinus;
    let expandIcon = props?.isViewMode ? expandIconChevron : chevronRight; //expandIconPlus;
    let rowWrapperLeftBorderProps = props?.isViewMode ? { borderLeft: 'none' } : { borderLeft: '1px solid #e0e0e0' };
    let rowWrapperContentBorder = props?.isViewMode ? 'none' : '1px solid #e0e0e0';
    let rowContentPadding = props?.isViewMode ? '0 5px 0 0px' : '0 5px 0 10px';
    let rowContentsMinWidth = props?.isViewMode ? 'unset' : '320px'
    return ({
      "& .ReactVirtualized__Collection": {},
      "& .ReactVirtualized__Collection__innerScrollContainer": {},
      "& .ReactVirtualized__Grid": {
        outline: "none",
      },
      "& .ReactVirtualized__Grid__innerScrollContainer": {
        overflow: "visible !important",
      },
      "& .ReactVirtualized__Table": {},
      "& .ReactVirtualized__Table__Grid": {},
      "& .ReactVirtualized__Table__headerRow": {
        fontWeight: "700",
        textTransform: "uppercase",
        display: "flex",
        fallbacks: [
          {
            display: "-ms-flexbox",
          },
          {
            display: "-webkit-box",
          },
        ],
        webkitBoxOrient: "horizontal",
        webkitBoxDirection: "normal",
        msFlexDirection: "row",
        flexDirection: "row",
        webkitBoxAlign: "center",
        msFlexAlign: "center",
        alignItems: "center",
      },
      "& .ReactVirtualized__Table__row": {
        display: "flex",
        fallbacks: [
          {
            display: "-ms-flexbox",
          },
          {
            display: "-webkit-box",
          },
        ],
        webkitBoxOrient: "horizontal",
        webkitBoxDirection: "normal",
        msFlexDirection: "row",
        flexDirection: "row",
        webkitBoxAlign: "center",
        msFlexAlign: "center",
        alignItems: "center",
      },
      "& .ReactVirtualized__Table__headerTruncatedText": {
        display: "inline-block",
        maxWidth: "100%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
      "& .ReactVirtualized__Table__headerColumn, .ReactVirtualized__Table__rowColumn": {
        marginRight: "10px",
        minWidth: "0px",
      },
      "& .ReactVirtualized__Table__rowColumn": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      "& .ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type": {
        marginLeft: "10px",
      },
      "& .ReactVirtualized__Table__sortableHeaderColumn": {
        cursor: "pointer",
      },
      "& .ReactVirtualized__Table__sortableHeaderIconContainer": {
        display: "flex",
        fallbacks: [
          {
            display: "-ms-flexbox",
          },
          {
            display: "-webkit-box",
          },
        ],
        webkitBoxAlign: "center",
        msFlexAlign: "center",
        alignItems: "center",
      },
      "& .ReactVirtualized__Table__sortableHeaderIcon": {
        webkitBoxFlex: "0",
        msFlex: "0 0 24px",
        flex: "0 0 24px",
        height: "1em",
        width: "1em",
        fill: "currentColor",
      },
      "& .ReactVirtualized__List": {},
      "& .rst__rowWrapper": {
        padding: wrapperPadding,
        height: "100%",
        boxSizing: "border-box",
      },
      "& .rst__rtl.rst__rowWrapper": {
        padding: "10px 0 10px 10px",
      },
      "& .rst__row": {
        height: "100%",
        whiteSpace: "nowrap",
        display: "flex",
      },
      "& .rst__borderLeft": {
        borderLeft: "1px solid #e0e0e0",
        borderRadius: "2px 0px 0px 2px"
      },
      "& .rst__row > *": {
        boxSizing: "border-box",
      },
      "& .rst__rowLandingPad, .rst__rowCancelPad": {
        border: "none !important",
        boxShadow: "none !important",
        outline: "none !important",
      },
      "& .rst__rowLandingPad > *, .rst__rowCancelPad > *": {
        opacity: "0 !important",
      },
      "& .rst__rowLandingPad::before, .rst__rowCancelPad::before": {
        backgroundColor: "lightblue",
        border: "3px dashed white",
        content: "''",
        position: "absolute",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        zIndex: "-1",
      },
      "& .rst__rowCancelPad::before": {
        backgroundColor: "#e6a8ad",
      },
      "& .rst__rowSearchMatch": {
        outline: "solid 3px #0080ff",
      },
      "& .rst__rowSearchFocus": {
        outline: "solid 3px #fc6421",
      },
      "& .rst__rowContents, .rst__rowLabel, .rst__rowToolbar, .rst__moveHandle, .rst__toolbarButton": {
        display: "inline-block",
        verticalAlign: "middle",
      },
      "& .rst__rowContents": {
        position: "relative",
        height: "100%",
        border: rowWrapperContentBorder,
        borderLeft: "none",
        padding: rowContentPadding,
        borderRadius: "0px 4px 4px 0px",
        minWidth: rowContentsMinWidth,
        maxWidth: "400px",
        flex: "1 0 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "white",
        boxShadow: "none",
        cursor: "pointer",
        '&.selectedNode': {
          border: `1px solid var(--primaryColor)`,
          boxShadow: `0px 0px 4px 0px var(--primaryColor)`,
          color: theme.palette.primary.main,
        },
        '&.unSelectableNode': {
          cursor: 'auto'
        }
      },
      "& .rst__rtl.rst__rowContents": {
        borderRight: "none",
        borderLeft: "solid #bbb 1px",
        padding: "0 10px 0 5px",
      },
      "& .rst__rowContentsDragDisabled": {
        borderLeft: rowWrapperContentBorder,
        borderRadius: 4
      },
      "& .rst__rtl.rst__rowContentsDragDisabled": {
        borderRight: "solid #bbb 1px",
        borderLeft: "solid #bbb 1px",
      },
      "& .rst__rowLabel": {
        flex: "0 1 auto",
        paddingRight: "20px",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      "& .rst__rtl.rst__rowLabel": {
        paddingLeft: "20px",
        paddingRight: "inherit",
      },
      "& .rst__rowToolbar": {
        flex: "0 1 auto",
        display: "flex",
      },
      "& .rst__moveHandle, .rst__loadingHandle": {
        height: "100%",
        width: "36px",
        background:
          "#fff\r\n    url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik00IDE1SDIxVjEzSDRWMTVaTTQgMTlIMjFWMTdINFYxOVpNNCAxMUgyMVY5SDRWMTFaTTQgNVY3SDIxVjVINFoiIGZpbGw9IiM5RTlFOUUiLz4KPC9zdmc+Cg==')\r\n    no-repeat center",
        border: "solid #e0e0e0 1px",
        cursor: "grab",
        borderRadius: "4px 0px 0px 4px",
        zIndex: "1",
        boxShadow: "none",
      },
      "& .rst__loadingHandle": {
        cursor: "default",
        background: "#d9d9d9",
      },
      "& .rst__loadingCircle": {
        width: "80%",
        height: "80%",
        margin: "10%",
        position: "relative",
      },
      "& .rst__loadingCirclePoint": {
        width: "100%",
        height: "100%",
        position: "absolute",
        left: "0",
        top: "0",
      },
      "& .rst__rtl.rst__loadingCirclePoint": {
        right: "0",
        left: "initial",
      },
      "& .rst__loadingCirclePoint::before": {
        content: "''",
        display: "block",
        margin: "0 auto",
        width: "11%",
        height: "30%",
        backgroundColor: "#fff",
        borderRadius: "30%",
        animation: "pointFade 800ms infinite ease-in-out both",
      },
      "& .rst__loadingCirclePoint:nth-of-type(1)": {
        transform: "rotate(0deg)",
      },
      "& .rst__loadingCirclePoint:nth-of-type(7)": {
        transform: "rotate(180deg)",
        fallbacks: [
          {
            transform: "rotate(180deg)",
          },
        ],
      },
      "& .rst__loadingCirclePoint:nth-of-type(1)::before, .rst__loadingCirclePoint:nth-of-type(7)::before": {
        animationDelay: "-800ms",
      },
      "& .rst__loadingCirclePoint:nth-of-type(2)": {
        transform: "rotate(30deg)",
      },
      "& .rst__loadingCirclePoint:nth-of-type(8)": {
        transform: "rotate(210deg)",
      },
      "& .rst__loadingCirclePoint:nth-of-type(2)::before, .rst__loadingCirclePoint:nth-of-type(8)::before": {
        animationDelay: "-666ms",
      },
      "& .rst__loadingCirclePoint:nth-of-type(3)": {
        transform: "rotate(60deg)",
      },
      "& .rst__loadingCirclePoint:nth-of-type(9)": {
        transform: "rotate(240deg)",
      },
      "& .rst__loadingCirclePoint:nth-of-type(3)::before, .rst__loadingCirclePoint:nth-of-type(9)::before": {
        animationDelay: "-533ms",
      },
      "& .rst__loadingCirclePoint:nth-of-type(4)": {
        transform: "rotate(90deg)",
      },
      "& .rst__loadingCirclePoint:nth-of-type(10)": {
        transform: "rotate(270deg)",
      },
      "& .rst__loadingCirclePoint:nth-of-type(4)::before, .rst__loadingCirclePoint:nth-of-type(10)::before": {
        animationDelay: "-400ms",
      },
      "& .rst__loadingCirclePoint:nth-of-type(5)": {
        transform: "rotate(120deg)",
      },
      "& .rst__loadingCirclePoint:nth-of-type(11)": {
        transform: "rotate(300deg)",
      },
      "& .rst__loadingCirclePoint:nth-of-type(5)::before, .rst__loadingCirclePoint:nth-of-type(11)::before": {
        animationDelay: "-266ms",
      },
      "& .rst__loadingCirclePoint:nth-of-type(6)": {
        transform: "rotate(150deg)",
      },
      "& .rst__loadingCirclePoint:nth-of-type(12)": {
        transform: "rotate(330deg)",
      },
      "& .rst__loadingCirclePoint:nth-of-type(6)::before, .rst__loadingCirclePoint:nth-of-type(12)::before": {
        animationDelay: "-133ms",
      },
      "& .rst__loadingCirclePoint:nth-of-type(13)": {
        transform: "rotate(360deg)",
      },
      "& .rst__loadingCirclePoint:nth-of-type(7)::before, .rst__loadingCirclePoint:nth-of-type(13)::before": {
        animationDelay: "0ms",
      },
      "& .rst__rowTitle": {
        fontFamily: "'Roboto', sans-serif",
        fontWeight: "600",
        fontSize: "14px",
      },
      "& .rst__rowTitleWithSubtitle": {
        display: "block",
        height: "0.8rem",
      },
      "& .rst__rowSubtitle": {
        fontFamily: "'Roboto', sans-serif",
        fontSize: "12px",
        lineHeight: "1",
      },
      "& .rst__collapseButton, .rst__expandButton": {
        appearance: "none",
        border: "none",
        position: "absolute",
        borderRadius: "100%",
        width: "16px",
        height: "16px",
        padding: "0",
        top: "50%",
        transform: "translate(-50%, -50%)",
        cursor: "pointer",
        boxShadow: "none",
      },
      "& .rst__rtl.rst__collapseButton, .rst__rtl.rst__expandButton": {
        transform: "translate(50%, -50%)",
      },
      "& .rst__collapseButton:focus, .rst__expandButton:focus": {
        outline: "none",
        boxShadow: "0 0 4px 2px #83bef9",
        backgroundSize: "24px",
        height: "20px",
        width: "20px",
      },
      "& .rst__collapseButton:hover:not(:active), .rst__expandButton:hover:not(:active)": {
        backgroundSize: "24px",
        height: "20px",
        width: "20px",
      },
      "& .rst__collapseButton": {
        background: `#fff url('${collapseIcon}')no-repeat center`,
      },
      "& .rst__expandButton": {
        background: `#fff url('${expandIcon}')no-repeat center`,
      },
      "& .rst__lineChildren": {
        height: "100%",
        display: "inline-block",
        position: "absolute",
      },
      "& .rst__lineChildren::after": {
        content: "''",
        position: "absolute",
        backgroundColor: transparentLines,//"var(--transparentLines)",
        width: "1px",
        left: "50%",
        bottom: "0",
        height: "10px",
      },
      "& .rst__rtl.rst__lineChildren::after": {
        right: "50%",
        left: "initial",
      },
      "& .rst__placeholder": {
        position: "relative",
        height: "68px",
        maxWidth: "unset",
        padding: "10px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .rst__placeholder, .rst__placeholder > *": {
        boxSizing: "border-box",
      },
      "& .rst__placeholder::before": {
        border: "3px dashed #d9d9d9",
        content: "''",
        position: "absolute",
        top: "8px",
        right: "unset",
        bottom: "unset",
        left: "8px",
        zIndex: "0",
        width: `calc(100% - 16px)`,
        height: `calc(100% - 16px)`,
      },
      "& .rst__placeholderLandingPad, .rst__placeholderCancelPad": {
        border: "none !important",
        boxShadow: "none !important",
        outline: "none !important",
      },
      "& .rst__placeholderLandingPad *, .rst__placeholderCancelPad *": {
        opacity: "0 !important",
      },
      "& .rst__placeholderLandingPad::before, .rst__placeholderCancelPad::before": {
        backgroundColor: "lightblue",
        borderColor: "white",
      },
      "& .rst__placeholderCancelPad::before": {
        backgroundColor: "#e6a8ad",
      },
      "& .rst__virtualScrollOverride": {
        overflow: "auto !important",
      },
      "& .rst__virtualScrollOverride *": {
        boxSizing: "border-box",
      },
      "& .rst__rtl .ReactVirtualized__Grid__innerScrollContainer": {
        direction: "rtl",
      },
      "& .rst__node": {
        minWidth: "100%",
        whiteSpace: "nowrap",
        position: "relative",
        textAlign: "left",
      },
      "& .rst__node.rst__rtl": {
        textAlign: "right",
      },
      "& .rst__nodeContent": {
        position: "absolute",
        top: "0",
        bottom: "0",
      },
      "& .rst__lineBlock, .rst__absoluteLineBlock": {
        height: "100%",
        position: "relative",
        display: "inline-block",
      },
      "& .rst__absoluteLineBlock": {
        position: "absolute",
        top: "0",
      },
      "& .rst__lineHalfHorizontalRight::before, .rst__lineFullVertical::after, .rst__lineHalfVerticalTop::after, .rst__lineHalfVerticalBottom::after": {
        position: "absolute",
        content: "''",
        backgroundColor: transparentLines, //"var(--transparentLines)",
      },
      "& .rst__lineHalfHorizontalRight::before": {
        height: "1px",
        top: "50%",
        right: "0",
        width: "50%",
      },
      "& .rst__rtl.rst__lineHalfHorizontalRight::before": {
        left: "0",
        right: "initial",
      },
      "& .rst__lineFullVertical::after, .rst__lineHalfVerticalTop::after, .rst__lineHalfVerticalBottom::after": {
        width: "1px",
        left: "50%",
        top: "0",
        height: "100%",
      },
      "& .rst__rtl.rst__lineFullVertical::after, .rst__rtl.rst__lineHalfVerticalTop::after, .rst__rtl.rst__lineHalfVerticalBottom::after": {
        right: "50%",
        left: "initial",
      },
      "& .rst__lineHalfVerticalTop::after": {
        height: "50%",
      },
      "& .rst__lineHalfVerticalBottom::after": {
        top: "auto",
        bottom: "0",
        height: "50%",
      },
      "& .rst__highlightLineVertical": {
        zIndex: "3",
      },
      "& .rst__highlightLineVertical::before": {
        position: "absolute",
        content: "''",
        backgroundColor: "#36c2f6",
        width: "8px",
        marginLeft: "-4px",
        left: "50%",
        top: "0",
        height: "100%",
      },
      "& .rst__rtl.rst__highlightLineVertical::before": {
        marginLeft: "initial",
        marginRight: "-4px",
        left: "initial",
        right: "50%",
      },
      "& .rst__highlightLineVertical::after": {
        content: "''",
        position: "absolute",
        height: "0",
        marginLeft: "-4px",
        left: "50%",
        top: "0",
        borderLeft: "4px solid transparent",
        borderRight: "4px solid transparent",
        borderTop: "4px solid white",
        animation: "arrowPulse 1s infinite linear both",
      },
      "& .rst__rtl.rst__highlightLineVertical::after": {
        marginLeft: "initial",
        marginRight: "-4px",
        right: "50%",
        left: "initial",
      },
      "& .rst__highlightTopLeftCorner::before": {
        zIndex: "3",
        content: "''",
        position: "absolute",
        borderTop: "solid 8px #36c2f6",
        borderLeft: "solid 8px #36c2f6",
        boxSizing: "border-box",
        height: "calc(50% + 4px)",
        top: "50%",
        marginTop: "-4px",
        right: "0",
        width: "calc(50% + 4px)",
      },
      "& .rst__rtl.rst__highlightTopLeftCorner::before": {
        borderRight: "solid 8px #36c2f6",
        borderLeft: "none",
        left: "0",
        right: "initial",
      },
      "& .rst__highlightBottomLeftCorner": {
        zIndex: "3",
      },
      "& .rst__highlightBottomLeftCorner::before": {
        content: "''",
        position: "absolute",
        borderBottom: "solid 8px #36c2f6",
        borderLeft: "solid 8px #36c2f6",
        boxSizing: "border-box",
        height: "calc(100% + 4px)",
        top: "0",
        right: "12px",
        width: "calc(50% - 8px)",
      },
      "& .rst__rtl.rst__highlightBottomLeftCorner::before": {
        borderRight: "solid 8px #36c2f6",
        borderLeft: "none",
        left: "12px",
        right: "initial",
      },
      "& .rst__highlightBottomLeftCorner::after": {
        content: "''",
        position: "absolute",
        height: "0",
        right: "0",
        top: "100%",
        marginTop: "-12px",
        borderTop: "12px solid transparent",
        borderBottom: "12px solid transparent",
        borderLeft: "12px solid #36c2f6",
      },
      "& .rst__rtl.rst__highlightBottomLeftCorner::after": {
        left: "0",
        right: "initial",
        borderRight: "12px solid #36c2f6",
        borderLeft: "none",
      },
    })
  },
  gridPlaceHolderSttyle: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",

    '@media(max-width:425px)': {
      width: "100%",
      // height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  },
  placeHolder: {
    width: "100%",
    display: "flex",
    gap: 3,
    padding: 8,
    justifyContent: "space-around",

    '@media(max-width:425px)': {
      width: "100%",
      // padding: "20px 5px",
      display: "flex",
      flexDirection: "column"
    },
  },
  ChevronLeft: {
    '@media(max-width:425px)': {
      // width: "100%",
      // padding: "20px 5px",
      display: "flex",
      flexDirection: "row",
      // backgroundColor:"yellow"
    },
  },
  SearchFeildBox: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      "borderColor": "var(--primaryColor)",
    },
  },
  appBar: {
    backgroundColor: "var(--primaryColor)"
  }

}))
