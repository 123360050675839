import React from "react"
import {
  Box,
  Card,
  Grid,
  Divider,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
} from "@material-ui/core"
import {
  Typography,
  CardContent,
  GreenText,
  RedText,
  LightRedText,
  YellowText,
  useStyles,
  BlackText,
} from "./styles"
import _ from "lodash"
import * as IconsGallery from "@iconsGallery"

const AlertsCard = (props) => {
  const classes = useStyles()

  const {
    isLoading = false,
    isColor = true,
    style = {},
    isIconEnable = false,
    icon: DynamicIcon = () => "",
    custom = false,
    list = [],
    color = "",
    header = "Alerts",
  } = props

  const GreenTypo = isColor ? GreenText : BlackText
  const RedTypo = isColor ? RedText : BlackText
  const LightRedTypo = isColor ? LightRedText : BlackText
  const YellowTypo = isColor ? YellowText : BlackText
  return (
    <Card className={classes.cardStyles} style={style}>
      <Grid container>
        <Grid>
          <Typography
            variant="h4"
            style={{
              paddingLeft: 16,
              paddingTop: 16,
              fontSize: 16,
              fontWeight: "500",
            }}
          >
            {header}
          </Typography>
        </Grid>
      </Grid>
      <CardContent>
        <Divider
          orientation="vertical"
          flexItem
          style={{
            marginLeft: "10px",
            marginRight: "10px",
          }}
        />

        {isLoading ? (
          <Grid container>
            <Grid item xs>
              <div>
                <Box textAlign="center" justifyContent="space-between">
                  <Typography variant="h4" className={classes.values}>
                    <IconsGallery.DotLoading />
                  </Typography>
                </Box>
              </div>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              style={{
                marginLeft: "10px",
                marginRight: "10px",
              }}
            />
            <Grid item xs>
              <div>
                <Box textAlign="center" justifyContent="space-between">
                  <Typography variant="h4" className={classes.values}>
                    <IconsGallery.DotLoading />
                  </Typography>
                </Box>
              </div>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              style={{
                marginLeft: "10px",
                marginRight: "10px",
              }}
            />
            <Grid item xs>
              <div>
                <Box textAlign="center" justifyContent="space-between">
                  <Typography variant="h4" className={classes.values}>
                    <IconsGallery.DotLoading />
                  </Typography>
                </Box>
              </div>
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              style={{
                marginLeft: "10px",
                marginRight: "10px",
              }}
            />
            <Grid item xs>
              <div>
                <Box textAlign="center" justifyContent="space-between">
                  <Typography variant="h4" className={classes.values}>
                    <IconsGallery.DotLoading />
                  </Typography>
                </Box>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <>
              <Grid item xs>
                <div>
                  <Box textAlign="center" justifyContent="space-between">
                    <Grid container>
                      {_.map(list, (x, i) => {
                        return (
                          <>
                            <Grid item xs>
                              <div>
                                <Box
                                  textAlign="center"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    variant="h4"
                                    className={classes.values}
                                  >
                                    {isLoading ? (
                                      <IconsGallery.DotLoading />
                                    ) : (
                                      <span>
                                        <span>{x?.alert_count}</span>
                                        {x?.suffix && (
                                          <span
                                            style={{
                                              fontSize: 12,
                                              marginLeft: 8,
                                            }}
                                          >
                                            {x?.suffix}
                                          </span>
                                        )}
                                      </span>
                                    )}
                                  </Typography>
                                  {x?.severity_id === 1 && (
                                    <RedTypo className={classes.textColorstyle}>
                                      {x?.severity_name}
                                    </RedTypo>
                                  )}
                                  {x?.severity_id === 2 && (
                                    <LightRedTypo
                                      className={classes.textColorstyle}
                                    >
                                      {x?.severity_name}
                                    </LightRedTypo>
                                  )}
                                  {x?.severity_id === 3 && (
                                    <YellowTypo
                                      className={classes.textColorstyle}
                                    >
                                      {x?.severity_name}
                                    </YellowTypo>
                                  )}
                                  {x?.severity_id === 4 && (
                                    <GreenTypo
                                      className={classes.textColorstyle}
                                    >
                                      {x?.severity_name}
                                    </GreenTypo>
                                  )}
                                </Box>
                              </div>
                            </Grid>
                            {!(list?.length === i + 1) && (
                              <Divider
                                orientation="vertical"
                                flexItem
                                style={{
                                  marginLeft: "10px",
                                  marginRight: "10px",
                                }}
                              />
                            )}
                          </>
                        )
                      })}
                    </Grid>
                  </Box>
                </div>
              </Grid>
            </>
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}
export default AlertsCard
export { AlertsCard }
