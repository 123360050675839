import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Paper,
  Typography,
} from "@material-ui/core"
import _, { forEach, values } from "lodash"
import {
  useAddAddressContentStyles,
  ButtonGroup,
  DialogWrapperKit,
  DialogWrapper,
  useEditAddressContentStyles,
} from "./styles"
import * as Features from "@features"
import * as Adm from "@adm"
import * as Utils from "@utils"
import { AddIcon, BorderColorOutlinedIcon, DeleteOutlined } from "@iconsGallery"
import {
  defaultCreateForm,
  defaultEditForm,
  defautEditListForm,
} from "./fixture"
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

export const AddressModal = (props) => {
  const {
    addressUpdate,
    isEdit = false,
    defautFormList = [],
    onChangeForm = () => { },
    onDelete = () => { },
    index = 0,
    setAllowPrompt = () => { },
    label = "",
    dialogTitle = "Add Address",
    isPlainObj = false,
    keyForValueReference = "",
  } = props
  const classes = useAddAddressContentStyles()
  const [formList, setFormList] = React.useState([])

  React.useEffect(() => {
    setFormList(defautFormList)
  }, [defautFormList])



  const [createNewForm, setCreateNewForm] = React.useState(false)
  const [billing, setBilling] = React.useState(false)
  const [shipping, setShipping] = React.useState(false)
  const [isDisabled, setDisabled] = React.useState(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [reDirect, setReDirect] = useState(false)

  const customRenderSubmitButton = ({ formUIProps }) => {
    const {
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      resetForm,
      touched,
      values,
      submitForm,
      renderSubmitButton,
      setFieldValue,
    } = formUIProps

    const cancelbuttonAttrs = {
      variant: "outlined",
      onClick: () => setCreateNewForm(false),
    }
    const savebuttonAttrs = {
      variant: "contained",
      type: "submit",
      color: "primary",
      disabled: isDisabled
    }
    const handleCustomCloseRedirect = () => {
      setOpenConfirmationDialog(false)
      setCreateNewForm(false)

    }

    return (
      <>
        <ConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          setReDirect={setReDirect}
          handleCustomCloseRedirect={() => handleCustomCloseRedirect(setFieldValue)}
          deleteContent="Are you sure you want to Delete ? Any unsaved changes will be lost."

        />
        <ButtonGroup>
          <Adm.Button {...cancelbuttonAttrs} onClick={() => { setOpenConfirmationDialog(true); }}>CANCEL</Adm.Button>
          <Adm.Button {...savebuttonAttrs}>SAVE</Adm.Button>
        </ButtonGroup>
      </>
    )
  }
  const customOnSubmit = (submitProps) => {
    setDisabled(true)
    const {
      name,
      line1,
      line2,
      line3,
      line4,
      city,
      country,
      zip_code_5,
      zip_code_4,
      billing,
      shipping,
      state,
    } = submitProps
    const configKit = {
      _id: Math.floor(100000 + Math.random() * 900000),
      name,
      line1,
      line2,
      line3,
      line4,
      city,
      country,
      state,
      zip_code_5,
      zip_code_4,
      default: false,
      billing,
      shipping,
      // zip_code_4: "",
      // zip_code_5_plus_4: "",
    }

    // setBilling(false)
    // setShipping(false)
    addressUpdate(configKit)
    setCreateNewForm(false)
  }

  let commonFormAttribute = {
    formList,
    label,
    setCreateNewForm,
    onSubmit: customOnSubmit,
    renderSubmitButton: customRenderSubmitButton,
    onChangeForm,
  }

  return (
    <>
      {isEdit === false && (
        <button
          className={classes.AddNew}
          onClick={() => {
            setCreateNewForm(true)
            setDisabled(false)
          }}
        >
          <AddIcon
            style={{ width: "50px", height: "50px", color: "#0058e9" }}
          />
        </button>
      )}
      {isEdit === true && (
        <Paper style={{ display: "flex", justifyContent: "flex-end" , cursor: "pointer"}}>
          <Paper
            className={classes.rightPaper}
            onClick={() => {
              setCreateNewForm(true)
              setDisabled(false)
            }}
          >
            <BorderColorOutlinedIcon
              style={{ fontSize: "20px", color: "#1565C0" }}
            />
          </Paper>
          <Paper
            style={{ marginLeft: "10px" }}
            className={classes.rightPaper}
            onClick={() => {
              onDelete({index})
            }}
          >
            <DeleteOutlined style={{ fontSize: "20px", color: "#f44336" }} />
          </Paper>
        </Paper>
      )}
      <DialogWrapperKit
        open={createNewForm}
        onClose={() => setCreateNewForm(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
        m={4}
      >
        <div className={classes.FormPopup}>
          <Typography className={classes.popupTitle}>{dialogTitle}</Typography>
          <Features.FormKit {...commonFormAttribute} isPlainObj={isPlainObj} keyForValueReference={keyForValueReference }/>
        </div>
      </DialogWrapperKit>
    </>
  )
}
