import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}
    
    const types = REDUX_CONSTANTS
         let loginFlag = false
            
            export default function reducer(state = loginFlag, actions) {
                    switch (actions.type) {
                        case types.LOGIN_FLAG:
                            return actions.loginFlag
                    
                              default:
                          return state
                       }
}