import { makeStyles, useTheme } from "@material-ui/core/styles"

export const useAddAddressContentStyles = makeStyles({
  AddNew: {
    background: "#0000ff12",
    border: 0,
    textAlign: "center",
    outline: "none",
    borderRadius: "8px",
  },
  FormPopup: {
    paddingTop: "20px",
  },
  popupTitle: {
    paddingLeft: "30px",
    paddingBottom: "20px",
    fontSize: "16px",
    fontWeight: "bold",
  },
})
